import {Button, Checkbox, Divider, Form, Input, Select, Space, message} from "antd";
import {InfoCircleOutlined, UnorderedListOutlined} from "@ant-design/icons";
import {BiSearchAlt} from "react-icons/bi";
import {truncate, without} from "lodash";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {selectItem, selectList, selectStatusAction} from "../../../../data/reselects/inboxSelector";

const SEARCH_TYPE = [{label: "Conversation", value: "CONVERSATION"}, {
    label: "Phone Number",
    value: "PHONE"
}, {label: "First/Last Name", value: "NAME"}]

const SearchComponent = props => {
    const [form] = Form.useForm(),
        [itemSelected, setItemSelected] = useState(""),
        [searchTypeActive, setSearchTypeActive] = useState(["CONVERSATION"]),
        itemList = useSelector(selectList());

    const onSubmit = val => {
        props?.onSearchConversation(val)
        // if (val?.search) {
        //
        // } else {
        //     message.error("Please input your keywords")
        // }
        // console.log(val)
    }

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label=""
                name="search_type"
                className="mb-0 d-none"
                initialValue={["CONVERSATION"]}
            >
                <Select
                    mode="multiple"
                    allowClear
                    style={{
                        width: '100%',
                    }}
                    placeholder="Please select"
                    // onChange={handleChange}
                    options={SEARCH_TYPE}
                />
            </Form.Item>
            <div className="hn__inbox--right">
                <div className="hn__inbox--right-search px-3">
                    <div className="clearfix">
                        <div className="float-start width-10">
                            <Button type="default" icon={<UnorderedListOutlined/>} size="large"/>
                        </div>
                        <div className="float-start ms-3 width-70">
                            <Form.Item
                                label=""
                                name="search"
                                className="mb-0"
                                // rules={[{
                                //     required: true,
                                //     message: 'Please input your name!'
                                // }]}
                            >
                                <Input size="large" allowClear placeholder="Search"
                                       prefix={<BiSearchAlt/>}/>
                            </Form.Item>
                        </div>
                        <div className="float-end width-10">
                            <Button type="primary" icon={<BiSearchAlt/>} size="large" htmlType="submit"/>
                        </div>
                    </div>
                    <div className="text-center">
                        <Space className="mt-2">
                            {SEARCH_TYPE.map((i, k) => {
                                return (
                                    <p key={k}
                                       onClick={e => {
                                           let items = [...searchTypeActive]
                                           if (items.includes(i?.value)) {
                                               items = without(items, i?.value)
                                           } else {
                                               items.push(i?.value)
                                           }
                                           if (items.length < 1) {
                                               setSearchTypeActive(["CONVERSATION"]);
                                               form.setFieldsValue({
                                                   search_type: ["CONVERSATION"]
                                               })
                                           } else {
                                               setSearchTypeActive(items);
                                               form.setFieldsValue({
                                                   search_type: items
                                               })
                                           }
                                       }}
                                       className={`mb-0 fs--12px hn__inbox--search-type-item ${searchTypeActive.includes(i?.value) ? "hn__inbox--search-type-item-active" : ""}`}>{i?.label}</p>
                                )
                            })}
                        </Space>
                    </div>
                </div>
                <Divider className="mb-0 mt-2"/>
                {/*<div>*/}
                {/*    <Alert*/}
                {/*        message="Success Tips"*/}
                {/*        type="success"*/}
                {/*        showIcon*/}
                {/*        closable*/}
                {/*    />*/}
                {/*</div>*/}
                <div className="hn__inbox--friend-wrapper">
                    {
                        ((itemList?.result || []).length > 0) && (itemList?.result || []).map((i, k) => {
                                return (
                                    <div
                                        className={`hn__inbox--item-friend ${i?.id === itemSelected ? "hn__inbox--item-friend-active" : ""}`}
                                        key={k} onClick={e => {
                                        props.setItemSelected(i?.id);
                                    }}>
                                        <div className="clearfix pt-3 pb-1">
                                            <div className="float-start p-t--2px">
                                                <Form.Item
                                                    label=""
                                                    name="friend_checked"
                                                    valuePropName="checked"
                                                    className=""
                                                    noStyle
                                                >
                                                    <Checkbox/>
                                                </Form.Item>
                                            </div>
                                            <div className="float-start ms-3">
                                                            <span className="fw-bold fs--16px">{truncate(i?.name, {
                                                                length: 17,
                                                                omission: "..."
                                                            })}</span>
                                            </div>
                                            <div className="float-end">
                                                        <span
                                                            className="color--disabled fs--14px font-weight-light">{i?.sms_conversation_last_update}</span>
                                                {
                                                    i?.sms_conversation_new &&
                                                    <InfoCircleOutlined className="icon-ant ms-2 color--primary"/>
                                                }
                                            </div>
                                        </div>
                                        <p className={`color--description fw--500 mb-3 ${i?.sms_conversation_new ? "color--primary" : ""}`}>{truncate(i?.message, {
                                            length: 30,
                                            omission: "..."
                                        })}</p>
                                        <Divider className="my-0"/>
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            </div>
        </Form>
    )
}

export default SearchComponent
