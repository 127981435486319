import * as Types from '../types/Template';
import {PAGINATION} from '../../constants/define';
// import HotelRequest from "../mapping/Request/HotelRequest";

const initialState = {
    isFetching: false,
    statusAction: {
        isSubmit: false,
        isUnlock: false,
        unlockField: "",
        isExport: false,
        isFetchingTemplateEmail: false
    },
    all: [],
    errors: [],
    // item: new HotelRequest().update(),
    data: {
        result: [],
        pagination: {
            page_size: PAGINATION.page_size,
            page_index: PAGINATION.page_index,
            total: PAGINATION.total,
            total_page: PAGINATION.total_page,
        }
    },
    templateEmailList: [],
    detail: {},
    master: {}
};

const getResult = (page) => {
    return {
        result: page.result || [],
        is_admin: page?.is_admin || 0,
        pagination: {
            page_size: page?.page_size || PAGINATION.page_size,
            page_index: page?.page_index || PAGINATION.page_index,
            total: page?.total || PAGINATION.total,
            total_page: page?.total_page || PAGINATION.total_page,
        }
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.TEMPLATE_READ_NOTIFY_SUCCESS:
        case Types.TEMPLATE_READ_NOTIFY_ACTION:
            return {...state};
        case Types.TEMPLATE_MASTER_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.TEMPLATE_MASTER_SUCCESS:
            return {...state, isFetching: false, master: action.master, statusAction: initialState.statusAction};
        case Types.TEMPLATE_LIST_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.TEMPLATE_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: getResult(action.list),
                statusAction: initialState.statusAction
            };
        case Types.TEMPLATE_DETAIL_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.TEMPLATE_DETAIL_SUCCESS:
            return {...state, isFetching: false, detail: action.detail, statusAction: initialState.statusAction};
        case Types.TEMPLATE_UPDATE_ACTION:
            return {...state, statusAction: {...initialState.statusAction, isSubmit: true}};
        case Types.TEMPLATE_UPDATE_SUCCESS:
            return {...state, statusAction: {...initialState.statusAction, isSubmit: false}};
        case Types.TEMPLATE_CREATE_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.TEMPLATE_CREATE_SUCCESS:
            return {...state, isFetching: false, statusAction: initialState.statusAction};

        case Types.TEMPLATE_CLONE_ACTION:
            return {...state, statusAction: {...initialState.statusAction, isSubmit: true}};
        case Types.TEMPLATE_CLONE_SUCCESS:
            return {...state, statusAction: {...initialState.statusAction, isSubmit: false}};
        case Types.TEMPLATE_DELETE_ACTION:
            return {...state, statusAction: {...initialState.statusAction, isSubmit: true}};
        case Types.TEMPLATE_DELETE_SUCCESS:
            return {...state, statusAction: {...initialState.statusAction, isSubmit: false}};
        case Types.TEMPLATE_EMAIL_LIST_ACTION:
            return {...state, statusAction: {...initialState.statusAction, isFetchingTemplateEmail: true}};
        case Types.TEMPLATE_EMAIL_LIST_SUCCESS:
            return {
                ...state,
                templateEmailList: action?.emailList || [],
                statusAction: {...initialState.statusAction, isFetchingTemplateEmail: false}
            };
        case Types.TEMPLATE_FAIL:
            return {...state, errors: action.errors, isFetching: false, statusAction: initialState.statusAction};
        case Types.TEMPLATE_INIT:
            return {
                ...state,
                data: action.params?.clearDetail ? state.data : initialState.data,
                detail: action.params?.clearDetail ? initialState.detail : state.detail,
                statusAction: initialState.statusAction
            };
        default:
            return state;
    }
}
