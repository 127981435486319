export const SMS_HISTORY_MASTER_ACTION = "SMS_HISTORY_MASTER_ACTION";
export const SMS_HISTORY_MASTER_SUCCESS = "SMS_HISTORY_MASTER_SUCCESS";

export const SMS_HISTORY_LIST_ACTION = "SMS_HISTORY_LIST_ACTION";
export const SMS_HISTORY_LIST_SUCCESS = "SMS_HISTORY_LIST_SUCCESS";

export const SMS_HISTORY_DETAIL_ACTION = "SMS_HISTORY_DETAIL_ACTION";
export const SMS_HISTORY_DETAIL_SUCCESS = "SMS_HISTORY_DETAIL_SUCCESS";

export const SMS_HISTORY_UPDATE_ACTION = "SMS_HISTORY_UPDATE_ACTION";
export const SMS_HISTORY_UPDATE_SUCCESS = "SMS_HISTORY_UPDATE_SUCCESS";

export const SMS_HISTORY_CREATE_ACTION = "SMS_HISTORY_CREATE_ACTION";
export const SMS_HISTORY_CREATE_SUCCESS = "SMS_HISTORY_CREATE_SUCCESS";

export const SMS_HISTORY_INIT = "SMS_HISTORY_INIT";
export const SMS_HISTORY_FAIL = "SMS_HISTORY_FAIL";
