import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import SubscriptionResponse from "../mapping/Response/SubscriptionResponse";

export function getSubscriptionCurrent() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_CURRENT,
        // params: removeObjectNull({ city_id }),
        parser: dataParser,
    }).get();
}

//
// export function actionContactList(params) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.CONTACT_LIST,
//         params: params,
//         parser: dataParser,
//     }).get();
// }
//
// export function actionContactExport(params) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.CONTACT_LIST,
//         params: params,
//         isDownload: true,
//         parser: data => data,
//     }).get();
// }
//
// export function actionContactDetail(id) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.CONTACT_GET_ITEM,
//         endpointParams: {id},
//         parser: data => new ContactResponse(data.data.result).exportDetail(),
//     }).get();
// }
//
// export function update(data) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.CONTACT_UPDATE,
//         endpointParams: {id: data?.id},
//     }).put(data);
// }
//
// export function create(data) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.CONTACT_CREATE,
//     }).post(data);
// }

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result?.current || []).map(item => new SubscriptionResponse(item).exportList()),
        // total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size)),
        recurring: new SubscriptionResponse(data.data.result).exportRecurring()?.item || []
    }
}
