import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

const listsColumn = {
    "0": "todo",
    "1": "inProgress",
    "2": "done",
};
export default class TaskResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.title = data?.title || "";
        this.status_explain = data?.status_explain || "";
        this.status = formatInt(data?.status);
        this.owner = data?.owner || "";
        this.created_date = data?.created_date || "";
        this.items = {
            "0": this.generateItems(data?.items, "0"),
            "1": this.generateItems(data?.items, "1"),
            "2": this.generateItems(data?.items, "2"),
        }
    }

    generateItems(items = {}, index) {
        let newItems = [];
        if ((Object?.keys(items).length > 0) && items[index]) {
            items[index].map(i => newItems.push({
                id: i?.id || "",
                title: i?.title || "",
                status: i?.status || 0,
                prefix: listsColumn[(i?.status + "")],
                coded: i?.coded || "",
                assignee: {
                    id: i?.assignee?.id || "",
                    name: i?.assignee?.name || "",
                    avatar: i?.assignee?.avatar || "",
                }
            }))
        }
        return newItems
    }

    exportList() {
        return {
            id: this.id,
            title: this.title,
            status_explain: this.status_explain,
            owner: this.owner,
            created_date: moment(this.created_date).format(DATE_TIME_FORMAT.SHORT_DATE_US),
        }
    }

    exportDetail() {
        return {
            id: this.id,
            title: this.title,
            status: this.status,
            status_explain: this.status_explain,
            created_date: moment(this.created_date).format(DATE_TIME_FORMAT.SHORT_DATE_US),
            owner: this.owner,
            items: this.items
        }
    }
}

export const column = ["title", "status_explain", "owner", "created_date"];

export const fieldDetailRemove = [];

export const filtersResponse = (filters) => {
    return removeObjectNullFull({
        search: filters?.search || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    })
}

export class TaskMasterResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.status = data?.STATUS
    }

    exportMaster() {
        return {
            status: this.status,
        }
    }
}
