import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {removeObjectNullFull} from "../../../utils/functions";

export default class AuthRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.first_name = data?.first_name || "";
        this.last_name = data?.last_name || "";
        this.email = data?.email || "";
        this.password = data?.password || "";
        this.otp = data?.otp || "";
        this.hash = data?.hash || "";
        this.w4_file = data?.w4_file || "";
        this.profile_update = {
            check_hash: data?.profile_update?.check_hash || "",
            marketing_email: data?.profile_update?.marketing_email || "",
            marketing_email_api_key: data?.profile_update?.marketing_email_api_key || "",
            sms_account_sid: data?.profile_update?.sms_account_sid || "",
            sms_token: data?.profile_update?.sms_token || "",
            sms_phone_number: data?.profile_update?.sms_phone_number || "",
            reiq_api_key: data?.profile_update?.reiq_api_key || "",
            reiq_first_name: data?.profile_update?.reiq_first_name || "",
            reiq_last_name: data?.profile_update?.reiq_last_name || "",
            reiq_email: data?.profile_update?.reiq_email || "",
            reiq_phone_number: data?.profile_update?.reiq_phone_number || "",
            reiq_extra_info: data?.profile_update?.reiq_extra_info?.join(","),
            sms_webhook: data?.profile_update?.sms_webhook || "",
            megalith_api_key: data?.profile_update?.megalith_api_key || "",
            reiq_address: data?.profile_update?.reiq_address || "",
            reiq_property_address: data?.profile_update?.reiq_property_address || "",
            reiq_property_city: data?.profile_update?.reiq_property_city || "",
            reiq_property_state: data?.profile_update?.reiq_property_state || "",
            reiq_property_zip_code: data?.profile_update?.reiq_property_zip_code || "",
            monday_address: data?.profile_update?.monday_address || "",
            monday_api_key: data?.profile_update?.monday_api_key || "",
            monday_board: data?.profile_update?.monday_board || "",
            monday_email: data?.profile_update?.monday_email || "",
            monday_extra_info: data?.profile_update?.monday_extra_info || "",
            monday_first_name: data?.profile_update?.monday_first_name || "",
            monday_last_name: data?.profile_update?.monday_last_name || "",
            monday_phone_number: data?.profile_update?.monday_phone_number || "",
            monday_property_address: data?.profile_update?.monday_property_address || "",
            monday_property_city: data?.profile_update?.monday_property_city || "",
            monday_property_state: data?.profile_update?.monday_property_state || "",
            monday_zip_code: data?.profile_update?.monday_zip_code || "",
            hubspot_api_key: data?.profile_update?.hubspot_api_key || "",
            hubspot_first_name: data?.profile_update?.hubspot_first_name || "",
            hubspot_last_name: data?.profile_update?.hubspot_last_name || "",
            hubspot_email: data?.profile_update?.hubspot_email || "",
            hubspot_phone_number: data?.profile_update?.hubspot_phone_number || "",
            hubspot_extra_info: data?.profile_update?.hubspot_extra_info || "",
            hubspot_address: data?.profile_update?.hubspot_address || "",
            hubspot_property_address: data?.profile_update?.hubspot_property_address || "",
            hubspot_property_city: data?.profile_update?.hubspot_property_city || "",
            hubspot_property_state: data?.profile_update?.hubspot_property_state || "",
            hubspot_property_zip_code: data?.profile_update?.hubspot_property_zip_code || "",

            podio_client_id: data?.profile_update?.podio_client_id || "",
            podio_client_secret: data?.profile_update?.podio_client_secret || "",
            podio_app_id: data?.profile_update?.podio_app_id || "",
            podio_app_token: data?.profile_update?.podio_app_token || "",
            podio_address: data?.profile_update?.podio_address || "",
            podio_property_address: data?.profile_update?.podio_property_address || "",
            podio_property_city: data?.profile_update?.podio_property_city || "",
            podio_property_state: data?.profile_update?.podio_property_state || "",
            podio_property_zip_code: data?.profile_update?.podio_property_zip_code || "",
            podio_first_name: data?.profile_update?.podio_first_name || "",
            podio_last_name: data?.profile_update?.podio_last_name || "",
            podio_email: data?.profile_update?.podio_email || "",
            podio_phone_number: data?.profile_update?.podio_phone_number || "",
            podio_extra_info: data?.profile_update?.podio_extra_info || "",
            podio_email_address_2: data?.profile_update?.podio_email_address_2 || "",
            podio_email_address_3: data?.profile_update?.podio_email_address_3 || "",
            podio_email_address_4: data?.profile_update?.podio_email_address_4 || "",
            podio_email_address_5: data?.profile_update?.podio_email_address_5 || "",
            podio_phone_number_2: data?.profile_update?.podio_phone_number_2 || "",
            podio_phone_number_3: data?.profile_update?.podio_phone_number_3 || "",
            podio_phone_number_4: data?.profile_update?.podio_phone_number_4 || "",
            podio_phone_number_5: data?.profile_update?.podio_phone_number_5 || "",
            podio_cell_phones_1: data?.profile_update?.podio_cell_phones_1 || "",
            podio_cell_phones_2: data?.profile_update?.podio_cell_phones_2 || "",
            podio_cell_phones_3: data?.profile_update?.podio_cell_phones_3 || "",
            podio_cell_phones_4: data?.profile_update?.podio_cell_phones_4 || "",
            podio_cell_phones_5: data?.profile_update?.podio_cell_phones_5 || "",
            podio_lead_type: data?.profile_update?.podio_lead_type || "",

            voice_api_sid: data?.profile_update?.voice_api_sid || "",
            voice_api_key: data?.profile_update?.voice_api_key || "",
            voice_twiml_app_sid: data?.profile_update?.voice_twiml_app_sid || "",
            voice_forward_phone_number: data?.profile_update?.voice_forward_phone_number || "",
            voice_webhook: data?.profile_update?.voice_webhook || "",
        }
    }

    exportUpdate() {
        return removeObjectNullFull({
            id: this.id,
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            password: this.password,
            w4_file: this.w4_file,
        })
    }

    exportPasswordRequest() {
        return {
            email: this.email
        }
    }

    exportPasswordReset() {
        return {
            password: this?.password,
            otp: this?.otp,
            hash: this?.hash,
        }
    }

    exportProfileConfigUpdate() {
        return {
            ...this.profile_update
        }
    }
}
