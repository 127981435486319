import { Alert, Statistic, Button, Card, Checkbox, Divider, Dropdown, Form, Input, Menu, Result, Space, Spin, Badge, Select } from "antd";
import { common } from "../../../data/mapping/Request/ContactRequest";
import React, { useEffect, useRef, useState } from "react";
import {
	SearchOutlined,
	SmileOutlined,
	UnorderedListOutlined,
	SmallDashOutlined,
	DownOutlined,
	EditOutlined,
	InfoCircleOutlined,
} from "@ant-design/icons";
import { ActionSend, ActionGetList, ActionGetItem, ActionInit } from "../../../data/hooks/inbox";
import { useSelector } from "react-redux";
import { selectList, selectItem, selectStatusAction } from "../../../data/reselects/inboxSelector";
import { truncate } from "lodash";
import Comment from "./components/Comment";
import TAG_DEFINE from "../../../constants/common";
import queryString from "query-string";
import ContactDetail from "./components/ContactDetail";
import ContactDetailNote from "./components/ContactDetailNote";
import { ActionInit as ActionContactInit, ActionGetItem as ActionContactGetItem, ActionOptIn, ActionOptOut } from "../../../data/hooks/contact";
import { selectItem as selectItemContact, selectFetching as selectContactFetching } from "../../../data/reselects/contactSelector";
import { BiSearchAlt } from "react-icons/bi";
import SearchComponent from "./components/SearchComponent";

const SEARCH_TYPE = [
	{ label: "Conversation", value: "CONVERSATION" },
	{
		label: "Phone Number",
		value: "PHONE",
	},
	{ label: "First/Last Name", value: "NAME" },
];
const Index = (props) => {
	const [form] = Form.useForm(),
		[itemSelected, setItemSelected] = useState(""),
		[sendMessageList, setSendMessageList] = useState([]),
		[searchTypeActive, setSearchTypeActive] = useState("CONVERSATION"),
		[filters, setFilters] = useState({}),
		[countdown, setCountdown] = useState(Date.now() + 1000 * 10),
		actionInit = ActionInit(),
		actionContactInit = ActionContactInit(),
		actionGetList = ActionGetList(),
		actionGetItem = ActionGetItem(),
		actionSend = ActionSend(),
		actionContactGetItem = ActionContactGetItem(),
		actionOptIn = ActionOptIn(),
		actionOptOut = ActionOptOut(),
		itemContactFetching = useSelector(selectContactFetching()),
		itemStatusAction = useSelector(selectStatusAction()),
		itemChat = useSelector(selectItem()),
		itemContactDetail = useSelector(selectItemContact()),
		itemList = useSelector(selectList());

	useEffect(() => {
		const { location } = props,
			newQuery = queryString.parse(location?.search);
		if (Object.keys(newQuery || {}).length > 0) {
			setItemSelected(newQuery?.p);
		}

		actionGetList();
		return () => {
			actionInit({ clearDetail: true });
		};
	}, []);

	const reloadAPI = () => {
		actionGetList(filters);
		if (itemSelected) {
			actionGetItem({ id: itemSelected });
		}
	};

	useEffect(() => {
		if (itemSelected) {
			actionContactInit({ clearDetail: true });
			actionGetItem({ id: itemSelected });
			actionContactGetItem({ id: itemSelected, props: props });
		}
	}, [itemSelected]);

	useEffect(() => {
		if (itemChat) {
			setSendMessageList([]);
			// scrollToBottom();
		}
	}, [itemChat]);

	const onSearchConversation = (val) => {
		// console.log(val)
		setFilters(val);
		actionGetList(val);
	};

	const onSendMessage = (val) => {
		let newMess = sendMessageList;
		newMess.push(val.send_message);
		setSendMessageList(newMess);
		actionSend({ ...val, id: itemSelected });
	};

	const menu = (
		<Menu>
			<Menu.Item>item 1</Menu.Item>
			<Menu.Item>item 2</Menu.Item>
		</Menu>
	);

	const chatRef = useRef(null);

	const scrollToBottom = () => {
		chatRef.current?.lastElementChild?.scrollIntoView({ behavior: "smooth" });
	};

	return (
		<div className="hn__inbox">
			<div className="row">
				<div className="col-12 col-lg-3">
					<SearchComponent setItemSelected={(e) => setItemSelected(e)} onSearchConversation={(e) => onSearchConversation(e)} />
				</div>
				<div className={`col-12 ${itemSelected ? "col-lg-5" : "col-lg-9"}`}>
					<div className="hn__inbox--center">
						{itemSelected && Object?.keys(itemChat || {}).length > 0 ? (
							<>
								<div className="hn__inbox--center-header">
									<div className="clearfix w-100">
										<div className="text-center">
											<p className="text-center fw-bold mb-1">{itemChat?.name}</p>
											<p className="text-center color--description mb-0">Phone Number: {itemChat?.phone_number || "(empty)"}</p>
										</div>
										{/*<div className="hn__inbox--center-header-action" id="header-action">*/}
										{/*    <Dropdown overlay={menu}*/}
										{/*              getPopupContainer={() => document.getElementById('header-action')}>*/}
										{/*        <a onClick={(e) => e.preventDefault()}>*/}
										{/*            <SmallDashOutlined className="cursorDefault"/>*/}
										{/*        </a>*/}
										{/*    </Dropdown>*/}
										{/*</div>*/}
									</div>
									<Divider className="mt-2 mb-0" />
								</div>
								<div className="hn__inbox--center-content pt-3 mt-2" ref={chatRef}>
									<section className="chat">
										<div className="messages-chat">
											{(itemChat?.message || []).length > 0 &&
												(itemChat?.message || []).map((i, k) => {
													if (i?.contact_phone_number !== i?.phone_number) {
														return (
															<div key={k}>
																<div className="message">
																	<div className="response">
																		<p className="text pre-wrap"> {i?.message}</p>
																	</div>
																</div>
																<p className="text-end time">{i?.created_at_string}</p>
															</div>
														);
													} else {
														return (
															<div key={k}>
																<div className="message">
																	<p className="text pre-wrap">{i?.message}</p>
																</div>
																<p className="time">{i?.created_at_string}</p>
															</div>
														);
													}
												})}
											{sendMessageList.length > 0 &&
												sendMessageList.map((i, k) => {
													return (
														<div className="hn__inbox--item-comment-right text-end" key={k}>
															<span className="mb-2 px-3 p-y--10px  bg--content border__radius--10px fs--16px">
																{i}
															</span>
														</div>
													);
												})}
											{/*<AlwaysScrollToBottom/>*/}
										</div>
									</section>
								</div>
								<Divider className="mt-0" />
								<div className="hn__inbox--center-comment">
									<Comment
										isDisabledField={Object.keys(itemContactDetail || {}).length < 1}
										itemChat={itemChat}
										onSendMessage={(e) => onSendMessage(e)}
									/>
								</div>
							</>
						) : (
							<div className="hn__inbox--no-seleted">
								<Result
									icon={<img src="/layout/logo.png" className="" width="200px" height="100px" />}
									status="success"
									title="Welcome to Megalith Solutions"
									subTitle="Here are some quick action to get you started"
									// extra={[
									//     <Button type="primary" key="console">
									//         Go Console
									//     </Button>,
									//     <Button key="buy">Buy Again</Button>,
									// ]}
								/>
							</div>
						)}
					</div>
				</div>
				{itemSelected ? (
					<div className="col-12 col-lg-4">
						<div className="hn__inbox--left px-3">
							<div className="row align-items-center">
								<div className="col-6">
									<p className="h5 fw-bold">{itemContactDetail?.name}</p>
								</div>
								<div className="col-6 text-end">
									<Badge
										onClick={(e) =>
											Object.keys(itemContactDetail || {}).length > 0
												? itemContactDetail?.is_opt_out
													? actionOptIn({
															query: { phone_numbers: [itemContactDetail?.phone_number] },
															id: itemSelected,
															isInbox: true,
													  })
													: actionOptOut({
															query: { phone_numbers: [itemContactDetail?.phone_number] },
															id: itemSelected,
															isInbox: true,
													  })
												: {}
										}
										className="site-badge-count-109 cursorPointer"
										count={`Change to Opt ${itemContactDetail?.is_opt_out ? "In" : "Out"}`}
										style={{ backgroundColor: itemContactDetail?.is_opt_out ? "#52c41a" : "#f50" }}
									/>
								</div>
								<div className="col-12">
									<p>Email: {itemContactDetail?.email}</p>
								</div>
							</div>
							<Divider className="my-2" />
							<div>
								<ProfileContactTabComponent
									isDisabledField={Object.keys(itemContactDetail || {}).length < 1}
									itemSelected={itemSelected}
								/>
							</div>
						</div>
					</div>
				) : null}
			</div>
			<Statistic.Countdown
				className="d-none"
				title="Countdown"
				value={countdown}
				onFinish={() => {
					reloadAPI();
					setCountdown(Date.now() + 1000 * 6);
				}}
			/>
		</div>
	);
};

export default Index;

const AlwaysScrollToBottom = () => {
	const elementRef = useRef();
	useEffect(() => elementRef.current.scrollIntoView());
	return <div ref={elementRef} />;
};

const ProfileContactTabComponent = (props) => {
	const [tabActive, setTabActive] = useState(0);
	return (
		<div>
			<div className="text-center my-4">
				{["Detail", "Notes"].map((i, k) => {
					return (
						<p
							onClick={(e) => setTabActive(k)}
							className={`w-25 mb-0 hn__inbox--profile-contact-tab ${tabActive === k ? "hn__inbox--profile-contact-tab-active" : ""}`}
						>
							{i}
						</p>
					);
				})}
			</div>
			<Divider className="mt-0 mb-4" />
			<div className="hn__inbox--content">
				{tabActive === 0 ? (
					<ContactDetail isDisabledField={props?.isDisabledField} itemSelected={props?.itemSelected} />
				) : (
					<ContactDetailNote isDisabledField={props?.isDisabledField} itemSelected={props?.itemSelected} />
				)}
			</div>
		</div>
	);
};
