import {Button, Form, Input, Select} from "antd";
import React, {useEffect} from "react";
import {Icon} from "../../../common";
import {ActionAdd as ActionAddTag, ActionGetList} from "../../../../data/hooks/tag";
import {selectFetching, selectList, selectStatusAction} from "../../../../data/reselects/tagSelector";
import {useSelector} from "react-redux";

const AddTags = props => {
    const [form] = Form.useForm(),
        // ============== ACTION ==============
        actionAddTag = ActionAddTag(),
        // ============== SELECT DATA ==============
        itemStatusAction = useSelector(selectStatusAction()),
        itemTagList = useSelector(selectList()),
        itemFetching = useSelector(selectFetching());

    useEffect(() => {
        if (itemStatusAction?.addTagSuccess) {
            form.resetFields();
        }
    }, [itemStatusAction]);

    const onSubmit = val => {
        actionAddTag({...val, ids: props?.listSelectedItem, filters: props?.paramsFilters})
    }

    const onRemove = val => {
        actionAddTag({
            ...form.getFieldsValue(),
            is_delete: 1,
            ids: props?.listSelectedItem,
            filters: props?.paramsFilters
        })
    }

    return (
        <Form
            layout="vertical"
            onFinish={onSubmit}
            form={form}
        >
            <div className="row">
                <div className="col-12">
                    <Form.Item
                        // rules={[{required: true, message: 'Please input your last name'}]}
                        label="Tag"
                        name="id"
                    >
                        <Select
                            // mode="multiple"
                            // disabled
                            allowClear
                            style={{
                                width: '100%',
                            }}
                        >
                            {
                                (itemTagList?.result || []).length > 0 && (itemTagList?.result || []).map((i, k) => {
                                    return (
                                        <Select.Option key={k} value={i?.id}>{i?.tag}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                </div>
                <div className="col-12">
                    <Form.Item className="text-end mb-0">
                        <Button type="primary"
                                htmlType="submit"
                                className="me-3 mb-0">
                            Add Tag {itemFetching &&
                            <Icon type="sync-outlined" spin/>}
                        </Button>
                        <Button danger
                                htmlType="button"
                                onClick={e => onRemove()}
                                className="me-3 mb-0 color--white">
                            Remove Tag {itemFetching &&
                            <Icon type="sync-outlined" spin/>}
                        </Button>
                        <Button htmlType="button"
                                className="mb-0"
                                onClick={() => {
                                    props?.setOnOpenAddTag(false)
                                }}>
                            Cancel
                        </Button>
                    </Form.Item>
                </div>
            </div>
        </Form>
    )
}

export default AddTags
