import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/taskAction'
import {tasProcessTaskListAction} from "../actions/taskAction";

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.taskInitStore(params)),
        [dispatch]
    )
}

export const ActionGetItem = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.taskDetailAction(params)),
        [dispatch]
    )
}

export const ActionMasterData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.taskMasterAction(params)),
        [dispatch]
    )
}

export const ActionGetList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.taskListAction(filters)),
        [dispatch]
    )
}

export const ActionCreate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.taskCreateAction(params)),
        [dispatch]
    )
}
export const ActionUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.taskUpdateAction(params)),
        [dispatch]
    )
}

export const ActionGetLisTaskProcess = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.taskProcessTaskListAction(filters)),
        [dispatch]
    )
}

export const ActionCreateTaskProcess = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.taskProcessTaskCreateAction(params)),
        [dispatch]
    )
}
export const ActionUpdateTaskProcess = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.taskProcessTaskUpdateAction(params)),
        [dispatch]
    )
}

export const ActionAdd = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.taskAddAction(params)),
        [dispatch]
    )
}
