import * as Types from '../types/Workflow';

export const workflowMasterAction = (filters) => ({type: Types.WORKFLOW_MASTER_ACTION, filters});
export const workflowMasterSuccess = (master) => ({
    type: Types.WORKFLOW_MASTER_SUCCESS,
    master
});

export const workflowListAction = (filters) => ({type: Types.WORKFLOW_LIST_ACTION, filters});
export const workflowListSuccess = (list) => ({
    type: Types.WORKFLOW_LIST_SUCCESS,
    list
});

export const workflowDetailAction = (params) => ({type: Types.WORKFLOW_DETAIL_ACTION, params});
export const workflowDetailSuccess = (detail) => ({
    type: Types.WORKFLOW_DETAIL_SUCCESS,
    detail
});

export const workflowUpdateAction = (params) => ({type: Types.WORKFLOW_UPDATE_ACTION, params});
export const workflowUpdateSuccess = (updateData) => ({
    type: Types.WORKFLOW_UPDATE_SUCCESS,
    updateData
});

export const workflowCreateAction = (params) => ({type: Types.WORKFLOW_CREATE_ACTION, params});
export const workflowCreateSuccess = (createData) => ({
    type: Types.WORKFLOW_CREATE_SUCCESS,
    createData
});

export const workflowDetailLogAction = (params) => ({type: Types.WORKFLOW_DETAIL_LOG_ACTION, params});
export const workflowDetailLogSuccess = (detailLog) => ({
    type: Types.WORKFLOW_DETAIL_LOG_SUCCESS,
    detailLog
});

export const workflowManualTriggerAction = (params) => ({type: Types.WORKFLOW_MANUAL_TRIGGER_ACTION, params});
export const workflowManualTriggerSuccess = (manualTrigger) => ({
    type: Types.WORKFLOW_MANUAL_TRIGGER_SUCCESS,
    manualTrigger
});

export const workflowInitStore = (params) => ({type: Types.WORKFLOW_INIT, params});
export const workflowClearStore = (params) => ({type: Types.WORKFLOW_FAIL, params});
