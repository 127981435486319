import * as Types from '../types/TeamPoint';

export const teamPointMasterAction = (filters) => ({type: Types.TEAM_POINT_MASTER_ACTION, filters});
export const teamPointMasterSuccess = (master) => ({
    type: Types.TEAM_POINT_MASTER_SUCCESS,
    master
});

export const teamPointListAction = (filters) => ({type: Types.TEAM_POINT_LIST_ACTION, filters});
export const teamPointListSuccess = (teamPoint) => ({
    type: Types.TEAM_POINT_LIST_SUCCESS,
    teamPoint
});

export const teamPointDetailAction = (filters) => ({type: Types.TEAM_POINT_DETAIL_ACTION, filters});
export const teamPointDetailSuccess = (teamPointDetail) => ({
    type: Types.TEAM_POINT_DETAIL_SUCCESS,
    teamPointDetail
});

export const teamPointUpdateAction = (params) => ({type: Types.TEAM_POINT_UPDATE_ACTION, params});
export const teamPointUpdateSuccess = (updateData) => ({
    type: Types.TEAM_POINT_UPDATE_SUCCESS,
    updateData
});

export const teamPointCreateAction = (params) => ({type: Types.TEAM_POINT_CREATE_ACTION, params});
export const teamPointCreateSuccess = (createData) => ({
    type: Types.TEAM_POINT_CREATE_SUCCESS,
    createData
});

export const teamPointInitStore = (params) => ({type: Types.TEAM_POINT_INIT, params});
export const teamPointClearStore = (params) => ({type: Types.TEAM_POINT_FAIL, params});
