import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class SubscriptionResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.product = data?.product || "";
        this.name = data?.name || "";
        this.valid_time = data?.valid_time || "";
        this.created_date = data?.created_date || "";
        this.raw_next_payment_date = data?.recurring?.raw_next_payment_date || "";
        this.next_payment_date = data?.recurring?.next_payment_date || "";
        this.item = this.generateRecurring(data?.recurring || []);
    }

    generateRecurring(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map(i => newItems.push({
                raw_next_payment_date: i?.raw_next_payment_date,
                next_payment_date: i?.next_payment_date,
                item: i?.item,
                total: i?.total,
            }))
        }
        return newItems
    }

    exportList() {
        return {
            id: this.id,
            product: this.product,
            name: this.name,
            valid_time: moment(this.valid_time).format(DATE_TIME_FORMAT.DATE_TIME),
            created_date: moment(this.created_date).format(DATE_TIME_FORMAT.SHORT_DATE_US),
        }
    }

    exportRecurring() {
        return {
            item: this.item
        }
    }
}

export const column = ["name", "email", "phone_number", "tag", "created_date", "company", "job_title", "owner", "status"];

export const fieldDetailRemove = [];

export const filtersResponse = (filters) => {
    return removeObjectNullFull({
        search: filters?.search || "",
        tag: filters?.tag || "",
        is_owned: convertBooleanToInt(filters?.is_owned),
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    })
}
