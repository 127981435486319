import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import TagResponse from "../mapping/Response/TagResponse";

export function actionTagList(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TAG_LIST,
        params: params,
        parser: dataParser,
    }).get();
}

export function actionTagDetail(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TAG_GET_ITEM,
        endpointParams: {id},
        parser: data => new TagResponse(data.data.result).exportDetail(),
    }).get();
}

export function update(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TAG_UPDATE,
        endpointParams: {id: data?.id},
    }).put(data);
}

export function create(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TAG_CREATE,
    }).post(data);
}

export function add(id, ids, is_delete) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TAG_ADD,
        endpointParams: {id},
    }).post({ids, is_delete});
}

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new TagResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
