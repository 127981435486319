import * as Types from '../types/SmsTemplate';

export const smsTemplateMasterAction = (filters) => ({type: Types.SMS_TEMPLATE_MASTER_ACTION, filters});
export const smsTemplateMasterSuccess = (master) => ({
    type: Types.SMS_TEMPLATE_MASTER_SUCCESS,
    master
});

export const smsTemplateListAction = (filters) => ({type: Types.SMS_TEMPLATE_LIST_ACTION, filters});
export const smsTemplateListSuccess = (list) => ({
    type: Types.SMS_TEMPLATE_LIST_SUCCESS,
    list
});

export const smsTemplateDetailAction = (params) => ({type: Types.SMS_TEMPLATE_DETAIL_ACTION, params});
export const smsTemplateDetailSuccess = (detail) => ({
    type: Types.SMS_TEMPLATE_DETAIL_SUCCESS,
    detail
});

export const smsTemplateUpdateAction = (params) => ({type: Types.SMS_TEMPLATE_UPDATE_ACTION, params});
export const smsTemplateUpdateSuccess = (updateData) => ({
    type: Types.SMS_TEMPLATE_UPDATE_SUCCESS,
    updateData
});

export const smsTemplateCreateAction = (params) => ({type: Types.SMS_TEMPLATE_CREATE_ACTION, params});
export const smsTemplateCreateSuccess = (createData) => ({
    type: Types.SMS_TEMPLATE_CREATE_SUCCESS,
    createData
});

export const smsTemplateInitStore = (params) => ({type: Types.SMS_TEMPLATE_INIT, params});
export const smsTemplateClearStore = (params) => ({type: Types.SMS_TEMPLATE_FAIL, params});
