export default {
    LOGIN: '/api/v1/login',
    REGISTER: '/api/v1/register',
    LOGOUT: '/',
    PROFILE: '/api/v1/profile',
    SSO_CODE: '/api/v1/sso-code',
    PROFILE_CONFIG: '/api/v1/profile-config',
    PROFILE_CONFIG_MASTER: '/api/v1/profile-config/master',
    PROFILE_CONFIG_UPDATE: '/api/v1/profile-config/update',
    REFRESH: 'api/admin/user/refresh',
    UPDATE_PROFILE: '/api/v1/profile/update',
    PASSWORD_REQUEST: '/api/v1/password/request',
    PASSWORD_RESET: '/api/v1/password/reset',
    AUTH_GENERAL: '/api/v1/general',

    CUSTOMER_LIST: '/api/v1/customer',
    CUSTOMER_CREATE: '/api/v1/customer',
    CUSTOMER_UPDATE: '/api/v1/customer/:id',
    CUSTOMER_GET_ITEM: '/api/v1/customer/:id',
    CUSTOMER_MASTER_DATA: '/api/v1/customer/master',

    TEMPLATE_LIST: '/api/v1/email-template',
    TEMPLATE_CREATE: '/api/v1/email-template',
    TEMPLATE_UPDATE: '/api/v1/email-template/:id',
    TEMPLATE_GET_ITEM: '/api/v1/email-template/:id',
    TEMPLATE_EMAIL_LIST: '/api/v1/email-template/admin-template',
    TEMPLATE_DELETE: '/api/v1/email-template/:id',
    TEMPLATE_CLONE: '/api/v1/email-template/clone',
    TEMPLATE_READ_NOTIFY: '/api/v1/read-notify/:id',

    EMAIL_HISTORY_LIST: '/api/v1/email-history',
    EMAIL_HISTORY_CREATE: '/api/v1/email-history',
    EMAIL_HISTORY_UPDATE: '/api/v1/email-history/:id',
    EMAIL_HISTORY_GET_ITEM: '/api/v1/email-history/:id',
    EMAIL_HISTORY_MASTER_DATA: '/api/v1/email-history/master',
    EMAIL_HISTORY_VERIFY_EMAIL: '/api/v1/email-history/verify-request',

    CONTACT_LIST: '/api/v1/contact',
    CONTACT_CREATE: '/api/v1/contact',
    CONTACT_HIGHLIGHT_CELL: '/api/v1/contact/row-tag/:id',
    CONTACT_UPDATE: '/api/v1/contact/:id',
    CONTACT_CREATE_NOTE: '/api/v1/contact/notes/:id',
    CONTACT_UPDATE_NOTE: '/api/v1/contact/notes/:id/:time',
    CONTACT_DELETE_NOTE: '/api/v1/contact/notes/:id/:time',
    CONTACT_IMPORT_MONDAY: '/api/v1/contact/import-monday',
    CONTACT_HUBSPOT_MONDAY: '/api/v1/contact/import-hubspot',
    CONTACT_PODIO_MONDAY: '/api/v1/contact/import-podio',
    CONTACT_DELETE: '/api/v1/contact/:id',
    CONTACT_GET_ITEM: '/api/v1/contact/:id',
    CONTACT_MASTER_DATA: '/api/v1/contact/master',
    CONTACT_OPT_OUT: '/api/v1/contact/opt-out',
    CONTACT_OPT_IN: '/api/v1/contact/opt-in',
    CONTACT_CHECK_DUPLICATE: '/api/v1/contact/check-duplicate',
    CONTACT_FILTERS_LIST: '/api/v1/master/filter/contact',
    CONTACT_FILTERS_SAVE: '/api/v1/master/filter/contact',
    CONTACT_FILTERS_DELETE: '/api/v1/master/filter/contact/:id',
    CONTACT_COLUMN_ACTION: '/api/v1/master/filter/:id',
    CONTACT_MASTER_PORTAL_FILTER: '/api/v1/master/portal-filter',

    TAG_LIST: '/api/v1/tag',
    TAG_CREATE: '/api/v1/tag',
    TAG_UPDATE: '/api/v1/tag/:id',
    TAG_ADD: '/api/v1/tag/:id',
    TAG_GET_ITEM: '/api/v1/tag/:id',

    CUSTOMER_TEAM_POINT: '/api/v1/customer/team-point',
    CUSTOMER_TEAM_POINT_DETAIL: '/api/v1/customer/team-point-detail',

    PACKAGE_PAYMENT_CREATE_ORDER: '/api/v1/subscription/order',

    // Fast CMA
    ADDRESS_LOOKUP: '/api/v1/fast-cma/address-lookup',
    FIND_COMPARABLES: '/api/v1/fast-cma/find-comparables',
    CMA_EXPORT_PDF: '/api/v1/fast-cma/export-report',

    BILL_DETAIL: '/api/v1/billing-info',

    CART_DETAIL: '/api/v1/subscription/cart',
    CART_CREATE: '/api/v1/subscription/order',
    CART_UPDATE: '/api/v1/subscription/cart',
    CART_REMOVE: '/api/v1/subscription/cart/:id',

    CONTACT_UPLOAD_FILE: '/api/v1/contact/import',
    UPLOAD_FILE: '/api/v1/upload',

    INVOICE_LIST: '/api/v1/subscription/invoice',

    SUBSCRIPTION_CURRENT: '/api/v1/subscription/current',

    PLANS_PRICING_MASTER: '/api/v1/subscription/product',

    SMS_TEMPLATE_LIST: '/api/v1/sms-template',
    SMS_TEMPLATE_CREATE: '/api/v1/sms-template',
    SMS_TEMPLATE_UPDATE: '/api/v1/sms-template/:id',
    SMS_TEMPLATE_GET_ITEM: '/api/v1/sms-template/:id',

    SMS_HISTORY_LIST: '/api/v1/sms-history',
    SMS_HISTORY_CREATE: '/api/v1/sms-history',
    SMS_HISTORY_UPDATE: '/api/v1/sms-history/:id',
    SMS_HISTORY_GET_ITEM: '/api/v1/sms-history/:id',
    SMS_HISTORY_MASTER_DATA: '/api/v1/sms-history/master',

    INBOX_LIST: '/api/v1/sms-conversation',
    INBOX_DETAIL: '/api/v1/sms-conversation/:id',
    INBOX_SEND: '/api/v1/sms-conversation/:id',

    WORKFLOW_MASTER: '/api/v1/workflow/master',
    WORKFLOW_LIST: '/api/v1/workflow',
    WORKFLOW_CREATE: '/api/v1/workflow',
    WORKFLOW_MANUAL_TRIGGER: '/api/v1/workflow/:id/exec',
    WORKFLOW_DETAIL_LOG: '/api/v1/workflow/:id/log',
    WORKFLOW_UPDATE: '/api/v1/workflow/:id',
    WORKFLOW_GET_ITEM: '/api/v1/workflow/:id',

    PAGE_BUILDERS_LIST_TEMPLATE: '/api/v1/website/template',
    PAGE_BUILDERS_GET_ITEM: '/api/v1/website',
    PAGE_BUILDERS_UPDATE: '/api/v1/website',

    EMBEDDED_LIST: '/api/v1/form',
    EMBEDDED_CREATE: '/api/v1/form',
    EMBEDDED_UPDATE: '/api/v1/form/:id',
    EMBEDDED_GET_ITEM: '/api/v1/form/:id',
    EMBEDDED_MASTER: '/api/v1/form/master',

    VOICE_GET_VERIFY: '/api/v1/voice-call-history/token',
    VOICE_CONFIG: '/api/v1/voice-call-history/update-voice-config',


    TASK_LIST: '/api/v1/task',
    TASK_CREATE: '/api/v1/task',
    TASK_UPDATE: '/api/v1/task/:id',
    TASK_ADD: '/api/v1/task/:id',
    TASK_GET_ITEM: '/api/v1/task/:id',
    TASK_CREATE_PROCESS_TASK: '/api/v1/task/:id/item',
    TASK_UPDATE_PROCESS_TASK: '/api/v1/task/:id/item/:idItem',
}
