import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/teamsAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.teamsInitStore(params)),
        [dispatch]
    )
}

export const ActionGetListTeams = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.teamsListAction(filters)),
        [dispatch]
    )
}

export const ActionGetItem = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.teamsDetailAction(params)),
        [dispatch]
    )
}

export const ActionMasterData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.teamsMasterAction(params)),
        [dispatch]
    )
}

export const ActionCreateTeams = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.teamsCreateAction(params)),
        [dispatch]
    )
}
export const ActionUpdateTeams = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.teamsUpdateAction(params)),
        [dispatch]
    )
}
