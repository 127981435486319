export const TRIGGER_TYPE = [
    {title: "Time Trigger", value: "TIME"}]
export const PERIOD = [
    {value: "DAILY", title: "Daily"},
    {value: "WEEKLY", title: "Weekly"},
    {value: "MONTHLY", title: "Monthly"},
    {value: "YEARLY", title: "Yearly"},
    {value: "TIME SKIPPING", title: "Time Skipping"}
]
export const DAY_OF_WEEK = [
    {title: "Monday", value: 0},
    {title: "Tuesday", value: 1},
    {title: "Wednesday", value: 2},
    {title: "Thursday", value: 3},
    {title: "Friday", value: 4},
    {title: "Saturday", value: 5},
    {title: "Sunday", value: 6},
]

export const SKIP_TYPE = [
    {value: "DAY", title: "Day"},
    {value: "WEEK", title: "Week"},
    {value: "MONTH", title: "Month"},
    {value: "YEAR", title: "Year"},
]

export const ACTION_TYPE = [
    {value: "SMS", title: "SMS"},
    {value: "EMAIL", title: "EMAIL"},
    {value: "REIQ_HUBSPOT", title: "Daily Sync Realestate IQ to Hubspot"},
    {value: "REIQ_MONDAY", title: "Daily Sync Realestate IQ to Monday"},
    {value: "REIQ_PODIO", title: "Daily Sync Realestate IQ to Podio"},
    {value: "ADD_TAG", title: "Add Tag"},
    {value: "UPDATE_TAG", title: "Update tag"},
]

export const STATUS = [
    {value: 0, title: "Inactive"},
    {value: 1, title: "Active"},
]

export const GROUP_FIELD = {
    SOURCE: ["tag"],
    RULE: ["trigger_type", "Period", "time", "day_of_week", "day_of_month", "month_of_year", "skip_value", "skip_type"],
    ACTION: ["type", "template"]
}
