import * as Types from '../types/Task';

export const taskMasterAction = (filters) => ({type: Types.TASK_MASTER_ACTION, filters});
export const taskMasterSuccess = (master) => ({
    type: Types.TASK_MASTER_SUCCESS,
    master
});

export const taskDetailAction = (params) => ({type: Types.TASK_DETAIL_ACTION, params});
export const taskDetailSuccess = (detail) => ({
    type: Types.TASK_DETAIL_SUCCESS,
    detail
});

export const taskListAction = (filters) => ({type: Types.TASK_LIST_ACTION, filters});
export const taskListSuccess = (list) => ({
    type: Types.TASK_LIST_SUCCESS,
    list
});

export const taskUpdateAction = (params) => ({type: Types.TASK_UPDATE_ACTION, params});
export const taskUpdateSuccess = (updateData) => ({
    type: Types.TASK_UPDATE_SUCCESS,
    updateData
});

export const taskCreateAction = (params) => ({type: Types.TASK_CREATE_ACTION, params});
export const taskCreateSuccess = (createData) => ({
    type: Types.TASK_CREATE_SUCCESS,
    createData
});
export const taskProcessTaskListAction = (filters) => ({type: Types.TASK_PROCESS_TASK_LIST_ACTION, filters});
export const taskProcessTaskListSuccess = (listProcessTask) => ({
    type: Types.TASK_PROCESS_TASK_LIST_SUCCESS,
    listProcessTask
});

export const taskProcessTaskUpdateAction = (params) => ({type: Types.TASK_PROCESS_TASK_UPDATE_ACTION, params});
export const taskProcessTaskUpdateSuccess = (updateProcessTask) => ({
    type: Types.TASK_PROCESS_TASK_UPDATE_SUCCESS,
    updateProcessTask
});

export const taskProcessTaskCreateAction = (params) => ({type: Types.TASK_PROCESS_TASK_CREATE_ACTION, params});
export const taskProcessTaskCreateSuccess = (createProcessTask) => ({
    type: Types.TASK_PROCESS_TASK_CREATE_SUCCESS,
    createProcessTask
});

export const taskAddAction = (params) => ({type: Types.TASK_ADD_ACTION, params});
export const taskAddSuccess = (addData) => ({
    type: Types.TASK_ADD_SUCCESS,
    addData
});

export const taskInitStore = (params) => ({type: Types.TASK_INIT, params});
export const taskClearStore = (params) => ({type: Types.TASK_FAIL, params});
