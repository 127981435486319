import * as Types from '../types/Tag';

export const tagMasterAction = (filters) => ({type: Types.TAG_MASTER_ACTION, filters});
export const tagMasterSuccess = (master) => ({
    type: Types.TAG_MASTER_SUCCESS,
    master
});

export const tagListAction = (filters) => ({type: Types.TAG_LIST_ACTION, filters});
export const tagListSuccess = (list) => ({
    type: Types.TAG_LIST_SUCCESS,
    list
});

export const tagDetailAction = (params) => ({type: Types.TAG_DETAIL_ACTION, params});
export const tagDetailSuccess = (detail) => ({
    type: Types.TAG_DETAIL_SUCCESS,
    detail
});

export const tagUpdateAction = (params) => ({type: Types.TAG_UPDATE_ACTION, params});
export const tagUpdateSuccess = (updateData) => ({
    type: Types.TAG_UPDATE_SUCCESS,
    updateData
});

export const tagCreateAction = (params) => ({type: Types.TAG_CREATE_ACTION, params});
export const tagCreateSuccess = (createData) => ({
    type: Types.TAG_CREATE_SUCCESS,
    createData
});

export const tagAddAction = (params) => ({type: Types.TAG_ADD_ACTION, params});
export const tagAddSuccess = (addData) => ({
    type: Types.TAG_ADD_SUCCESS,
    addData
});

export const tagInitStore = (params) => ({type: Types.TAG_INIT, params});
export const tagClearStore = (params) => ({type: Types.TAG_FAIL, params});
