import {Button, Form, Select, Space} from "antd";
import {common} from "../../../../data/mapping/Request/EmailHistoryRequest";
import {Icon} from "../../../common";
import React from "react";
import {ActionCreate} from "../../../../data/hooks/emailHistory";
import {useSelector} from "react-redux";
import {selectErrors, selectStatusAction} from "../../../../data/reselects/emailHistorySelector";

const ModalSendMail = props => {
    const [form] = Form.useForm();

    const onCreate = (val) => {
        props?.actionCreate(val)
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onCreate}
            // onValuesChange={onValuesChange}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <div className="row bg-white align-items-center">
                <div className="col-12">
                    <Form.Item
                        label="Tag"
                        name="tag_id"
                        rules={[{required: true, message: 'Tag is required.'}]}
                    >
                        <Select
                            // mode="multiple"
                            // disabled
                            allowClear
                            style={{
                                width: '100%',
                            }}
                        >
                            {
                                Object?.keys(props?.itemMasterData?.tag || {}).length > 0 && Object?.keys(props?.itemMasterData?.tag || {}).map((i, k) => {
                                    return (
                                        <Select.Option key={k} value={i}>{props?.itemMasterData?.tag[i]}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                </div>
                <div className="col-12">
                    <Form.Item
                        label="Template"
                        name="template_id"
                        rules={[{required: true, message: 'Template is required.'}]}
                    >
                        <Select
                            // mode="multiple"
                            // disabled
                            allowClear
                            style={{
                                width: '100%',
                            }}
                        >
                            {
                                Object?.keys(props?.itemMasterData?.template || {}).length > 0 && Object?.keys(props?.itemMasterData?.template || {}).map((i, k) => {
                                    return (
                                        <Select.Option key={k}
                                                       value={i}>{props?.itemMasterData?.template[i]}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                </div>
                <div className="col-12 text-end">
                    <Space>
                        <Form.Item className="mb-0" label="">
                            <Button type="default" htmlType="button" onClick={e => props.onCancel(false)}>
                                Cancel
                            </Button>
                        </Form.Item>
                        <Form.Item className="mb-0" label="">
                            <Button type="primary" htmlType="submit">
                                Send {props?.isFetching && <Icon type="sync-outlined" spin/>}
                            </Button>
                        </Form.Item>
                    </Space>
                </div>
            </div>
        </Form>
    )
}
export default ModalSendMail
