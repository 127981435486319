import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/plansPricingAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.plansPricingInitStore(params)),
        [dispatch]
    )
}

export const ActionGetList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.plansPricingListAction(filters)),
        [dispatch]
    )
}

export const ActionGetItem = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.plansPricingDetailAction(params)),
        [dispatch]
    )
}

export const ActionMasterData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.plansPricingMasterAction(params)),
        [dispatch]
    )
}

export const ActionCreate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.plansPricingCreateAction(params)),
        [dispatch]
    )
}

export const ActionUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.plansPricingUpdateAction(params)),
        [dispatch]
    )
}

export const ActionRemove = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.plansPricingRemoveAction(params)),
        [dispatch]
    )
}
