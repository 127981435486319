import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class EmailHistoryRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.tag_id = data?.tag_id || "";
        this.template_id = data?.template_id || "";
        this.verify_email = {
            email: data?.email || "",
            nickname: data?.nickname || "",
            name: data?.name || "",
            reply_email: data?.reply_email || "",
            reply_name: data?.reply_name || "",
            address_1: data?.address_1 || "",
            address_2: data?.address_2 || "",
            state: data?.state || "",
            city: data?.city || "",
            country: data?.country || "",
            zip: data?.zip || "",
        }
    }

    exportCreate() {
        return {
            tag_id: this.tag_id,
            template_id: this.template_id,
        }
    }

    exportUpdate() {
        return {
            // id: this.id,
            // title: this.title,
            // template: this.template,
        }
    }

    exportVerifyEmail() {
        return {
            ...this.verify_email
        }
    }

}

export const common = {
    ACTION_FIELD: {
        tag_id: "tag_id",
        template_id: "template_id",
    },
}

export const filtersParams = (filters) => {
    return removeObjectNullFull({
        search: filters?.search || "",
        tag: filters?.tag || "",
        is_owned: filters?.is_owned,
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
    })
}

