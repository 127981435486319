import {Button, Form, Input, Select, Space} from "antd";
import React, {useEffect} from "react";
import {ActionGetList} from "../../../../data/hooks/smsTemplate";
import {useSelector} from "react-redux";
import {selectList} from "../../../../data/reselects/smsTemplateSelector";
import {COUNTRY_CODE} from "../../../../constants/define";

const Comment = props => {
    const [form] = Form.useForm(),
        getList = ActionGetList(),
        itemList = useSelector(selectList());

    useEffect(() => {
        getList({page_size: 1000})
    }, []);

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={e => {
                // console.log(9999, e)
                props?.onSendMessage(e)
                form.resetFields()
            }}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label=""
                name="send_message"
                className=""
                noStyle
            >
                <Input.TextArea
                    disabled={props?.isDisabledField}
                    maxLength={160}
                    placeholder="Type your message"/>
            </Form.Item>
            <div className="clearfix py-2">
                <div className="float-start w-75">
                    <div className="clearfix">
                        <p className="float-start pt-1">Templates:</p>
                        <div className="float-start w-75 ms-2">
                            <Select
                                disabled={props?.isDisabledField}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                style={{
                                    width: '100%',
                                }}
                                onChange={e => form.setFieldsValue({send_message: e})}
                            >
                                {
                                    Object?.keys(props?.itemChat?.template || {}).length > 0 && Object?.keys(props?.itemChat?.template || {}).map((i, k) => {
                                        return (
                                            <Select.Option key={k} value={props?.itemChat?.template[i]}>{i}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="float-end text-end">
                    <Space>
                        {/*<p className="color--description">{(form.getFieldValue("send_message") || []).length} / 160</p>*/}
                        <Button htmlType="submit" type="link" disabled={props?.isDisabledField}>Send</Button>
                    </Space>
                </div>
            </div>
        </Form>
    )
}

export default Comment
