import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/SmsHistory';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/smsHistoryService';
import * as FunctionAction from '../actions/smsHistoryAction';
import {message} from "antd";
import SmsHistoryRequest, {filtersParams} from "../mapping/Request/SmsHistoryRequest";
import TAG_DEFINE from "../../constants/common";

function* actionGetListSmsHistory() {
    yield takeLatest(Types.SMS_HISTORY_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(filtersParams(action.filters) || {});
        const result = yield call(FunctionService.actionSmsHistoryList, params);
        yield put(FunctionAction.smsHistoryListSuccess(result));
    }, errorHandle(Types.SMS_HISTORY_FAIL)))
}

function* actionGetItem() {
    yield takeLatest(Types.SMS_HISTORY_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionSmsHistoryDetail, action.params.id);
        yield put(FunctionAction.smsHistoryDetailSuccess(result));
    }, errorHandle(Types.SMS_HISTORY_FAIL)))
}

function* actionGetMasterSmsHistory() {
    yield takeLatest(Types.SMS_HISTORY_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.masterSmsHistory, action.params);
        yield put(FunctionAction.smsHistoryMasterSuccess(result));
    }, errorHandle(Types.SMS_HISTORY_FAIL)))
}

function* createAction() {
    yield takeLatest(Types.SMS_HISTORY_CREATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new SmsHistoryRequest(action.params?.request).exportCreate();
        const result = yield call(FunctionService.create, data);
        message.success(result);
        yield put(FunctionAction.smsHistoryCreateSuccess(result));
        // action.params.props.history.goBack();
        yield put(FunctionAction.smsHistoryListAction(action?.params?.filters));
    }, errorHandle(Types.SMS_HISTORY_FAIL)))
}

function* updateAction() {
    yield takeLatest(Types.SMS_HISTORY_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new SmsHistoryRequest(action.params).exportUpdate();
        yield call(FunctionService.update, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.smsHistoryDetailAction({id: action.params.id}));
    }, errorHandle(Types.SMS_HISTORY_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        console.log("===> ", e)
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* smsHistorySaga() {
    yield all([
        fork(actionGetListSmsHistory),
        fork(createAction),
        fork(actionGetMasterSmsHistory),
        fork(actionGetItem),
        fork(updateAction),
    ])
}
