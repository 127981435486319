import * as Types from '../types/Template';

export const templateMasterAction = (filters) => ({type: Types.TEMPLATE_MASTER_ACTION, filters});
export const templateMasterSuccess = (master) => ({
    type: Types.TEMPLATE_MASTER_SUCCESS,
    master
});

export const templateListAction = (filters) => ({type: Types.TEMPLATE_LIST_ACTION, filters});
export const templateListSuccess = (list) => ({
    type: Types.TEMPLATE_LIST_SUCCESS,
    list
});

export const templateDetailAction = (params) => ({type: Types.TEMPLATE_DETAIL_ACTION, params});
export const templateDetailSuccess = (detail) => ({
    type: Types.TEMPLATE_DETAIL_SUCCESS,
    detail
});

export const templateUpdateAction = (params) => ({type: Types.TEMPLATE_UPDATE_ACTION, params});
export const templateUpdateSuccess = (updateData) => ({
    type: Types.TEMPLATE_UPDATE_SUCCESS,
    updateData
});

export const templateCreateAction = (params) => ({type: Types.TEMPLATE_CREATE_ACTION, params});
export const templateCreateSuccess = (createData) => ({
    type: Types.TEMPLATE_CREATE_SUCCESS,
    createData
});

export const templateDeleteAction = (params) => ({type: Types.TEMPLATE_DELETE_ACTION, params});
export const templateDeleteSuccess = (deleteData) => ({
    type: Types.TEMPLATE_DELETE_SUCCESS,
    deleteData
});

export const templateCloneAction = (params) => ({type: Types.TEMPLATE_CLONE_ACTION, params});
export const templateCloneSuccess = (cloneData) => ({
    type: Types.TEMPLATE_CLONE_SUCCESS,
    cloneData
});

export const templateEmailListAction = (filters) => ({type: Types.TEMPLATE_EMAIL_LIST_ACTION, filters});
export const templateEmailListSuccess = (emailList) => ({
    type: Types.TEMPLATE_EMAIL_LIST_SUCCESS,
    emailList
});

export const templateReadNotifyAction = (params) => ({
    type: Types.TEMPLATE_READ_NOTIFY_ACTION,
    params
});
export const templateReadNotifySuccess = (readNotify) => ({
    type: Types.TEMPLATE_READ_NOTIFY_SUCCESS,
    readNotify
});

export const templateInitStore = (params) => ({type: Types.TEMPLATE_INIT, params});
export const templateClearStore = (params) => ({type: Types.TEMPLATE_FAIL, params});
