import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class MembershipPlansResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
    }

}

export const quotationColumn = ["description", "entry", "elite", "entrepreneur"];

export const plansPrice = [
    {
        key: '1',
        description: 'Real Estate IQ Credits',
        entry: true,
        elite: true,
        entrepreneur: true,
    },
    {
        key: '2',
        description: 'Dashboard',
        entry: false,
        elite: true,
        entrepreneur: true,
    },
    {
        key: '3',
        description: '24/7 Priority Support',
        entry: false,
        elite: false,
        entrepreneur: true,
    },
    {
        key: '4',
        description: 'Affiliate Program',
        entry: false,
        elite: true,
        entrepreneur: true,
    },
    {
        key: '5',
        description: 'Virtual Assistance',
        entry: false,
        elite: true,
        entrepreneur: true,
    },
    {
        key: '6',
        description: 'Networking Access',
        entry: false,
        elite: true,
        entrepreneur: true,
    },
];
export const featureProduct = [
    {
        key: '1',
        description: '50 free leads',
        entry: true,
        elite: true,
        entrepreneur: true,
    },
    {
        key: '2',
        description: 'Fast CMA comps',
        entry: true,
        elite: true,
        entrepreneur: true,
    },
    {
        key: '3',
        description: 'Special price for Off Market Leads & Skip tracing',
        entry: false,
        elite: true,
        entrepreneur: true,
    },
];
export const featureInsights = [
    {
        key: '1',
        description: 'Blog posts (read & write)',
        entry: "Only read",
        elite: true,
        entrepreneur: true,
    },
    {
        key: '2',
        description: 'Books',
        entry: "1 book",
        elite: "Unlimited",
        entrepreneur: "Unlimited",
    },
    {
        key: '3',
        description: 'Events & Webinars',
        entry: "Limited",
        elite: true,
        entrepreneur: true,
    },
    {
        key: '4',
        description: 'Events & Webinars',
        entry: false,
        elite: true,
        entrepreneur: true,
    },
    {
        key: '5',
        description: 'Program Certificates',
        entry: false,
        elite: true,
        entrepreneur: true,
    },
    {
        key: '6',
        description: 'Be the expert',
        entry: false,
        elite: "Limited",
        entrepreneur: true,
    },
];
export const featureConsulting = [
    {
        key: '1',
        description: 'Market Insights',
        entry: false,
        elite: true,
        entrepreneur: true,
    },
    {
        key: '2',
        description: 'Financial Planning 1:1',
        entry: true,
        elite: true,
        entrepreneur: true,
    },
    {
        key: '3',
        description: 'Consulting 1:1',
        entry: true,
        elite: true,
        entrepreneur: true,
    },
    {
        key: '4',
        description: 'Exclusive Consulting with experts',
        entry: false,
        elite: false,
        entrepreneur: true,
    },
    {
        key: '5',
        description: 'IQ Matching',
        entry: false,
        elite: false,
        entrepreneur: true,
    },
    {
        key: '6',
        description: 'Real Estate IQ Agency',
        entry: false,
        elite: false,
        entrepreneur: true,
    },
];
