import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/SmsTemplate';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/smsTemplateService';
import * as FunctionAction from '../actions/smsTemplateAction';
import {message} from "antd";
import SmsTemplateRequest, {filtersParams} from "../mapping/Request/SmsTemplateRequest";
import TAG_DEFINE from "../../constants/common";

function* actionGetListSmsTemplate() {
    yield takeLatest(Types.SMS_TEMPLATE_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(filtersParams(action.filters) || {});
        const result = yield call(FunctionService.actionSmsTemplateList, params);
        yield put(FunctionAction.smsTemplateListSuccess(result));
    }, errorHandle(Types.SMS_TEMPLATE_FAIL)))
}

function* actionGetItem() {
    yield takeLatest(Types.SMS_TEMPLATE_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionSmsTemplateDetail, action.params.id);
        yield put(FunctionAction.smsTemplateDetailSuccess(result));
    }, errorHandle(Types.SMS_TEMPLATE_FAIL)))
}

function* actionGetMasterSmsTemplate() {
    yield takeLatest(Types.SMS_TEMPLATE_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.masterSmsTemplate, action.params);
        yield put(FunctionAction.smsTemplateMasterSuccess(result));
    }, errorHandle(Types.SMS_TEMPLATE_FAIL)))
}

function* createAction() {
    yield takeLatest(Types.SMS_TEMPLATE_CREATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new SmsTemplateRequest(action.params).exportCreate();
        const result = yield call(FunctionService.create, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
        action.params.props.history.goBack();
        yield put({type: Types.SMS_TEMPLATE_LIST_ACTION});
    }, errorHandle(Types.SMS_TEMPLATE_FAIL)))
}

function* updateAction() {
    yield takeLatest(Types.SMS_TEMPLATE_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new SmsTemplateRequest(action.params).exportUpdate();
        yield call(FunctionService.update, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.smsTemplateDetailAction({id: action.params.id}));
    }, errorHandle(Types.SMS_TEMPLATE_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        console.log("===> ", e)
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* smsTemplateSaga() {
    yield all([
        fork(actionGetListSmsTemplate),
        fork(createAction),
        fork(actionGetMasterSmsTemplate),
        fork(actionGetItem),
        fork(updateAction),
    ])
}
