import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/templateAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.templateInitStore(params)),
        [dispatch]
    )
}

export const ActionGetList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.templateListAction(filters)),
        [dispatch]
    )
}

export const ActionGetItem = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.templateDetailAction(params)),
        [dispatch]
    )
}

export const ActionMasterData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.templateMasterAction(params)),
        [dispatch]
    )
}

export const ActionCreate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.templateCreateAction(params)),
        [dispatch]
    )
}
export const ActionUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.templateUpdateAction(params)),
        [dispatch]
    )
}

export const ActionGetTemplateEmailList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.templateEmailListAction(filters)),
        [dispatch]
    )
}

export const ActionDelete = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.templateDeleteAction(params)),
        [dispatch]
    )
}

export const ActionClone = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.templateCloneAction(params)),
        [dispatch]
    )
}


export const ActionLayoutReadNotify = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.templateReadNotifyAction(params)),
        [dispatch]
    )
}
