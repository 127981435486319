import {Droppable} from "react-beautiful-dnd";
import TaskItem from "./TaskItem";
import {useState} from "react";
import {Button, Checkbox, Form, Input} from "antd";
import {Icon} from "../../../common";
import {LoadingOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {selectFetching, selectStatusAction} from "../../../../data/reselects/taskSelector";

const TaskList = ({prefix, elements, status, listsColumn, onCreateTask, onSubmit, isCreate}) => {
    const itemStatusAction = useSelector(selectStatusAction());

    return (
        <div className="task__list--wrapper">
            <div className="task__list--column-header">{prefix}</div>
            <Droppable droppableId={prefix} key={prefix}>
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} style={{
                        height: "90vh"
                    }}>
                        {elements.map((item, index) => (
                            <TaskItem key={item.id} item={item} index={index}/>
                        ))}
                        {provided.placeholder}
                        {
                            (status === "0") ? (
                                <div>
                                    {
                                        !isCreate ? (
                                            <div className="cursorPointer px-1" onClick={e => onCreateTask(true)}>
                                                <span className="fs--20px">+</span> Create Task
                                            </div>
                                        ) : (
                                            <Form.Item
                                                label=""
                                                name="title"
                                            >
                                                <Input
                                                    allowClear
                                                    disabled={itemStatusAction?.isSubmit}
                                                    suffix={
                                                        itemStatusAction?.isSubmit ? <LoadingOutlined/> : null
                                                    }
                                                />
                                            </Form.Item>
                                        )
                                    }
                                </div>
                            ) : null
                        }
                    </div>
                )}
            </Droppable>
        </div>
    )
}

export default TaskList
