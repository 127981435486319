import {createSelector} from 'reselect'

const stateReducer = state => state.teamPointReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        resultReducer => resultReducer.isFetching
    )

export const selectErrors = (initItems) =>
    createSelector(
        stateReducer,
        resultReducer => resultReducer.errors
    )

export const selectListTeamPoint = (initItems) =>
    createSelector(
        stateReducer,
        resultReducer => resultReducer.data
    )


export const selectDetailTeamPoint = (initItems) =>
    createSelector(
        stateReducer,
        resultReducer => resultReducer.detail
    )
