import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class PlansPricingResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.period_type = data?.period_type || [];
        this.bundle = this.generateItemsBundle(data?.bundle || {});
        this.title = this.generateTitle(data?.period_type || []);
        this.item = this.generateItems(data?.item || {})
        this.defaultTab = this.generateItemDefault(data?.period_type || [])
    }

    generateItemsBundle(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items?.map(i => newItems.push({
                id: i?.id || "",
                name: i?.name || "",
                price: i?.price || "",
                period: i?.period || "",
                description: i?.description ? {
                    checked: i?.description?.checked || [],
                    unchecked: i?.description?.unchecked || [],
                } : "",
            }))
        }
        return newItems;
    }

    generateItems(items = {}) {
        let newItems = {};
        if (Object?.keys(items).length > 0) {
            Object?.keys(items).map(i => {
                let newChild = [];
                if ((items[i] || [])?.length > 0) {
                    (items[i] || []).map(i2 => {
                        newChild.push({
                            id: i2?.id || "",
                            name: i2?.name || "",
                            price: i2?.price || "",
                            period: i2?.period || "",
                            description: i2?.description ? {
                                checked: i2?.description?.checked || [],
                                unchecked: i2?.description?.unchecked || [],
                            } : "",
                        })
                    })
                }
                newItems = {
                    ...newItems,
                    [i]: newChild
                }
            })
        }
        return newItems;
    }

    generateTitle(types = []){
        let newTypes = [];
        if(types.length > 0){
            types.map(i => newTypes.push(Object?.keys(i || {})[0]));
        }
        return newTypes
    }

    generateItemDefault(types = []){
        let newTypes = "";
        if(types.length > 0){
            types.map(i => {
                if(Object?.values(i || {})[0]){
                    newTypes = Object?.keys(i || {})[0]
                }
            });
        }
        return newTypes
    }

    exportItem() {
        return {
            period_type: this.period_type,
            item: this.item,
            title: this.title,
            defaultTab: this.defaultTab,
            bundle: this.bundle,
        }
    }
}

export const column = ["name", "email", "phone_number", "tag", "created_date", "company", "job_title", "owner", "status"];

export const fieldDetailRemove = [];

export const CONSTANT = {

}

