import React, {Component, Fragment, useEffect, useState} from 'react';
import {connect, useSelector} from "react-redux";
import {Switch, Route, withRouter} from 'react-router-dom';
import {checkUserAction, refreshTokenAction} from "../data/actions/authAction";
import {logoutAction} from "../data/actions/authAction";
import DEFINE, {CONFIG_LOCAL_STORAGE} from "../constants/define";
import $ from "jquery";
import * as LocalStorage from "../utils/localStorage";
import {message, Modal, Space, Spin, Tabs} from "antd"
import {Header} from "./common";
import Navigation from "./common/layout/Navigation";
import {capitalizeFirstLetter} from "../utils/functions";
import {ActionGeneral, ActionSetting} from "../data/hooks/auth";
import {getGeneral} from "../data/reselects/authSelector";
import {copyToClipboard} from "../utils/commonUtils";
import {find} from "lodash";
import {ActionLayoutReadNotify} from "../data/hooks/template";

const {TabPane} = Tabs;

const ContainerIndex = props => {

    const [collapsed, setCollapsed] = useState(false),
        [isCollapse, setIsCollapse] = useState(false),
        [listTabs, setListTabs] = useState([]),
        [activeTab, setActiveTab] = useState(0),
        [isShowNotify, setIsShowNotify] = useState(false),
        [isHideNotify, setIsHideNotify] = useState(false),
        [popup, setPopup] = useState([]),
        actionSetting = ActionSetting(),
        actionGeneral = ActionGeneral(),
        actionLayoutReadNotify = ActionLayoutReadNotify(),
        itemGeneral = useSelector(getGeneral());

    const toggleCollapsed = () => {
        setCollapsed(!collapsed)
    };

    useEffect(() => {
        if ((itemGeneral?.companies || []).length > 0) {
            if (!LocalStorage.get(CONFIG_LOCAL_STORAGE.COMPANIES_SYSTEM) || (LocalStorage.get(CONFIG_LOCAL_STORAGE.COMPANIES_SYSTEM) === "undefined")) {
                LocalStorage.set(CONFIG_LOCAL_STORAGE.COMPANIES_SYSTEM, itemGeneral?.companies[0]?.id)
            } else if (LocalStorage.get(CONFIG_LOCAL_STORAGE.COMPANIES_SYSTEM)) {
                if (Object?.keys(find(itemGeneral?.companies, {id: LocalStorage.get(CONFIG_LOCAL_STORAGE.COMPANIES_SYSTEM)}) || {}).length < 1) {
                    LocalStorage.remove(CONFIG_LOCAL_STORAGE.COMPANIES_SYSTEM)
                    LocalStorage.set(CONFIG_LOCAL_STORAGE.COMPANIES_SYSTEM, itemGeneral?.companies[0]?.id)
                }
            }
        }
    }, [itemGeneral]);

    useEffect(() => {
        actionGeneral();
        const unlisten = props.history.listen((location, action) => {
            // actionSetting();
            actionGeneral();
        })
        let e = (window.innerHeight > 0 ? window.innerHeight : window.screen.height) - 1;
        $(".page-wrapper").css("min-height", e + "px");
        console.log('VERSION:', process.env.REACT_APP_VERSION);
        // props.onCheckUser();

        // const profile = LocalStorage.get(CONFIG_LOCAL_STORAGE.PROFILE);
        const token = LocalStorage.get(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
        // const refreshToken = LocalStorage.get(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
        // const expires = LocalStorage.get(CONFIG_LOCAL_STORAGE.EXPIRES);
        //TODO: Ad-hoc fix, recheck
        if (!token) {
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.COMPANIES_SYSTEM);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.PROFILE);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.EXPIRES);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
        } else
            props.onCheckUser();

        return unlisten;
    }, [])

    const onCopyRef = val => {
        copyToClipboard(val);
        message.success("Copied!");
    }

    useEffect(() => {
        if ((popup || []).length < 1) {
            setIsShowNotify(false)
        }
    }, [popup]);

    useEffect(() => {
        let newPopup = [];
        if (!isHideNotify) {
            if ((itemGeneral?.popup || []).length > 0) {
                (itemGeneral?.popup || []).map(i => {
                    if (!Boolean(i?.is_read)) {
                        newPopup.push(i)
                    }
                })
                setPopup(newPopup)
                if (newPopup.length > 0) {
                    setIsShowNotify(true)
                } else {
                    setIsShowNotify(false)
                }
            } else {
                setPopup(itemGeneral?.popup || [])
                setIsShowNotify(false)
            }
        } else {
            setIsShowNotify(false)
        }
    }, [itemGeneral]);

    const onReadNotify = item => {
        let newItem = [...popup];
        if (newItem.length > 0) {
            actionLayoutReadNotify({id: newItem[0]?.id})
            newItem.splice(0, 1);
            setPopup(newItem)
        }
    }

    const onShowNotify = item => {
        setPopup([item])
        setIsShowNotify(true)
    }

    if (Object.keys(props.authLogin?.user || {}).length > 0) {
        return (
            <>
                <Modal
                    className=""
                    destroyOnClose
                    footer={null}
                    title={false}
                    width={700}
                    visible={isShowNotify}
                    onOk={() => {
                    }}
                    onCancel={() => {
                        setIsShowNotify(false)
                        setIsHideNotify(true)
                    }}
                >
                    <p className="fs--36px text-center color--black px-5 mb-2" style={{fontWeight: "600"}}>Discover Our
                        Improved
                        Portal!</p>
                    {
                        (popup.length > 0) ? (
                            <div className="overflow-hidden px-5">
                                <div className="overflow-hidden">
                                    {
                                        (popup[0]?.popup_content || []).map((item, index) => {
                                            return (
                                                <div className="clearfix mb-3" key={index}>
                                                    <div className="float-start pt-1" style={{width: "4%"}}>
                                                        <img
                                                            src={`/layout/icons/${(item?.type || "").toLowerCase() === "update" ? "notify-start.svg" : "notify-filters.svg"}`}
                                                            alt=""
                                                            className="w-100"/>
                                                    </div>
                                                    <div className="float-start px-3" style={{width: "96%"}}>
                                                        <p className="fs--20px color--black mb-2"
                                                           style={{fontWeight: 600}}>
                                                            {item?.title}
                                                        </p>
                                                        <div className="fs--16px color--black mb-0"
                                                           style={{fontWeight: 400, whiteSpace: "pre-line"}}>
                                                            {item?.description}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        ) : null
                    }
                    <div className="w-75 m-auto text-center mt-3">
                        <Space>
                            <p className="d-block text-decoration-none color--primary border__radius--4px py-2 px-4 fs--16px cursorPointer"
                               style={{border: "1px solid #00A1C9", fontWeight: 600}}
                               onClick={e => {
                                   props?.history?.push("/ticket")
                                   setPopup([])
                                   setIsHideNotify(true)
                                   setIsShowNotify(false)
                               }}>Got any questions?</p>
                            <p className="d-block text-decoration-none color--primary color--white border__radius--4px py-2 px-4 fs--16px cursorPointer"
                               style={{backgroundColor: "#00A1C9", fontWeight: 600}} onClick={e => onReadNotify()}>Got
                                it</p>
                        </Space>
                    </div>
                </Modal>
                {
                    !props?.isPageBuilders ? (
                        <Header history={props?.history} toggleCollapsed={toggleCollapsed} collapsed={collapsed}
                                onCopyRef={e => onCopyRef(e)} onShowNotify={e => onShowNotify(e)}/>
                    ) : null
                }
                {
                    !props?.isPageBuilders ? (
                        <Navigation toggleCollapsed={toggleCollapsed} collapsed={collapsed} history={props.history}/>
                    ) : null
                }
                {/*<div className="hn__navigation float-left">*/}
                {/*</div>*/}
                <div className={`hn__content height--100 ${collapsed ? "hn__content--expanded" : ""}`} style={{
                    minHeight: "calc(100vh - 120px)"
                }}>
                    {
                        props.children
                    }
                </div>
                {
                    !props?.isPageBuilders ? (
                        <div className="text-end hn__footer">
                            <a href="https://megalithinc.com" target="_blank" className="text-decoration-none" style={{
                                color: "#a0a0a0"
                            }}>
                                Powered by Megalith Solutions
                            </a>
                        </div>
                    ) : null
                }
            </>
            // <>
            //     {
            //         !props?.isPageBuilders ? (
            //             <Header
            //                 isCollapse={isCollapse}
            //                 setIsCollapse={e => setIsCollapse(e)}
            //                 history={props?.history} toggleCollapsed={toggleCollapsed} collapsed={collapsed}
            //                 onCopyRef={e => onCopyRef(e)}/>
            //         ) : null
            //     }
            //     {
            //         !props?.isPageBuilders ? (
            //             <Navigation
            //                 isCollapse={isCollapse}
            //                 setIsCollapse={e => setIsCollapse(e)}
            //                 toggleCollapsed={toggleCollapsed} collapsed={collapsed} history={props.history}/>
            //         ) : null
            //     }
            //     {/*<div className="hn__navigation float-left">*/}
            //     {/*</div>*/}
            //     <div className={`hn__content pb-4 ${collapsed ? "hn__content--expanded" : ""}`} style={!isCollapse ? {
            //         height: "calc(100vh - 50px)",
            //         overflowY: "scroll",
            //     } : {
            //         height: "calc(100vh - 50px)",
            //         overflowY: "scroll",
            //         width: "97%"
            //     }}>
            //         <div>
            //             {
            //                 props.children
            //             }
            //         </div>
            //     </div>
            //     {/*{*/}
            //     {/*    !props?.isPageBuilders ? (*/}
            //     {/*        <div className="text-end hn__footer">*/}
            //     {/*            <a href="https://megalithinc.com" target="_blank" className="text-decoration-none" style={{*/}
            //     {/*                color: "#a0a0a0"*/}
            //     {/*            }}>*/}
            //     {/*                Powered by Megalith Solutions*/}
            //     {/*            </a>*/}
            //     {/*        </div>*/}
            //     {/*    ) : null*/}
            //     {/*}*/}
            // </>
        );
    } else {
        return null
    }
}

const mapStateToProps = state => {
    return {
        authLogin: state.authReducer,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCheckUser: () => {
            dispatch(checkUserAction())
        },
        onLogoutRequest: () => {
            dispatch(logoutAction());
        },
        refreshToken: token => dispatch(refreshTokenAction(token)),
    }
};

// export default ContainerIndex;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContainerIndex));

