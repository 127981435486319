export const TASK_MASTER_ACTION = "TASK_MASTER_ACTION";
export const TASK_MASTER_SUCCESS = "TASK_MASTER_SUCCESS";

export const TASK_DETAIL_ACTION = "TASK_DETAIL_ACTION";
export const TASK_DETAIL_SUCCESS = "TASK_DETAIL_SUCCESS";

export const TASK_LIST_ACTION = "TASK_LIST_ACTION";
export const TASK_LIST_SUCCESS = "TASK_LIST_SUCCESS";

export const TASK_UPDATE_ACTION = "TASK_UPDATE_ACTION";
export const TASK_UPDATE_SUCCESS = "TASK_UPDATE_SUCCESS";

export const TASK_CREATE_ACTION = "TASK_CREATE_ACTION";
export const TASK_CREATE_SUCCESS = "TASK_CREATE_SUCCESS";

export const TASK_PROCESS_TASK_LIST_ACTION = "TASK_PROCESS_TASK_LIST_ACTION";
export const TASK_PROCESS_TASK_LIST_SUCCESS = "TASK_PROCESS_TASK_LIST_SUCCESS";

export const TASK_PROCESS_TASK_UPDATE_ACTION = "TASK_PROCESS_TASK_UPDATE_ACTION";
export const TASK_PROCESS_TASK_UPDATE_SUCCESS = "TASK_PROCESS_TASK_UPDATE_SUCCESS";

export const TASK_PROCESS_TASK_CREATE_ACTION = "TASK_PROCESS_TASK_CREATE_ACTION";
export const TASK_PROCESS_TASK_CREATE_SUCCESS = "TASK_PROCESS_TASK_CREATE_SUCCESS";

export const TASK_ADD_ACTION = "TASK_ADD_ACTION";
export const TASK_ADD_SUCCESS = "TASK_ADD_SUCCESS";

export const TASK_INIT = "TASK_INIT";
export const TASK_FAIL = "TASK_FAIL";
