import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class InboxResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.name = data?.name || "";
        this.phone_number = data?.phone_number || "";
        this.sms_conversation_last_update = data?.sms_conversation_last_update || "";
        this.sms_conversation_new = data?.sms_conversation_new || "";
        this.template = data?.template || {};
        this.message = Array.isArray(data?.message) ? this.generateMessage(data?.message || []) : (data?.message || "")
    }

    generateMessage(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map(i => newItems.push({
                created_at: i?.created_at || "",
                created_at_string: moment(i?.created_at || "").format(DATE_TIME_FORMAT.INBOX_DATE),
                phone_number: i?.phone_number || "",
                contact_phone_number: i?.contact_phone_number || "",
                message: i?.message || "",
            }))
        }
        return newItems
    }

    exportList() {
        return {
            id: this.id,
            name: this.name,
            phone_number: this.phone_number,
            sms_conversation_last_update: moment(this.sms_conversation_last_update).format(DATE_TIME_FORMAT.INBOX_DATE),
            sms_conversation_new: this.sms_conversation_new,
            message: this.message,
            template: this.template,
        }
    }

    exportDetail() {
        return {
            id: this.id,
            name: this.name,
            phone_number: this.phone_number,
            sms_conversation_last_update: this.sms_conversation_last_update,
            sms_conversation_new: this.sms_conversation_new,
            message: this.message,
            template: this.template,
        }
    }

}

export const column = ["name", "email", "phone_number", "tag", "created_date", "company", "job_title", "owner", "status"];

export const fieldDetailRemove = [];

export const filtersResponse = (filters) => {
    return removeObjectNullFull({
        search: filters?.search || "",
        tag: filters?.tag || "",
        is_owned: convertBooleanToInt(filters?.is_owned),
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    })
}

export class InboxMasterResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.status = data?.STATUS;
        this.tag = data?.TAG
    }

    exportMaster() {
        return {
            status: this.status,
            tag: this.tag,
        }
    }
}
