import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    removeObjectNull, removeObjectNullFull, setParamsUrlFilter
} from "../../../utils/functions";
import {omit} from "lodash";
import TAG_DEFINE from "../../../constants/common";
import {PATHS} from "../../../constants/define";

// ============== LAYOUT ==============
import HeaderAction from "../../common/layout/HeaderAction";
import {Icon} from "../../common";
import ListComponent from "../../common/ListComponent";
import {Tooltip, Button, Tag, Form, Input, Select, Checkbox, Modal, Upload, message, Space} from 'antd'

// ============== ACTION ==============
import {ActionGetList, ActionMasterData, ActionCreate} from '../../../data/hooks/smsHistory';
import {ActionUpload} from '../../../data/hooks/upload';

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectErrors,
    selectList,
    selectMasterData, selectStatusAction
} from '../../../data/reselects/smsHistorySelector';
import SmsHistoryResponse, {column, filtersResponse} from "../../../data/mapping/Response/SmsHistoryResponse";
import {ROUTE_REDIRECT} from "../../../constants/define";
import queryString from "query-string";
import {common} from "../../../data/mapping/Request/SmsHistoryRequest";
import ModalSendMail from "./components/ModalSendMail";

const ModelFields = Object.getOwnPropertyNames(new SmsHistoryResponse());
const Index = props => {
    const namePage = "sms_history",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(column, ["action"], []),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        [form] = Form.useForm(),
        componentTable = {
            action: {
                width: 100,
                align: 'center',
                key: 'action',
                fixed: 'right',
                render: (text, record) => <Icon type="eye-outlined" className="cursorPointer"
                                                onClick={() => {
                                                    setDataDetail(record?.detail);
                                                    setShowDetail(true)
                                                }}/>,
            }
        };
    const [fileList, setFileList] = useState([]),
        [showDetail, setShowDetail] = useState(false),
        [dataDetail, setDataDetail] = useState([]),
        [isSendMail, setIsSendMail] = useState(false),
        [isFetching, setIsFetching] = useState(false),
        [isImport, setIsImport] = useState(false),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        // ============== ACTION ==============
        getList = ActionGetList(),
        actionUpload = ActionUpload(),
        actionMasterData = ActionMasterData(),
        actionCreate = ActionCreate(),
        // ============== SELECT DATA ==============
        itemList = useSelector(selectList()),
        itemErrors = useSelector(selectErrors()),
        itemStatusAction = useSelector(selectStatusAction()),
        itemMasterData = useSelector(selectMasterData()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        actionMasterData();
        if (Object.keys(queryString.parse(props.location.search)).length > 0) {
            let paramsFilters = queryString.parse(props.location.search, {arrayFormat: 'comma'});
            paramsFilters = removeObjectNullFull(paramsFilters)
            setPramsFilters(paramsFilters)
            form.setFieldsValue(paramsFilters)
            getList(paramsFilters);
        } else {
            getList();
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        if (itemStatusAction?.sendMailSuccess) {
            setIsSendMail(false)
        }
    }, [itemStatusAction]);

    useEffect(() => {
        setResultList(itemList?.result || [])
        setPagination({
            total: itemList?.pagination?.total || 0,
            totalPage: itemList?.pagination?.total_page || 0,
            defaultCurrent: itemList?.pagination?.page_index || 1,
            pageSize: itemList?.pagination?.page_size || 10
        })
    }, [itemList]);

    const onTableChange = (pagination, filters, sorter) => {
        // let currentFilters = getCurrentFilters();
        filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        setPramsFilters(newFilters)
        setPramsFilters(newFilters)
        form.setFieldsValue(newFilters)
        setInitData({
            ...initData,
            filters: newFilters
        });
        props.history.replace(setParamsUrlFilter(ROUTE_REDIRECT.SMS_HISTORY.HOME, newFilters))
        getList(newFilters);
    };

    // const onCreate = (val) => {
    //     actionCreate(val)
    // };

    const onSearch = (val) => {
        let getFilters = filtersResponse(removeObjectNullFull({
            ...initData.filters, ...paramsFilters, ...val,
            page_index: 1
        }));
        setPramsFilters(getFilters);
        setInitData({...initData, filters: paramsFilters})
        props.history.replace(setParamsUrlFilter(ROUTE_REDIRECT.SMS_HISTORY.HOME, getFilters))
        getList(removeObjectNullFull(getFilters));
    };

    const onSendMail = val => {
        actionCreate({request: val, filters: paramsFilters})
    }

    return <>
        <HeaderAction
            isActionSignlePage
            title="SMS Management"
            disabledFormatUpperCaseTitle
            isCreate
            titleCreate="Send SMS"
            onClick={e => setIsSendMail(true)}
            isCustomize
        >
            <button type="button" className="btn btn-danger btn-rounded fs--14px ms-3" onClick={e => {
                props.history.push(ROUTE_REDIRECT.INBOX)
            }}>
                Inbox
            </button>
        </HeaderAction>
        <Modal
            maskClosable={false}
            destroyOnClose
            title="Send SMS"
            visible={isSendMail}
            footer={null}
            // onOk={e => onCreate()}
            onCancel={() => setIsSendMail(false)}
        >
            <ModalSendMail
                onCancel={() => setIsSendMail(false)}
                actionCreate={onSendMail}
                itemMasterData={itemMasterData}
                isFetching={isFetching}
            />
        </Modal>
        <Modal
            maskClosable={false}
            destroyOnClose
            title="Detail"
            visible={showDetail}
            footer={null}
            // onOk={e => onCreate()}
            onCancel={() => {
                setDataDetail([]);
                setShowDetail(false)
            }}
        >
            {dataDetail?.join(", ")}
        </Modal>
        <div className="container-fluid px-4">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSearch}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="row bg-white py-3 align-items-center">
                    <div className="col-4">
                        <Form.Item
                            label="Search"
                            name="search"
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </div>
                    <div className="col-2">
                        <Form.Item
                            label=" "
                            name="is_owned"
                            valuePropName="checked"
                        >
                            <Checkbox>My History</Checkbox>
                        </Form.Item>
                    </div>
                    <div className="col-2">
                        <Space>
                            <Form.Item className="" label=" ">
                                <Button type="primary" htmlType="submit">
                                    Search {isFetching && <Icon type="sync-outlined" spin/>}
                                </Button>
                            </Form.Item>
                        </Space>
                    </div>
                </div>
            </Form>
        </div>
        <ListComponent
            scroll={{x: 1000}}
            classParent="m-0 p-0 col-md-12"
            className="custom-table"
            marginTop={'0'} marginBottom={'0'}
            loading={{spinning: isFetching, size: 'large'}}
            rowKey="id"
            dataSource={getDataSource(resultList || [], initData.modelItem)}
            columns={columnsTable({
                titleTable: initData.titleItemTable,
                component: componentTable,
                itemName: namePage
            })}
            pagination={{
                total: pagination.total,
                totalPage: pagination.totalPage,
                current: pagination.defaultCurrent,
                pageSize: pagination.pageSize,
                responsive: true
            }}
            onChange={onTableChange}
            componentTableList={{}}
        />
    </>
}

export default Index
