import * as Types from '../types/Voice';
import {PAGINATION} from '../../constants/define';
// import HotelRequest from "../mapping/Request/HotelRequest";

const initialState = {
    isFetching: false,
    statusAction: {
        isSubmit: false,
        isUnlock: false,
        unlockField: "",
        isExport: false,
        isUpdateConfig: false
    },
    all: [],
    errors: [],
    // item: new HotelRequest().update(),
    data: {
        result: [],
        pagination: {
            page_size: PAGINATION.page_size,
            page_index: PAGINATION.page_index,
            total: PAGINATION.total,
            total_page: PAGINATION.total_page,
        }
    },
    verify: {},
    master: {}
};

const getResult = (page) => {
    return {
        result: page.result || [],
        pagination: {
            page_size: page?.page_size || PAGINATION.page_size,
            page_index: page?.page_index || PAGINATION.page_index,
            total: page?.total || PAGINATION.total,
            total_page: page?.total_page || PAGINATION.total_page,
        }
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.VOICE_MASTER_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.VOICE_MASTER_SUCCESS:
            return {...state, isFetching: false, master: action.master, statusAction: initialState.statusAction};
        case Types.VOICE_CONFIG_ACTION:
            return {...state, statusAction: {...initialState.statusAction, isUpdateConfig: true}};
        case Types.VOICE_CONFIG_SUCCESS:
            return {...state, statusAction: {...initialState.statusAction, isUpdateConfig: false}};
        case Types.VOICE_LIST_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.VOICE_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: getResult(action.teams),
                statusAction: initialState.statusAction
            };
        case Types.VOICE_VERIFY_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.VOICE_VERIFY_SUCCESS:
            return {...state, isFetching: false, verify: action.verify, statusAction: initialState.statusAction};
        case Types.VOICE_UPDATE_ACTION:
            return {...state, statusAction: {...initialState.statusAction, isSubmit: true}};
        case Types.VOICE_UPDATE_SUCCESS:
            return {...state, statusAction: {...initialState.statusAction, isSubmit: false}};
        case Types.VOICE_CREATE_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.VOICE_CREATE_SUCCESS:
            return {...state, isFetching: false, statusAction: initialState.statusAction};
        case Types.VOICE_FAIL:
            return {...state, errors: action.errors, isFetching: false, statusAction: initialState.statusAction};
        case Types.VOICE_INIT:
            return {
                ...state,
                data: action.params?.clearDetail ? state.data : initialState.data,
                verify: action.params?.clearDetail ? initialState.verify : state.verify,
                statusAction: initialState.statusAction
            };
        default:
            return state;
    }
}
