import {checkNumber} from "../../utils/commonUtils";

export default class User {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.profile?.id || undefined;
        this.avatar = data?.profile?.avatar || undefined;
        this.username = data?.profile?.username || undefined;
        this.email = data?.profile?.email || undefined;
        this.password = data?.profile?.password || undefined;
        this.first_name = data?.profile?.first_name || undefined;
        this.last_name = data?.profile?.last_name || undefined;
        this.status = data?.profile?.status || 1;
        this.menu = data?.profile?.menu || [];
        this.is_edit_data = data?.profile?.is_edit_data || false;
        this.cash = data?.profile?.cash || "";
        this.point = data?.profile?.point || "";
        this.referral_code = data?.profile?.referral_code || "";
        this.membership_code = data?.profile?.membership_code || "";
        this.w4_file = data?.profile?.w4_file || "";
        this.api_key = data?.profile?.api_key || "";
        this.profile_config = {
            check_hash: data?.profile_config?.check_hash || "",
            w4_file: data?.profile_config?.w4_file || "",

            monday_config: this.generateMondayConfig(data?.profile_config?.monday_config || []),
            podio_config: this.generateMondayConfig(data?.profile_config?.podio_config || []),
            marketing_email: data?.profile_config?.marketing_email || "",
            marketing_email_api_key: data?.profile_config?.marketing_email_api_key || "",
            marketing_email_valid: checkNumber(data?.profile_config?.marketing_email_valid) ? !!data?.profile_config?.marketing_email_valid : data?.profile_config?.marketing_email_valid || "",
            sms_account_sid: data?.profile_config?.sms_account_sid || "",
            sms_token: data?.profile_config?.sms_token || "",
            sms_phone_number: data?.profile_config?.sms_phone_number || "",
            sms_webhook: data?.profile_config?.sms_webhook || "",

            monday_address: data?.profile_config?.monday_address || "",
            monday_api_key: data?.profile_config?.monday_api_key || "",
            monday_board: data?.profile_config?.monday_board || "",
            monday_email: data?.profile_config?.monday_email || "",
            monday_extra_info: data?.profile_config?.monday_extra_info || "",
            monday_first_name: data?.profile_config?.monday_first_name || "",
            monday_last_name: data?.profile_config?.monday_last_name || "",
            monday_phone_number: data?.profile_config?.monday_phone_number || "",
            monday_property_address: data?.profile_config?.monday_property_address || "",
            monday_property_city: data?.profile_config?.monday_property_city || "",
            monday_property_state: data?.profile_config?.monday_property_state || "",
            monday_zip_code: data?.profile_config?.monday_zip_code || "",

            reiq_api_key: data?.profile_config?.reiq_api_key || "",
            reiq_first_name: data?.profile_config?.reiq_first_name || "",
            reiq_last_name: data?.profile_config?.reiq_last_name || "",
            reiq_email: data?.profile_config?.reiq_email || "",
            reiq_phone_number: data?.profile_config?.reiq_phone_number || "",
            reiq_extra_info: data?.profile_config?.reiq_extra_info || [],
            reiq_address: data?.profile_config?.reiq_address || "",
            reiq_property_address: data?.profile_config?.reiq_property_address || "",
            reiq_property_city: data?.profile_config?.reiq_property_city || "",
            reiq_property_state: data?.profile_config?.reiq_property_state || "",
            reiq_property_zip_code: data?.profile_config?.reiq_property_zip_code || "",

            hubspot_api_key: data?.profile_config?.hubspot_api_key || "",
            hubspot_first_name: data?.profile_config?.hubspot_first_name || "",
            hubspot_last_name: data?.profile_config?.hubspot_last_name || "",
            hubspot_email: data?.profile_config?.hubspot_email || "",
            hubspot_phone_number: data?.profile_config?.hubspot_phone_number || "",
            hubspot_extra_info: data?.profile_config?.hubspot_extra_info || "",
            hubspot_address: data?.profile_config?.hubspot_address || "",
            hubspot_property_address: data?.profile_config?.hubspot_property_address || "",
            hubspot_property_city: data?.profile_config?.hubspot_property_city || "",
            hubspot_property_state: data?.profile_config?.hubspot_property_state || "",
            hubspot_property_zip_code: data?.profile_config?.hubspot_property_zip_code || "",

            podio_client_id: data?.profile_config?.podio_client_id || "",
            podio_client_secret: data?.profile_config?.podio_client_secret || "",
            podio_app_id: data?.profile_config?.podio_app_id || "",
            podio_app_token: data?.profile_config?.podio_app_token || "",
            podio_address: data?.profile_config?.podio_address || "",
            podio_property_address: data?.profile_config?.podio_property_address || "",
            podio_property_city: data?.profile_config?.podio_property_city || "",
            podio_property_state: data?.profile_config?.podio_property_state || "",
            podio_property_zip_code: data?.profile_config?.podio_property_zip_code || "",
            podio_first_name: data?.profile_config?.podio_first_name || "",
            podio_last_name: data?.profile_config?.podio_last_name || "",
            podio_email: data?.profile_config?.podio_email || "",
            podio_phone_number: data?.profile_config?.podio_phone_number || "",
            podio_extra_info: data?.profile_config?.podio_extra_info || "",

            podio_email_address_2: data?.profile_config?.podio_email_address_2 || "",
            podio_email_address_3: data?.profile_config?.podio_email_address_3 || "",
            podio_email_address_4: data?.profile_config?.podio_email_address_4 || "",
            podio_email_address_5: data?.profile_config?.podio_email_address_5 || "",
            podio_phone_number_2: data?.profile_config?.podio_phone_number_2 || "",
            podio_phone_number_3: data?.profile_config?.podio_phone_number_3 || "",
            podio_phone_number_4: data?.profile_config?.podio_phone_number_4 || "",
            podio_phone_number_5: data?.profile_config?.podio_phone_number_5 || "",
            podio_cell_phones_1: data?.profile_config?.podio_cell_phones_1 || "",
            podio_cell_phones_2: data?.profile_config?.podio_cell_phones_2 || "",
            podio_cell_phones_3: data?.profile_config?.podio_cell_phones_3 || "",
            podio_cell_phones_4: data?.profile_config?.podio_cell_phones_4 || "",
            podio_cell_phones_5: data?.profile_config?.podio_cell_phones_5 || "",
            podio_lead_type: data?.profile_config?.podio_lead_type || "",

            voice_api_sid: data?.profile_config?.voice_api_sid || "",
            voice_api_key: data?.profile_config?.voice_api_key || "",
            voice_twiml_app_sid: data?.profile_config?.voice_twiml_app_sid || "",
            voice_forward_phone_number: data?.profile_config?.voice_forward_phone_number || "",
            voice_webhook: data?.profile_config?.voice_webhook || "",
            megalith_api_key: data?.profile_config?.megalith_api_key || "",

            api_key: data?.profile_config?.api_key || "",
        };
        this.common = data?.profile?.common || [];
    }

    generateMondayConfig(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map(i => {
                newItems.push({
                    board_id: i?.board_id,
                    columns: this.generateMondayConfigColumns(i?.columns || []),
                    name: i?.name
                })
            })
        }
        return newItems
    }

    generateMondayConfigColumns(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map(i => {
                newItems.push({key: i?.key, name: i?.name})
            })
        }
        return newItems
    }

    profile(data) {
        this.id = data.id || undefined;
        this.avatar = data.avatar || undefined;
        this.username = data.username || undefined;
        this.email = data.email || undefined;
        this.status = data.status || 1;
        this.menu = data.menu || [];
        this.is_edit_data = data.is_edit_data || false;
        this.cash = data.cash || false;
        this.point = data.point || false;
        this.referral_code = data.referral_code || false;
        this.membership_code = data.membership_code || false;
        this.w4_file = data.w4_file || false;
        this.api_key = data.api_key || false;
        this.common = data.common;
        return this
    }

    setPermission(data = {}) {
        this.permissions = data.permissions || [];
    }

    setProperties(data = {}) {
        this.propertyIds = data.propertyIds || [];
    }

    actionRegister() {
        return {
            email: this.email,
            password: this.password,
            first_name: this.first_name,
            last_name: this.last_name,
        }
    }

    export() {
        return {
            id: this.id,
            password: this.password,
            email: this.email,
            firstname: this.firstname,
            lastname: this.lastname,
            username: this.username,
            phoneNumber: this.phoneNumber,
            avatar: this.avatar,
            api_key: this.api_key,
            common: this.common
        }
    }
}
