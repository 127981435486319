import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/Workflow';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/workflowService';
import * as FunctionAction from '../actions/workflowAction';
import {message} from "antd";
import WorkflowRequest, {filtersParams} from "../mapping/Request/WorkflowRequest";
import TAG_DEFINE from "../../constants/common";

function* actionGetListWorkflow() {
    yield takeLatest(Types.WORKFLOW_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(filtersParams(action.filters) || {});
        const result = yield call(FunctionService.actionWorkflowList, params);
        yield put(FunctionAction.workflowListSuccess(result));
    }, errorHandle(Types.WORKFLOW_FAIL)))
}

function* actionGetItem() {
    yield takeLatest(Types.WORKFLOW_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionWorkflowDetail, action.params.id);
        yield put(FunctionAction.workflowDetailSuccess(result));
    }, errorHandle(Types.WORKFLOW_FAIL)))
}

function* actionGetMasterWorkflow() {
    yield takeLatest(Types.WORKFLOW_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.masterWorkflow, action.params);
        yield put(FunctionAction.workflowMasterSuccess(result));
    }, errorHandle(Types.WORKFLOW_FAIL)))
}

function* createAction() {
    yield takeLatest(Types.WORKFLOW_CREATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new WorkflowRequest(action.params.query).exportCreate();
        const result = yield call(FunctionService.create, data);
        message.success(result);
        action.params.props.history.push("/workflow");
        yield put({type: Types.WORKFLOW_LIST_ACTION});
    }, errorHandle(Types.WORKFLOW_FAIL)))
}

function* updateAction() {
    yield takeLatest(Types.WORKFLOW_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new WorkflowRequest(action.params.query).exportUpdate(),
            result = yield call(FunctionService.update, data, action.params.query.id);
        // console.log(444, data)
        message.success(result);
        yield put(FunctionAction.workflowDetailAction({id: action.params.query.id}));
    }, errorHandle(Types.WORKFLOW_FAIL)))
}

function* manualTriggerAction() {
    yield takeLatest(Types.WORKFLOW_MANUAL_TRIGGER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.manualTrigger, {}, action.params.query.id);
        // console.log(444, data)
        message.success(result);
        yield put(FunctionAction.workflowListAction(action.params.filters));
    }, errorHandle(Types.WORKFLOW_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        console.log("===> ", e)
        yield put({type: errorActionType, errors: e?.errors})
    });
}

function* actionDetailLogWorkflow() {
    yield takeLatest(Types.WORKFLOW_DETAIL_LOG_ACTION, Func.sagaWrapper(function* (action) {
        // const params = parseListRequestParams(filtersParams(action.filters) || {});
        const result = yield call(FunctionService.actionWorkflowDetailLogList, action.params.query.id);
        yield put(FunctionAction.workflowDetailLogSuccess(result));
    }, errorHandle(Types.WORKFLOW_FAIL)))
}

export function* workflowSaga() {
    yield all([
        fork(actionGetListWorkflow),
        fork(createAction),
        fork(actionGetMasterWorkflow),
        fork(actionGetItem),
        fork(updateAction),
        fork(manualTriggerAction),
        fork(actionDetailLogWorkflow),
    ])
}
