import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class TaskRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.title = data?.title || "";
        this.status = formatInt(data?.status);
    }

    exportCreate() {
        return {
            title: this.title,
            status: this.status,
        }
    }

    exportUpdate() {
        return {
            title: this.title,
            status: this.status,
        }
    }

}

export const common = {
    ACTION_FIELD: {
        title: "title",
        status: "status",
    },
}

export const filtersParams = (filters) => {
    return removeObjectNullFull({
        search: filters?.search || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
    })
}

