import * as Types from '../types/Subscription';

export const subscriptionCurrentAction = (filters) => ({type: Types.SUBSCRIPTION_CURRENT_ACTION, filters});
export const subscriptionCurrentSuccess = (current) => ({
    type: Types.SUBSCRIPTION_CURRENT_SUCCESS,
    current
});

export const subscriptionListAction = (filters) => ({type: Types.SUBSCRIPTION_LIST_ACTION, filters});
export const subscriptionListSuccess = (subscription) => ({
    type: Types.SUBSCRIPTION_LIST_SUCCESS,
    subscription
});

export const subscriptionDetailAction = (params) => ({type: Types.SUBSCRIPTION_DETAIL_ACTION, params});
export const subscriptionDetailSuccess = (detail) => ({
    type: Types.SUBSCRIPTION_DETAIL_SUCCESS,
    detail
});

export const subscriptionUpdateAction = (params) => ({type: Types.SUBSCRIPTION_UPDATE_ACTION, params});
export const subscriptionUpdateSuccess = (updateData) => ({
    type: Types.SUBSCRIPTION_UPDATE_SUCCESS,
    updateData
});

export const subscriptionCreateAction = (params) => ({type: Types.SUBSCRIPTION_CREATE_ACTION, params});
export const subscriptionCreateSuccess = (createData) => ({
    type: Types.SUBSCRIPTION_CREATE_SUCCESS,
    createData
});

export const subscriptionInitStore = (params) => ({type: Types.SUBSCRIPTION_INIT, params});
export const subscriptionClearStore = (params) => ({type: Types.SUBSCRIPTION_FAIL, params});
