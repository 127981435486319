export const CONTACT_MASTER_ACTION = "CONTACT_MASTER_ACTION";
export const CONTACT_MASTER_SUCCESS = "CONTACT_MASTER_SUCCESS";

export const CONTACT_LIST_ACTION = "CONTACT_LIST_ACTION";
export const CONTACT_LIST_SUCCESS = "CONTACT_LIST_SUCCESS";

export const CONTACT_DETAIL_ACTION = "CONTACT_DETAIL_ACTION";
export const CONTACT_DETAIL_SUCCESS = "CONTACT_DETAIL_SUCCESS";

export const CONTACT_UPDATE_ACTION = "CONTACT_UPDATE_ACTION";
export const CONTACT_UPDATE_SUCCESS = "CONTACT_UPDATE_SUCCESS";

export const CONTACT_CREATE_ACTION = "CONTACT_CREATE_ACTION";
export const CONTACT_CREATE_SUCCESS = "CONTACT_CREATE_SUCCESS";

export const CONTACT_DELETE_ACTION = "CONTACT_DELETE_ACTION";
export const CONTACT_DELETE_SUCCESS = "CONTACT_DELETE_SUCCESS";

export const CONTACT_OPT_OUT_ACTION = "CONTACT_OPT_OUT_ACTION";
export const CONTACT_OPT_OUT_SUCCESS = "CONTACT_OPT_OUT_SUCCESS";

export const CONTACT_OPT_IN_ACTION = "CONTACT_OPT_IN_ACTION";
export const CONTACT_OPT_IN_SUCCESS = "CONTACT_OPT_IN_SUCCESS";

export const CONTACT_CHECK_DUPLICATE_ACTION = "CONTACT_CHECK_DUPLICATE_ACTION";
export const CONTACT_CHECK_DUPLICATE_SUCCESS = "CONTACT_CHECK_DUPLICATE_SUCCESS";

export const CONTACT_CREATE_NOTE_ACTION = "CONTACT_CREATE_NOTE_ACTION";
export const CONTACT_CREATE_NOTE_SUCCESS = "CONTACT_CREATE_NOTE_SUCCESS";

export const CONTACT_UPDATE_NOTE_ACTION = "CONTACT_UPDATE_NOTE_ACTION";
export const CONTACT_UPDATE_NOTE_SUCCESS = "CONTACT_UPDATE_NOTE_SUCCESS";

export const CONTACT_DELETE_NOTE_ACTION = "CONTACT_DELETE_NOTE_ACTION";
export const CONTACT_DELETE_NOTE_SUCCESS = "CONTACT_DELETE_NOTE_SUCCESS";

export const CONTACT_IMPORT_MONDAY_ACTION = "CONTACT_IMPORT_MONDAY_ACTION";
export const CONTACT_IMPORT_MONDAY_SUCCESS = "CONTACT_IMPORT_MONDAY_SUCCESS";

export const CONTACT_IMPORT_HUBSPOT_ACTION = "CONTACT_IMPORT_HUBSPOT_ACTION";
export const CONTACT_IMPORT_HUBSPOT_SUCCESS = "CONTACT_IMPORT_HUBSPOT_SUCCESS";

export const CONTACT_IMPORT_PODIO_ACTION = "CONTACT_IMPORT_PODIO_ACTION";
export const CONTACT_IMPORT_PODIO_SUCCESS = "CONTACT_IMPORT_PODIO_SUCCESS";

export const CONTACT_FILTER_LIST_ACTION = "CONTACT_FILTER_LIST_ACTION";
export const CONTACT_FILTER_LIST_SUCCESS = "CONTACT_FILTER_LIST_SUCCESS";

export const CONTACT_MASTER_PORTAL_FILTER_ACTION = "CONTACT_MASTER_PORTAL_FILTER_ACTION";
export const CONTACT_MASTER_PORTAL_FILTER_SUCCESS = "CONTACT_MASTER_PORTAL_FILTER_SUCCESS";

export const CONTACT_FILTER_SAVE_ACTION = "CONTACT_FILTER_SAVE_ACTION";
export const CONTACT_FILTER_SAVE_SUCCESS = "CONTACT_FILTER_SAVE_SUCCESS";

export const CONTACT_FILTER_DELETE_ACTION = "CONTACT_FILTER_DELETE_ACTION";
export const CONTACT_FILTER_DELETE_SUCCESS = "CONTACT_FILTER_DELETE_SUCCESS";

export const CONTACT_COLUMN_LIST_ACTION = "CONTACT_COLUMN_LIST_ACTION";
export const CONTACT_COLUMN_LIST_SUCCESS = "CONTACT_COLUMN_LIST_SUCCESS";
export const CONTACT_COLUMN_SAVE_ACTION = "CONTACT_COLUMN_SAVE_ACTION";
export const CONTACT_COLUMN_SAVE_SUCCESS = "CONTACT_COLUMN_SAVE_SUCCESS";

export const CONTACT_INIT = "CONTACT_INIT";
export const CONTACT_FAIL = "CONTACT_FAIL";

export const CONTACT_HIGHLIGHT_CELL_ACTION = "CONTACT_HIGHLIGHT_CELL_ACTION";
export const CONTACT_HIGHLIGHT_CELL_SUCCESS = "CONTACT_HIGHLIGHT_CELL_SUCCESS";
