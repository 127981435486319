import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class TeamsRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.first_name = data?.first_name || "";
        this.last_name = data?.last_name || "";
        this.email = data?.email || "";
        this.password = data?.password || "";
        this.phone_number = data?.phone_number || "";
        this.membership_code = data?.membership_code || "";
        this.address = data?.address || "";
        this.status = formatInt(data?.status) || 0;
    }

    exportCreate() {
        return {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            password: this.password,
            phone_number: this.phone_number,
            membership_code: this.membership_code,
            address: this.address,
            status: this.status,
        }
    }

    exportUpdate() {
        return {
            id: this.id,
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            password: this.password,
            phone_number: this.phone_number,
            membership_code: this.membership_code,
            address: this.address,
            status: this.status,
        }
    }

}

export const common = {
    ACTION_FIELD: {
        first_name: "first_name",
        last_name: "last_name",
        email: "email",
        status: "status",
        password: "password",
        phone_number: "phone_number",
        membership_code: "membership_code",
        address: "address",
    },
}

export const teamsFiltersParams = (filters) => {
    return removeObjectNullFull({
        search: filters?.search || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
    })
}

