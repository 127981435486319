import {Button, Form, Input, Select, Spin} from "antd";
import {common} from "../../../../data/mapping/Request/ContactRequest";
import {isEmail} from "../../../../utils/validation";
import {Icon} from "../../../common";
import React, {useEffect, useState} from "react";
import TAG_DEFINE from "../../../../constants/common";
import {ActionCreate, ActionGetItem, ActionInit, ActionMasterData, ActionUpdate} from "../../../../data/hooks/contact";
import {useSelector} from "react-redux";
import {selectFetching, selectItem, selectMasterData} from "../../../../data/reselects/contactSelector";

const ContactDetail = props => {
    const [form] = Form.useForm(),
        [isFetching, setIsFetching] = useState(false),
        [typePage, setTypePage] = useState(TAG_DEFINE.ACTION.update),
        [masterStatus, setMasterStatus] = useState({}),
        [masterTag, setMasterTag] = useState({}),
        [isUpdate, setIsUpdate] = useState(true),
        [isCreateNote, setIsCreateNote] = useState(false),
        // ============== ACTION ==============
        getMasterData = ActionMasterData(),
        actionCreate = ActionCreate(),
        actionUpdate = ActionUpdate(),
        getInitAction = ActionInit(),
        actionGetItem = ActionGetItem(),
        // ============== SELECT DATA ==============
        itemFetching = useSelector(selectFetching()),
        itemDetail = useSelector(selectItem()),
        itemMasterData = useSelector(selectMasterData());

    useEffect(() => {
        // const {match} = props;
        // if (Object.keys(match?.params).length > 0) {
        //     setIsUpdate(true);
        //     if (Object.keys(itemDetail || {}).length < 1) {
        //         setTypePage(TAG_DEFINE.ACTION.update)
        //         actionGetItem({id: match?.params?.id, props: props});
        //     }
        // } else {
        //     setIsUpdate(false)
        // }
    }, [props.match]);

    useEffect(() => {
        getMasterData()

        return () => {
            // getInitAction({clearDetail: true});
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);

    useEffect(() => {
        if (Object.keys(itemDetail || {}).length > 0) {
            form.setFieldsValue(itemDetail)
        } else {
            form.resetFields();
        }
    }, [itemDetail]);

    useEffect(() => {
        if (Object.keys(itemMasterData?.status || {}).length > 0) {
            setMasterStatus(itemMasterData?.status)
        }
        if (Object.keys(itemMasterData?.tag || {}).length > 0) {
            setMasterTag(itemMasterData?.tag)
        }
    }, [itemMasterData]);

    const onFinish = (val) => {
        if (isUpdate) {
            actionUpdate({...val, props, id: itemDetail?.id})
        } else {
            actionCreate({...val, props})
        }
    }

    const genExtra = (val) => {
        return val
    };

    useEffect(() => {
        // if (props?.itemSelected) {
        //    form.resetFields();
        // }
    }, [props?.itemSelected]);

    return (
        <div>
            <Spin tip="Loading..." spinning={isFetching}>
                <Form
                    form={form}
                    // layout="vertical"
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                >
                    <div className="row">
                        <div className="col-12">
                            <Form.Item
                                label="Name"
                                name={common.ACTION_FIELD.name}
                                rules={[{
                                    required: true,
                                    message: 'Please input your name!'
                                }]}
                            >
                                <Input disabled={props?.isDisabledField}/>
                            </Form.Item>
                        </div>
                        <div className="col-12">
                            <Form.Item
                                label="Company"
                                name={common.ACTION_FIELD.company}
                            >
                                <Input disabled={props?.isDisabledField}/>
                            </Form.Item>
                        </div>
                        <div className="col-12">
                            <Form.Item
                                label="Job Title"
                                name={common.ACTION_FIELD.job_title}
                            >
                                <Input disabled={props?.isDisabledField}/>
                            </Form.Item>
                        </div>
                        <div className="col-12">
                            <Form.Item
                                label="Email"
                                name={common.ACTION_FIELD.email}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Email!'
                                    }, ({getFieldValue}) => ({
                                        validator(rule, value) {
                                            if ((itemDetail[common.ACTION_FIELD.email] === value)) {
                                                return Promise.resolve();
                                            }
                                            if (value) {
                                                if (!isEmail(value) && ((value || "").length >= 10)) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject("Invalid email");
                                            }
                                            return Promise.reject("Invalid email")
                                        },
                                    })
                                ]}
                            >
                                <Input disabled={props?.isDisabledField}/>
                            </Form.Item>
                        </div>
                        <div className="col-12">
                            <Form.Item
                                label="Full Address"
                                name={common.ACTION_FIELD.address}
                            >
                                <Input disabled={props?.isDisabledField}/>
                            </Form.Item>
                        </div>

                        <div className="col-12">
                            <Form.Item
                                label="Property Address"
                                name={common.ACTION_FIELD.property_address}
                            >
                                <Input disabled={props?.isDisabledField}/>
                            </Form.Item>
                        </div>
                        <div className="col-12">
                            <Form.Item
                                label="Property City"
                                name={common.ACTION_FIELD.property_city}
                            >
                                <Input disabled={props?.isDisabledField}/>
                            </Form.Item>
                        </div>
                        <div className="col-12">
                            <Form.Item
                                label="Property State"
                                name={common.ACTION_FIELD.property_state}
                            >
                                <Input disabled={props?.isDisabledField}/>
                            </Form.Item>
                        </div>
                        <div className="col-12">
                            <Form.Item
                                label="Property Zip Code"
                                name={common.ACTION_FIELD.property_zip_code}
                            >
                                <Input disabled={props?.isDisabledField}/>
                            </Form.Item>
                        </div>

                        {
                            isUpdate && (
                                <div className="col-12">
                                    <Form.Item
                                        label="Owner"
                                        name={common.ACTION_FIELD.owner}
                                    >
                                        <Input disabled/>
                                    </Form.Item>
                                </div>
                            )
                        }
                        <div className="col-12">
                            <Form.Item
                                label="Phone number"
                                name={common.ACTION_FIELD.phone_number}
                            >
                                <Input disabled={props?.isDisabledField}/>
                            </Form.Item>
                        </div>
                        {
                            isUpdate && (
                                <>
                                    <div className="col-12">
                                        <Form.Item
                                            label="Source"
                                            name={common.ACTION_FIELD.source}
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </div>
                                    {/*<div className="col-12">*/}
                                    {/*    <Form.Item*/}
                                    {/*        label="Status"*/}
                                    {/*        name={common.ACTION_FIELD.status}*/}
                                    {/*        rules={[{required: true, message: 'Please select your status!'}]}*/}
                                    {/*    >*/}
                                    {/*        <Select*/}
                                    {/*            className="w-100"*/}
                                    {/*            style={{width: 120}}>*/}
                                    {/*            {*/}
                                    {/*                (Object.keys(masterStatus)).length > 0 && (Object.keys(masterStatus)).map((i, k) => {*/}
                                    {/*                    return (*/}
                                    {/*                        <Select.Option value={i}*/}
                                    {/*                                       key={k}>{masterStatus[i]}</Select.Option>*/}
                                    {/*                    )*/}
                                    {/*                })*/}
                                    {/*            }*/}
                                    {/*        </Select>*/}
                                    {/*    </Form.Item>*/}
                                    {/*</div>*/}
                                    <div className="col-12">
                                        <Form.Item
                                            label="Created Date"
                                            name={common.ACTION_FIELD.created_date}
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </div>
                                    {
                                        <div className="col-12">
                                            <Form.Item
                                                label="Tag"
                                                name={common.ACTION_FIELD.tag}
                                            >
                                                <Select
                                                    disabled={props?.isDisabledField}
                                                    mode="multiple"
                                                    // disabled
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                >
                                                    {
                                                        (Object.keys(masterTag)).length > 0 && (Object.keys(masterTag)).map((i, k) => {
                                                            return (
                                                                <Select.Option value={i}
                                                                               key={k}>{masterTag[i]}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    }
                                    <div className="col-12">
                                        <Form.Item
                                            label="Extra Info"
                                            name={common.ACTION_FIELD.extra_info}
                                        >
                                            <Input.TextArea disabled/>
                                        </Form.Item>
                                    </div>
                                </>
                            )
                        }
                        <div className="text-end col-12">
                            <Button type="primary" htmlType="submit" disabled={props?.isDisabledField}>
                                Save {isFetching && <Icon type="sync-outlined" spin/>}
                            </Button>
                        </div>
                    </div>
                </Form>
            </Spin>
        </div>
    )
}

export default ContactDetail
