import {Redirect, Route, withRouter} from "react-router-dom";
import ContainerIndex from "../../ContainerIndex";
import {connect, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {getFetchingAuth, getFetchingLogin, getProfile, selectSsoCode} from "../../../data/reselects/authSelector";
import {ActionCheckUser, ActionAuthenticationSSO} from "../../../data/hooks/auth";
import {Button, Result} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import TAG_DEFINE from "../../../constants/common";
import queryString from "query-string";
import Login from "../../Login";
import * as LocalStorage from "../../../utils/localStorage";
import {CONFIG_LOCAL_STORAGE} from "../../../constants/define";
import {removeElementObject} from "../../../utils/functions";

export const Authentication = props => {
    const [urlCallback, setUrlCallback] = useState(""),
        [agent, setAgent] = useState(""),
        actionAuthenticationSSO = ActionAuthenticationSSO(),
        actionCheckUser = ActionCheckUser(),
        itemProfile = useSelector(getProfile()),
        itemGetSso = useSelector(selectSsoCode()),
        itemFetching = useSelector(getFetchingLogin());

    useEffect(() => {
        actionCheckUser();
        let queryParams = queryString.parse(props?.location?.search);
        if (queryParams?.action === "logout") {
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.EXPIRES);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.PROFILE);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.COMPANIES_SYSTEM);
            queryParams = queryString.stringify(removeElementObject(queryParams, "action"))
            window.location = `/auth/verify?${queryParams}`
        }
        return () => {
            setUrlCallback("")
        }
    }, []);

    useEffect(() => {
        if (Object?.keys(itemProfile || {}).length > 0) {
            actionAuthenticationSSO()
        }
    }, [itemProfile]);

    useEffect(() => {
        if (itemGetSso) {
            let queryParams = queryString.parse(props?.location?.search);
            if (queryParams?.callback) {
                setAgent(queryParams?.agent)
                setUrlCallback(`${queryParams?.callback}?code=${itemGetSso}&redirectTo=${queryParams?.redirectTo || "/"}${queryParams?.fullAddress ? `&fullAddress=${queryParams?.fullAddress}` : ""}`)
                window.location = `${queryParams?.callback}?code=${itemGetSso}&redirectTo=${queryParams?.redirectTo || "/"}${queryParams?.fullAddress ? `&fullAddress=${queryParams?.fullAddress}` : ""}`
            }
        }
    }, [itemGetSso]);

    return (
        <div className="hn__authentication container">
            <div className="hn__authentication--screen">
                {
                    (queryString.parse(props?.location?.search) || {})?.callback ? (
                        <div className="hn__authentication--screen__content">
                            {/*{*/}
                            {/*    itemFetching ? (*/}
                            {/*        <div className="text-center" style={{*/}
                            {/*            paddingTop: "110px",*/}
                            {/*            fontSize: "50px"*/}
                            {/*        }}>*/}
                            {/*            <LoadingOutlined*/}
                            {/*                className="color--white"*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*    ) : (*/}
                            {/*        <>*/}
                            {/*            <div className="text-center mt-5 color--white">*/}
                            {/*                <p className="mb-0 h5">An External Application</p>*/}
                            {/*                <p className="mb-0 h3 py-2">{agent}</p>*/}
                            {/*                <p className="mb-0">want to access your Account</p>*/}
                            {/*                <p className="mb-0">Signed in as <span*/}
                            {/*                    className="fw-bold">{itemProfile?.email}</span>*/}
                            {/*                </p>*/}
                            {/*                <hr/>*/}
                            {/*            </div>*/}
                            {/*            <div className="px-3 mt-4">*/}
                            {/*                <a href={urlCallback}*/}
                            {/*                   className="button hn__authentication--login__submit">*/}
                            {/*                    <span className="button__text">Log In Now</span>*/}
                            {/*                    <i className="button__icon fas fa-chevron-right"></i>*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*        </>*/}
                            {/*    )*/}
                            {/*}*/}
                            <div className="text-center" style={{
                                paddingTop: "110px",
                                fontSize: "50px"
                            }}>
                                <LoadingOutlined
                                    className="color--primary"
                                />
                                <p className="fs--14px mt-5" style={{color: "#444444"}}>Please wait, the system is
                                    processing.</p>
                            </div>
                        </div>
                    ) : (
                        <Result
                            className="color--white mt-4"
                            status="error"
                            title="Submission Failed"
                            subTitle="Please check and modify the following information before resubmitting."
                        />
                    )
                }
            </div>
        </div>
    )
}

const VerifyAuthRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => {
        const {authentication} = rest;
        return authentication.logged
            ? <Component {...props} />
            :
            <Login {...props} />
    }}/>
);

const mapStateToProps = state => ({
    authentication: state.authReducer,
});

export default withRouter(connect(mapStateToProps)(VerifyAuthRoute));
