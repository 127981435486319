import * as Types from "../types/FastCMA";
import { STEPS } from "../../constants/fastCMA";
import { PROPERTY } from "../../constants/fastCMA";

const initState = {
	status: "idle",
	type: "address",
	step: STEPS.LOOKUP,
	address_lookup: {
		status: "idle",
		result: []
	},
	google: {
		session: false
	},
	cma: {
		status: 'idle',
		market: 'houston',
		result: false,
		active_status: PROPERTY.STATUS.SOLD.key,
		estimate: null,
		selected_properties: {}
	},
};

const calculateEstimate = (subject, properties, selected_properties, activeStatus) => {
	let result = {}

	for( let key in PROPERTY.STATUS ) {
		let status = PROPERTY.STATUS[key]
		let sold_price = 0;
		let data = properties[status.key]
		let price_per_square_foot = 0;

		if ( properties ) {
			data = data.filter((item, index) => selected_properties[status.key].indexOf(index)>=0)
			if ( data.length > 0 ) {
				for (let property of data) {
					price_per_square_foot += (property.sold_price||property.list_price)/property.square_feet;
				}

				price_per_square_foot = price_per_square_foot/data.length;
				sold_price = price_per_square_foot * subject.square_feet;
			}
		}

		result[status.key] = {
			price_per_square_foot: price_per_square_foot,
			sold_price: sold_price
		}
		if ( status.key === PROPERTY.STATUS.LEASED.key ) {
			result[status.key].rental_index = sold_price/subject.list_price
		}
	}
	return result;
}

const FastCMAReducer = (state = initState, action) => {
	let estimate;
	let selected_properties;
	let data;

	switch (action.type) {
		case Types.GG_MAP_LOOKUP:
			return {
				...state,
				type: "gg_map_lookup",
			};
		case Types.MLS_LOOKUP:
			return {
        ...state,
        step: STEPS.FIND_COMPARABLES
      }

		case Types.ADDRESS_LOOKUP:
			return {
        ...state,
				address_lookup: {
					status: 'searching',
					result: [],
					query: action.params
				}
      }
		case Types.ADDRESS_LOOKUP_SUCCESS:
			return {
        ...state,
				address_lookup: {
					status: 'success',
					result: action.params
				}
      }
		case Types.ADDRESS_LOOKUP_RESET:
			return {
        ...state,
				step: STEPS.LOOKUP,
				address_lookup: initState.address_lookup
      }
		case Types.CHANGE_MARKET_CHANNEL:
			return {
        ...state,
				cma: {
					...state.cma,
					market: action.params.market,
				}
      }
		case Types.ADDRESS_LOOKUP_FAIL:
			let result = state.address_lookup.query;
			result.Lat && (result.latitude = result.Lat);
			result.Lon  && (result.longitude = result.Lon);
			return {
        ...state,
				address_lookup: {
					status: 'success',
					result: [result],
				}
      }
		case Types.FILTER_COMPARABLES:
			let subject = state.address_lookup.result.filter(i=>i.mls_num === action?.params.mls_num || i.address === action?.params.address)
			if ( subject.length > 0 ) {
				subject = subject[0];
				!subject.latitude && (subject['latitude'] = action?.params.latitude);
				!subject.longitude && (subject['longitude'] = action?.params.longitude)
			} else {
				subject = {}
			}
			return {
				...state,
				step: STEPS.FIND_COMPARABLES,
				address_lookup: {
					...state.address_lookup,
					subject: subject
				}
			}
		case Types.FIND_COMPARABLES:
			return {
        ...state,
				cma: {
					...state.cma,
					status: 'searching',
				}
      }
		case Types.FIND_COMPARABLES_SUCCESS:
			data = action.params;
			selected_properties = {}
			for(let i in PROPERTY.STATUS ) {
				let currentStatus = PROPERTY.STATUS[i]
				selected_properties[currentStatus.key] = data.payload && data.payload[currentStatus.key] ?
						data.payload[currentStatus.key].map((i,idx)=>({
							index: idx,
							select: i.select
						})).filter(i=>i.select).map(i=>i.index) : [];
			}
			estimate = calculateEstimate(data.subject, data.payload, selected_properties, PROPERTY.STATUS.SOLD.key)

			let nextStep = data.total > 0 ? STEPS.RUN_CMA : state.step

			return {
        ...state,
        step: nextStep,
				cma: {
					...state.cma,
					status: 'success',
					result: action.params,
					active_status: PROPERTY.STATUS.SOLD.key,
					selected_properties: selected_properties,
					estimate: estimate
				}
      }
		case Types.FIND_COMPARABLES_ERROR:
			return {
				...state,
				cma: {
					...state.cma,
					status: 'fail'
				}
			}
		case Types.SWITCH_TYPE:
			return {
        ...state,
        step: STEPS.LOOKUP
      }
		case Types.RUN_CMA:
			// calculate estimate here
			return {
				...state,
				step: STEPS.SHOW_CMA_RESULTS,
			}
		case Types.GOOGLE_SPACES_SESSION_TOKEN:
			return {
				...state,
				google: {
					session: action.params.session_token
				}
			}
		case Types.CMA_SET_ACTIVE_STATUS:
			estimate = {}
			selected_properties = {}
			let status = action.params.status;
			if ( state.cma?.result && status ) {
				selected_properties = state.cma.result.payload[status].map((i,idx)=>({
					index: idx,
					select: i.select
				})).filter(i=>i.select).map(i=>i.index)
			}
			data = state.cma.result;
			let updateSelected = {
				...state.cma.selected_properties,
				[status]: selected_properties
			}
			estimate = calculateEstimate(data.subject, data.payload, updateSelected, status);

			return {
				...state,
				cma: {
					...state.cma,
					active_status: status,
					selected_properties: updateSelected,
					estimate: estimate
				}
			}
		case Types.CMA_SET_SELECTED_PROPERTY:
			let selectedIndex = [];
			estimate = {}
			if ( state.cma?.result && action.params.selectedIndex ) {
				selectedIndex = action.params.selectedIndex;

			}
			data = state.cma.result;
			selected_properties = {
				...state.cma.selected_properties,
				[state.cma.active_status]: selectedIndex
			}
			estimate = calculateEstimate(data.subject, data.payload, selected_properties, state.cma.active_status);

			return {
				...state,
				cma: {
					...state.cma,
					selected_properties: selected_properties,
					estimate: estimate
				},
			}
		case Types.SHOW_CMA_REPORTS:
			return {
        ...state,
				step: STEPS.SHOW_CMA_REPORT,
      }
		default:
			return state;
	}
};

export default FastCMAReducer;
