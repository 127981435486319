import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {PATHS, ROUTE_LIST} from '../constants/define';
import Layout from './Main';
import LoginRoute from './LoginRoute';
import PrivateRoute from './PrivateRoute';

import '../assets/index.scss';
import 'grapesjs/dist/css/grapes.min.css';
import VerifyAuthRoute from "./pages/authentication";

class MainCheck extends Component {

    componentDidMount() {
        // window.resizeLoadBody();
    }

    render() {
        return (
            <Route>
                <Switch>
                    <LoginRoute path="/login" component={Layout.Login}/>
                    <VerifyAuthRoute path="/auth/verify" component={Layout.Authentication}/>
                    {/*<Route path='/auth/verify' exact component={Layout.Authentication}/>*/}
                    <PrivateRoute path="/" exact component={Layout.Index}/>
                    <PrivateRoute path="/teams" exact component={Layout.Teams}/>
                    <PrivateRoute path="/teams/action" exact component={Layout.TeamsAction}/>
                    <PrivateRoute path="/teams/action/:id" exact component={Layout.TeamsAction}/>
                    <PrivateRoute path="/team-point" exact component={Layout.TeamPoint}/>
                    <PrivateRoute path="/team-point/detail" exact component={Layout.TeamPointDetail}/>
                    <PrivateRoute path="/contact" exact component={Layout.Contact}/>
                    <PrivateRoute path="/contact/action" exact component={Layout.ContactAction}/>
                    <PrivateRoute path="/contact/action/:id" exact component={Layout.ContactAction}/>
                    <PrivateRoute path="/tag" exact component={Layout.Tag}/>
                    <PrivateRoute path="/tag/action" exact component={Layout.TagAction}/>
                    <PrivateRoute path="/tag/action/:id" exact component={Layout.TagAction}/>
                    <PrivateRoute path="/email-template" exact component={Layout.Template}/>
                    <PrivateRoute path="/email-template/action" exact component={Layout.TemplateAction}/>
                    <PrivateRoute path="/email-template/action/:id" exact component={Layout.TemplateAction}/>
                    <PrivateRoute path="/email-history" exact component={Layout.EmailHistory}/>
                    <PrivateRoute path="/email-history/action" exact component={Layout.EmailHistoryAction}/>
                    <PrivateRoute path="/email-history/action/:id" exact component={Layout.EmailHistoryAction}/>
                    <PrivateRoute path="/bill/detail" exact component={Layout.Bill}/>
                    <PrivateRoute path="/cart" exact component={Layout.Cart}/>
                    <PrivateRoute path="/cart/result" exact component={Layout.CartResult}/>
                    <PrivateRoute path="/my-subscription" exact component={Layout.MySubscription}/>
                    <PrivateRoute path="/plans-pricing" exact component={Layout.PlansPricing}/>
                    <PrivateRoute path="/sms-template" exact component={Layout.SmsTemplate}/>
                    <PrivateRoute path="/sms-template/action" exact component={Layout.SmsTemplateAction}/>
                    <PrivateRoute path="/sms-template/action/:id" exact component={Layout.SmsTemplateAction}/>

                    <PrivateRoute path="/sms-history" exact component={Layout.SmsHistory}/>
                    <PrivateRoute path="/sms-history/action" exact component={Layout.SmsHistoryAction}/>
                    <PrivateRoute path="/sms-history/action/:id" exact component={Layout.SmsHistoryAction}/>

                    <PrivateRoute path="/inbox" exact component={Layout.InboxMessage}/>
                    {/*<PrivateRoute path="/workflow" exact component={Layout.Workflow}/>*/}
                    <PrivateRoute path="/workflow" exact component={Layout.WorkflowList}/>
                    <PrivateRoute path="/workflow/action" exact component={Layout.Workflow}/>
                    <PrivateRoute path="/workflow/action/:id" exact component={Layout.Workflow}/>
                    <PrivateRoute path="/task" exact component={Layout.Task}/>
                    <PrivateRoute path="/task/action" exact component={Layout.TaskAction}/>
                    <PrivateRoute path="/task/action/:id" exact component={Layout.TaskAction}/>
                    <PrivateRoute path="/settings" exact component={Layout.Settings}/>

                    <PrivateRoute path="/embedded-form" exact component={Layout.EmbeddedForm}/>
                    <PrivateRoute path="/embedded-form/action" exact component={Layout.EmbeddedFormAction}/>
                    <PrivateRoute path="/embedded-form/action/:id" exact component={Layout.EmbeddedFormAction}/>
                    <PrivateRoute path="/page-builders" exact isPageBuilders component={Layout.PageBuilders}/>
                    <PrivateRoute path="/voice-call" exact component={Layout.VoiceCall}/>
                    <Route path="/embedded-form/share" exact component={Layout.EmbeddedFormShare}/>

                    <PrivateRoute path="/403" exact component={Layout.Error403}/>
                    <Route path='' exact={false} component={Layout.Error}/>
                </Switch>
            </Route>

        );
    }
}

export default (MainCheck);

