import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/inboxAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.inboxInitStore(params)),
        [dispatch]
    )
}

export const ActionGetList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.inboxListAction(filters)),
        [dispatch]
    )
}

export const ActionGetItem = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.inboxDetailAction(params)),
        [dispatch]
    )
}

export const ActionMasterData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.inboxMasterAction(params)),
        [dispatch]
    )
}

export const ActionSend = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.inboxSendAction(params)),
        [dispatch]
    )
}
export const ActionUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.inboxUpdateAction(params)),
        [dispatch]
    )
}
