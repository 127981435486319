import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class ContactResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.name = data?.name || "";
        this.email = data?.email || "";
        this.phone_number = data?.phone_number || "";
        this.company = data?.company || "";
        this.job_title = data?.job_title || "";
        this.address = data?.address || "";
        this.owner = data?.owner || "";
        this.status = formatInt(data?.status);
        this.tag = data?.tag || [];
        this.created_date = data?.created_date || "";
        this.first_name = data?.first_name || "";
        this.last_name = data?.last_name || "";
        this.source = data?.source || "";
        this.extra_info = data?.extra_info || "";
        this.inbox_message_error = data?.inbox_message_error || "";
        this.is_delete = data?.is_delete;
        this.is_duplicate = data?.is_duplicate;
        this.is_opt_out = data?.is_opt_out;
        this.property_address = data?.property_address || "";
        this.property_city = data?.property_city || "";
        this.property_state = data?.property_state || "";
        this.property_zip_code = data?.property_zip_code || "";
        this.is_opt_out_email = data?.is_opt_out_email;
        this.notes = this.generateNotes(data?.notes || []);
        this.list_filters = this.generateListFilters(data || {});
        this.yr_bt = data?.yr_bt || "";
        this.estimated_equity = data?.estimated_equity || "";
        this.equity = data?.equity || "";
        this.appraised_value = data?.appraised_value || "";
        this.cell_phones_1 = data?.cell_phones_1 || "";
        this.cell_phones_2 = data?.cell_phones_2 || "";
        this.cell_phones_3 = data?.cell_phones_3 || "";
        this.cell_phones_4 = data?.cell_phones_4 || "";
        this.cell_phones_5 = data?.cell_phones_5 || "";
        this.email_addresses_2 = data?.email_addresses_2 || "";
        this.email_addresses_3 = data?.email_addresses_3 || "";
        this.email_addresses_4 = data?.email_addresses_4 || "";
        this.email_addresses_5 = data?.email_addresses_5 || "";
        this.lead_type = data?.lead_type || "";
        this.phone_numbers_2 = data?.phone_numbers_2 || "";
        this.phone_numbers_3 = data?.phone_numbers_3 || "";
        this.phone_numbers_4 = data?.phone_numbers_4 || "";
        this.phone_numbers_5 = data?.phone_numbers_5 || "";
        this.auction_date = data?.auction_date || "";
        this.latest_note = data?.latest_note || "";
        this.action_note = data?.action_note || [];
        this.row_tag = data?.row_tag || {};

    }

    generateNotes(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map(i => newItems.push({
                owner: {
                    id: i?.owner?.id || "",
                    email: i?.owner?.email || "",
                },
                note: i?.note,
                time: moment(i?.time).format(DATE_TIME_FORMAT.DATE_TIME),
                real_time: i?.time
            }))
        }
        return newItems
    }

    exportList() {
        return {
            id: this.id,
            name: this.name,
            email: this.email,
            phone_number: this.phone_number,
            company: this.company,
            job_title: this.job_title,
            address: this.address,
            owner: this.owner,
            status: this.status,
            tag: this.tag,
            created_date: moment(this.created_date).format(DATE_TIME_FORMAT.SHORT_DATE_US),
            first_name: this.first_name,
            last_name: this.last_name,
            source: this.source,
            extra_info: this.extra_info,
            inbox_message_error: this.inbox_message_error,
            is_delete: this.is_delete,
            is_duplicate: this.is_duplicate,
            is_opt_out: this.is_opt_out,
            property_address: this.property_address,
            property_city: this.property_city,
            property_state: this.property_state,
            property_zip_code: this.property_zip_code,
            is_opt_out_email: this.is_opt_out_email,
            notes: this.notes,
            list_filters: this.list_filters,
            yr_bt: this.yr_bt,
            estimated_equity: this.estimated_equity,
            equity: this.equity,
            appraised_value: this.appraised_value,
            cell_phones_1: this.cell_phones_1,
            cell_phones_2: this.cell_phones_2,
            cell_phones_3: this.cell_phones_3,
            cell_phones_4: this.cell_phones_4,
            cell_phones_5: this.cell_phones_5,
            email_addresses_2: this.email_addresses_2,
            email_addresses_3: this.email_addresses_3,
            email_addresses_4: this.email_addresses_4,
            email_addresses_5: this.email_addresses_5,
            lead_type: this.lead_type,
            phone_numbers_2: this.phone_numbers_2,
            phone_numbers_3: this.phone_numbers_3,
            phone_numbers_4: this.phone_numbers_4,
            phone_numbers_5: this.phone_numbers_5,
            auction_date: this.auction_date,
            action_note: this.action_note,
            row_tag: this.row_tag,
            latest_note: this.latest_note,
        }
    }

    generateListFilters(filters = {}) {
        let newFilters = []
        if (Object?.keys(filters).length > 0) {
            Object?.keys(filters).map(i => newFilters.push({
                value: i,
                params: filters[i]
            }))
        }
        return newFilters
    }

    exportListFilters() {
        return {
            listFilters: this.list_filters
        }
    }

    exportDetail() {
        return {
            id: this.id,
            name: this.name,
            email: this.email,
            phone_number: this.phone_number,
            company: this.company,
            job_title: this.job_title,
            address: this.address,
            owner: this.owner,
            tag: this.tag,
            source: this.source,
            extra_info: this.extra_info,
            notes: this.notes,
            status: (this.status).toString(),
            property_address: this.property_address,
            property_city: this.property_city,
            property_state: this.property_state,
            property_zip_code: this.property_zip_code,
            is_opt_out: this.is_opt_out,
            created_date: moment(this.created_date).format(DATE_TIME_FORMAT.SHORT_DATE_US),
            lead_type: this.lead_type,
            yr_bt: this.yr_bt,
            estimated_equity: this.estimated_equity,
            equity: this.equity,
            appraised_value: this.appraised_value,
            auction_date: this.auction_date,
            email_addresses_2: this.email_addresses_2,
            email_addresses_3: this.email_addresses_3,
            email_addresses_4: this.email_addresses_4,
            email_addresses_5: this.email_addresses_5,
            cell_phones_1: this.cell_phones_1,
            cell_phones_2: this.cell_phones_2,
            cell_phones_3: this.cell_phones_3,
            cell_phones_4: this.cell_phones_4,
            cell_phones_5: this.cell_phones_5,
            phone_numbers_2: this.phone_numbers_2,
            phone_numbers_3: this.phone_numbers_3,
            phone_numbers_4: this.phone_numbers_4,
            phone_numbers_5: this.phone_numbers_5,
        }
    }
}

export const column = ["action", "auction_date", "first_name", "last_name", "email", "phone_number", "tag", "address", "created_date", "extra_info", "yr_bt", "estimated_equity", "equity", "appraised_value", "owner"];

export const fieldDetailRemove = [];

export const filtersResponse = (filters) => {
    return removeObjectNullFull({
        search: filters?.search || "",
        source: filters?.source || "",
        tag: filters?.tag || "",
        status: filters?.status || "",
        is_owned: convertBooleanToInt(filters?.is_owned),
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    })
}

export class ContactMasterResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.column = this.generateColumn(data?.COLUMN || {});
        this.real_column = data?.COLUMN;
        this.source = data?.SOURCE;
        this.tag = data?.TAG
        this.status = data?.STATUS
        this.lead_type = data?.LEAD_TYPE
    }

    generateColumn(items = {}) {
        let newItems = [];
        if (Object.keys(items).length > 0) {
            Object.keys(items).map(i => newItems.push({
                label: items[i],
                value: i
            }))
        }
        return newItems
    }

    exportMaster() {
        return {
            column: this.column,
            real_column: this.real_column,
            status: this.status,
            tag: this.tag,
            source: this.source,
            lead_type: this.lead_type,
        }
    }
}
