import {LoremIpsum} from "lorem-ipsum";
import {useMemo} from "react";
import {Draggable} from "react-beautiful-dnd";
import {Tooltip} from "antd";

const lorem = new LoremIpsum();

const TaskItem = ({item, index}) => {
    const randomHeader = useMemo(() => lorem.generateWords(5), []);

    return (
        <Draggable draggableId={item.id} index={index}>
            {(provided, snapshot) => {
                return (
                    <div
                        className="task__item--drag-item"
                        ref={provided.innerRef}
                        snapshot={snapshot}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <div className="task__item--card-header fs--14px fw-bold">{item?.title}</div>
                        {/*<span>Content</span>*/}
                        <div className="task__item--card-footer">
                            {/*<span>{item.content}</span>*/}
                            <div className="task__item--author row">
                                <div className="col-6">
                                    <p className="fs--13px">{item.code || "001"}</p>
                                </div>
                                <div className="col-6 text-end">
                                    <Tooltip title={item?.assignee?.name}>
                                        <img
                                            className="task__item--avatar"
                                            src={item?.assignee?.avatar}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Draggable>
    );
}

export default TaskItem
