import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/embeddedAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.embeddedInitStore(params)),
        [dispatch]
    )
}

export const ActionGetList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.embeddedListAction(filters)),
        [dispatch]
    )
}

export const ActionGetItem = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.embeddedDetailAction(params)),
        [dispatch]
    )
}

export const ActionMasterData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.embeddedMasterAction(params)),
        [dispatch]
    )
}

export const ActionCreate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.embeddedCreateAction(params)),
        [dispatch]
    )
}
export const ActionUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.embeddedUpdateAction(params)),
        [dispatch]
    )
}

export const ActionHandleFormShare = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.embeddedHandleFormShareAction(params)),
        [dispatch]
    )
}
