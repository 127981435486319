import {all} from 'redux-saga/effects';
import {authSaga} from './authSaga';
import {fastCMASaga} from './fastCMASaga'
import {teamsSaga} from './teamsSaga'
import {teamPointSaga} from './teamPointSaga'
import {contactSaga} from './contactSaga'
import {uploadSaga} from './uploadSaga'
import {tagSaga} from './tagSaga'
import {templateSaga} from './templateSaga'
import {emailHistorySaga} from './emailHistorySaga'
import {billSaga} from './billSaga'
import {cartSaga} from './cartSaga'
import {invoiceSaga} from './invoiceSaga'
import {subscriptionSaga} from './subscriptionSaga'
import {plansPricingSaga} from './plansPricingSaga'
import {smsTemplateSaga} from './smsTemplateSaga'
import {smsHistorySaga} from './smsHistorySaga'
import {inboxSaga} from './inboxSaga'
import {workflowSaga} from './workflowSaga'
import {embeddedSaga} from './embeddedSaga'
import {pageBuildersSaga} from './pageBuildersSaga'
import {voiceSaga} from './voiceSaga'
import {taskSaga} from './taskSaga'

export default function* rootSaga() {
    yield all([
        authSaga(),
        fastCMASaga(),
        teamsSaga(),
        teamPointSaga(),
        contactSaga(),
        uploadSaga(),
        tagSaga(),
        templateSaga(),
        emailHistorySaga(),
        billSaga(),
        cartSaga(),
        invoiceSaga(),
        subscriptionSaga(),
        plansPricingSaga(),
        smsTemplateSaga(),
        smsHistorySaga(),
        inboxSaga(),
        workflowSaga(),
        embeddedSaga(),
        pageBuildersSaga(),
        voiceSaga(),
        taskSaga(),
    ])
}
