import * as Types from '../types/Embedded';

export const embeddedMasterAction = (filters) => ({type: Types.EMBEDDED_MASTER_ACTION, filters});
export const embeddedMasterSuccess = (master) => ({
    type: Types.EMBEDDED_MASTER_SUCCESS,
    master
});

export const embeddedListAction = (filters) => ({type: Types.EMBEDDED_LIST_ACTION, filters});
export const embeddedListSuccess = (list) => ({
    type: Types.EMBEDDED_LIST_SUCCESS,
    list
});

export const embeddedDetailAction = (params) => ({type: Types.EMBEDDED_DETAIL_ACTION, params});
export const embeddedDetailSuccess = (detail) => ({
    type: Types.EMBEDDED_DETAIL_SUCCESS,
    detail
});

export const embeddedUpdateAction = (params) => ({type: Types.EMBEDDED_UPDATE_ACTION, params});
export const embeddedUpdateSuccess = (updateData) => ({
    type: Types.EMBEDDED_UPDATE_SUCCESS,
    updateData
});

export const embeddedCreateAction = (params) => ({type: Types.EMBEDDED_CREATE_ACTION, params});
export const embeddedCreateSuccess = (createData) => ({
    type: Types.EMBEDDED_CREATE_SUCCESS,
    createData
});

export const embeddedHandleFormShareAction = (params) => ({type: Types.EMBEDDED_HANDLE_FORM_SHARE_ACTION, params});
export const embeddedHandleFormShareSuccess = (components) => ({
    type: Types.EMBEDDED_HANDLE_FORM_SHARE_SUCCESS,
    components
});

export const embeddedInitStore = (params) => ({type: Types.EMBEDDED_INIT, params});
export const embeddedClearStore = (params) => ({type: Types.EMBEDDED_FAIL, params});
