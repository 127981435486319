import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class TeamPointResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.team_point = data?.team_point || 0;
        this.team_point_level_1 = data?.team_point_level_1 || 0;
        this.team_point_level_2 = data?.team_point_level_2 || 0;
        this.team_point_level_3 = data?.team_point_level_3 || 0;
        this.team_point_level_4 = data?.team_point_level_4 || 0;
        this.team_trans = data?.team_trans || 0;
        this.team_trans_level_1 = data?.team_trans_level_1 || 0;
        this.team_trans_level_2 = data?.team_trans_level_2 || 0;
        this.team_trans_level_3 = data?.team_trans_level_3 || 0;
        this.team_trans_level_4 = data?.team_trans_level_4 || 0;
        this.total_commission = data?.total_commission || 0;
        this.team_commission = data?.team_commission || 0;
        this.team_commission_level_1 = data?.team_commission_level_1 || 0;
        this.team_commission_level_2 = data?.team_commission_level_2 || 0;
        this.team_commission_level_3 = data?.team_commission_level_3 || 0;
        this.team_commission_level_4 = data?.team_commission_level_4 || 0;
        this.email = data?.email || "";
        this.total_point = data?.total_point || 0;
        this.total_trans = data?.total_trans || 0;
        this.email = data?.email || "";
        this.point_amount = data?.point_amount || 0;
        this.source = data?.source || "";
        this.created_at = data?.created_at || "";
        this.referral = data?.referral || "";
        this.note = data?.note || "";
    }

    exportList() {
        return {
            id: this.id,
            team_point: this.team_point,
            team_point_level_1: this.team_point_level_1,
            team_point_level_2: this.team_point_level_2,
            team_point_level_3: this.team_point_level_3,
            team_point_level_4: this.team_point_level_4,
            team_trans: this.team_trans,
            team_trans_level_1: this.team_trans_level_1,
            team_trans_level_2: this.team_trans_level_2,
            team_trans_level_3: this.team_trans_level_3,
            team_trans_level_4: this.team_trans_level_4,
            email: this.email,
            total_point: this.total_point,
            total_trans: this.total_trans,
            total_commission: this.total_commission,
            team_commission: this.team_commission,
            team_commission_level_1: this.team_commission_level_1,
            team_commission_level_2: this.team_commission_level_2,
            team_commission_level_3: this.team_commission_level_3,
            team_commission_level_4: this.team_commission_level_4,
        }
    }

    exportDetail() {
        return {
            id: this.id,
            email: this.email,
            point_amount: this.point_amount,
            source: this.source,
            created_at: this?.created_at ? moment(this?.created_at).format(DATE_TIME_FORMAT.SHORT_DATE_US) : "",
            referral: this.referral,
            note: this.note,
        }
    }
}

export const column = ["email", "total_point", "total_commission", "total_trans"];

export const columnDetail = ["email", "point_amount", "note", "source", "created_at", "referral"]

export const fieldDetailRemove = [];

export const filtersResponse = (filters) => {
    return removeObjectNullFull({
        search: filters?.search || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    })
}
