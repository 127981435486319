import Index from "./pages";
import Teams from "./pages/teams";
import TeamsAction from "./pages/teams/actions";
import TeamPoint from "./pages/teamPoint";
import TeamPointDetail from "./pages/teamPoint/detail";
import Contact from "./pages/contact";
import ContactAction from "./pages/contact/actions";
import Tag from "./pages/tag";
import TagAction from "./pages/tag/actions";
import Template from "./pages/template";
import TemplateAction from "./pages/template/actions";
import EmailHistory from "./pages/emailHistory";
import EmailHistoryAction from "./pages/emailHistory/actions";
import Bill from "./pages/bill";
import Cart from "./pages/cart";
import CartResult from "./pages/cart/result";
import MySubscription from "./pages/mySubscription";
import PlansPricing from "./pages/plansPricing";
import Login from "./Login";
import Error403 from "./403";
import Error from "./Error";
import SmsTemplate from "./pages/smsTemplate";
import SmsTemplateAction from "./pages/smsTemplate/actions";
import SmsHistory from "./pages/smsHistory";
import SmsHistoryAction from "./pages/smsHistory/actions";
import InboxMessage from "./pages/inbox";
import Workflow from "./pages/workflow/detail";
import WorkflowList from "./pages/workflow";
import TaskAction from "./pages/task/actions";
import Task from "./pages/task";
import Settings from "./pages/settings";
import {Authentication} from "./pages/authentication";
import EmbeddedFormAction from "./pages/embeddedForm/action";
import EmbeddedForm from "./pages/embeddedForm";
import EmbeddedFormShare from "./pages/embeddedForm/share";
import PageBuilders from "./pages/pageBuilders";
import VoiceCall from "./pages/voiceCall";
// import Login from "../Login";

export default {
    Index,
    Teams,
    TeamsAction,
    TeamPoint,
    TeamPointDetail,
    Contact,
    ContactAction,
    Template,
    TemplateAction,
    Tag,
    TagAction,
    EmailHistory,
    EmailHistoryAction,
    Bill,
    Cart,
    CartResult,
    MySubscription,
    PlansPricing,
    SmsTemplate,
    SmsTemplateAction,
    SmsHistory,
    SmsHistoryAction,
    InboxMessage,
    Login,
    Workflow,
    WorkflowList,
    TaskAction,
    Settings,
    Error403,
    Error,
    Authentication,
    EmbeddedFormAction,
    EmbeddedForm,
    EmbeddedFormShare,
    PageBuilders,
    VoiceCall,
    Task
    // Login,
};
