import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/Inbox';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/inboxService';
import * as FunctionAction from '../actions/inboxAction';
import {message} from "antd";
import InboxRequest from "../mapping/Request/InboxRequest";
import TAG_DEFINE from "../../constants/common";

function* actionGetListInbox() {
    yield takeLatest(Types.INBOX_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(action.filters);
        const result = yield call(FunctionService.actionList, params);
        yield put(FunctionAction.inboxListSuccess(result));
    }, errorHandle(Types.INBOX_FAIL)))
}

function* actionGetItem() {
    yield takeLatest(Types.INBOX_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionGetDetail, action?.params?.id);
        yield put(FunctionAction.inboxDetailSuccess(result));
    }, errorHandle(Types.INBOX_FAIL)))
}

// function* actionGetMasterInbox() {
//     yield takeLatest(Types.INBOX_MASTER_ACTION, Func.sagaWrapper(function* (action) {
//         const result = yield call(FunctionService.masterInbox, action.params);
//         yield put(FunctionAction.inboxMasterSuccess(result));
//     }, errorHandle(Types.INBOX_FAIL)))
// }

function* sendAction() {
    yield takeLatest(Types.INBOX_SEND_ACTION, Func.sagaWrapper(function* (action) {
        const data = new InboxRequest(action.params).exportCreate();
        const result = yield call(FunctionService.send, data, action.params?.id);
        yield put(FunctionAction.inboxSendSuccess(result));
        yield put(FunctionAction.inboxDetailAction({id: action.params.id}));
    }, errorHandle(Types.INBOX_FAIL)))
}

// function* updateAction() {
//     yield takeLatest(Types.INBOX_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
//         const data = new InboxRequest(action.params).exportUpdate();
//         yield call(FunctionService.update, data);
//         message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
//         yield put(FunctionAction.inboxDetailAction({id: action.params.id}));
//     }, errorHandle(Types.INBOX_FAIL)))
// }

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        console.log("===> ", e)
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* inboxSaga() {
    yield all([
        fork(actionGetListInbox),
        fork(sendAction),
        // fork(actionGetMasterInbox),
        fork(actionGetItem),
        // fork(updateAction),
    ])
}
