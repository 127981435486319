import ModalCreateNote from "../../contact/components/ModalCreateNote";
import {Button, Collapse, Form, Spin, Tabs} from "antd";
import React, {useEffect, useState} from "react";
import TAG_DEFINE from "../../../../constants/common";
import {ActionCreate, ActionGetItem, ActionInit, ActionMasterData, ActionUpdate} from "../../../../data/hooks/contact";
import {useSelector} from "react-redux";
import {selectFetching, selectItem, selectMasterData} from "../../../../data/reselects/contactSelector";

const ContactDetailNote = props => {
    const [form] = Form.useForm(),
        [isFetching, setIsFetching] = useState(false),
        [typePage, setTypePage] = useState(TAG_DEFINE.ACTION.create),
        [masterStatus, setMasterStatus] = useState({}),
        [masterTag, setMasterTag] = useState({}),
        [isUpdate, setIsUpdate] = useState(false),
        [isCreateNote, setIsCreateNote] = useState(false),
        // ============== ACTION ==============
        getMasterData = ActionMasterData(),
        actionCreate = ActionCreate(),
        actionUpdate = ActionUpdate(),
        getInitAction = ActionInit(),
        actionGetItem = ActionGetItem(),
        // ============== SELECT DATA ==============
        itemFetching = useSelector(selectFetching()),
        itemDetail = useSelector(selectItem()),
        itemMasterData = useSelector(selectMasterData());

    useEffect(() => {
        // const {match} = props;
        // if (Object.keys(match?.params).length > 0) {
        //     setIsUpdate(true);
        //     if (Object.keys(itemDetail || {}).length < 1) {
        //         setTypePage(TAG_DEFINE.ACTION.update)
        //         actionGetItem({id: match?.params?.id, props: props});
        //     }
        // } else {
        //     setIsUpdate(false)
        // }
    }, [props.match]);

    useEffect(() => {
        getMasterData()

        return () => {
            // getInitAction({clearDetail: true});
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);

    useEffect(() => {
        if (Object.keys(itemDetail || {}).length > 0) {
            form.setFieldsValue(itemDetail)
        }
    }, [itemDetail]);

    useEffect(() => {
        if (Object.keys(itemMasterData?.status || {}).length > 0) {
            setMasterStatus(itemMasterData?.status)
        }
        if (Object.keys(itemMasterData?.tag || {}).length > 0) {
            setMasterTag(itemMasterData?.tag)
        }
    }, [itemMasterData]);

    const onFinish = (val) => {
        if (isUpdate) {
            actionUpdate({...val, props, id: itemDetail?.id})
        } else {
            actionCreate({...val, props})
        }
    }

    const genExtra = (val) => {
        return <p className="mb-0 fs--12px">{val}</p>
    };

    return (
        <Spin tip="Loading..." spinning={isFetching}>
            <div className="container bg-white mt-0 pb-4 mb-2">
                <ModalCreateNote
                    isOpen={isCreateNote}
                    isClose={e => setIsCreateNote(false)}
                    isAction={{isDetail: true, id: itemDetail?.id}}
                />
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Notes" key="1">
                        <div className="text-end">
                            <Button type="primary" htmlType="button" className="mb-3"
                                    disabled={props?.isDisabledField}
                                    onClick={e => setIsCreateNote(true)}>
                                Create Note
                            </Button>
                        </div>
                        {
                            ((itemDetail?.notes || []).length > 0) && (
                                <Collapse
                                    bordered={false}
                                    // defaultActiveKey={['1']}
                                    activeKey={Object?.keys(itemDetail?.notes || [])}
                                    expandIcon={({isActive}) => null}
                                    // expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                    className="bg-transparent"
                                    collapsible="disabled"
                                >
                                    {
                                        (itemDetail?.notes)?.map((i, k) => {
                                            return (
                                                <Collapse.Panel
                                                    key={k}
                                                    header={<p className="mb-0 color--black fw-bold">Note <span
                                                        className="color--description fw--300 fs--12px">by {i?.owner?.email}</span>
                                                    </p>}
                                                    className="mb-3"
                                                    extra={genExtra(i?.time)}>
                                                    <p className="mb-0">{i?.note}</p>
                                                </Collapse.Panel>
                                            )
                                        })
                                    }
                                </Collapse>
                            )
                        }
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </Spin>
    )
}

export default ContactDetailNote
