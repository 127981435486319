import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class TeamsResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.referral_email = data?.referral_email || "";
        this.email = data?.email || "";
        this.first_name = data?.first_name || "";
        this.last_name = data?.last_name || "";
        this.phone_number = data?.phone_number || "";
        this.status = (data?.status || "0").toString();
        this.membership_code = data?.membership_code || "";
        this.address = data?.address || "";
        this.created_at = data?.created_at ? moment(data?.created_at).format(DATE_TIME_FORMAT.SHORT_DATE_US) : "";
    }

    exportList() {
        return {
            id: this.id,
            referral_email: this.referral_email,
            email: this.email,
            first_name: this.first_name,
            last_name: this.last_name,
            phone_number: this.phone_number,
            status: this.status,
            membership_code: this.membership_code,
            created_at: this.created_at
        }
    }

    exportDetail() {
        return {
            id: this.id,
            email: this.email,
            first_name: this.first_name,
            last_name: this.last_name,
            phone_number: this.phone_number,
            status: this.status,
            membership_code: this.membership_code,
            address: this.address,
            created_at: this.created_at,
        }
    }
}

export const column = ["referral_email", "email", "first_name", "last_name", "phone_number", "membership_code", "status", "created_at"];

export const fieldDetailRemove = [];

export const filtersResponse = (filters) => {
    return removeObjectNullFull({
        search: filters?.search || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    })
}

export class TeamsMasterResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.status = data?.STATUS
    }

    exportMaster() {
        return {
            status: this.status,
        }
    }
}
