export const EMBEDDED_MASTER_ACTION = "EMBEDDED_MASTER_ACTION";
export const EMBEDDED_MASTER_SUCCESS = "EMBEDDED_MASTER_SUCCESS";

export const EMBEDDED_LIST_ACTION = "EMBEDDED_LIST_ACTION";
export const EMBEDDED_LIST_SUCCESS = "EMBEDDED_LIST_SUCCESS";

export const EMBEDDED_DETAIL_ACTION = "EMBEDDED_DETAIL_ACTION";
export const EMBEDDED_DETAIL_SUCCESS = "EMBEDDED_DETAIL_SUCCESS";

export const EMBEDDED_UPDATE_ACTION = "EMBEDDED_UPDATE_ACTION";
export const EMBEDDED_UPDATE_SUCCESS = "EMBEDDED_UPDATE_SUCCESS";

export const EMBEDDED_CREATE_ACTION = "EMBEDDED_CREATE_ACTION";
export const EMBEDDED_CREATE_SUCCESS = "EMBEDDED_CREATE_SUCCESS";

export const EMBEDDED_HANDLE_FORM_SHARE_ACTION = "EMBEDDED_HANDLE_FORM_SHARE_ACTION";
export const EMBEDDED_HANDLE_FORM_SHARE_SUCCESS = "EMBEDDED_HANDLE_FORM_SHARE_SUCCESS";

export const EMBEDDED_INIT = "EMBEDDED_INIT";
export const EMBEDDED_FAIL = "EMBEDDED_FAIL";
