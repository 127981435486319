import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class TagResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.tag = data?.tag || "";
        this.owner = data?.owner || "";
        this.created_date = data?.created_date || "";
        this.emails = data?.emails || "";
    }

    exportList() {
        return {
            id: this.id,
            tag: this.tag,
            owner: this.owner,
            created_date: moment(this.created_date).format(DATE_TIME_FORMAT.SHORT_DATE_US),
        }
    }

    exportDetail() {
        return {
            id: this.id,
            tag: this.tag,
            owner: this.owner,
            created_date: moment(this.created_date).format(DATE_TIME_FORMAT.SHORT_DATE_US),
            emails: this.emails
        }
    }
}

export const column = ["tag", "owner", "created_date"];

export const fieldDetailRemove = [];

export const filtersResponse = (filters) => {
    return removeObjectNullFull({
        search: filters?.search || "",
        is_owned: convertBooleanToInt(filters?.is_owned),
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    })
}

export class TagMasterResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.status = data?.STATUS
    }

    exportMaster() {
        return {
            status: this.status,
        }
    }
}
