import {Badge, Card, Form, Input, Space, Spin} from "antd";
import {BsFillTelephoneFill, BsFillTelephoneXFill} from "react-icons/bs";
import {useEffect, useMemo, useState} from "react";
import {Device} from "twilio-client";
import CallingLoading from "./CallLoading";
import CallnComingLoading from "./CallInComingLoading";
import LoadingInComing from "./LoadingInComing";
import TAG_DEFINE from "../../../../constants/common";
import queryString from "query-string";
import {removeObjectNullFull, setParamsUrlFilter} from "../../../../utils/functions";
import {ROUTE_REDIRECT} from "../../../../constants/define";

const states = {
    CONNECTING: "Connecting",
    READY: "Ready",
    INCOMING: "Incoming",
    INCOMING_ON_CALL: "IncomingOnCall",
    ON_CALL: "On_call",
    OFFLINE: "Offline"
};

const Ready = props => {
    const [form] = Form.useForm()
    const [token, setToken] = useState(),
        [state, setState] = useState(),
        [isFetching, setIsFetching] = useState(true),
        [isCalling, setIsCalling] = useState(false),
        [audioDevices, setAudioDevices] = useState([]),
        [ringtoneDevices, setRingtoneDevices] = useState([]),
        [messageLogs, setMessageLogs] = useState([]),
        [isDevicesSupport, setIsDevicesSupport] = useState(true),
        [isInComing, setIsInComing] = useState(false),
        [micVolume, setMicVolume] = useState(0),
        [micBackground, setMicBackground] = useState(""),
        [speakerVolume, setSpeakerVolume] = useState(0),
        [speakerBackground, setSpeakerBackground] = useState("");
    const [number, setNumber] = useState("");
    const [conn, setConn] = useState(null);
    const [device, setDevice] = useState(null);

    useEffect(() => {
        if (Object.keys(queryString.parse(props.location.search)).length > 0) {
            let params = queryString.parse(props.location.search, {arrayFormat: 'comma'});
            form.setFieldsValue(params)
            // props.history.replace(setParamsUrlFilter(ROUTE_REDIRECT.VOICE_CALL.HOME, {}))
        }
    }, []);

    useEffect(() => {
        const device = new Device();
        if (props?.token) {
            device.setup(props?.token, {debug: false});

            device.on("ready", () => {
                // console.log(1111, "Ready", device);
                setMessageLogs(e => [...e, "Already to make and receive calls!"])
                setIsFetching(false)
                setDevice(device);
                props?.getDevice(device);
                setState(states.READY);
                if (Object.keys(queryString.parse(props.location.search)).length > 0) {
                    let params = queryString.parse(props.location.search, {arrayFormat: 'comma'});
                    if (params?.phone_number) {
                        device.connect({To: params?.phone_number})
                        props.history.replace(setParamsUrlFilter(ROUTE_REDIRECT.VOICE_CALL.HOME, {}))
                    }
                }
            });
            device.on("connect", connection => {
                // console.log(2222, "Connect event", state);
                setConn(connection);
                connection.on("volume", function (inputVolume, outputVolume) {
                    // console.log({inputVolume, outputVolume})
                    var inputColor = "red";
                    if (inputVolume < 0.5) {
                        inputColor = "green";
                    } else if (inputVolume < 0.75) {
                        inputColor = "yellow";
                    }
                    setMicVolume(Math.floor((inputVolume) * 100))
                    setMicBackground(inputColor)
                    var outputColor = "red";
                    if (outputVolume < 0.5) {
                        outputColor = "green";
                    } else if (outputVolume < 0.75) {
                        outputColor = "yellow";
                    }
                    setSpeakerVolume(Math.floor((outputVolume) * 100))
                    setSpeakerBackground(outputColor)
                });
                if (states.INCOMING !== state) {
                    setIsCalling(false)
                    setState(states.ON_CALL);
                }
            });

            device.on("incoming", connection => {
                // console.log(33333, "InComing", connection);
                setIsCalling(true)
                setIsInComing(true)
                setState(states.INCOMING);
                setConn(connection);
                setMessageLogs(e => [...e, `Incoming Call from ${connection?.parameters?.From}`])
                connection.on("reject", () => {
                    setMicBackground("")
                    setSpeakerBackground("")
                    setMessageLogs(e => [...e, `Reject Call from ${connection?.parameters?.From}`])
                    setState(states.READY);
                    setIsCalling(false)
                    setIsInComing(false)
                    setConn(null);
                });
                connection.on("accept", () => {
                    setMessageLogs(e => [...e, `Accept Call from ${connection?.parameters?.From}`])
                    setState(states.INCOMING_ON_CALL);
                    // setIsCalling(false)
                    // setConn(null);
                });
                connection.on("disconnect", () => {
                    setMicBackground("")
                    setSpeakerBackground("")
                    setMessageLogs(e => [...e, `Hang up Call from ${connection?.parameters?.From}`])
                    // setState(states.READY);
                    // setIsCalling(false)
                    setIsInComing(false)
                    setConn(null);
                });
            });
            device.on("cancel", () => {
                // console.log(4444, "cancel");
                setIsCalling(false)
                setState(states.READY);
                setConn(null);
            });
            device.on("reject", () => {
                // console.log(5555, "reject");
                setIsCalling(false)
                setState(states.READY);
                setConn(null);
            });

            device.on("disconnect", () => {
                setState(states.READY);
                setConn(null);
            });

            device.audio.on("deviceChange", e => generateAllDevice(device));

            device.on('error', (twilioError, call) => {
                setMessageLogs(e => [...e, twilioError])
                // console.log('An error has occurred: ', twilioError);
            });

            if (!device.audio.isOutputSelectionSupported) {
                setIsDevicesSupport(false)
            }
            // console.log(7778, device.audio.availableInputDevices)
            // console.log(5454, device.audio.availableOutputDevices)
        }
    }, [props?.token]);

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({audio: true});
        return () => {
            device.destroy();
            setDevice(null);
            setState(states.OFFLINE);
            window.location.reload();
        };
    }, [])

    const onCall = val => {
        if (val?.phone_number) {
            setMessageLogs(e => [...e, `Call To ${val?.phone_number}`])
            setIsCalling(true)
            device.connect({To: val?.phone_number})
        }
    }

    const onHangup = () => {
        setMessageLogs(e => [...e, `Hang up Call`])
        setMicBackground("")
        setSpeakerBackground("")
        device.disconnectAll();
    }

    const generateAllDevice = (item) => {
        let getItem = device ? device : item
        if (getItem) {
            let audioDevices = [],
                ringtoneDevices = [];
            getItem.audio.availableOutputDevices.forEach(function (device, id) {
                let isActiveSpeaker = getItem.audio.speakerDevices.get().size === 0 && id === "default";
                getItem.audio.speakerDevices.get().forEach(function (device) {
                    if (device.deviceId === id) {
                        isActiveSpeaker = true;
                    }
                });
                let isActiveRingtone = getItem.audio.ringtoneDevices.get().size === 0 && id === "default";
                getItem.audio.ringtoneDevices.get().forEach(function (device) {
                    if (device.deviceId === id) {
                        isActiveSpeaker = true;
                    }
                });

                if (id) {
                    audioDevices.push(<option value={id} selected={isActiveSpeaker}>{device.label}</option>)
                    ringtoneDevices.push(<option value={id} selected={isActiveRingtone}>{device.label}</option>)
                }
            });
            setAudioDevices(audioDevices)
            setRingtoneDevices(ringtoneDevices)
        }
    }

    const getMicVolume = useMemo(() => micVolume, [micVolume]);
    const getMicBackground = useMemo(() => micBackground, [micBackground]);

    const getSpeakerVolume = useMemo(() => speakerVolume, [speakerVolume]);
    const getSpeakerBackground = useMemo(() => speakerBackground, [speakerBackground]);

    // console.log("%c===== STATUS ====>", 'color: red', state, conn, getMicVolume)

    return (
        <Spin spinning={isFetching}>
            <div className="row">
                <div className="col-3">
                    <Card title={<p className="h5 text-center">Your Device Info</p>}>
                        {
                            (audioDevices.length > 0 && ringtoneDevices.length > 0) ? (
                                <>
                                    <p className="mb-2"><span
                                        className="fw-bold">Your client name:</span> {props?.identify || ""}</p>
                                    <p className="fs--14px fw-bold">Speaker Devices</p>
                                    <select id="ringtone-devices" multiple
                                            onChange={e => device.audio.speakerDevices.set(e.target.value)}>{audioDevices}</select>
                                    <p className="fs--14px fw-bold">Ringtone Devices</p>
                                    <select id="ringtone-devices" multiple
                                            onChange={e => device.audio.ringtoneDevices.set(e.target.value)}>{ringtoneDevices}</select>
                                </>
                            ) : null
                        }

                    </Card>
                </div>
                <div className="col-6">
                    <Card title={<p className="h5 text-center">Make a Call</p>}>
                        {
                            (isCalling && isInComing) ? (
                                <div className="text-center mb-5">
                                    <CallnComingLoading/>
                                </div>
                            ) : null
                        }
                        {
                            conn ? (
                                <div
                                    className={!isInComing ? "d-none" : ""}
                                >
                                    <LoadingInComing
                                        call={conn}
                                        fromPhone={conn?.parameters?.From}
                                        onReject={e => {
                                            conn.reject();
                                        }}
                                        onListen={e => {
                                            conn.accept();
                                        }}
                                        onHangUp={e => {
                                            conn.disconnect();
                                        }}
                                    />
                                </div>
                            ) : null
                        }
                        <div style={{
                            width: "60%",
                            margin: "auto"
                        }}>
                            <Form
                                className={isInComing ? "d-none" : ""}
                                layout="vertical"
                                onFinish={onCall}
                                form={form}
                            >
                                <div className="row align-items-center text-center">
                                    <div className="col-10">
                                        <Form.Item
                                            label="Enter a phone number or client name"
                                            name="phone_number"
                                        >
                                            <Input disabled={state === states.ON_CALL}/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-2">
                                        <div
                                            onClick={e => {
                                                if (state !== states.ON_CALL) {
                                                    form.submit()
                                                } else {
                                                    onHangup()
                                                }
                                            }}
                                            style={{
                                                // backgroundColor: "#F33A38",
                                                backgroundColor: state !== states.ON_CALL ? "#1ECB62" : "#F33A38",
                                                width: "40px",
                                                height: "40px",
                                                textAlign: "center",
                                                padding: "8px 0",
                                                borderRadius: "50%",
                                                cursor: "pointer"
                                            }}>
                                            {
                                                state !== states.ON_CALL ? (
                                                    <BsFillTelephoneFill className="color--white" size={18}/>
                                                ) : (
                                                    <BsFillTelephoneXFill className="color--white" size={18}/>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        {
                            ((state === states.ON_CALL) || (state === states.INCOMING_ON_CALL)) ? (
                                <>
                                    <div className="mb-3">
                                        <p className="text-center fw-bold mb-1">Mic Volume</p>
                                        <div className="volume-control relative">
                                            <div className="volume-bar">
                                                <div
                                                    className="volume-level"
                                                    style={{
                                                        width: `${getMicVolume}%`,
                                                        backgroundColor: getMicBackground
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="text-center fw-bold mb-1">Speaker Volume</p>
                                        <div className="volume-control relative">
                                            <div className="volume-bar">
                                                <div
                                                    className="volume-level"
                                                    style={{
                                                        width: `${getSpeakerVolume}%`,
                                                        backgroundColor: getSpeakerBackground
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : null
                        }
                    </Card>
                </div>
                <div className="col-3">
                    <Card title={<p className="h5 text-center">Event Log</p>}>
                        <div style={{
                            maxHeight: "300px",
                            overflowY: "scroll"
                        }}>
                            {
                                messageLogs.length > 0 ? (
                                    messageLogs.map((i, k) => <div key={k}><Badge className="mb-2" color="#40A2A1"
                                                                                  text={i}/>
                                    </div>)
                                ) : (
                                    <div className="snippet" data-title="dot-carousel">
                                        <div className="stage">
                                            <div className="dot-carousel"></div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </Card>
                </div>
            </div>
        </Spin>
    )
}

export default Ready
