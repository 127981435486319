import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class CartRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        // this.redirect_url = data?.redirect_url || "http://localhost:3002/cart/result";
        this.redirect_url = data?.redirect_url || `${process.env.REACT_APP_BASE_URL}/cart/result`;
        this.package_id = data?.package_id || "";
    }

    exportCreate() {
        return {
            redirect_url: this.redirect_url,
        }
    }

    exportUpdate() {
        return {
            package_id: this.package_id
        }
    }

}

export const common = {
    ACTION_FIELD: {},
}
