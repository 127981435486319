import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class AuthResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.hash = data?.hash || "";
        this.general = {
            popup: data?.popup,
            cart: {
                count: data?.cart?.count || 0
            },
            inbox: {
                count: data?.inbox?.count || 0
            },
            companies: this.generateGeneralCompanies(data?.companies || [])
        };
        this.setting = {
            check_hash: data?.check_hash || "",
            marketing_email: data?.marketing_email || "",
            marketing_email_valid: data?.marketing_email_valid || "",
            marketing_email_api_key: data?.marketing_email_api_key || "",
            sms_account_sid: data?.sms_account_sid || "",
            sms_token: data?.sms_token || "",
            sms_phone_number: data?.sms_phone_number || "",
            reiq_api_key: data?.reiq_api_key || "",
            reiq_first_name: data?.reiq_first_name || "",
            reiq_last_name: data?.reiq_last_name || "",
            reiq_email: data?.reiq_email || "",
            reiq_phone_number: data?.reiq_phone_number || "",
            reiq_extra_info: data?.reiq_extra_info ? data?.reiq_extra_info?.split(",") : [],
            sms_webhook: data?.sms_webhook || "",
            megalith_api_key: data?.megalith_api_key || "",
            reiq_address: data?.reiq_address || "",
            reiq_property_address: data?.reiq_property_address || "",
            reiq_property_city: data?.reiq_property_city || "",
            reiq_property_state: data?.reiq_property_state || "",
            reiq_property_zip_code: data?.reiq_property_zip_code || "",
            monday_config: data?.monday_config || [],
            podio_config: data?.podio_config || [],
            monday_api_key: data?.monday_api_key || "",
            monday_board: data?.monday_board || "",
            monday_address: data?.monday_address || "",
            monday_email: data?.monday_email || "",
            monday_extra_info: data?.monday_extra_info || "",
            monday_first_name: data?.monday_first_name || "",
            monday_last_name: data?.monday_last_name || "",
            monday_phone_number: data?.monday_phone_number || "",
            monday_property_address: data?.monday_property_address || "",
            monday_property_city: data?.monday_property_city || "",
            monday_property_state: data?.monday_property_state || "",
            monday_zip_code: data?.monday_zip_code || "",
            hubspot_api_key: data?.hubspot_api_key || "",
            hubspot_first_name: data?.hubspot_first_name || "",
            hubspot_last_name: data?.hubspot_last_name || "",
            hubspot_email: data?.hubspot_email || "",
            hubspot_phone_number: data?.hubspot_phone_number || "",
            hubspot_extra_info: data?.hubspot_extra_info || "",
            hubspot_address: data?.hubspot_address || "",
            hubspot_property_address: data?.hubspot_property_address || "",
            hubspot_property_city: data?.hubspot_property_city || "",
            hubspot_property_state: data?.hubspot_property_state || "",
            hubspot_property_zip_code: data?.hubspot_property_zip_code || "",

            podio_client_id: data?.podio_client_id || "",
            podio_client_secret: data?.podio_client_secret || "",
            podio_app_id: data?.podio_app_id || "",
            podio_app_token: data?.podio_app_token || "",
            podio_address: data?.podio_address || "",
            podio_property_address: data?.podio_property_address || "",
            podio_property_city: data?.podio_property_city || "",
            podio_property_state: data?.podio_property_state || "",
            podio_property_zip_code: data?.podio_property_zip_code || "",
            podio_first_name: data?.podio_first_name || "",
            podio_last_name: data?.podio_last_name || "",
            podio_email: data?.podio_email || "",
            podio_phone_number: data?.podio_phone_number || "",
            podio_extra_info: data?.podio_extra_info || "",
            podio_email_address_2: data?.podio_email_address_2 || "",
            podio_email_address_3: data?.podio_email_address_3 || "",
            podio_email_address_4: data?.podio_email_address_4 || "",
            podio_email_address_5: data?.podio_email_address_5 || "",
            podio_phone_number_2: data?.podio_phone_number_2 || "",
            podio_phone_number_3: data?.podio_phone_number_3 || "",
            podio_phone_number_4: data?.podio_phone_number_4 || "",
            podio_phone_number_5: data?.podio_phone_number_5 || "",
            podio_cell_phones_1: data?.podio_cell_phones_1 || "",
            podio_cell_phones_2: data?.podio_cell_phones_2 || "",
            podio_cell_phones_3: data?.podio_cell_phones_3 || "",
            podio_cell_phones_4: data?.podio_cell_phones_4 || "",
            podio_cell_phones_5: data?.podio_cell_phones_5 || "",
            podio_lead_type: data?.podio_lead_type || "",


            voice_api_sid: data?.voice_api_sid || "",
            voice_api_key: data?.voice_api_key || "",
            voice_twiml_app_sid: data?.voice_twiml_app_sid || "",
            voice_forward_phone_number: data?.voice_forward_phone_number || "",
            voice_webhook: data?.voice_webhook || "",
        }
    }

    generateGeneralCompanies(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map((i, k) => newItems.push({
                key: i?.id,
                id: i?.id,
                name: i?.name,
                label: i?.name,
            }))
        }
        return newItems
    }

    exportPasswordRequest() {
        return {
            hash: this.hash
        }
    }

    exportGeneral() {
        return {
            ...this.general
        }
    }

    exportSetting() {
        return {
            ...this.setting
        }
    }
}
