export const SUBSCRIPTION_CURRENT_ACTION = "SUBSCRIPTION_CURRENT_ACTION";
export const SUBSCRIPTION_CURRENT_SUCCESS = "SUBSCRIPTION_CURRENT_SUCCESS";

export const SUBSCRIPTION_LIST_ACTION = "SUBSCRIPTION_LIST_ACTION";
export const SUBSCRIPTION_LIST_SUCCESS = "SUBSCRIPTION_LIST_SUCCESS";

export const SUBSCRIPTION_DETAIL_ACTION = "SUBSCRIPTION_DETAIL_ACTION";
export const SUBSCRIPTION_DETAIL_SUCCESS = "SUBSCRIPTION_DETAIL_SUCCESS";

export const SUBSCRIPTION_UPDATE_ACTION = "SUBSCRIPTION_UPDATE_ACTION";
export const SUBSCRIPTION_UPDATE_SUCCESS = "SUBSCRIPTION_UPDATE_SUCCESS";

export const SUBSCRIPTION_CREATE_ACTION = "SUBSCRIPTION_CREATE_ACTION";
export const SUBSCRIPTION_CREATE_SUCCESS = "SUBSCRIPTION_CREATE_SUCCESS";

export const SUBSCRIPTION_INIT = "SUBSCRIPTION_INIT";
export const SUBSCRIPTION_FAIL = "SUBSCRIPTION_FAIL";
