import {Space} from "antd";
import {BsFillTelephoneFill, BsFillTelephoneXFill} from "react-icons/bs";
import {useEffect, useState} from "react";

const LoadingInComing = props => {

    const [isAccept, setIsAccept] = useState(false)

    useEffect(() => {
        return () => {
            setIsAccept(false)
        }
    }, [])

    return (
        <div className="">
            <p className="text-center">Incoming Call from {props?.fromPhone}</p>
            <div className="snippet" data-title="dot-carousel" style={{
                width: "10px",
                textAlign: "center",
                margin: "auto",
                marginBottom: "15px"
            }}>
                <div className="stage">
                    <div className="dot-carousel"></div>
                </div>
            </div>
            {/* Hiển thị nút hoặc nội dung bạn muốn */}
            {
                isAccept ? (
                    <div className="w-100 text-center">
                        <div style={{
                            // backgroundColor: "#F33A38",
                            backgroundColor: "#F33A38",
                            width: "40px",
                            height: "40px",
                            textAlign: "center",
                            padding: "8px 0",
                            borderRadius: "50%",
                            cursor: "pointer",
                            margin: "auto"
                        }}
                             onClick={e => props?.onHangUp()}
                        >
                            <BsFillTelephoneXFill className="color--white" size={18}/>
                        </div>
                    </div>
                ) : (
                    <div className="w-100 text-center">
                        <div
                            onClick={e => {
                                props?.onListen();
                                setIsAccept(true)
                            }}
                            style={{
                                // backgroundColor: "#F33A38",
                                backgroundColor: "#1ECB62",
                                width: "40px",
                                height: "40px",
                                textAlign: "center",
                                padding: "8px 0",
                                borderRadius: "50%",
                                marginRight: "20px",
                                cursor: "pointer",
                                display: "inline-block"
                            }}>
                            <BsFillTelephoneFill className="color--white" size={18}/>
                        </div>
                        <div
                            onClick={e => props?.onReject()}
                            style={{
                                // backgroundColor: "#F33A38",
                                backgroundColor: "#F33A38",
                                width: "40px",
                                height: "40px",
                                textAlign: "center",
                                padding: "8px 0",
                                borderRadius: "50%",
                                cursor: "pointer",
                                display: "inline-block"
                            }}>
                            <BsFillTelephoneXFill className="color--white" size={18}/>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default LoadingInComing
