import React, {useState, useEffect} from "react";
import {useSelector} from 'react-redux'

// ============== LAYOUT ==============
import {Form, Input, Button, Select, Spin, Checkbox} from 'antd';
import HeaderAction from "../../../common/layout/HeaderAction";

// ============== ACTION ==============
import {
    ActionMasterData,
    ActionCreate,
    ActionInit,
    ActionGetItem,
    ActionUpdate
} from "../../../../data/hooks/tag";

// ============== SELECT DATA ==============
import {selectFetching, selectItem, selectMasterData} from "../../../../data/reselects/tagSelector";
import {common} from "../../../../data/mapping/Request/TagRequest";
import {Icon} from "../../../common";
import {isEmail} from "../../../../utils/validation";
import TAG_DEFINE from "../../../../constants/common";
import {ROUTE_REDIRECT} from "../../../../constants/define";

const Index = (props) => {
    const [form] = Form.useForm(),
        [isFetching, setIsFetching] = useState(false),
        [typePage, setTypePage] = useState(TAG_DEFINE.ACTION.create),
        [masterStatus, setMasterStatus] = useState({}),
        [isUpdate, setIsUpdate] = useState(false),
        // ============== ACTION ==============
        getMasterData = ActionMasterData(),
        actionCreate = ActionCreate(),
        actionUpdate = ActionUpdate(),
        getInitAction = ActionInit(),
        actionGetItem = ActionGetItem(),
        // ============== SELECT DATA ==============
        itemFetching = useSelector(selectFetching()),
        itemDetail = useSelector(selectItem()),
        itemMasterData = useSelector(selectMasterData());

    useEffect(() => {
        const {match} = props;
        if (Object.keys(match.params).length > 0) {
            setIsUpdate(true);
            if (Object.keys(itemDetail || {}).length < 1) {
                setTypePage(TAG_DEFINE.ACTION.update)
                actionGetItem({id: match.params?.id, props: props});
            }
        } else {
            setIsUpdate(false)
        }
    }, [props.match]);

    useEffect(() => {
        return () => {
            getInitAction({clearDetail: true});
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);

    useEffect(() => {
        if (Object.keys(itemDetail || {}).length > 0) {
            form.setFieldsValue(itemDetail)
        }
    }, [itemDetail]);

    useEffect(() => {
        if (Object.keys(itemMasterData?.status || {}).length > 0) {
            setMasterStatus(itemMasterData?.status)
        }
    }, [itemMasterData]);

    const onFinish = (val) => {
        if (isUpdate) {
            actionUpdate({...val, props, id: itemDetail?.id})
        } else {
            actionCreate({...val, props})
        }
    }

    return (
        <>
            <HeaderAction title={`Tag ${TAG_DEFINE.ACTION[typePage.toLowerCase()]}`}/>
            <Spin tip="Loading..." spinning={isFetching}>
                <div className="container bg-white mt-4 p-4">
                    <div className="row">
                        <div className="col-12">
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={onFinish}
                                // onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <div className="row">
                                    <div className="col-4">
                                        <Form.Item
                                            label="Tag"
                                            name={common.ACTION_FIELD.tag}
                                            rules={[{
                                                required: true,
                                                message: 'Please input your tag!'
                                            }]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    {
                                        isUpdate && (
                                            <>
                                                <div className="col-4">
                                                    <Form.Item
                                                        label="Created Date"
                                                        name={common.ACTION_FIELD.created_date}
                                                    >
                                                        <Input disabled/>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-4">
                                                    <Form.Item
                                                        label="Owner"
                                                        name={common.ACTION_FIELD.owner}
                                                    >
                                                        <Input disabled/>
                                                    </Form.Item>
                                                </div>
                                            </>
                                        )
                                    }
                                    <div className="col-12">
                                        <Form.Item
                                            label="Emails"
                                            name={common.ACTION_FIELD.emails}
                                            // rules={[{
                                            //     required: true,
                                            //     message: 'Please input your list email!'
                                            // }]}
                                        >
                                            <Input.TextArea
                                                placeholder="Insert list contact email, separated by comma ex: abc@test.com, def@test.com, ghi@test.com"/>
                                        </Form.Item>
                                    </div>
                                </div>
                                <Form.Item className="text-end">
                                    <Button type="default" htmlType="button" className="me-3"
                                            onClick={() => props.history.push(ROUTE_REDIRECT.TAG.HOME)}>
                                        Back
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Save {isFetching && <Icon type="sync-outlined" spin/>}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </Spin>
        </>
    )
}

export default Index
