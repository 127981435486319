import {BsFillTelephoneFill, BsFillTelephoneXFill} from "react-icons/bs";

const CallnComingLoading = () => {
    return (
        <div className="calling-InComing-container">
            <BsFillTelephoneFill
                className="color--white calling-icon" size={25}
            />
        </div>
    );
};

export default CallnComingLoading;
