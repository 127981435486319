export const VOICE_MASTER_ACTION = "VOICE_MASTER_ACTION";
export const VOICE_MASTER_SUCCESS = "VOICE_MASTER_SUCCESS";

export const VOICE_LIST_ACTION = "VOICE_LIST_ACTION";
export const VOICE_LIST_SUCCESS = "VOICE_LIST_SUCCESS";

export const VOICE_VERIFY_ACTION = "VOICE_VERIFY_ACTION";
export const VOICE_VERIFY_SUCCESS = "VOICE_VERIFY_SUCCESS";

export const VOICE_UPDATE_ACTION = "VOICE_UPDATE_ACTION";
export const VOICE_UPDATE_SUCCESS = "VOICE_UPDATE_SUCCESS";

export const VOICE_CREATE_ACTION = "VOICE_CREATE_ACTION";
export const VOICE_CREATE_SUCCESS = "VOICE_CREATE_SUCCESS";

export const VOICE_CONFIG_ACTION = "VOICE_CONFIG_ACTION";
export const VOICE_CONFIG_SUCCESS = "VOICE_CONFIG_SUCCESS";

export const VOICE_INIT = "VOICE_INIT";
export const VOICE_FAIL = "VOICE_FAIL";
