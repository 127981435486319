import React, {useEffect, useState} from 'react';
import {connect, useSelector} from "react-redux";
import {withRouter} from 'react-router-dom';
import {loginAction} from "../../data/actions/authAction";
import {ActionUpdateProfile} from "../../data/hooks/auth"
import {ROUTE_LIST} from "../../constants/define";
import MainLayout from "../common/layout/MainLayout";
import {Button, Checkbox, Form, Input, Table} from "antd";
import {columnsTable, getTitleTable} from "../../utils/functions";
import {
    featureConsulting,
    featureInsights,
    featureProduct,
    plansPrice,
    quotationColumn
} from "../../data/mapping/Response/MembershipPlansResponse";
import {CheckCircleOutlined, DownOutlined, LockOutlined, UpOutlined, UserOutlined} from "@ant-design/icons";
import {CONSTANT} from "../../data/mapping/Response/CartResponse";
import {ActionCreate, ActionUpdate} from "../../data/hooks/cart";
import {selectFetching} from "../../data/reselects/cartSelector";

const Index = (props) => {
    const [form] = Form.useForm(),
        actionUpdate = ActionUpdate(),
        itemFetching = useSelector((selectFetching()));
    const componentTableQuotation = {
            entry: {
                width: 300,
                title: (
                    <div className="text-center">
                        <p className="h3">Entry</p>
                        <p className="fw-light fs--16px">Begin your journey</p>
                        <Button
                            className="mt-3 pulse-grow-on-hover"
                            type="primary"
                            ghost
                            shape="round"
                            onClick={e => addCart(CONSTANT?.LIST_PLANS?.entry)}
                        >
                            Free
                        </Button>
                    </div>
                ),
                render: (val, record) => <div className="text-center">{val ? <CheckCircleOutlined/> : " - "}</div>
            },
            elite: {
                width: 300,
                title: (
                    <div className="text-center">
                        <p className="h3">Elite</p>
                        <p className="fw-light fs--16px">Learn & Grow</p>
                        <Button
                            className="mt-3 pulse-grow-on-hover"
                            type="primary"
                            ghost
                            shape="round"
                            onClick={e => addCart(CONSTANT?.LIST_PLANS?.elite)}
                        >
                            49$ / year
                        </Button>
                    </div>
                ),
                render: (val, record) => <div className="text-center">{val ? <CheckCircleOutlined/> : " - "}</div>
            },
            entrepreneur: {
                width: 300,
                title: (
                    <div className="text-center position-relative">
                        <p className="h3">Entrepreneur</p>
                        <p className="fw-light fs--16px">Build your best version</p>
                        <Button
                            className="mt-3 pulse-grow-on-hover"
                            type="primary"
                            ghost
                            shape="round"
                            onClick={e => addCart(CONSTANT?.LIST_PLANS?.entrepreneur)}
                        >
                            99$ / year
                        </Button>
                        <span className="color--primary hn__memberships-plans--tag-recommended">RECOMMENDED</span>
                    </div>
                ),
                render: (val, record) => <div className="text-center">{val ? <CheckCircleOutlined/> : " - "}</div>
            },
        },
        componentFeature = {
            entry: {
                width: 300,
                align: "center",
                render: (val, record) => <div className="text-center">{typeof val === "boolean" ? val ?
                    <CheckCircleOutlined/> : " - " : val}</div>
            },
            elite: {
                width: 300,
                align: "center",
                render: (val, record) => <div className="text-center">{typeof val === "boolean" ? val ?
                    <CheckCircleOutlined/> : " - " : val}</div>
            },
            entrepreneur: {
                width: 300,
                align: "center",
                render: (val, record) => <div className="text-center">{typeof val === "boolean" ? val ?
                    <CheckCircleOutlined/> : " - " : val}</div>
            },
        };

    const [featureTab, setFeatureTab] = useState({
            op_1: false,
            op_2: false,
            op_3: false,
        }),
        [showMoreFeature, setShowMoreFeature] = useState(false);

    useEffect(() => {
        // ==> First page loading <===
        // props.history.push(ROUTE_LIST.PreForeClosure)
    }, []);

    const onFinish = val => {

    }

    const addCart = (val) => {
        actionUpdate({package_id: val})
    }

    return (
        <>
            <div className="">
                <p className="color--main fs--25px fw-bold text-center">START, DEVELOP, SUCCEED</p>
                <p className="m-auto width-50 fs--18px text-center">Unlock amazing benefits: special prices on our
                    lists, exclusive coaching 1:1, 24/7 priority support and much more.</p>
            </div>
            <div className="width__lg--80 m-auto">
                <div className={`mt-5 hn__memberships-plans--quotation`}>
                    <div className="d-none d-lg-block">
                        <Table
                            loading={itemFetching}
                            className="hn__memberships-plans--quotation-table"
                            bordered
                            pagination={false}
                            dataSource={plansPrice}
                            columns={columnsTable({
                                titleTable: getTitleTable(quotationColumn, [], []),
                                component: componentTableQuotation,
                                itemName: "membership_plans_quotation"
                            })}/>
                    </div>

                    {/* =============== MOBILE =============== */}
                    <div className='d-lg-none'>
                        <div className="container">
                            <div className="row px-3">
                                <div className="col-12 hn__memberships-plans--quotation-item">
                                    <div className="text-center">
                                        <p className="h3 mb-1">Entry</p>
                                        <p className="fw-light fs--16px">Begin your journey</p>
                                    </div>
                                    <div>
                                        <p className="fw-bold mb-1">Membership</p>
                                        <p>Real Estate IQ Credits</p>

                                        <p className="fw-bold mb-1">Real Estate IQ Products</p>
                                        <p>50 free leadsFast CMA comps.</p>

                                        <p className="fw-bold mb-1">Education</p>
                                        <p className="mb-0">Blog posts (read &amp; write)</p>
                                        <p className="mb-0">Books</p>
                                        <p className="mb-0">Events&amp; Webinars</p>
                                        <p className="mt-3">Go to <a
                                            href="https://www.realestaetiq.co" target="_blank"
                                            rel="noopener">Realestaetiq.co</a> on your computer to see in detail the
                                            functions of the membership plans.

                                        </p>
                                    </div>
                                    <Button
                                        className="pulse-grow-on-hover w-100"
                                        type="primary"
                                        ghost
                                        shape="round"
                                        onClick={e => addCart(CONSTANT?.LIST_PLANS?.entry)}
                                    >
                                        Free
                                    </Button>
                                </div>
                                <div className="col-12 hn__memberships-plans--quotation-item">
                                    <div className="text-center">
                                        <p className="h3 mb-1">Elite</p>
                                        <p className="fw-light fs--16px">Learn & Grow</p>
                                    </div>
                                    <div>
                                        <p className="fw-bold mb-1">Membership</p>
                                        <p className="mb-0">Real Estate IQ Credits</p>
                                        <p className="mb-0">Dashboard</p>
                                        <p className="mb-0">Affiliate Program</p>
                                        <p className="mb-0">Virtual Assistance</p>
                                        <p className="mb-3">Networking Access</p>

                                        <p className="fw-bold mb-1">Real Estate IQ Products</p>
                                        <p className="mb-0">50 free leads</p>
                                        <p className="mb-0">Real Estate IQ Products</p>
                                        <p className="mb-0">Fast CMA comps.</p>
                                        <p className="mb-3">Special price for Off Market Leads &amp;Skip tracing</p>

                                        <p className="fw-bold mb-1">Education</p>
                                        <p className="mb-0">Blog posts (read &amp; write)</p>
                                        <p className="mb-0">Books</p>
                                        <p className="mb-0">Events&amp; Webinars</p>
                                        <p className="mb-0">Masterclass with experts</p>
                                        <p className="mb-0">Program Certificates</p>
                                        <p className="mb-3">Be the expert</p>

                                        <p className="fw-bold mb-1">Coaching</p>
                                        <p className="mb-0">Market Insights</p>
                                        <p className="mb-0">Financial Planning 1:1</p>
                                        <p className="mb-3">Coaching 1:1</p>

                                        <p>Go to <a
                                            href="https://www.realestaetiq.co" target="_blank"
                                            rel="noopener">Realestaetiq.co</a> on your computer to see in detail the
                                            functions of the membership plans.
                                        </p>
                                    </div>
                                    <Button
                                        className="pulse-grow-on-hover w-100"
                                        type="primary"
                                        ghost
                                        shape="round"
                                        onClick={e => addCart(CONSTANT?.LIST_PLANS?.elite)}
                                    >
                                        49$ / year
                                    </Button>
                                </div>
                                <div className="col-12 hn__memberships-plans--quotation-item">
                                    <div className="text-center">
                                        <p className="h3 mb-1">Entrepreneur</p>
                                        <p className="fw-light fs--16px">Build your best version</p>
                                    </div>
                                    <div>
                                        <p className="fw-bold mb-1">Membership</p>
                                        <p className="mb-0">Real Estate IQ Credits</p>
                                        <p className="mb-0">Dashboard</p>
                                        <p className="mb-0">Affiliate Program</p>
                                        <p className="mb-0">24/7 Priority Support</p>
                                        <p className="mb-0">Virtual Assistance</p>
                                        <p className="mb-3">Networking Access</p>

                                        <p className="fw-bold mb-1">Real Estate IQ Products</p>
                                        <p className="mb-0">50 free leads</p>
                                        <p className="mb-0">Fast CMA comps.</p>
                                        <p className="mb-3">Special price for Off Market Leads &amp;Skip tracing</p>

                                        <p className="fw-bold mb-1">Education</p>
                                        <p className="mb-0">Blog posts (read &amp; write)</p>
                                        <p className="mb-0">Books</p>
                                        <p className="mb-0">Events&amp; Webinars</p>
                                        <p className="mb-0">Masterclass with experts</p>
                                        <p className="mb-0">Program Certificates</p>
                                        <p className="mb-3">Be the expert</p>

                                        <p className="fw-bold mb-1">Coaching</p>
                                        <p className="mb-0">Market Insights</p>
                                        <p className="mb-0">Financial Planning 1:1</p>
                                        <p className="mb-0">Coaching 1:1</p>
                                        <p className="mb-0">Exclusive Coaching with experts</p>
                                        <p className="mb-0">IQ Matching</p>
                                        <p className="mb-3">Real Estate IQ Agency</p>

                                        <p>Go to <a
                                            href="https://www.realestaetiq.co" target="_blank"
                                            rel="noopener">Realestaetiq.co</a> on your computer to see in detail the
                                            functions of the membership plans.
                                        </p>
                                    </div>
                                    <Button
                                        className="pulse-grow-on-hover w-100"
                                        type="primary"
                                        ghost
                                        shape="round"
                                        onClick={e => addCart(CONSTANT?.LIST_PLANS?.entrepreneur)}
                                    >
                                        99$ / year
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* =============== [END] MOBILE =============== */}

                </div>
                <div className="hn__memberships-plans--feature mt-4 d-none d-lg-block">
                    <div className="hn__memberships-plans--feature-item row align-items-center">
                        <div className="col-6">
                            <p className="fw-bold fs--20ps mb-0">Real Estate IQ Products</p>
                            <p>Our best tools to build your business.</p>
                        </div>
                        <div className="col-6 text-end">
                            <a className="fs--14px text-decoration-none color--primary"
                               onClick={e => setFeatureTab({
                                   ...featureTab,
                                   op_1: !featureTab?.op_1
                               })}>{featureTab?.op_1 ? "Hide" : "Show"} Features {featureTab?.op_1 ? <UpOutlined className="icon-ant"/> :
                                    <DownOutlined className="icon-ant"/>}</a>
                        </div>
                        <div
                            className={`hn__memberships-plans--feature-item-content hn__memberships-plans--feature-item-content${featureTab?.op_1 ? "-active" : ""}`}>
                            <Table
                                className="hn__memberships-plans--quotation-table mb-3"
                                bordered
                                pagination={false}
                                dataSource={featureProduct}
                                columns={columnsTable({
                                    titleTable: getTitleTable(quotationColumn, [], []),
                                    component: componentFeature,
                                    itemName: "membership_plans_quotation"
                                })}/>
                        </div>
                    </div>
                    <div
                        className={`hn__memberships-plans--feature-item-content hn__memberships-plans--feature-item-content${showMoreFeature ? "-active" : ""}`}>
                        <div className="hn__memberships-plans--feature-item row align-items-center">
                            <div className="col-6">
                                <p className="fw-bold fs--20ps mb-0">Insights</p>
                                <p>Grow your expertise with our courses, ebooks and webinars.</p>
                            </div>
                            <div className="col-6 text-end">
                                <a className="fs--14px text-decoration-none color--primary"
                                   onClick={e => setFeatureTab({
                                       ...featureTab,
                                       op_2: !featureTab?.op_2
                                   })}>{featureTab?.op_2 ? "Hide" : "Show"} Features {featureTab?.op_2 ? <UpOutlined className="icon-ant"/> :
                                        <DownOutlined className="icon-ant"/>}</a>
                            </div>
                            <div
                                className={`hn__memberships-plans--feature-item-content hn__memberships-plans--feature-item-content${featureTab?.op_2 ? "-active" : ""}`}>
                                <Table
                                    className="hn__memberships-plans--quotation-table mb-3"
                                    bordered
                                    pagination={false}
                                    dataSource={featureInsights}
                                    columns={columnsTable({
                                        titleTable: getTitleTable(quotationColumn, [], []),
                                        component: componentFeature,
                                        itemName: "membership_plans_quotation"
                                    })}/>
                            </div>
                        </div>
                        <div className="hn__memberships-plans--feature-item row align-items-center">
                            <div className="col-6">
                                <p className="fw-bold fs--20ps mb-0">Consulting</p>
                                <p>Learn along experts and build your most competent version.</p>
                            </div>
                            <div className="col-6 text-end">
                                <a className="fs--14px text-decoration-none color--primary"
                                   onClick={e => setFeatureTab({
                                       ...featureTab,
                                       op_3: !featureTab?.op_3
                                   })}>{featureTab?.op_3 ? "Hide" : "Show"} Features {featureTab?.op_3 ? <UpOutlined className="icon-ant"/> :
                                        <DownOutlined className="icon-ant"/>}</a>
                            </div>
                            <div
                                className={`hn__memberships-plans--feature-item-content hn__memberships-plans--feature-item-content${featureTab?.op_3 ? "-active" : ""}`}>
                                <Table
                                    className="hn__memberships-plans--quotation-table"
                                    bordered
                                    pagination={false}
                                    dataSource={featureConsulting}
                                    columns={columnsTable({
                                        titleTable: getTitleTable(quotationColumn, [], []),
                                        component: componentFeature,
                                        itemName: "membership_plans_quotation"
                                    })}/>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <Button
                            onClick={e => setShowMoreFeature(!showMoreFeature)}
                            className="mt-3 pulse-grow-on-hover"
                            type="primary"
                            ghost
                            shape="round">
                            {showMoreFeature ? "Hide" : "Show"} All Features {showMoreFeature ?
                            <UpOutlined className="icon-ant"/> :
                            <DownOutlined className="icon-ant"/>}
                        </Button>
                    </div>
                </div>
                <div className="hn__memberships-plans--description-plan mt-4">
                    <div className="hn__memberships-plans--plan-item">
                        <p className="fs--30px fw-bold color--main mb-2">Enterprise plans</p>
                        <p className="mb-2">Real Estate IQ Enterprise solutions are tailored to meet your business’s
                            unique needs.
                            Discover a new way to boost and grow your business.</p>
                        <p className="color--primary mb-0">Schedule a consultation with our Account Manager</p>
                    </div>
                    <div className="hn__memberships-plans--plan-item">
                        <p className="fs--30px fw-bold color--main mb-2">Custom Plan</p>
                        <p className="mb-2">Contact our Account Manager to create a plan that fits your needs.</p>
                        <p className="color--primary mb-0">Schedule a consultation with our Account Manager</p>
                    </div>
                </div>
                <div className="mt-4 hn__memberships-plans--contact">
                    <div className="row">
                        <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                            <p className="fs--25px color--main">Request a Call</p>
                            <p className="mb-0">Schedule a call with our team to discuss custom solutions for your brand
                                or business.</p>
                        </div>
                        <div className="col-12 col-lg-4">
                            <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
                                <div className="row">
                                    <div className="col-6 mb-3">
                                        <Form.Item
                                            className="w-100"
                                            name="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your username!',
                                                },
                                            ]}
                                        >
                                            <Input className="w-100"
                                                   placeholder="First Name"/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6 mb-3">
                                        <Form.Item
                                            className="w-100"
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your password!',
                                                },
                                            ]}
                                        >
                                            <Input className="w-100"
                                                   placeholder="Last Name"/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <Form.Item
                                            className="w-100"
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your password!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="w-100"
                                                placeholder="Email"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <Form.Item
                                            className="w-100"
                                            name="email"
                                        >
                                            <Input
                                                className="w-100"
                                                placeholder="Phone"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 mb-1">
                                        <Form.Item
                                            name="remember"
                                            valuePropName="checked"
                                        >
                                            <Checkbox>I agree to receive your latest updates by email or sms
                                                *</Checkbox>
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <p className="mb-0 color--main">You can unsubscribe at any time or change your
                                            preferences. We only use data provided to us in accordance with our Privacy
                                            Policy.</p>
                                    </div>
                                    <div className="col-12 text-center">
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                className="fw-bold border__radius--10px"
                                                // disabled={
                                                //     !form.isFieldsTouched(true) ||
                                                //     !!form.getFieldsError().filter(({errors}) => errors.length).length
                                                // }
                                            >
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
        return {
            // categories: state.reducerCategory,
            auth: state.authReducer,
        }
    }
;

const mapDispatchToProps = (dispatch) => {
        return {
            onLogin: (params) => {
                dispatch(loginAction(params))
            },
        }
    }
;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index))
