import React, {useEffect, useState} from "react";
import {Button, Checkbox, Form, Input, List, message, Modal, Select, Space, Spin} from "antd";
import {isEmail} from "../../../../utils/validation";
import {CONFIG_LOCAL_STORAGE, COUNTRY_CODE} from "../../../../constants/define";
import {Icon} from "../../../common";
import {ActionColumnSave, ActionCreateNote} from "../../../../data/hooks/contact";
import {selectListColumn, selectMasterData, selectStatusAction} from "../../../../data/reselects/contactSelector";
import {useSelector} from "react-redux";
import {CheckCircleOutlined} from "@ant-design/icons";
import * as LocalStorage from "../../../../utils/localStorage";
import {debounce} from "../../../../utils/functions";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {RxDragHandleDots2} from "react-icons/rx";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const ModalColumn = props => {
    const [form] = Form.useForm(),
        [isModalOpen, setIsModalOpen] = useState(false),
        [listColumn, setListColumn] = useState([]),
        actionCreateNote = ActionCreateNote(),
        actionColumnSave = ActionColumnSave(),
        itemStatusAction = useSelector(selectStatusAction()),
        itemListColumn = useSelector(selectListColumn()),
        itemMasterData = useSelector(selectMasterData());
    const [itemColumn, setItemColumn] = useState([]);

    useEffect(() => {
        setIsModalOpen(props?.isOpen)
    }, [props?.isOpen]);

    useEffect(() => {
        if ((itemListColumn?.list_column?.listColumn || []).length > 0) {
            setListColumn(itemListColumn?.list_column?.listColumn || [])
        } else {
            if (Object.keys(itemMasterData?.real_column || {}).length > 0) {
                setListColumn(Object.keys(itemMasterData?.real_column || {}))
            }
        }
        if ((itemListColumn?.list_column?.position || []).length > 0) {
            setItemColumn(itemListColumn?.list_column?.position || [])
        } else if ((itemMasterData?.column || []).length > 0) {
            setItemColumn(itemMasterData?.column)
        }
    }, [itemListColumn, itemMasterData]);

    useEffect(() => {
        props?.listColumn(listColumn)
        if ((itemColumn || []).length > 0) {
            formSubmitDebounced()
        }
    }, [listColumn]);

    useEffect(() => {
        return () => {
            setIsModalOpen(false)
        }
    }, []);


    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        form.resetFields()
        setIsModalOpen(false);
        props?.isClose(false)
    };

    const formSubmitDebounced = debounce(() => {
        actionColumnSave({
            query: {
                title: "list_column",
                value: {listColumn, position: itemColumn},
            },
            id: props?.source
        })
    }, 300);

    useEffect(() => {
        if (itemColumn.length > 0) {
            let listColumnNew = []
            itemColumn.map(i => listColumnNew.push({[i?.value]: i?.label}))
            props?.getPositionColumn(itemColumn)
            // setItemColumn(listColumnNew.map(i => Object.keys(i)[0]))
        }
    }, [itemColumn]);

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const getItems = reorder(
            itemColumn,
            result.source.index,
            result.destination.index
        );

        const sortPosition = getItems.map(i => i?.value)
            .filter(item => listColumn.includes(item))
            .concat(listColumn.filter(item => !getItems.map(i => i?.value).includes(item)));
        setListColumn(sortPosition)

        setItemColumn(getItems);
    }

    return (
        <Modal
            title={<p className="mb-0">Columns {itemStatusAction?.isSaveColumn ?
                <span className="bg-danger px-2 py-1 border__radius--10px color--white">Saving <Icon
                    type="sync-outlined" spin/></span> : ""}</p>}
            maskClosable={false}
            destroyOnClose
            visible={isModalOpen}
            onOk={handleOk}
            footer={null}
            onCancel={handleCancel}>
            <Spin spinning={itemStatusAction?.isCreateNote}>
                <div className="mb-3">
                    <Checkbox
                        checked={Object.keys(itemMasterData?.real_column || {}).length === listColumn.length}
                        onChange={e => {
                            const {value, checked} = e.target;
                            if (checked) {
                                if (Object.keys(itemMasterData?.real_column || {}).length > 0) {
                                    setListColumn(Object.keys(itemMasterData?.real_column || {}))
                                }
                            } else {
                                setListColumn([])
                            }
                        }}>Check All</Checkbox>
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="list-group"
                            >
                                {itemColumn.map((item, index) => (
                                    <Draggable key={item.value} draggableId={item.value} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className="py-3 list-group-item"
                                            >
                                                <Space.Compact block>
                                                    <RxDragHandleDots2 className="mt-1 me-2"/>
                                                    <p className="mb-0" style={{
                                                        width: 'calc(100% - 20px)'
                                                    }}>{item?.label}</p>
                                                    <Checkbox checked={listColumn.includes(item.value)} onChange={e => {
                                                        const {value, checked} = e.target;
                                                        let newListColumn = [...listColumn]
                                                        if (checked) {
                                                            newListColumn.push(item.value)
                                                        } else {
                                                            newListColumn = newListColumn.filter((i) => i !== item.value)
                                                        }

                                                        const sortPosition = itemColumn.map(i => i?.value)
                                                            .filter(item => newListColumn.includes(item))
                                                            .concat(newListColumn.filter(item => !itemColumn.map(i => i?.value).includes(item)));

                                                        setListColumn(sortPosition);
                                                    }}/>
                                                </Space.Compact>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Spin>
        </Modal>
    )
}

export default ModalColumn
