import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import WorkflowResponse, {
    WorkflowMasterResponse,
    WorkflowDetailLogResponse
} from "../mapping/Response/WorkflowResponse";

export function masterWorkflow() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.WORKFLOW_MASTER,
        // params: removeObjectNull({ city_id }),
        parser: data => new WorkflowMasterResponse(data.data.result).exportMaster(),
    }).get();
}

export function actionWorkflowList(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.WORKFLOW_LIST,
        params: params,
        parser: dataParser,
    }).get();
}

export function actionWorkflowExport(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.WORKFLOW_LIST,
        params: params,
        isDownload: true,
        parser: data => data,
    }).get();
}

export function actionWorkflowDetail(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.WORKFLOW_GET_ITEM,
        endpointParams: {id},
        parser: data => new WorkflowResponse({detail: data.data.result}).exportDetail(),
    }).get();
}

export function update(data, id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.WORKFLOW_UPDATE,
        endpointParams: {id},
        parser: data => data?.data?.result,
    }).put(data);
}

export function create(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.WORKFLOW_CREATE,
        parser: data => data?.data?.result,
    }).post(data);
}

export function manualTrigger(data, id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.WORKFLOW_MANUAL_TRIGGER,
        endpointParams: {id},
        parser: data => data?.data?.result,
    }).post(data);
}


export function actionWorkflowDetailLogList(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.WORKFLOW_DETAIL_LOG,
        // params: params,
        endpointParams: {id},
        parser: detailLogParser,
    }).get();
}

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new WorkflowResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}

function detailLogParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new WorkflowDetailLogResponse(item).exportDetail()),
    }
}
