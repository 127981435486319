import React, {useEffect, useState} from "react";
import MainLayout from "../../common/layout/MainLayout";
import {Divider, Form, Input, Radio, Select, Space, Spin} from "antd";
import {
    ActionMasterData,
} from "../../../data/hooks/plansPricing"
import {selectMasterData, selectFetching} from "../../../data/reselects/plansPricingSelector"
import {selectFetching as selectCartFetching} from "../../../data/reselects/cartSelector"
import {useSelector} from "react-redux";
import {capitalizeFirstLetter} from "../../../utils/functions";
import {debounce} from "lodash";
import {ActionUpdate} from "../../../data/hooks/cart";

const PlansPricing = props => {
    const [form] = Form.useForm(),
        [dimensions, setDimensions] = React.useState({
            height: window.innerHeight,
            width: window.innerWidth
        }),
        [isFetching, setIsFetching] = useState(false),
        [tabStateActive, setTabStateActive] = useState(),
        [disabledSubmit, setDisabledSubmit] = useState({
            city: true,
            metro: true,
            state: true,
            county: true,
        }),
        [cityDescription, setCityDescription] = useState({checked: [], unchecked: []}),
        [metroDescription, setMetroDescription] = useState({checked: [], unchecked: []}),
        [stateDescription, setStateDescription] = useState({checked: [], unchecked: []}),
        [countyDescription, setCountyDescription] = useState({checked: [], unchecked: []}),
        // actionPlansPricingUpdate = ActionPlansPricingUpdate(),
        actionPlansPricingMaster = ActionMasterData(),
        actionUpdate = ActionUpdate(),
        itemFetching = useSelector(selectFetching()),
        itemCartFetching = useSelector(selectCartFetching()),
        itemPlansPricingMaster = useSelector(selectMasterData());

    useEffect(() => {
        const debouncedHandleResize = () => {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }

        window.addEventListener('resize', debouncedHandleResize)

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)

        }
    })

    useEffect(() => {
        actionPlansPricingMaster();
        form.setFieldsValue({
            level: "standard"
        })
    }, []);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);

    useEffect(() => {
        form.setFieldsValue({
            time: itemPlansPricingMaster?.defaultTab
        })
        setTabStateActive(itemPlansPricingMaster?.defaultTab)
    }, [itemPlansPricingMaster]);

    const onSearch = val => {
        // console.log("%c ====================== SUBMIT ======================", "color: yellow", val)
        // actionPlansPricingUpdate(val)
    }

    const changeValueField = res => {
        if (res.time) {
            setTabStateActive(res.time)
        }
    }

    const onChangeState = val => {
    }

    const addCart = (val) => {
        actionUpdate({package_id: val})
    }

    return (
        <Spin spinning={isFetching || itemCartFetching}>
            <Form
                form={form}
                onFinish={onSearch}
                layout="vertical"
                onValuesChange={(res) => changeValueField(res)}
            >
                <Form.Item label="" name="state" className="d-none">
                    <Input/>
                </Form.Item>
                <Form.Item label="" name="code" className="d-none">
                    <Input/>
                </Form.Item>
                <Form.Item label="" name="package_id" className="d-none">
                    <Input/>
                </Form.Item>
                <div className="">
                    <Divider className="mt-0"/>
                    <div className="container">
                        <div className="row py-3">
                            <div className="col-12 col-lg-12">
                                <div className="hn__plans-pricing__segment text-center">
                                    <Space className="text-center">
                                        <Form.Item label="" name="time" className=""
                                                   initialValue={itemPlansPricingMaster?.defaultTab}>
                                            <Radio.Group buttonStyle="solid">
                                                {
                                                    (itemPlansPricingMaster?.title || []).map((i, k) => (
                                                        <Radio.Button
                                                            value={i}
                                                            key={k}>{capitalizeFirstLetter((i).toLowerCase())}</Radio.Button>
                                                    ))
                                                }
                                            </Radio.Group>
                                        </Form.Item>
                                        {/*<Form.Item label="" name="level" className="">*/}
                                        {/*    <Radio.Group buttonStyle="solid">*/}
                                        {/*        <Radio.Button value="standard">Standard</Radio.Button>*/}
                                        {/*        <Radio.Button value="premium">*/}
                                        {/*            Premium*/}
                                        {/*        </Radio.Button>*/}
                                        {/*    </Radio.Group>*/}
                                        {/*</Form.Item>*/}
                                    </Space>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hn__plans-pricing__packages">
                        <div className="container">
                            <div className="row">
                                <div
                                    className={`mb-3 col-sm-12 col-lg${dimensions?.width <= "1450" ? "-6" : "-3"} d-none d-lg-block`}/>
                                {
                                    form.getFieldValue("time") && ((itemPlansPricingMaster?.item[form.getFieldValue("time")] || []).length > 0) && (
                                        (itemPlansPricingMaster?.item[form.getFieldValue("time")] || []).map((i, k) => {
                                            return (
                                                <div
                                                    className={`mb-3 col-sm-12 col-lg${dimensions?.width <= "1450" ? "-6" : "-3"}`}
                                                    key={k}>
                                                    <div
                                                        className={`hn__plans-pricing__packages--item ${(form.getFieldValue("level") || "").toLowerCase() === "premium" ? "hn__plans-pricing__packages--item-premium" : ""}`}>
                                                        <div className="hn__plans-pricing__packages--title">
                                                            <div className="clearfix">
                                                                <div
                                                                    className="float-lg-start hn__plans-pricing__packages--title-content vertical--middle">
                                                                    <p className="mb-0 fs--24px fw-bold color--main text-center text-lg-start">{i?.name}</p>
                                                                </div>
                                                                <div
                                                                    className="float-lg-end text-center hn__plans-pricing__packages--title-price vertical--middle width-136px">
                                                                    <p className="mb-0 fw-bold fs--24px text-center">{i?.price}</p>
                                                                    <p className="mb-0 text-center">{i?.period}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Divider/>
                                                        <div className="mb-3">
                                                            <ul className="list-style-none min-height--180px">
                                                                {
                                                                    i?.description?.checked?.map((i, k) => {
                                                                        return (
                                                                            <li className="clearfix mb-2" key={k}>
                                                                                <i className="float-start fa fa-check-circle fs--23px vertical--middle me-2 color--primary"/>
                                                                                <p className="float-start color--main fs--16px vertical--middle width-80">
                                                                                    {i}
                                                                                </p>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    i?.description?.unchecked?.map((i, k) => {
                                                                        return (
                                                                            <li className="clearfix mb-2" key={k}>
                                                                                <i className="float-start fa fa-window-close fs--23px vertical--middle me-2 color--disabled"/>
                                                                                <p className="float-start fs--16px vertical--middle width-80 color--disabled">
                                                                                    {i}
                                                                                </p>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                        <p className={`hn__plans-pricing__packages--submit`}
                                                           onClick={() => {
                                                               addCart(i?.id)
                                                           }}>Select</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    )
                                }
                                <div
                                    className={`mb-3 col-sm-12 col-lg${dimensions?.width <= "1450" ? "-6" : "-3"} d-none d-lg-block`}/>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 mb-3">
                                    <p className="fw-bold h3 text-center">Bundle</p>
                                </div>
                                {
                                    ((itemPlansPricingMaster?.bundle || []).length > 0) && (
                                        (itemPlansPricingMaster?.bundle || []).map((i, k) => {
                                            return (
                                                <div
                                                    className={`m-auto mb-3 col-sm-12 col-lg${dimensions?.width <= "1450" ? "-6" : "-3"}`}
                                                    key={k}>
                                                    <div
                                                        className={`hn__plans-pricing__packages--item ${(form.getFieldValue("level") || "").toLowerCase() === "premium" ? "hn__plans-pricing__packages--item-premium" : ""}`}>
                                                        <div className="hn__plans-pricing__packages--title">
                                                            <div className="clearfix">
                                                                <div
                                                                    className="float-lg-start hn__plans-pricing__packages--title-content vertical--middle">
                                                                    <p className="mb-0 fs--24px fw-bold color--main text-center text-lg-start">{i?.name}</p>
                                                                </div>
                                                                <div
                                                                    className="float-lg-end text-center hn__plans-pricing__packages--title-price vertical--middle width-136px">
                                                                    <p className="mb-0 fw-bold fs--24px text-center">{i?.price}</p>
                                                                    <p className="mb-0 text-center">{i?.period}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Divider/>
                                                        <div className="mb-3">
                                                            <ul className="list-style-none min-height--180px">
                                                                {
                                                                    i?.description?.checked?.map((i, k) => {
                                                                        return (
                                                                            <li className="clearfix mb-2" key={k}>
                                                                                <i className="float-start fa fa-check-circle fs--23px vertical--middle me-2 color--primary"/>
                                                                                <p className="float-start color--main fs--16px vertical--middle width-80">
                                                                                    {i}
                                                                                </p>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    i?.description?.unchecked?.map((i, k) => {
                                                                        return (
                                                                            <li className="clearfix mb-2" key={k}>
                                                                                <i className="float-start fa fa-window-close fs--23px vertical--middle me-2 color--disabled"/>
                                                                                <p className="float-start fs--16px vertical--middle width-80 color--disabled">
                                                                                    {i}
                                                                                </p>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                        <p className={`hn__plans-pricing__packages--submit`}
                                                           onClick={() => {
                                                               addCart(i?.id)
                                                           }}>Select</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Spin>
    )
}

export default PlansPricing
