import * as Types from '../types/PageBuilders';
import {PAGINATION} from '../../constants/define';
// import HotelRequest from "../mapping/Request/HotelRequest";

const initialState = {
    isFetching: false,
    statusAction: {
        isSubmit: false,
        isUnlock: false,
        unlockField: "",
        isExport: false
    },
    all: [],
    errors: [],
    // item: new HotelRequest().update(),
    data: {
        result: [],
        pagination: {
            page_size: PAGINATION.page_size,
            page_index: PAGINATION.page_index,
            total: PAGINATION.total,
            total_page: PAGINATION.total_page,
        },
        is_edit: false
    },
    detail: {},
    detailLog: [],
    master: {}
};

const getResult = (page) => {
    return {
        result: page.result || [],
        pagination: {
            page_size: page?.page_size || PAGINATION.page_size,
            page_index: page?.page_index || PAGINATION.page_index,
            total: page?.total || PAGINATION.total,
            total_page: page?.total_page || PAGINATION.total_page,
        },
        is_edit: page?.is_edit
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.PAGE_BUILDERS_DETAIL_LOG_ACTION:
        case Types.PAGE_BUILDERS_MANUAL_TRIGGER_ACTION:
        case Types.PAGE_BUILDERS_MASTER_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.PAGE_BUILDERS_MASTER_SUCCESS:
            return {...state, isFetching: false, master: action.master, statusAction: initialState.statusAction};
        case Types.PAGE_BUILDERS_LIST_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.PAGE_BUILDERS_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: getResult(action.list),
                statusAction: initialState.statusAction
            };
        case Types.PAGE_BUILDERS_DETAIL_LOG_SUCCESS:
            return {
                ...state,
                isFetching: false,
                detailLog: action?.detailLog,
                statusAction: initialState.statusAction
            };
        case Types.PAGE_BUILDERS_DETAIL_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.PAGE_BUILDERS_DETAIL_SUCCESS:
            return {...state, isFetching: false, detail: action.detail, statusAction: initialState.statusAction};
        case Types.PAGE_BUILDERS_UPDATE_ACTION:
            return {...state, isFetching: true, statusAction: {...initialState.statusAction, isSubmit: true}};
        case Types.PAGE_BUILDERS_UPDATE_SUCCESS:
            console.log(999999)
            return {...state, isFetching: true, statusAction: {...initialState.statusAction, isSubmit: false}};
        case Types.PAGE_BUILDERS_CREATE_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.PAGE_BUILDERS_CREATE_SUCCESS:
        case Types.PAGE_BUILDERS_MANUAL_TRIGGER_SUCCESS:
            return {...state, isFetching: false, statusAction: initialState.statusAction};
        case Types.PAGE_BUILDERS_FAIL:
            return {...state, errors: action.errors, isFetching: false, statusAction: initialState.statusAction};
        case Types.PAGE_BUILDERS_INIT:
            return {
                ...state,
                // data: action.params?.clearDetail ? state.data : initialState.data,
                detail: action.params?.clearDetail ? initialState.detail : state.detail,
                detailLog: action.params?.clearDetailLog ? initialState.detailLog : state.detailLog,
                statusAction: initialState.statusAction
            };
        default:
            return state;
    }
}
