export const TAG_MASTER_ACTION = "TAG_MASTER_ACTION";
export const TAG_MASTER_SUCCESS = "TAG_MASTER_SUCCESS";

export const TAG_LIST_ACTION = "TAG_LIST_ACTION";
export const TAG_LIST_SUCCESS = "TAG_LIST_SUCCESS";

export const TAG_DETAIL_ACTION = "TAG_DETAIL_ACTION";
export const TAG_DETAIL_SUCCESS = "TAG_DETAIL_SUCCESS";

export const TAG_UPDATE_ACTION = "TAG_UPDATE_ACTION";
export const TAG_UPDATE_SUCCESS = "TAG_UPDATE_SUCCESS";

export const TAG_CREATE_ACTION = "TAG_CREATE_ACTION";
export const TAG_CREATE_SUCCESS = "TAG_CREATE_SUCCESS";

export const TAG_ADD_ACTION = "TAG_ADD_ACTION";
export const TAG_ADD_SUCCESS = "TAG_ADD_SUCCESS";

export const TAG_INIT = "TAG_INIT";
export const TAG_FAIL = "TAG_FAIL";
