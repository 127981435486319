import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/contactAction'
import {contactFilterDeleteAction} from "../actions/contactAction";

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactInitStore(params)),
        [dispatch]
    )
}

export const ActionGetList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.contactListAction(filters)),
        [dispatch]
    )
}

export const ActionGetItem = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactDetailAction(params)),
        [dispatch]
    )
}

export const ActionMasterData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactMasterAction(params)),
        [dispatch]
    )
}

export const ActionCreate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactCreateAction(params)),
        [dispatch]
    )
}
export const ActionUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactUpdateAction(params)),
        [dispatch]
    )
}

export const ActionDelete = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactDeleteAction(params)),
        [dispatch]
    )
}

export const ActionOptOut = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactOptOutAction(params)),
        [dispatch]
    )
}

export const ActionOptIn = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactOptInAction(params)),
        [dispatch]
    )
}

export const ActionCheckDuplicate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactCheckDuplicateAction(params)),
        [dispatch]
    )
}

export const ActionCreateNote = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactCreateNoteAction(params)),
        [dispatch]
    )
}

export const ActionImportMonday = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactImportMondayAction(params)),
        [dispatch]
    )
}

export const ActionImportHubspot = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactImportHubspotAction(params)),
        [dispatch]
    )
}

export const ActionImportPodio = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactImportPodioAction(params)),
        [dispatch]
    )
}


export const ActionFiltersList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactFilterListAction(params)),
        [dispatch]
    )
}


export const ActionFiltersSave = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactFilterSaveAction(params)),
        [dispatch]
    )
}

export const ActionFiltersDelete = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactFilterDeleteAction(params)),
        [dispatch]
    )
}


export const ActionColumnList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactColumnListAction(params)),
        [dispatch]
    )
}

export const ActionColumnSave = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactColumnSaveAction(params)),
        [dispatch]
    )
}

export const ActionUpdateNote = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactUpdateNoteAction(params)),
        [dispatch]
    )
}

export const ActionDeleteNote = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactDeleteNoteAction(params)),
        [dispatch]
    )
}

export const ActionMasterPortalFilter = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactMasterPortalFilterAction(params)),
        [dispatch]
    )
}

export const ActionHighlightCell = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.contactHighlightCellAction(params)),
        [dispatch]
    )
}
