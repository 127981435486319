export const SMS_TEMPLATE_MASTER_ACTION = "SMS_TEMPLATE_MASTER_ACTION";
export const SMS_TEMPLATE_MASTER_SUCCESS = "SMS_TEMPLATE_MASTER_SUCCESS";

export const SMS_TEMPLATE_LIST_ACTION = "SMS_TEMPLATE_LIST_ACTION";
export const SMS_TEMPLATE_LIST_SUCCESS = "SMS_TEMPLATE_LIST_SUCCESS";

export const SMS_TEMPLATE_DETAIL_ACTION = "SMS_TEMPLATE_DETAIL_ACTION";
export const SMS_TEMPLATE_DETAIL_SUCCESS = "SMS_TEMPLATE_DETAIL_SUCCESS";

export const SMS_TEMPLATE_UPDATE_ACTION = "SMS_TEMPLATE_UPDATE_ACTION";
export const SMS_TEMPLATE_UPDATE_SUCCESS = "SMS_TEMPLATE_UPDATE_SUCCESS";

export const SMS_TEMPLATE_CREATE_ACTION = "SMS_TEMPLATE_CREATE_ACTION";
export const SMS_TEMPLATE_CREATE_SUCCESS = "SMS_TEMPLATE_CREATE_SUCCESS";

export const SMS_TEMPLATE_INIT = "SMS_TEMPLATE_INIT";
export const SMS_TEMPLATE_FAIL = "SMS_TEMPLATE_FAIL";
