import {Button, Result} from "antd";
import queryString from "query-string";
import {useEffect, useState} from "react";

const ResultComponent = props => {
    const [message, setMessage] = useState({
        // status: "404",
        // title: "404",
        // subTitle: "Sorry, the page you visited does not exist.",
        status: "success",
        title: "Payment success",
        subTitle: "Please check your subscription info for more detail.",
    });

    useEffect(() => {
        const {match} = props;
        if (Object.keys(queryString.parse(props.location.search)).length > 0) {
            let paramsFilters = queryString.parse(props.location.search, {arrayFormat: 'comma'}),
                type = paramsFilters?.type
            if (type.toLowerCase() === "f") {
                setMessage({
                    status: "error",
                    title: "Payment fail",
                    subTitle: "We are so sorry that there is something wrong with your card. Please check again or contact administrator.",
                })
            }
            type = paramsFilters?.type
            if (type.toLowerCase() === "s") {
                setMessage({
                    status: "success",
                    title: "Payment success",
                    subTitle: "Please check your subscription info for more detail.",
                })
            }
        }
    }, [props.location]);
    return (
        <Result
            status={message.status}
            title={message.title}
            subTitle={message.subTitle}
            extra={[
                <Button key={1} type="default" onClick={() => props.history.push("/")}>Home</Button>,
            ]}
        />
    )
}

export default ResultComponent
