import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/smsHistoryAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.smsHistoryInitStore(params)),
        [dispatch]
    )
}

export const ActionGetList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.smsHistoryListAction(filters)),
        [dispatch]
    )
}

export const ActionGetItem = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.smsHistoryDetailAction(params)),
        [dispatch]
    )
}

export const ActionMasterData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.smsHistoryMasterAction(params)),
        [dispatch]
    )
}

export const ActionCreate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.smsHistoryCreateAction(params)),
        [dispatch]
    )
}
export const ActionUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.smsHistoryUpdateAction(params)),
        [dispatch]
    )
}
