import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/workflowAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.workflowInitStore(params)),
        [dispatch]
    )
}

export const ActionGetList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.workflowListAction(filters)),
        [dispatch]
    )
}

export const ActionGetItem = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.workflowDetailAction(params)),
        [dispatch]
    )
}

export const ActionMasterData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.workflowMasterAction(params)),
        [dispatch]
    )
}

export const ActionCreate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.workflowCreateAction(params)),
        [dispatch]
    )
}
export const ActionUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.workflowUpdateAction(params)),
        [dispatch]
    )
}

export const ActionDetailLog = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.workflowDetailLogAction(params)),
        [dispatch]
    )
}

export const ActionManualTrigger = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.workflowManualTriggerAction(params)),
        [dispatch]
    )
}
