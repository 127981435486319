import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import TeamsResponse, {TeamsMasterResponse} from "../mapping/Response/TeamsResponse";

export function masterTeams() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_MASTER_DATA,
        // params: removeObjectNull({ city_id }),
        parser: data => new TeamsMasterResponse(data.data.result).exportMaster(),
    }).get();
}

export function actionTeamsList(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_LIST,
        params: params,
        parser: dataParser,
    }).get();
}

export function actionTeamsExport(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_LIST,
        params: params,
        isDownload: true,
        parser: data => data,
    }).get();
}

export function actionTeamsDetail(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_GET_ITEM,
        endpointParams: {id: encodeURIComponent(id)},
        parser: data => new TeamsResponse(data.data.result).exportDetail(),
    }).get();
}

export function update(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_UPDATE,
        endpointParams: {id: encodeURIComponent(data?.id)},
    }).put(data);
}

export function create(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_CREATE,
    }).post(data);
}

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new TeamsResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
