import {call, put, takeLatest, fork, all, delay, select} from 'redux-saga/effects';
import * as Types from '../types/Contact';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/contactService';
import * as FunctionAction from '../actions/contactAction';
import {message} from "antd";
import moment from "moment";
import ContactRequest, {contactFiltersParams} from "../mapping/Request/ContactRequest";
import TAG_DEFINE from "../../constants/common";
import {actionContactList, deleteFunction, getAllContact, highlightCell} from "../services/contactService";
import {downloadFile} from "../../utils/functions";

function* actionGetListContact() {
    yield takeLatest(Types.CONTACT_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(action?.filters || {});
        const selectOldData = yield select();

        if (params?.is_export) {
            const result = yield call(FunctionService.actionContactList, params, {isDownload: params?.is_export})
            yield put(FunctionAction.contactListSuccess(selectOldData.contactReducer.data));
            const fileName = ("Contact_List_" + moment().format('DDMMYYYY') + ".xlsx");
            downloadFile(result, fileName, {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        } else {
            const result = yield call(FunctionService.getAllContact, params);
            yield put(FunctionAction.contactListSuccess(result?.contactList));
            yield put(FunctionAction.contactColumnListSuccess(result?.contactColumnList));
        }
    }, errorHandle(Types.CONTACT_FAIL)))
}

function* actionGetItem() {
    yield takeLatest(Types.CONTACT_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionContactDetail, action.params.id);
        yield put(FunctionAction.contactDetailSuccess(result));
    }, errorHandle(Types.CONTACT_FAIL)))
}

function* actionGetMasterContact() {
    yield takeLatest(Types.CONTACT_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.masterContact, action.params);
        yield put(FunctionAction.contactMasterSuccess(result));
    }, errorHandle(Types.CONTACT_FAIL)))
}

function* createAction() {
    yield takeLatest(Types.CONTACT_CREATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new ContactRequest(action.params).exportCreate();
        const result = yield call(FunctionService.create, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
        action.params.props.history.goBack();
        yield put({type: Types.CONTACT_LIST_ACTION});
    }, errorHandle(Types.CONTACT_FAIL)))
}

function* createNoteAction() {
    yield takeLatest(Types.CONTACT_CREATE_NOTE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new ContactRequest(action.params?.query).exportCreateNote();
        const result = yield call(FunctionService.createNote, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
        // action.params.props.history.goBack();
        if (action?.params?.isDetail) {
            yield put(FunctionAction.contactDetailAction({id: data?.id}));
            yield put(FunctionAction.contactCreateNoteSuccess());
        } else {
            yield put(FunctionAction.contactListAction(action.params?.filters));
            yield put(FunctionAction.contactCreateNoteSuccess());
        }
    }, errorHandle(Types.CONTACT_FAIL)))
}

function* updateAction() {
    yield takeLatest(Types.CONTACT_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new ContactRequest(action.params).exportUpdate();
        yield call(FunctionService.update, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.contactDetailAction({id: action.params.id}));
    }, errorHandle(Types.CONTACT_FAIL)))
}

function* updateNoteAction() {
    yield takeLatest(Types.CONTACT_UPDATE_NOTE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new ContactRequest(action.params.query).exportUpdateNote();
        yield call(FunctionService.updateNote, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.contactDetailAction({id: action.params.id}));
    }, errorHandle(Types.CONTACT_FAIL)))
}

function* deleteNoteAction() {
    yield takeLatest(Types.CONTACT_DELETE_NOTE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(FunctionService.deleteNote, action.params?.query);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["203"]);
        yield put(FunctionAction.contactDetailAction({id: action.params?.query.id}));
    }, errorHandle(Types.CONTACT_FAIL)))
}

function* deleteAction() {
    yield takeLatest(Types.CONTACT_DELETE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(FunctionService.deleteFunction, action.params?.id);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["203"]);
        yield put(FunctionAction.contactListAction(action.params?.filters));
    }, errorHandle(Types.CONTACT_FAIL)))
}

function* optOutAction() {
    yield takeLatest(Types.CONTACT_OPT_OUT_ACTION, Func.sagaWrapper(function* (action) {
        const data = new ContactRequest(action.params.query).exportOptOut();
        yield call(FunctionService.optOut, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["209"]);
        if (action.params.isInbox) {
            yield put(FunctionAction.contactDetailAction({id: action?.params?.id}));
        } else {
            yield put(FunctionAction.contactListAction(action?.params?.filters));
        }
    }, errorHandle(Types.CONTACT_FAIL)))
}

function* optInAction() {
    yield takeLatest(Types.CONTACT_OPT_IN_ACTION, Func.sagaWrapper(function* (action) {
        const data = new ContactRequest(action.params.query).exportOptOut();
        yield call(FunctionService.optIn, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["209"]);
        if (action.params.isInbox) {
            yield put(FunctionAction.contactDetailAction({id: action?.params?.id}));
        } else {
            yield put(FunctionAction.contactListAction(action?.params?.filters));
        }
    }, errorHandle(Types.CONTACT_FAIL)))
}

function* checkDuplicate() {
    yield takeLatest(Types.CONTACT_CHECK_DUPLICATE_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.checkDuplicate);
        yield put(FunctionAction.contactCheckDuplicateSuccess(result));
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["209"]);
        yield put(FunctionAction.contactListAction(action?.params?.filters));
    }, errorHandle(Types.CONTACT_FAIL)))
}

function* importMondayAction() {
    yield takeLatest(Types.CONTACT_IMPORT_MONDAY_ACTION, Func.sagaWrapper(function* (action) {
        // const data = new ContactRequest(action.params).exportCreate();
        const result = yield call(FunctionService.importMonday, {});
        message.success(result);
        yield put(FunctionAction.contactImportMondaySuccess(result));
    }, errorHandle(Types.CONTACT_FAIL)))
}

function* importHubspotAction() {
    yield takeLatest(Types.CONTACT_IMPORT_HUBSPOT_ACTION, Func.sagaWrapper(function* (action) {
        // const data = new ContactRequest(action.params).exportCreate();
        const result = yield call(FunctionService.importHubspot, {});
        message.success(result);
        yield put(FunctionAction.contactImportHubspotSuccess(result));
    }, errorHandle(Types.CONTACT_FAIL)))
}

function* importPodioAction() {
    yield takeLatest(Types.CONTACT_IMPORT_PODIO_ACTION, Func.sagaWrapper(function* (action) {
        // const data = new ContactRequest(action.params).exportCreate();
        const result = yield call(FunctionService.importPodio, {});
        message.success(result);
        yield put(FunctionAction.contactImportPodioSuccess(result));
    }, errorHandle(Types.CONTACT_FAIL)))
}

function* actionGetListFilters() {
    yield takeLatest(Types.CONTACT_FILTER_LIST_ACTION, Func.sagaWrapper(function* (action) {
        // const params = parseListRequestParams(contactFiltersParams(action.filters) || {});
        const result = yield call(FunctionService.actionContactFiltersList, action.filters);
        yield put(FunctionAction.contactFilterListSuccess(result));
    }, errorHandle(Types.CONTACT_FAIL)))
}

function* saveFiltersAction() {
    yield takeLatest(Types.CONTACT_FILTER_SAVE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new ContactRequest(action.params).exportFilterSave();
        const result = yield call(FunctionService.saveFilters, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
        yield put(FunctionAction.contactFilterSaveSuccess(true));
        yield put(FunctionAction.contactFilterListAction());
        // action.params.props.history.goBack();
        // yield put({type: Types.CONTACT_LIST_ACTION});
    }, errorHandle(Types.CONTACT_FAIL)))
}

function* deleteFiltersAction() {
    yield takeLatest(Types.CONTACT_FILTER_DELETE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(FunctionService.deleteFilters, action.params?.title);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["203"]);
        yield put(FunctionAction.contactFilterDeleteSuccess(true));
        yield put(FunctionAction.contactFilterListAction());
    }, errorHandle(Types.CONTACT_FAIL)))
}

function* createColumnAction() {
    yield takeLatest(Types.CONTACT_COLUMN_SAVE_ACTION, Func.sagaWrapper(function* (action) {
        // yield call(FunctionService.deleteColumn, action.params?.id)
        yield call(FunctionService.createColumn, action.params?.query, action.params?.id);
        yield put(FunctionAction.contactColumnSaveSuccess(true));
        // message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
        // action.params.props.history.goBack();
        // yield put({type: Types.CONTACT_LIST_ACTION});
    }, errorHandle(Types.CONTACT_FAIL)))
}

function* actionMasterPortalFilter() {
    yield takeLatest(Types.CONTACT_MASTER_PORTAL_FILTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionMasterPortalFilter, action.params?.id);
        yield put(FunctionAction.contactMasterPortalFilterSuccess(result));
    }, errorHandle(Types.CONTACT_FAIL)))
}

function* highlightCellAction() {
    yield takeLatest(Types.CONTACT_HIGHLIGHT_CELL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.highlightCell, action.params?.query, action.params?.id);
        message.success(result);
        yield put(FunctionAction.contactListAction(action.params?.filters));
    }, errorHandle(Types.CONTACT_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        console.log("===> ", e)
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* contactSaga() {
    yield all([
        fork(actionGetListContact),
        fork(createAction),
        fork(actionGetMasterContact),
        fork(actionGetItem),
        fork(updateAction),
        fork(deleteAction),
        fork(optOutAction),
        fork(optInAction),
        fork(checkDuplicate),
        fork(createNoteAction),
        fork(importMondayAction),
        fork(actionGetListFilters),
        fork(saveFiltersAction),
        fork(deleteFiltersAction),
        fork(importHubspotAction),
        fork(createColumnAction),
        fork(updateNoteAction),
        fork(deleteNoteAction),
        fork(actionMasterPortalFilter),
        fork(importPodioAction),
        fork(highlightCellAction),
    ])
}
