import React, {useState, useEffect, useRef} from "react";
import {useSelector} from 'react-redux'

// ============== LAYOUT ==============
import {Form, Input, Button, Select, Spin, Checkbox, Modal} from 'antd';
import HeaderAction from "../../../common/layout/HeaderAction";
import EmailEditor from 'react-email-editor';

// ============== ACTION ==============
import {
    ActionMasterData,
    ActionCreate,
    ActionInit,
    ActionGetItem,
    ActionUpdate
} from "../../../../data/hooks/template";

// ============== SELECT DATA ==============
import {selectFetching, selectItem, selectMasterData} from "../../../../data/reselects/templateSelector";
import {common} from "../../../../data/mapping/Request/ContactRequest";
import {Icon} from "../../../common";
import {isEmail} from "../../../../utils/validation";
import TAG_DEFINE from "../../../../constants/common";
import {ROUTE_REDIRECT} from "../../../../constants/define";
import CkEditor from "../../../common/CkEditor";

const Index = (props) => {
    const [form] = Form.useForm(),
        emailEditorRef = useRef(null),
        [isFetching, setIsFetching] = useState(false),
        [isPreview, setIsPreview] = useState(false),
        [typePage, setTypePage] = useState(TAG_DEFINE.ACTION.create),
        [masterStatus, setMasterStatus] = useState({}),
        [isUpdate, setIsUpdate] = useState(false),
        // ============== ACTION ==============
        getMasterData = ActionMasterData(),
        actionCreate = ActionCreate(),
        actionUpdate = ActionUpdate(),
        getInitAction = ActionInit(),
        actionGetItem = ActionGetItem(),
        // ============== SELECT DATA ==============
        itemFetching = useSelector(selectFetching()),
        itemDetail = useSelector(selectItem()),
        itemMasterData = useSelector(selectMasterData());

    useEffect(() => {
        const {match} = props;
        if (Object.keys(match.params).length > 0) {
            setIsUpdate(true);
            if (Object.keys(itemDetail || {}).length < 1) {
                setTypePage(TAG_DEFINE.ACTION.update)
                actionGetItem({id: match.params?.id, props: props});
            }
        } else {
            setIsUpdate(false)
        }
    }, [props.match]);

    useEffect(() => {
        // getMasterData()

        return () => {
            getInitAction({clearDetail: true});
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);

    useEffect(() => {
        if (Object.keys(itemDetail || {}).length > 0) {
            form.setFieldsValue(itemDetail)
        }
    }, [itemDetail]);

    useEffect(() => {
        if (Object.keys(itemMasterData?.status || {}).length > 0) {
            setMasterStatus(itemMasterData?.status)
        }
    }, [itemMasterData]);


    const onFinish = (val) => {
        if (isUpdate) {
            actionUpdate({...val, props, id: itemDetail?.id})
        } else {
            actionCreate({...val, props})
        }
    }

    const exportHtml = () => {
        emailEditorRef.current.editor.exportHtml((data) => {
            const {design, html} = data;
            form.setFieldsValue({
                template: JSON.stringify({design, html: btoa(unescape(encodeURIComponent(html)))})
            })
            form.submit();
        });
    };
    const onLoad = () => {
        // editor instance is created
        // you can load your template here;
        // const templateJson = {};
        if (itemDetail?.template) {
            emailEditorRef.current.editor.loadDesign(JSON.parse(itemDetail?.template).design)
        }
    }

    const onReady = () => {
        // editor is ready
        // console.log('onReady');
    };

    return (
        <>
            <HeaderAction
                title={`Template ${TAG_DEFINE.ACTION[typePage.toLowerCase()]}`}
            />
            <Spin tip="Loading..." spinning={isFetching}>
                <div className="container bg-white mt-4 p-4">
                    <div className="row">
                        <div className="col-12">
                            <p className="mb-1 color--description">{`* Note: You can set specific data of each contact in template by using these string
                                    values {{first_name}} {{last_name}} {{address}} {{phone_number}} {{email}}`}</p>
                            <p className="mb-3 color--description">{`Example: "Hello {{first_name}}, {{last_name}}" => "Hello Joe, Smith" if first name
                                    and last name of contact are Joe and Smith.`}</p>
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={onFinish}
                                // onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <div className="row align-items-center">
                                            <div className="col-9">
                                                <Form.Item
                                                    label="Title"
                                                    name="title"
                                                >
                                                    <Input/>
                                                </Form.Item>
                                            </div>
                                            <div className="col-3">
                                                {
                                                    itemDetail?.is_admin ? (
                                                        <Form.Item
                                                            label=" "
                                                            name="is_template"
                                                            valuePropName="checked"
                                                        >
                                                            <Checkbox>Is Publish</Checkbox>
                                                        </Form.Item>
                                                    ) : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <Form.Item
                                            label="Template"
                                            name="template"
                                            rules={[{
                                                required: true,
                                                message: 'Please input your template!'
                                            }]}
                                        >
                                            <EmailEditor ref={emailEditorRef} onLoad={onLoad()}/>
                                        </Form.Item>
                                    </div>
                                </div>
                                <Form.Item className="text-end">
                                    <Button type="default" htmlType="button" className="me-3"
                                            onClick={() => props.history.push(ROUTE_REDIRECT.TEMPLATE.HOME)}>
                                        Back
                                    </Button>
                                    <Button type="primary" htmlType="button" onClick={e => exportHtml()}>
                                        Save {isFetching && <Icon type="sync-outlined" spin/>}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </Spin>
        </>
    )
}

export default Index
