import * as Types from '../types/Inbox';
import {PAGINATION} from '../../constants/define';
// import HotelRequest from "../mapping/Request/HotelRequest";

const initialState = {
    isFetching: false,
    statusAction: {
        isSubmit: false,
        isLoadDetail: false
    },
    all: [],
    errors: [],
    // item: new HotelRequest().update(),
    data: {
        result: [],
        pagination: {
            page_size: PAGINATION.page_size,
            page_index: PAGINATION.page_index,
            total: PAGINATION.total,
            total_page: PAGINATION.total_page,
        }
    },
    item: {},
    master: {}
};

const getResult = (page) => {
    return {
        result: page.result || [],
        pagination: {
            page_size: page?.page_size || PAGINATION.page_size,
            page_index: page?.page_index || PAGINATION.page_index,
            total: page?.total || PAGINATION.total,
            total_page: page?.total_page || PAGINATION.total_page,
        }
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.INBOX_MASTER_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.INBOX_MASTER_SUCCESS:
            return {...state, isFetching: false, master: action.master, statusAction: initialState.statusAction};
        case Types.INBOX_LIST_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.INBOX_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: getResult(action.list),
                statusAction: initialState.statusAction
            };
        case Types.INBOX_DETAIL_ACTION:
            return {
                ...state,
                statusAction: {
                    ...initialState.statusAction,
                    isLoadDetail: action?.params?.disabledLoad || true
                }
            };
        case Types.INBOX_DETAIL_SUCCESS:
            return {
                ...state, item: action.item, statusAction: {
                    ...initialState.statusAction,
                    isLoadDetail: false
                }
            };
        case Types.INBOX_UPDATE_ACTION:
            return {...state, statusAction: {...initialState.statusAction, isSubmit: true}};
        case Types.INBOX_UPDATE_SUCCESS:
            return {...state, statusAction: {...initialState.statusAction, isSubmit: false}};
        case Types.INBOX_SEND_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.INBOX_SEND_SUCCESS:
            return {...state, isFetching: false, statusAction: initialState.statusAction};
        case Types.INBOX_FAIL:
            return {...state, errors: action.errors, isFetching: false, statusAction: initialState.statusAction};
        case Types.INBOX_INIT:
            // return initialState
            return {
                ...state,
                data: action.params?.clearList ? initialState.data : state.data,
                item: action.params?.clearDetail ? initialState.item : state.item,
                statusAction: initialState.statusAction
            };
        default:
            return state;
    }
}
