import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class WorkflowRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.source = this.generateSource(data?.SOURCE || {});
        this.rule = this.generateRule(data?.RULE || {});
        this.action = this.generateAction(data?.ACTION?.action || []);
        this.title = data?.title || "";
        this.timezone = data?.timezone || "";
        this.status = data?.status;
    }

    generateAction(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map(i => newItems.push({
                type: i?.type || "",
                template: i?.template || "",
                tag_id: i?.tag_id || ""
            }))
        }
        return newItems
    }

    generateSource(items = {}) {
        let newItems = {};
        if (Object.keys(items).length > 0) {
            newItems = {
                tag: items?.tag || []
            }
        }
        return newItems
    }

    generateRule(items = {}) {
        let newItems = {};
        if (Object.keys(items).length > 0) {
            newItems = {
                trigger_type: items?.trigger_type || "",
                period: items?.period || "",
                time: items?.time || [],
                day_of_week: items?.day_of_week || "",
                day_of_month: items?.day_of_month || "",
                month_of_year: items?.month_of_year || "",
                skip_value: items?.skip_value || "",
                skip_type: items?.skip_type || "",
            }
        }
        return newItems
    }

    exportCreate() {
        return {
            source: this.source,
            rule: this.rule,
            action: this.action,
            title: this.title,
            timezone: this.timezone,
            status: this.status,
        }
    }

    exportUpdate() {
        return {
            source: this.source,
            rule: this.rule,
            action: this.action,
            title: this.title,
            timezone: this.timezone,
            status: this.status,
        }
    }

}

export const common = {
    ACTION_FIELD: {
        title: "title",
        template: "template",
    },
}

export const filtersParams = (filters) => {
    return removeObjectNullFull({
        search: filters?.search || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
    })
}

