import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import Img from './Img';

import 'react-lazy-load-image-component/src/effects/blur.css';
import {FILES} from "../../../constants/define";

const LazyImage = ({
                       className, imageClass, alt, src,
                       placeholder = false, threshold = 200,
                       width, height,
                       layout,
                       loading = 'lazy',
                       objectFit = 'cover',
                       objectPosition,
                       unoptimized = false,
                       ...resProps
                   }) => {

    const [error, setError] = useState(false)
    const [source, setSource] = useState("")

    const onError = () => {
        setError(true)
    }

    useEffect(() => {
        setError(false)
        setSource(src)
    }, [src])

    return source || !error ? (
        <LazyLoadImage
            alt={alt}
            wrapperClassName={clsx('d-block', className)}
            // className={clsx('lazy-image w-full', imageClass)}
            className={clsx('lazy-image w-100', imageClass)}
            threshold={threshold}
            // effect={'blur'}
            // placeholderSrc={placeholder ? `/api/image/placeholder?src=${encodeURIComponent(source)}` : undefined}
            // placeholderSrc={FILES.SPINNING}
            src={source}
            onError={onError}
            {...resProps}
        />
    ) : (
        <div className={clsx('d-flex items-center justify-center', className)}>
            <Img alt={alt}
                // className={clsx('lazy-image w-full', imageClass)}
                 className={clsx('lazy-image w-100', imageClass)}
                 src={FILES.BANNER_DEFAULT}
                 layout={layout}
                 width={width || "100%"}
                 height={height || "100%"}
                 loading={loading}
                 objectFit={objectFit}
                 objectPosition={objectPosition}
                 unoptimized={unoptimized}
                 style={{objectFit: 'cover'}}
            />
        </div>
    )
}

LazyImage.propTypes = {
    className: PropTypes.string,
    alt: PropTypes.string,
    src: PropTypes.string,
}

export default LazyImage
