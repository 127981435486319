import * as Types from '../types/EmailHistory';

export const emailHistoryMasterAction = (filters) => ({type: Types.EMAIL_HISTORY_MASTER_ACTION, filters});
export const emailHistoryMasterSuccess = (master) => ({
    type: Types.EMAIL_HISTORY_MASTER_SUCCESS,
    master
});

export const emailHistoryListAction = (filters) => ({type: Types.EMAIL_HISTORY_LIST_ACTION, filters});
export const emailHistoryListSuccess = (list) => ({
    type: Types.EMAIL_HISTORY_LIST_SUCCESS,
    list
});

export const emailHistoryDetailAction = (params) => ({type: Types.EMAIL_HISTORY_DETAIL_ACTION, params});
export const emailHistoryDetailSuccess = (detail) => ({
    type: Types.EMAIL_HISTORY_DETAIL_SUCCESS,
    detail
});

export const emailHistoryUpdateAction = (params) => ({type: Types.EMAIL_HISTORY_UPDATE_ACTION, params});
export const emailHistoryUpdateSuccess = (updateData) => ({
    type: Types.EMAIL_HISTORY_UPDATE_SUCCESS,
    updateData
});

export const emailHistoryCreateAction = (params) => ({type: Types.EMAIL_HISTORY_CREATE_ACTION, params});
export const emailHistoryCreateSuccess = (createData) => ({
    type: Types.EMAIL_HISTORY_CREATE_SUCCESS,
    createData
});

export const emailHistoryVerifyEmailAction = (params) => ({type: Types.EMAIL_HISTORY_VERIFY_EMAIL_ACTION, params});
export const emailHistoryVerifyEmailSuccess = (verifyEmail) => ({
    type: Types.EMAIL_HISTORY_VERIFY_EMAIL_SUCCESS,
    verifyEmail
});

export const emailHistoryInitStore = (params) => ({type: Types.EMAIL_HISTORY_INIT, params});
export const emailHistoryClearStore = (params) => ({type: Types.EMAIL_HISTORY_FAIL, params});
