import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class ContactRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.time = data?.time || "";
        this.name = data?.name || "";
        this.email = data?.email || "";
        this.address = data?.address || "";
        this.phone_number = data?.phone_number || "";
        this.phone_numbers = data?.phone_numbers || [];
        this.company = data?.company || "";
        this.job_title = data?.job_title || "";
        this.tag = data?.tag || "";
        this.status = formatInt(data?.status);
        this.property_address = data?.property_address || "";
        this.property_city = data?.property_city || "";
        this.property_state = data?.property_state || "";
        this.property_zip_code = data?.property_zip_code || "";
        this.note = data?.note || "";
        this.title = data?.title || "";
        this.value = data?.value || "";
        this.created_date = data?.created_date || "";

        this.lead_type = data?.lead_type || "";
        this.yr_bt = data?.yr_bt || "";
        this.estimated_equity = data?.estimated_equity || "";
        this.equity = data?.equity || "";
        this.appraised_value = data?.appraised_value || "";
        this.auction_date = data?.auction_date || "";
        this.email_addresses_2 = data?.email_addresses_2 || "";
        this.email_addresses_3 = data?.email_addresses_3 || "";
        this.email_addresses_4 = data?.email_addresses_4 || "";
        this.email_addresses_5 = data?.email_addresses_5 || "";
        this.cell_phones_1 = data?.cell_phones_1 || "";
        this.cell_phones_2 = data?.cell_phones_2 || "";
        this.cell_phones_3 = data?.cell_phones_3 || "";
        this.cell_phones_4 = data?.cell_phones_4 || "";
        this.cell_phones_5 = data?.cell_phones_5 || "";
        this.phone_numbers_2 = data?.phone_numbers_2 || "";
        this.phone_numbers_3 = data?.phone_numbers_3 || "";
        this.phone_numbers_4 = data?.phone_numbers_4 || "";
        this.phone_numbers_5 = data?.phone_numbers_5 || "";
    }

    exportCreate() {
        return {
            name: this.name,
            email: this.email,
            address: this.address,
            phone_number: this.phone_number,
            company: this.company,
            job_title: this.job_title,
            property_address: this.property_address,
            property_city: this.property_city,
            property_state: this.property_state,
            property_zip_code: this.property_zip_code,

            lead_type: this.lead_type,
            yr_bt: this.yr_bt,
            estimated_equity: this.estimated_equity,
            equity: this.equity,
            appraised_value: this.appraised_value,
            auction_date: this.auction_date,
            email_addresses_2: this.email_addresses_2,
            email_addresses_3: this.email_addresses_3,
            email_addresses_4: this.email_addresses_4,
            email_addresses_5: this.email_addresses_5,
            cell_phones_1: this.cell_phones_1,
            cell_phones_2: this.cell_phones_2,
            cell_phones_3: this.cell_phones_3,
            cell_phones_4: this.cell_phones_4,
            cell_phones_5: this.cell_phones_5,
            phone_numbers_2: this.phone_numbers_2,
            phone_numbers_3: this.phone_numbers_3,
            phone_numbers_4: this.phone_numbers_4,
            phone_numbers_5: this.phone_numbers_5,
        }
    }

    exportUpdate() {
        return {
            id: this.id,
            name: this.name,
            email: this.email,
            phone_number: this.phone_number,
            company: this.company,
            job_title: this.job_title,
            tag: this.tag,
            address: this.address,
            status: this.status,
            property_address: this.property_address,
            property_city: this.property_city,
            property_state: this.property_state,
            property_zip_code: this.property_zip_code,
            created_date: this.created_date,


            lead_type: this.lead_type,
            yr_bt: this.yr_bt,
            estimated_equity: this.estimated_equity,
            equity: this.equity,
            appraised_value: this.appraised_value,
            auction_date: this.auction_date,
            email_addresses_2: this.email_addresses_2,
            email_addresses_3: this.email_addresses_3,
            email_addresses_4: this.email_addresses_4,
            email_addresses_5: this.email_addresses_5,
            cell_phones_1: this.cell_phones_1,
            cell_phones_2: this.cell_phones_2,
            cell_phones_3: this.cell_phones_3,
            cell_phones_4: this.cell_phones_4,
            cell_phones_5: this.cell_phones_5,
            phone_numbers_2: this.phone_numbers_2,
            phone_numbers_3: this.phone_numbers_3,
            phone_numbers_4: this.phone_numbers_4,
            phone_numbers_5: this.phone_numbers_5,
        }
    }

    exportCreateNote() {
        return {
            id: this.id,
            note: this.note
        }
    }

    exportUpdateNote() {
        return {
            id: this.id,
            note: this.note,
            time: this.time
        }
    }

    exportOptOut() {
        return {
            phone_numbers: this.phone_numbers
        }
    }

    exportFilterSave() {
        return {
            title: this.title,
            value: this.value,
        }
    }
}

export const common = {
    ACTION_FIELD: {
        name: "name",
        email: "email",
        address: "address",
        phone_number: "phone_number",
        company: "company",
        job_title: "job_title",
        status: "status",
        owner: "owner",
        tag: "tag",
        source: "source",
        extra_info: "extra_info",
        created_date: "created_date",
        property_address: "property_address",
        property_city: "property_city",
        property_state: "property_state",
        property_zip_code: "property_zip_code",
        lead_type: "lead_type",
        yr_bt: "yr_bt",
        estimated_equity: "estimated_equity",
        equity: "equity",
        appraised_value: "appraised_value",
        auction_date: "auction_date",
        email_addresses_2: "email_addresses_2",
        email_addresses_3: "email_addresses_3",
        email_addresses_4: "email_addresses_4",
        email_addresses_5: "email_addresses_5",
        cell_phones_1: "cell_phones_1",
        cell_phones_2: "cell_phones_2",
        cell_phones_3: "cell_phones_3",
        cell_phones_4: "cell_phones_4",
        cell_phones_5: "cell_phones_5",
        phone_numbers_2: "phone_numbers_2",
        phone_numbers_3: "phone_numbers_3",
        phone_numbers_4: "phone_numbers_4",
        phone_numbers_5: "phone_numbers_5",
    },
}

export const contactFiltersParams = (filters) => {
    let newDateAdded = (filters?.date || []).length > 0 ? [moment(filters?.date[0]).format("YYYY-MM-DD"), moment(filters?.date[1]).format("YYYY-MM-DD")] : ["", ""]
    let newAuctionDate = (filters?.auction_date || []).length > 0 ? [moment(filters?.auction_date[0]).format("YYYY-MM-DD"), moment(filters?.auction_date[1]).format("YYYY-MM-DD")] : ["", ""]
    return removeObjectNullFull({
        search: filters?.search || "",
        source: filters?.source || "",
        tag: filters?.tag || "",
        status: filters?.status || "",
        lead_type: filters?.lead_type || "",
        is_owned: convertBooleanToInt(filters?.is_owned),
        from_date: newDateAdded[0],
        to_date: newDateAdded[1],
        auction_date_from: newAuctionDate[0],
        auction_date_to: newAuctionDate[1],
        // date: filters?.date || [],
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        without_date: filters?.without_date || "",
        is_export: filters?.is_export || "",
    })
}

