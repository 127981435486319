import * as Types from '../types/Inbox';

export const inboxMasterAction = (filters) => ({type: Types.INBOX_MASTER_ACTION, filters});
export const inboxMasterSuccess = (master) => ({
    type: Types.INBOX_MASTER_SUCCESS,
    master
});

export const inboxListAction = (filters) => ({type: Types.INBOX_LIST_ACTION, filters});
export const inboxListSuccess = (list) => ({
    type: Types.INBOX_LIST_SUCCESS,
    list
});

export const inboxDetailAction = (params) => ({type: Types.INBOX_DETAIL_ACTION, params});
export const inboxDetailSuccess = (item) => ({
    type: Types.INBOX_DETAIL_SUCCESS,
    item
});

export const inboxUpdateAction = (params) => ({type: Types.INBOX_UPDATE_ACTION, params});
export const inboxUpdateSuccess = (updateData) => ({
    type: Types.INBOX_UPDATE_SUCCESS,
    updateData
});

export const inboxSendAction = (params) => ({type: Types.INBOX_SEND_ACTION, params});
export const inboxSendSuccess = (sendData) => ({
    type: Types.INBOX_SEND_SUCCESS,
    sendData
});

export const inboxInitStore = (params) => ({type: Types.INBOX_INIT, params});
export const inboxClearStore = (params) => ({type: Types.INBOX_FAIL, params});
