import {Button, Form, Input, Modal, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {ActionGetList, ActionMasterData, ActionCreate} from "../../../../data/hooks/task";
import {useSelector} from "react-redux";
import {
    selectErrors,
    selectFetching,
    selectList,
    selectMasterData,
    selectStatusAction
} from "../../../../data/reselects/taskSelector";

const CreateModal = props => {
    const [form] = Form.useForm(),
        [isCreate, setIsCreate] = useState(false),
        // ============== ACTION ==============
        actionCreate = ActionCreate(),
        actionMasterData = ActionMasterData(),
        // ============== SELECT DATA ==============
        itemStatusAction = useSelector(selectStatusAction()),
        itemList = useSelector(selectList()),
        itemErrors = useSelector(selectErrors()),
        itemMasterData = useSelector(selectMasterData()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        form.resetFields()
        return () => {
            form.resetFields()
            setIsCreate(false)
        }
    }, []);

    useEffect(() => {
        if (itemStatusAction?.createSuccess) {
            form.resetFields()
            setIsCreate(false)
            props?.setIsCreate(false)
        }
    }, [itemStatusAction]);

    useEffect(() => {
        setIsCreate(props?.isCreate)
    }, [props?.isCreate]);

    const handleOk = () => {
        setIsCreate(false);
    };

    const handleCancel = () => {
        form.resetFields()
        setIsCreate(false);
        props?.setIsCreate(false)
    };

    const onSubmit = val => {
        actionCreate(val)
        // actionCreateNote({
        //     ...props?.isAction,
        //     query: {...val, id: props?.isAction?.id},
        // })
    }

    return (
        <Modal
            title="Create Task"
            maskClosable={false}
            destroyOnClose
            visible={isCreate}
            onOk={handleOk}
            footer={null}
            onCancel={handleCancel}>
            <Spin spinning={itemStatusAction?.isSubmit}>
                <Form
                    layout="vertical"
                    onFinish={onSubmit}
                    form={form}
                >
                    <div className="row">
                        <div className="col-12">
                            <Form.Item
                                className="w-100"
                                label="Title"
                                name="title"
                            >
                                <Input/>
                            </Form.Item>
                        </div>
                        <div className="col-12">
                            <Form.Item className="text-end mb-0">
                                <Button type="primary"
                                        htmlType="submit"
                                        className="me-3 mb-0">
                                    Submit
                                </Button>
                                <Button htmlType="button"
                                        className="mb-0"
                                        onClick={() => {
                                            form.resetFields()
                                            setIsCreate(false)
                                            props?.setIsCreate(false)
                                        }}>
                                    Cancel
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </Spin>
        </Modal>
    )
}

export default CreateModal
