import React, {useState, useEffect} from "react";
import {Link, withRouter} from "react-router-dom";
import {Navigation as NavigationComponent} from "megalith"
import routers from "../../../routers";
import {find} from "lodash";
import {Menu, Button, Drawer} from 'antd';
import Icon, {
    AppstoreOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    PieChartOutlined,
    DesktopOutlined,
    ContainerOutlined,
    MailOutlined, StepForwardOutlined, RightOutlined,
} from '@ant-design/icons';
import {useSelector} from "react-redux";
import {capitalizeFirstLetter} from "../../../utils/functions";
import {getProfile} from "../../../data/reselects/authSelector";

const {SubMenu} = Menu;

const Navigation = (props) => {
    const [itemMenu, setItemMenu] = useState([]),
        itemAuthProfile = useSelector(getProfile());

    useEffect(() => {
        setItemMenu(itemAuthProfile?.menu || [])
    }, [itemAuthProfile.menu]);

    const renderMenuItem = (item) => {
        let arrayMenu = [];
        if (item.length > 0) {
            item.map((i, k) => arrayMenu.push(
                <Menu.Item key={`/${i.route}`} className="">
                    <Link to={`/${i.route}`}
                          className="fs--18px text-decoration-none">{capitalizeFirstLetter(i.name)}</Link>
                </Menu.Item>
            ))
        }
        return arrayMenu
    }

    return (
        <>
            {/*<NavigationComponent*/}
            {/*    isCollapse={props?.isCollapse}*/}
            {/*    className={`d-none ${props?.collapsed ? "" : "d-lg-block"}`}*/}
            {/*    style={{width: props?.isCollapse ? "3%" : "13%", float: "left"}}*/}
            {/*    items={itemAuthProfile?.common?.navigation?.items}*/}
            {/*    itemsButton={itemAuthProfile?.common?.navigation?.items_button}*/}
            {/*    currentPathname={props?.location?.pathname}*/}
            {/*    onPush={e => props?.history.push(e)}*/}
            {/*/>*/}
            <div className="hn__navigation d-none" style={{width: props?.collapsed ? 50 : 230}}>
                {
                    itemMenu.length > 0 && (
                        <Menu
                            defaultSelectedKeys={['/']}
                            selectedKeys={[props?.location?.pathname]}
                            mode="vertical"
                            theme="dark"
                            inlineCollapsed={props?.collapsed}
                            className="pt-3"
                        >
                            {
                                itemMenu.map((i, k) => {
                                    if ((i?.items || []).length > 0) {
                                        return (
                                            <SubMenu
                                                popupClassName={`${props?.collapsed ? "left--60px" : ""} hn__navigation--hide-all-popup-submenu`}
                                                key={i?.group} title={
                                                <>
                                                    <i className={`width-18px height--20px hn__navigation--icon icon--${i?.icon ? i?.icon : "off-market"} ${props?.collapsed ? "me-2" : "me-2"}`}/>
                                                    {
                                                        props.collapsed ?
                                                            <i className={`icon--arrow-right ${props?.collapsed ? "me-2" : ""}`}/> : ""
                                                    }
                                                    {!props?.collapsed && i?.name}
                                                </>
                                            } className="fs--18px hn__navigation--hide-all-submenu"
                                                onTitleClick={() => props.history.push(`/${i?.route}`)}>
                                                {renderMenuItem(i?.items || [])}
                                            </SubMenu>
                                        )
                                    } else {
                                        return (
                                            <Menu.Item key={`/${i.route}`} className="">
                                                <Link to={`/${i.route}`}
                                                      className="fs--18px text-decoration-none">
                                                    <i className={`width-18px height--20px hn__navigation--icon icon--${i?.icon ? i?.icon : "off-market"} ${props?.collapsed ? "me-3" : "me-2"}`}/>
                                                    {!props?.collapsed && capitalizeFirstLetter(i.name)}
                                                </Link>
                                            </Menu.Item>
                                        )
                                    }
                                })
                            }
                        </Menu>
                    )
                }
            </div>
            <Drawer title={<img src="./layout/logo.png" width="200px" height="80px"/>}
                    className="hn__navigation--mobile"
                    placement="left" onClose={props.toggleCollapsed} visible={props?.collapsed}>
                {
                    itemMenu.length > 0 && (
                        <Menu
                            defaultSelectedKeys={['/']}
                            selectedKeys={[props?.location?.pathname]}
                            mode="inline"
                            theme="dark"
                        >
                            {
                                itemMenu.map((i, k) => {
                                    if ((i?.items || []).length > 0) {
                                        return (
                                            <SubMenu key={i?.group} title={i?.name} className="fs--18px">
                                                {renderMenuItem(i?.items || [])}
                                            </SubMenu>
                                        )
                                    } else {
                                        return (
                                            <Menu.Item key={`/${i.route}`} className="">
                                                <Link to={`/${i.route}`}
                                                      className="fs--18px text-decoration-none">{capitalizeFirstLetter(i.name)}</Link>
                                            </Menu.Item>
                                        )
                                    }
                                })
                            }
                        </Menu>
                    )
                }
            </Drawer>
        </>
    );
};

export default withRouter(Navigation);
