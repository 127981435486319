import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import EmbeddedResponse, {EmbeddedMasterResponse} from "../mapping/Response/EmbeddedResponse";

export function actionMasterEmbedded() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.EMBEDDED_MASTER,
        parser: data => new EmbeddedMasterResponse(data.data.result).exportMaster(),
    }).get();
}

export function actionHandleFormShare(url) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: url,
        parser: data => data,
    }).get();
}

export function actionEmbeddedList(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.EMBEDDED_LIST,
        params: params,
        parser: dataParser,
    }).get();
}

export function actionEmbeddedDetail(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.EMBEDDED_GET_ITEM,
        endpointParams: {id},
        parser: data => new EmbeddedResponse(data.data.result).exportDetail(),
    }).get();
}

export function update(data, id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.EMBEDDED_UPDATE,
        endpointParams: {id},
        parser: data => data.data.result,
    }).put(data);
}

export function create(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.EMBEDDED_CREATE,
        parser: data => data.data.result,
    }).post(data);
}

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new EmbeddedResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
