import * as Types from '../types/Contact';

export const contactMasterAction = (filters) => ({type: Types.CONTACT_MASTER_ACTION, filters});
export const contactMasterSuccess = (master) => ({
    type: Types.CONTACT_MASTER_SUCCESS,
    master
});

export const contactListAction = (filters) => ({type: Types.CONTACT_LIST_ACTION, filters});
export const contactListSuccess = (contact) => ({
    type: Types.CONTACT_LIST_SUCCESS,
    contact
});

export const contactDetailAction = (params) => ({type: Types.CONTACT_DETAIL_ACTION, params});
export const contactDetailSuccess = (detail) => ({
    type: Types.CONTACT_DETAIL_SUCCESS,
    detail
});

export const contactUpdateAction = (params) => ({type: Types.CONTACT_UPDATE_ACTION, params});
export const contactUpdateSuccess = (updateData) => ({
    type: Types.CONTACT_UPDATE_SUCCESS,
    updateData
});

export const contactCreateAction = (params) => ({type: Types.CONTACT_CREATE_ACTION, params});
export const contactCreateSuccess = (createData) => ({
    type: Types.CONTACT_CREATE_SUCCESS,
    createData
});

export const contactDeleteAction = (params) => ({type: Types.CONTACT_DELETE_ACTION, params});
export const contactDeleteSuccess = (deleteData) => ({
    type: Types.CONTACT_DELETE_SUCCESS,
    deleteData
});

export const contactOptOutAction = (params) => ({type: Types.CONTACT_OPT_OUT_ACTION, params});
export const contactOptOutSuccess = (optOutData) => ({
    type: Types.CONTACT_OPT_OUT_SUCCESS,
    optOutData
});

export const contactOptInAction = (params) => ({type: Types.CONTACT_OPT_IN_ACTION, params});
export const contactOptInSuccess = (optInData) => ({
    type: Types.CONTACT_OPT_IN_SUCCESS,
    optInData
});

export const contactCheckDuplicateAction = (params) => ({type: Types.CONTACT_CHECK_DUPLICATE_ACTION, params});
export const contactCheckDuplicateSuccess = (checkDuplicateData) => ({
    type: Types.CONTACT_CHECK_DUPLICATE_SUCCESS,
    checkDuplicateData
});

export const contactCreateNoteAction = (params) => ({type: Types.CONTACT_CREATE_NOTE_ACTION, params});
export const contactCreateNoteSuccess = (createNoteData) => ({
    type: Types.CONTACT_CREATE_NOTE_SUCCESS,
    createNoteData
});
export const contactUpdateNoteAction = (params) => ({type: Types.CONTACT_UPDATE_NOTE_ACTION, params});
export const contactUpdateNoteSuccess = (updateNoteData) => ({
    type: Types.CONTACT_UPDATE_NOTE_SUCCESS,
    updateNoteData
});
export const contactDeleteNoteAction = (params) => ({type: Types.CONTACT_DELETE_NOTE_ACTION, params});
export const contactDeleteNoteSuccess = (deleteNoteData) => ({
    type: Types.CONTACT_DELETE_NOTE_SUCCESS,
    deleteNoteData
});


export const contactImportMondayAction = (params) => ({type: Types.CONTACT_IMPORT_MONDAY_ACTION, params});
export const contactImportMondaySuccess = (importMondayData) => ({
    type: Types.CONTACT_IMPORT_MONDAY_SUCCESS,
    importMondayData
});

export const contactImportHubspotAction = (params) => ({type: Types.CONTACT_IMPORT_HUBSPOT_ACTION, params});
export const contactImportHubspotSuccess = (importHubspotData) => ({
    type: Types.CONTACT_IMPORT_HUBSPOT_SUCCESS,
    importHubspotData
});

export const contactImportPodioAction = (params) => ({type: Types.CONTACT_IMPORT_PODIO_ACTION, params});
export const contactImportPodioSuccess = (importPodioData) => ({
    type: Types.CONTACT_IMPORT_PODIO_SUCCESS,
    importPodioData
});

export const contactFilterListAction = (filters) => ({type: Types.CONTACT_FILTER_LIST_ACTION, filters});
export const contactFilterListSuccess = (listFilters) => ({
    type: Types.CONTACT_FILTER_LIST_SUCCESS,
    listFilters
});

export const contactFilterDeleteAction = (params) => ({type: Types.CONTACT_FILTER_DELETE_ACTION, params});
export const contactFilterDeleteSuccess = (deleteFilters) => ({
    type: Types.CONTACT_FILTER_DELETE_SUCCESS,
    deleteFilters
});

export const contactFilterSaveAction = (params) => ({type: Types.CONTACT_FILTER_SAVE_ACTION, params});
export const contactFilterSaveSuccess = (saveFilters) => ({
    type: Types.CONTACT_FILTER_SAVE_SUCCESS,
    saveFilters
});

export const contactColumnListAction = (params) => ({type: Types.CONTACT_COLUMN_LIST_ACTION, params});
export const contactColumnListSuccess = (columns) => ({
    type: Types.CONTACT_COLUMN_LIST_SUCCESS,
    columns
});

export const contactColumnSaveAction = (params) => ({type: Types.CONTACT_COLUMN_SAVE_ACTION, params});
export const contactColumnSaveSuccess = (columnSave) => ({
    type: Types.CONTACT_COLUMN_SAVE_SUCCESS,
    columnSave
});

export const contactMasterPortalFilterAction = (params) => ({type: Types.CONTACT_MASTER_PORTAL_FILTER_ACTION, params});
export const contactMasterPortalFilterSuccess = (masterPortalFilter) => ({
    type: Types.CONTACT_MASTER_PORTAL_FILTER_SUCCESS,
    masterPortalFilter
});

export const contactInitStore = (params) => ({type: Types.CONTACT_INIT, params});
export const contactClearStore = (params) => ({type: Types.CONTACT_FAIL, params});

export const contactHighlightCellAction = (params) => ({type: Types.CONTACT_HIGHLIGHT_CELL_ACTION, params});
export const contactHighlightCellSuccess = (highlightCellData) => ({
    type: Types.CONTACT_HIGHLIGHT_CELL_SUCCESS,
    highlightCellData
});
