import * as Types from '../types/Cart';

export const cartMasterAction = (filters) => ({type: Types.CART_MASTER_ACTION, filters});
export const cartMasterSuccess = (master) => ({
    type: Types.CART_MASTER_SUCCESS,
    master
});

export const cartListAction = (filters) => ({type: Types.CART_LIST_ACTION, filters});
export const cartListSuccess = (list) => ({
    type: Types.CART_LIST_SUCCESS,
    list
});

export const cartDetailAction = (params) => ({type: Types.CART_DETAIL_ACTION, params});
export const cartDetailSuccess = (item) => ({
    type: Types.CART_DETAIL_SUCCESS,
    item
});

export const cartUpdateAction = (params) => ({type: Types.CART_UPDATE_ACTION, params});
export const cartUpdateSuccess = (updateData) => ({
    type: Types.CART_UPDATE_SUCCESS,
    updateData
});

export const cartCreateAction = (params) => ({type: Types.CART_CREATE_ACTION, params});
export const cartCreateSuccess = (createData) => ({
    type: Types.CART_CREATE_SUCCESS,
    createData
});


export const cartRemoveAction = (params) => ({type: Types.CART_REMOVE_ACTION, params});
export const cartRemoveSuccess = (removeData) => ({
    type: Types.CART_REMOVE_SUCCESS,
    removeData
});

export const cartInitStore = (params) => ({type: Types.CART_INIT, params});
export const cartClearStore = (params) => ({type: Types.CART_FAIL, params});
