import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    removeObjectNull, removeObjectNullFull, setParamsUrlFilter
} from "../../../utils/functions";
import {omit} from "lodash";
import TAG_DEFINE from "../../../constants/common";
import {PATHS} from "../../../constants/define";

// ============== LAYOUT ==============
import HeaderAction from "../../common/layout/HeaderAction";
import {Icon} from "../../common";
import ListComponent from "../../common/ListComponent";
import {Tooltip, Button, Tag, Form, Input, Select} from 'antd'

// ============== ACTION ==============
import {ActionGetListTeams, ActionMasterData} from '../../../data/hooks/teams';

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectErrors,
    selectListTeams,
    selectMasterData
} from '../../../data/reselects/teamsSelector';
import TeamsResponse, {column} from "../../../data/mapping/Response/TeamsResponse";
import {ROUTE_REDIRECT} from "../../../constants/define";
import queryString from "query-string";
import moment from "moment";

const ModelFields = Object.getOwnPropertyNames(new TeamsResponse());
const Index = props => {
    const namePage = "teams",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(column, ['action'], []),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        [form] = Form.useForm(),
        componentTable = {
            status: {
                width: 150,
                render: (val, record) => Object.keys(itemMasterData).length > 0 && (itemMasterData?.status[val]),
            },
            action: {
                width: 100,
                align: 'center',
                key: 'action',
                fixed: 'right',
                render: (text, record) => <Icon type="edit-outlined" className="cursorPointer"
                                                onClick={() => props.history.push((ROUTE_REDIRECT.TEAMS.UPDATE).replace(":id", record.id))}/>,
            }
        };
    const [isFetching, setIsFetching] = useState(false),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        // ============== ACTION ==============
        getListTeams = ActionGetListTeams(),
        getMasterData = ActionMasterData(),
        // ============== SELECT DATA ==============
        itemListTeams = useSelector(selectListTeams()),
        itemErrors = useSelector(selectErrors()),
        itemMasterData = useSelector(selectMasterData()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        if (Object.keys(queryString.parse(props.location.search)).length > 0) {
            let paramsFilters = queryString.parse(props.location.search, {arrayFormat: 'comma'});
            setPramsFilters(paramsFilters)
            // if (paramsFilters?.from_date && paramsFilters?.to_date) {
            //     paramsFilters.date = [moment(paramsFilters?.from_date), moment(paramsFilters?.to_date)]
            // }
            form.setFieldsValue(paramsFilters)
            getListTeams(removeObjectNullFull(paramsFilters));
        } else {
            getListTeams();
        }
        getMasterData()
    }, []);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        setResultList(itemListTeams?.result || [])
        setPagination({
            total: itemListTeams?.pagination?.total || 0,
            totalPage: itemListTeams?.pagination?.total_page || 0,
            defaultCurrent: itemListTeams?.pagination?.page_index || 1,
            pageSize: itemListTeams?.pagination?.page_size || 10
        })
    }, [itemListTeams]);

    const onTableChange = (pagination, filters, sorter) => {
        // let currentFilters = getCurrentFilters();
        filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        setPramsFilters(newFilters)
        setPramsFilters(newFilters)
        setInitData({
            ...initData,
            filters: newFilters
        });
        props.history.replace(setParamsUrlFilter(ROUTE_REDIRECT.TEAMS.HOME, newFilters))
        getListTeams(newFilters);
    };

    const onCreate = (e, data) => {
        if (e === TAG_DEFINE.ACTION_OPTIONS.UPDATE) {
            props.history.push((ROUTE_REDIRECT.TEAMS.UPDATE).replace(":id", data.id))
        } else {
            props.history.push(ROUTE_REDIRECT.TEAMS.CREATE)
        }
    };

    const onSearch = (val) => {
        let getFilters = removeObjectNullFull({...initData.filters, ...paramsFilters, ...val, page_index: 1});
        setPramsFilters(getFilters);
        setInitData({...initData, filters: paramsFilters})
        props.history.replace(setParamsUrlFilter(ROUTE_REDIRECT.TEAMS.HOME, getFilters))
        getListTeams(removeObjectNullFull(getFilters));
    };

    return <>
        <HeaderAction isActionSignlePage title="Teams List" disabledFormatUpperCaseTitle isCreate
                      onClick={e => onCreate(e)}/>
        <div className="container-fluid px-4">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSearch}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="row bg-white py-3 align-items-center">
                    <div className="col-6">
                        <Form.Item
                            label="Search"
                            name="search"
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </div>
                    <div className="col-2">
                        <Form.Item className="" label=" ">
                            <Button type="primary" htmlType="submit">
                                Search {isFetching && <Icon type="sync-outlined" spin/>}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
        <ListComponent
            scroll={{x: 1500}}
            classParent="m-0 p-0 col-md-12"
            className="custom-table"
            marginTop={'0'} marginBottom={'0'}
            loading={{spinning: isFetching, size: 'large'}}
            rowKey="id"
            dataSource={getDataSource(resultList || [], initData.modelItem)}
            columns={columnsTable({
                titleTable: initData.titleItemTable,
                component: componentTable,
                itemName: namePage
            })}
            pagination={{
                total: pagination.total,
                totalPage: pagination.totalPage,
                current: pagination.defaultCurrent,
                pageSize: pagination.pageSize,
                responsive: true
            }}
            onChange={onTableChange}
            componentTableList={{}}
        />
    </>
}

export default Index
