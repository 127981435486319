import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import InboxResponse from "../mapping/Response/InboxResponse";

// export function master() {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.CONTACT_MASTER_DATA,
//         // params: removeObjectNull({ city_id }),
//         parser: data => new MasterResponse(data.data.result).exportMaster(),
//     }).get();
// }
//
export function actionList(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.INBOX_LIST,
        params: params,
        parser: dataParser,
    }).get();
}

// export function actionExport(params) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.CONTACT_LIST,
//         params: params,
//         isDownload: true,
//         parser: data => data,
//     }).get();
// }

export function actionGetDetail(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.INBOX_DETAIL,
        endpointParams: {id},
        parser: data => new InboxResponse(data.data.result).exportDetail(),
    }).get();
}

// export function update(data) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.CONTACT_UPDATE,
//         endpointParams: {id: data?.id},
//     }).put(data);
// }

export function send(data, id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.INBOX_SEND,
        endpointParams: {id},
    }).post(data);
}

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new InboxResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
