import HeaderAction from "../../common/layout/HeaderAction";
import React, {Fragment, useEffect, useState} from "react";
import {Card, Divider, Modal} from "antd";
import SlideItem from "../../common/components/SlideItem";
import LazyImage from "../../common/components/LazyImage";
import {ActionGetCurrent} from "../../../data/hooks/subscription";
import {selectGetCurrent} from "../../../data/reselects/subscriptionSelector";
import {useSelector} from "react-redux";
import {Icon} from "../../common";

const Index = props => {
    const [items, setItems] = useState([]),
        actionGetCurrent = ActionGetCurrent(),
        itemSubscriptionCurrent = useSelector(selectGetCurrent());

    useEffect(() => {
        actionGetCurrent()
    }, []);

    const showInfo = (items = []) => {
        if (items?.length > 0) {
            Modal.info({
                title: 'Item List',
                content: (
                    <div className="mt-3">
                        {
                            items?.map((i, k) => <p key={k} className="mb-1">{i}</p>)
                        }
                    </div>
                ),
                onOk() {
                },
            });
        }
    }

    return (
        <>
            <HeaderAction
                isActionSignlePage
                title="My Subscription"
                disabledFormatUpperCaseTitle
            />
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-6">
                        <Card title="Current Plan"
                              className="hn__my-subscription__card box--shadow height--300px min-height--300px overflow-hidden">
                            <div className="min-height--65px">
                                {
                                    ((itemSubscriptionCurrent?.result || []).length > 0) && (itemSubscriptionCurrent?.result || []).map((i, k) => {
                                        return <p className="fs--16px color--black" key={k}><span
                                            className="fw-bold">{i?.product}: </span> {i?.name} (Valid until {i?.valid_time})
                                        </p>
                                    })
                                }
                                {/*<p className="fs--16px color--black"><span*/}
                                {/*    className="fw-bold">AAA</span> BBBBBB</p>*/}
                            </div>
                            <Divider/>
                            <div className="row align-items-center">
                                <div className="col-8">
                                    <p className="fs--14px fw-bold">Plan</p>
                                    <p className="fs--14px">Your payment will be automatically renewed</p>
                                </div>
                                <div className="col-4 text-end">
                                    <button
                                        className={`btn btn-default hn__my-subscription__card--btn-cancel-renewal`}
                                        onClick={() => {
                                        }}>
                                        Cancel Renewal
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="col-6">
                        <Card title="Invoices"
                              className="hn__my-subscription__card box--shadow height--300px min-height--300px overflow-hidden">
                            <div className="min-height--65px">
                                {/*{*/}
                                {/*    (Object.keys(resultItem?.subscription || []).length > 0) && Object.keys(resultItem?.subscription || []).map((i, k) => {*/}
                                {/*        return <p className="fs--16px color--black" key={k}><span*/}
                                {/*            className="fw-bold">{i}</span> {resultItem?.subscription[i]}</p>*/}
                                {/*    })*/}
                                {/*}*/}
                                <div className="fs--16px color--black clearfix">
                                    <div
                                        className="fw-bold float-start me-3">
                                        Next payment:
                                    </div>
                                    <div className="float-start w-75">
                                        {
                                            ((itemSubscriptionCurrent?.recurring || []).length > 0) && (itemSubscriptionCurrent?.recurring || []).map((i, k) => {
                                                return (
                                                    <div className="" key={k}>
                                                        <span
                                                            className="fw-bold mb-0">{i?.next_payment_date}:</span>
                                                        <span className="mb-0 ms-2">{(i?.item || []).length} item(s)
                                                            - {i?.total}</span>
                                                        <i className="fa fa-info-circle color--primary cursorPointer ms-2"
                                                           onClick={e => {
                                                               showInfo(i?.item);
                                                           }}/>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    {/*2023-03-22: 1 item(s) - $20 View Detail*/}
                                </div>
                            </div>
                            <Divider/>
                            <div className="row align-items-center">
                                <div className="col-8"/>
                                <div className="col-4 text-end">
                                    <a className="fs--14px cursorPointer color--primary fw-bold text-decoration-none"
                                       href="/bill/detail"
                                       target="_blank">Check invoice history.</a>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="col-12 mt-4">
                        <div className="m-auto slide-dot-inside max-width--1390px bg-white box--shadow mb-2">
                            <SlideItem className=""
                                       settings={{
                                           dots: true,
                                           infinite: true,
                                           slidesToShow: 1,
                                           slidesToScroll: 1,
                                       }}>
                                <div onClick={() => {
                                    // setIsOpenModalBannerUpgrade(true)
                                }}>
                                    <LazyImage
                                        imageClass="w-100 max-width--1400px object-fit "
                                        alt="asdasd"
                                        placeholder={true}
                                        src="/layout/banner/subscription-banner-1.png"
                                        // src="https://wallpaperaccess.com/full/267434.jpg"
                                    />
                                </div>
                            </SlideItem>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index
