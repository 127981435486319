import * as Types from '../types/PageBuilders';

export const pageBuildersMasterAction = (filters) => ({type: Types.PAGE_BUILDERS_MASTER_ACTION, filters});
export const pageBuildersMasterSuccess = (master) => ({
    type: Types.PAGE_BUILDERS_MASTER_SUCCESS,
    master
});

export const pageBuildersListAction = (filters) => ({type: Types.PAGE_BUILDERS_LIST_ACTION, filters});
export const pageBuildersListSuccess = (list) => ({
    type: Types.PAGE_BUILDERS_LIST_SUCCESS,
    list
});

export const pageBuildersDetailAction = (params) => ({type: Types.PAGE_BUILDERS_DETAIL_ACTION, params});
export const pageBuildersDetailSuccess = (detail) => ({
    type: Types.PAGE_BUILDERS_DETAIL_SUCCESS,
    detail
});

export const pageBuildersUpdateAction = (params) => ({type: Types.PAGE_BUILDERS_UPDATE_ACTION, params});
export const pageBuildersUpdateSuccess = (updateData) => ({
    type: Types.PAGE_BUILDERS_UPDATE_SUCCESS,
    updateData
});

export const pageBuildersCreateAction = (params) => ({type: Types.PAGE_BUILDERS_CREATE_ACTION, params});
export const pageBuildersCreateSuccess = (createData) => ({
    type: Types.PAGE_BUILDERS_CREATE_SUCCESS,
    createData
});

export const pageBuildersDetailLogAction = (params) => ({type: Types.PAGE_BUILDERS_DETAIL_LOG_ACTION, params});
export const pageBuildersDetailLogSuccess = (detailLog) => ({
    type: Types.PAGE_BUILDERS_DETAIL_LOG_SUCCESS,
    detailLog
});

export const pageBuildersManualTriggerAction = (params) => ({type: Types.PAGE_BUILDERS_MANUAL_TRIGGER_ACTION, params});
export const pageBuildersManualTriggerSuccess = (manualTrigger) => ({
    type: Types.PAGE_BUILDERS_MANUAL_TRIGGER_SUCCESS,
    manualTrigger
});

export const pageBuildersInitStore = (params) => ({type: Types.PAGE_BUILDERS_INIT, params});
export const pageBuildersClearStore = (params) => ({type: Types.PAGE_BUILDERS_FAIL, params});
