import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/Voice';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/voiceService';
import * as FunctionAction from '../actions/voiceAction';
import {message} from "antd";

function* actionGetVerify() {
    yield takeLatest(Types.VOICE_VERIFY_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionGetVerify, action?.params?.id);
        yield put(FunctionAction.voiceVerifySuccess(result));
    }, errorHandle(Types.VOICE_FAIL)))
}

function* actionConfig() {
    yield takeLatest(Types.VOICE_CONFIG_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.configVoice, action?.params?.query);
        yield put(FunctionAction.voiceConfigSuccess(result));
    }, errorHandle(Types.VOICE_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        console.log("===> ", e)
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* voiceSaga() {
    yield all([
        fork(actionGetVerify),
        fork(actionConfig),
    ])
}
