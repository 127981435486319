export const TEAM_POINT_MASTER_ACTION = "TEAM_POINT_MASTER_ACTION";
export const TEAM_POINT_MASTER_SUCCESS = "TEAM_POINT_MASTER_SUCCESS";

export const TEAM_POINT_LIST_ACTION = "TEAM_POINT_LIST_ACTION";
export const TEAM_POINT_LIST_SUCCESS = "TEAM_POINT_LIST_SUCCESS";

export const TEAM_POINT_DETAIL_ACTION = "TEAM_POINT_DETAIL_ACTION";
export const TEAM_POINT_DETAIL_SUCCESS = "TEAM_POINT_DETAIL_SUCCESS";

export const TEAM_POINT_UPDATE_ACTION = "TEAM_POINT_UPDATE_ACTION";
export const TEAM_POINT_UPDATE_SUCCESS = "TEAM_POINT_UPDATE_SUCCESS";

export const TEAM_POINT_CREATE_ACTION = "TEAM_POINT_CREATE_ACTION";
export const TEAM_POINT_CREATE_SUCCESS = "TEAM_POINT_CREATE_SUCCESS";

export const TEAM_POINT_INIT = "TEAM_POINT_INIT";
export const TEAM_POINT_FAIL = "TEAM_POINT_FAIL";
