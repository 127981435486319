import ApiService from './../../utils/ApiService';
import Urls from './../../utils/Urls';
// import Token from './../models/Token';
import User from './../../data/mapping/User';
import AuthResponse from "../mapping/Response/AuthResponse";


export function login(email, password, captcha) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.LOGIN,
        // parser: (data) => new Token(data),
    }).post({email, password});
}

export function logout() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.LOGOUT,
    }).post({});
}

export function getUserProfile() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.PROFILE,
        parser: data => data.data.result,
        // parser: data => new User(data.data.result),
    }).get();
}

export function getSsoCode() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SSO_CODE,
        parser: data => data.data.result,
    }).get();
}

export function getUserProfileConfig() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.PROFILE_CONFIG,
        parser: data => new AuthResponse(data.data.result).exportSetting(),
        // parser: data => new User(data.data.result),
    }).get();
}

export function getUserProfileConfigMaster() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.PROFILE_CONFIG_MASTER,
        parser: data => data.data.result,
        // parser: data => new User(data.data.result),
    }).get();
}

export function getUserProfileConfigUpdate(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.PROFILE_CONFIG_UPDATE,
    }).put(data);
}

export function refreshToken(refreshToken) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.REFRESH,
        withoutAuth: true,
        // parser: (data) => new Token(data),
    }).post({refreshToken});
}

export function register(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.REGISTER,
    }).post(data);
}

export function passwordRequest(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.PASSWORD_REQUEST,
        parser: data => new AuthResponse(data.data.result).exportPasswordRequest(),
    }).post(data);
}

export function passwordReset(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.PASSWORD_RESET,
    }).post(data);
}

export function getGeneral() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.AUTH_GENERAL,
        parser: data => new AuthResponse(data.data.result).exportGeneral(),
    }).get();
}

export function update(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.UPDATE_PROFILE,
        endpointParams: {id: data?.id},
    }).put(data);
}

export function getAllProfile() {
    return new Promise((resolve, reject) => {
        return Promise.all([
            getUserProfile(),
            getUserProfileConfig(),
        ]).then((responses) => {
            const profile = responses[0] || [],
                profile_config = responses[1] || [];
            const result = new User({
                profile, profile_config
            });
            return resolve(result);
        }).catch((e) => {
            console.log('===== Get getAllProfile service error', e);
            reject(e)
            // resolve({})
        })
    })
}
