export const TEMPLATE_MASTER_ACTION = "TEMPLATE_MASTER_ACTION";
export const TEMPLATE_MASTER_SUCCESS = "TEMPLATE_MASTER_SUCCESS";

export const TEMPLATE_LIST_ACTION = "TEMPLATE_LIST_ACTION";
export const TEMPLATE_LIST_SUCCESS = "TEMPLATE_LIST_SUCCESS";

export const TEMPLATE_DETAIL_ACTION = "TEMPLATE_DETAIL_ACTION";
export const TEMPLATE_DETAIL_SUCCESS = "TEMPLATE_DETAIL_SUCCESS";

export const TEMPLATE_UPDATE_ACTION = "TEMPLATE_UPDATE_ACTION";
export const TEMPLATE_UPDATE_SUCCESS = "TEMPLATE_UPDATE_SUCCESS";

export const TEMPLATE_CREATE_ACTION = "TEMPLATE_CREATE_ACTION";
export const TEMPLATE_CREATE_SUCCESS = "TEMPLATE_CREATE_SUCCESS";

export const TEMPLATE_DELETE_ACTION = "TEMPLATE_DELETE_ACTION";
export const TEMPLATE_DELETE_SUCCESS = "TEMPLATE_DELETE_SUCCESS";

export const TEMPLATE_CLONE_ACTION = "TEMPLATE_CLONE_ACTION";
export const TEMPLATE_CLONE_SUCCESS = "TEMPLATE_CLONE_SUCCESS";

export const TEMPLATE_EMAIL_LIST_ACTION = "TEMPLATE_EMAIL_LIST_ACTION";
export const TEMPLATE_EMAIL_LIST_SUCCESS = "TEMPLATE_EMAIL_LIST_SUCCESS";

export const TEMPLATE_READ_NOTIFY_ACTION = "TEMPLATE_READ_NOTIFY_ACTION";
export const TEMPLATE_READ_NOTIFY_SUCCESS = "TEMPLATE_READ_NOTIFY_SUCCESS";

export const TEMPLATE_INIT = "TEMPLATE_INIT";
export const TEMPLATE_FAIL = "TEMPLATE_FAIL";
