import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    removeObjectNull, removeObjectNullFull, setParamsUrlFilter
} from "../../../utils/functions";
import {omit} from "lodash";
import moment from "moment";
import TAG_DEFINE from "../../../constants/common";

// ============== LAYOUT ==============
import HeaderAction from "../../common/layout/HeaderAction";
import {Icon} from "../../common";
import ListComponent from "../../common/ListComponent";
import {Tooltip, Button, Tag, Form, Input, Select, DatePicker} from 'antd'

// ============== ACTION ==============
import {ActionGetListTeamPoint, ActionDetailTeamPoint} from '../../../data/hooks/teamPoint';

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectErrors,
    selectListTeamPoint,
    selectDetailTeamPoint
} from '../../../data/reselects/teamPointSelector';
import TeamPointResponse, {column, columnDetail} from "../../../data/mapping/Response/TeamPointResponse";
import {ROUTE_REDIRECT} from "../../../constants/define";
import queryString from "query-string";
import {customerTeamPointDetailFiltersParams} from "../../../data/mapping/Request/TeamPointRequest";

const {RangePicker} = DatePicker;

const ModelFields = Object.getOwnPropertyNames(new TeamPointResponse());
const Index = props => {
    const namePage = "team_point_detail",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(columnDetail, [], []),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        [form] = Form.useForm(),
        componentTable = {};
    const [isFetching, setIsFetching] = useState(false),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        // ============== ACTION ==============
        getListTeamPoint = ActionDetailTeamPoint(),
        // ============== SELECT DATA ==============
        itemListTeamPoint = useSelector(selectDetailTeamPoint()),
        itemErrors = useSelector(selectErrors()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        if (Object.keys(queryString.parse(props.location.search)).length > 0) {
            let paramsFilters = queryString.parse(props.location.search, {arrayFormat: 'comma'});
            setPramsFilters(paramsFilters)
            // if (paramsFilters?.from_date && paramsFilters?.to_date) {
            //     paramsFilters.date = [moment(paramsFilters?.from_date), moment(paramsFilters?.to_date)]
            // }
            if (paramsFilters?.from_date) {
                paramsFilters.from_date = moment(paramsFilters?.from_date)
            }
            if (paramsFilters?.to_date) {
                paramsFilters.to_date = moment(paramsFilters?.to_date)
            }
            form.setFieldsValue(paramsFilters)
            getListTeamPoint(removeObjectNullFull(paramsFilters));
        } else {
            getListTeamPoint();
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        setResultList(itemListTeamPoint?.result || [])
        setPagination({
            total: itemListTeamPoint?.pagination?.total || 0,
            totalPage: itemListTeamPoint?.pagination?.total_page || 0,
            defaultCurrent: itemListTeamPoint?.pagination?.page_index || 1,
            pageSize: itemListTeamPoint?.pagination?.page_size || 10
        })
    }, [itemListTeamPoint]);

    const onTableChange = (pagination, filters, sorter) => {
        // let currentFilters = getCurrentFilters();
        filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        setPramsFilters(newFilters)
        setPramsFilters(newFilters)
        setInitData({
            ...initData,
            filters: newFilters
        });
        props.history.replace(setParamsUrlFilter(ROUTE_REDIRECT.TEAM_POINT.DETAIL, newFilters))
        getListTeamPoint(newFilters);
    };

    const onSearch = (val) => {
        let getFilters = removeObjectNullFull({...initData.filters, ...paramsFilters, ...val, page_index: 1});
        setPramsFilters(getFilters);
        setInitData({...initData, filters: paramsFilters})
        props.history.replace(setParamsUrlFilter(ROUTE_REDIRECT.TEAM_POINT.DETAIL, customerTeamPointDetailFiltersParams(getFilters)))
        getListTeamPoint(removeObjectNullFull(getFilters));
    };

    return <>
        <HeaderAction
            isActionSignlePage
            title="Transactions"
            disabledFormatUpperCaseTitle
            isCustomTitle
            listCustomTitle={[{url: ROUTE_REDIRECT.TEAM_POINT.HOME, title: "Team Point List"}]}
        />
        <div className="container-fluid px-4">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSearch}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="row bg-white py-3 align-items-center">
                    <div className="col-4">
                        <Form.Item
                            label="Search"
                            name="search"
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </div>
                    <div className="col-3">
                        <Form.Item
                            label="Created Date From"
                            name="from_date"
                        >
                            <DatePicker className="w-100"/>
                        </Form.Item>
                    </div>
                    <div className="col-3">
                        <Form.Item
                            label="Created Date To"
                            name="to_date"
                        >
                            <DatePicker className="w-100"/>
                        </Form.Item>
                    </div>
                    <div className="col-2">
                        <Form.Item className="" label=" ">
                            <Button type="primary" htmlType="submit">
                                Search {isFetching && <Icon type="sync-outlined" spin/>}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
        <ListComponent
            scroll={{x: 1000}}
            classParent="m-0 p-0 col-md-12"
            className="custom-table"
            marginTop={'0'} marginBottom={'0'}
            loading={{spinning: isFetching, size: 'large'}}
            rowKey="id"
            dataSource={getDataSource(resultList || [], initData.modelItem)}
            columns={columnsTable({
                titleTable: initData.titleItemTable,
                component: componentTable,
                itemName: namePage
            })}
            pagination={{
                total: pagination.total,
                totalPage: pagination.totalPage,
                current: pagination.defaultCurrent,
                pageSize: pagination.pageSize,
                responsive: true
            }}
            onChange={onTableChange}
            componentTableList={{}}
        />
    </>
}

export default Index
