import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import * as HookAction from '../actions/authAction'
import {checkUserAction} from "../actions/authAction";

export const ActionUpdateProfile = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.updateProfileAction(params)),
        [dispatch]
    )
}

export const ActionPasswordRequest = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.passwordRequestAction(params)),
        [dispatch]
    )
}

export const ActionPasswordReset = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.passwordResetAction(params)),
        [dispatch]
    )
}

export const ActionGeneral = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.generalAction(params)),
        [dispatch]
    )
}

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.initAction(params)),
        [dispatch]
    )
}

export const ActionProfileConfigUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.profileConfigUpdateAction(params)),
        [dispatch]
    )
}

export const ActionSetting = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.settingAction(params)),
        [dispatch]
    )
}

export const ActionSettingMaster = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.settingMasterAction(params)),
        [dispatch]
    )
}

export const ActionCheckUser = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.checkUserAction(params)),
        [dispatch]
    )
}

export const ActionAuthenticationSSO = () => {
    const dispatch = useDispatch()
    return useCallback(
        () => dispatch(HookAction.authenticationSSOAction()),
        [dispatch]
    )
}
