import {
	call,
	put,
	all,
	fork,
	takeEvery,
	select,
} from "redux-saga/effects";
import { message } from "antd";
import * as Types from "../../data/types/FastCMA";
import * as Actions from "../actions/fastCMA";
import * as FastCMAService from "../services/fastCMAService";
import * as Func from "../../utils/functions";
import FastCMAAddresLookupRequest, { FastCMAFindComparableWrapperRequest } from "../mapping/Request/FastCMAAddresLookupRequest"

function errorHandle(errorActionType) {
	return Func.sagaErrorHandler(function* (e) {
		yield put({ type: errorActionType });
	});
}

function* addressLookup() {
	yield takeEvery(
		Types.ADDRESS_LOOKUP,
		Func.sagaWrapper(function* (action) {
			const state = yield select();
			const market = state?.FastCMAReducer.cma.market || 'houston';
			let params = action.params;
			params.marketplace = market;
			console.log(params)
			
			const data = new FastCMAAddresLookupRequest(params).exportAddressDetail();
			const response = yield call(FastCMAService.addressLookup, data);
			if (response.data && response.data.status === "SUCCESS") {
				const result = response.data?.data.result;
				if ( result && result.length > 0 ) {
					yield put(Actions.address_lookup_success(result));
				} else {
					yield put(Actions.address_lookup_fail(response.errors));
				}
			} else {
				yield put(Actions.address_lookup_fail(response.errors))
			}
		}, errorHandle(Types.ADDRESS_LOOKUP_FAIL))
	);
}

function* findComparables() {
  yield takeEvery(
    Types.FIND_COMPARABLES,
    Func.sagaWrapper(function* (action) {
			const state = yield select();
			const market = state?.FastCMAReducer.cma.market || 'houston';
			
			let filter = action.params?.filter
			const showResult = action.params?.showResult
			const type = showResult ? 'custom' : action.params?.type

			filter.type = type || 1;
			filter.market_name = market;
			
			let request = new FastCMAFindComparableWrapperRequest(filter),
				data;
			switch ( type ) {
				case 'custom':
					data = request.exportCustomFilter();
					break;
				case 'arv-subdivision':
				case 'arv-distance':
				default:
					data = request.exportArvDistanceQuery();
					break;
			}
			
      const response = yield call(FastCMAService.findComparables, data);
      if ( response.data.status === 'SUCCESS' ) {
        if ( response.data.data.total <= 0 ) {
          message.error('No comparables found');
        }
        yield put(Actions.find_comparables_success(response.data.data))
        if ( showResult ) {
					yield put(Actions.run_cma())
				}
      }
    }, errorHandle(Types.FIND_COMPARABLES_ERROR))
  )
}

function* exportReportPDF() {
	yield takeEvery(
		Types.EXPORT_CMA_REPORT_PDF,
		Func.sagaWrapper(function* (action){
			const state = yield select();
			const fastCMA = state?.FastCMAReducer;
			const data = fastCMA?.cma.result;
			data.market = fastCMA?.cma.market || 'houston';
    	data.selectedProperties = fastCMA?.cma.selected_properties;
    	data.estimate = fastCMA?.cma.estimate;
			data.configs = action.params;
			
			// .cma.market || 'houston';
			const response = yield call(FastCMAService.exportCMAReportPDF, data);

			if ( !data.configs.isEmail ) {
				// Download
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'CMA_Report.pdf');
				document.body.appendChild(link);
				link.click();
				window.dispatchEvent(new Event('cma-file-download-already'));
			}

		}, errorHandle(Types.EXPORT_CMA_ERROR))
	)
}

export function* fastCMASaga() {
	yield all([
    fork(addressLookup),
    fork(findComparables),
		fork(exportReportPDF)
  ]);
}
