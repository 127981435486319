import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class BillResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.card = {
            info: data?.card?.info || "",
            expired: data?.card?.expired || "",
        };
        this.billing = {
            name: data?.billing?.name || "",
            email: data?.billing?.email || "",
            phone: data?.billing?.phone || "",
        }
    }

    exportList() {
        return {
            card: this.card,
            billing: this.billing,
        }
    }

    exportDetail() {
        return {
            card: this.card,
            billing: this.billing,
        }
    }
}

export const column = ["name", "email", "phone_number", "tag", "created_date", "company", "job_title", "owner", "status"];

export const fieldDetailRemove = [];

export const filtersResponse = (filters) => {
    return removeObjectNullFull({
        search: filters?.search || "",
        tag: filters?.tag || "",
        is_owned: convertBooleanToInt(filters?.is_owned),
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    })
}

export class ContactMasterResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.status = data?.STATUS;
        this.tag = data?.TAG
    }

    exportMaster() {
        return {
            status: this.status,
            tag: this.tag,
        }
    }
}
