import React, {useState, useEffect} from "react";
import {useSelector} from 'react-redux'

// ============== LAYOUT ==============
import {Form, Input, Button, Select, Spin, Checkbox, Tabs, Collapse, Space, Popconfirm} from 'antd';
import HeaderAction from "../../../common/layout/HeaderAction";

// ============== ACTION ==============
import {
    ActionMasterData,
    ActionCreate,
    ActionDeleteNote,
    ActionInit,
    ActionGetItem,
    ActionUpdate
} from "../../../../data/hooks/contact";

// ============== SELECT DATA ==============
import {selectFetching, selectItem, selectMasterData} from "../../../../data/reselects/contactSelector";
import {common} from "../../../../data/mapping/Request/ContactRequest";
import {Icon} from "../../../common";
import {isEmail} from "../../../../utils/validation";
import TAG_DEFINE from "../../../../constants/common";
import {ROUTE_REDIRECT} from "../../../../constants/define";
import {CaretRightOutlined} from "@ant-design/icons";
import ModalCreateNote from "../components/ModalCreateNote";
import {AiFillEdit, AiOutlineEdit} from "react-icons/ai";
import {MdOutlineDeleteOutline} from "react-icons/md";

const Index = (props) => {
    const [form] = Form.useForm(),
        [isFetching, setIsFetching] = useState(false),
        [typePage, setTypePage] = useState(TAG_DEFINE.ACTION.create),
        [masterStatus, setMasterStatus] = useState({}),
        [masterTag, setMasterTag] = useState({}),
        [isUpdate, setIsUpdate] = useState(false),
        [isCreateNote, setIsCreateNote] = useState(false),
        [noteContentUpdate, setNoteContentUpdate] = useState({}),
        // ============== ACTION ==============
        getMasterData = ActionMasterData(),
        actionDeleteNote = ActionDeleteNote(),
        actionCreate = ActionCreate(),
        actionUpdate = ActionUpdate(),
        getInitAction = ActionInit(),
        actionGetItem = ActionGetItem(),
        // ============== SELECT DATA ==============
        itemFetching = useSelector(selectFetching()),
        itemDetail = useSelector(selectItem()),
        itemMasterData = useSelector(selectMasterData());

    useEffect(() => {
        const {match} = props;
        if (Object.keys(match.params).length > 0) {
            setIsUpdate(true);
            if (Object.keys(itemDetail || {}).length < 1) {
                setTypePage(TAG_DEFINE.ACTION.update)
                actionGetItem({id: match.params?.id, props: props});
            }
        } else {
            setIsUpdate(false)
        }
    }, [props.match]);

    useEffect(() => {
        getMasterData()

        return () => {
            setNoteContentUpdate({})
            getInitAction({clearDetail: true});
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);

    useEffect(() => {
        if (Object.keys(itemDetail || {}).length > 0) {
            form.setFieldsValue(itemDetail)
        }
    }, [itemDetail]);

    useEffect(() => {
        if (Object.keys(itemMasterData?.status || {}).length > 0) {
            setMasterStatus(itemMasterData?.status)
        }
        if (Object.keys(itemMasterData?.tag || {}).length > 0) {
            setMasterTag(itemMasterData?.tag)
        }
    }, [itemMasterData]);

    const onFinish = (val) => {
        if (isUpdate) {
            actionUpdate({...val, props, id: itemDetail?.id})
        } else {
            actionCreate({...val, props})
        }
    }

    const genExtra = (val) => {
        return val
    };

    return (
        <>
            <HeaderAction title={`Contact ${TAG_DEFINE.ACTION[typePage.toLowerCase()]}`}/>
            <Spin tip="Loading..." spinning={isFetching}>
                <div className="container bg-white mt-4 pt-4">
                    <div className="row">
                        <div className="col-12">
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={onFinish}
                                // onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <div className="row">
                                    <div className="col-4">
                                        <Form.Item
                                            label="Name"
                                            name={common.ACTION_FIELD.name}
                                            rules={[{
                                                required: true,
                                                message: 'Please input your name!'
                                            }]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Company"
                                            name={common.ACTION_FIELD.company}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Job Title"
                                            name={common.ACTION_FIELD.job_title}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Email"
                                            name={common.ACTION_FIELD.email}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your Email!'
                                                }, ({getFieldValue}) => ({
                                                    validator(rule, value) {
                                                        if ((itemDetail[common.ACTION_FIELD.email] === value)) {
                                                            return Promise.resolve();
                                                        }
                                                        if (value) {
                                                            if (!isEmail(value) && ((value || "").length >= 10)) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject("Invalid email");
                                                        }
                                                        return Promise.reject("Invalid email")
                                                    },
                                                })
                                            ]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Full Address"
                                            name={common.ACTION_FIELD.address}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>

                                    <div className="col-4">
                                        <Form.Item
                                            label="Property Address"
                                            name={common.ACTION_FIELD.property_address}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Property City"
                                            name={common.ACTION_FIELD.property_city}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Property State"
                                            name={common.ACTION_FIELD.property_state}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Property Zip Code"
                                            name={common.ACTION_FIELD.property_zip_code}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>

                                    <div className="col-4">
                                        <Form.Item
                                            label="Lead Type"
                                            name={common.ACTION_FIELD.lead_type}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Yr Bt"
                                            name={common.ACTION_FIELD.yr_bt}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Estimated Equity"
                                            name={common.ACTION_FIELD.estimated_equity}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Equity"
                                            name={common.ACTION_FIELD.equity}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Appraised Value"
                                            name={common.ACTION_FIELD.appraised_value}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Auction Date"
                                            name={common.ACTION_FIELD.auction_date}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Email Address 2"
                                            name={common.ACTION_FIELD.email_addresses_2}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Email Address 3"
                                            name={common.ACTION_FIELD.email_addresses_3}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Email Address 4"
                                            name={common.ACTION_FIELD.email_addresses_4}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Email Address 5"
                                            name={common.ACTION_FIELD.email_addresses_5}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Cell Phones 1"
                                            name={common.ACTION_FIELD.cell_phones_1}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Cell Phones 2"
                                            name={common.ACTION_FIELD.cell_phones_2}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Cell Phones 3"
                                            name={common.ACTION_FIELD.cell_phones_3}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Cell Phones 4"
                                            name={common.ACTION_FIELD.cell_phones_4}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Cell Phones 5"
                                            name={common.ACTION_FIELD.cell_phones_5}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Phone Number 2"
                                            name={common.ACTION_FIELD.phone_numbers_2}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Phone Number 3"
                                            name={common.ACTION_FIELD.phone_numbers_3}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Phone Number 4"
                                            name={common.ACTION_FIELD.phone_numbers_4}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Phone Number 5"
                                            name={common.ACTION_FIELD.phone_numbers_5}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>

                                    {
                                        isUpdate && (
                                            <div className="col-4">
                                                <Form.Item
                                                    label="Owner"
                                                    name={common.ACTION_FIELD.owner}
                                                >
                                                    <Input disabled/>
                                                </Form.Item>
                                            </div>
                                        )
                                    }
                                    <div className="col-4">
                                        <Form.Item
                                            label="Phone number"
                                            name={common.ACTION_FIELD.phone_number}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    {
                                        isUpdate && (
                                            <>
                                                <div className="col-4">
                                                    <Form.Item
                                                        label="Source"
                                                        name={common.ACTION_FIELD.source}
                                                    >
                                                        <Input disabled/>
                                                    </Form.Item>
                                                </div>
                                                {/*<div className="col-4">*/}
                                                {/*    <Form.Item*/}
                                                {/*        label="Status"*/}
                                                {/*        name={common.ACTION_FIELD.status}*/}
                                                {/*        rules={[{required: true, message: 'Please select your status!'}]}*/}
                                                {/*    >*/}
                                                {/*        <Select*/}
                                                {/*            className="w-100"*/}
                                                {/*            style={{width: 120}}>*/}
                                                {/*            {*/}
                                                {/*                (Object.keys(masterStatus)).length > 0 && (Object.keys(masterStatus)).map((i, k) => {*/}
                                                {/*                    return (*/}
                                                {/*                        <Select.Option value={i}*/}
                                                {/*                                       key={k}>{masterStatus[i]}</Select.Option>*/}
                                                {/*                    )*/}
                                                {/*                })*/}
                                                {/*            }*/}
                                                {/*        </Select>*/}
                                                {/*    </Form.Item>*/}
                                                {/*</div>*/}
                                                <div className="col-4">
                                                    <Form.Item
                                                        label="Created Date"
                                                        name={common.ACTION_FIELD.created_date}
                                                    >
                                                        <Input disabled/>
                                                    </Form.Item>
                                                </div>
                                                {
                                                    <div className="col-4">
                                                        <Form.Item
                                                            label="Tag"
                                                            name={common.ACTION_FIELD.tag}
                                                        >
                                                            <Select
                                                                mode="multiple"
                                                                // disabled
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                {
                                                                    (Object.keys(masterTag)).length > 0 && (Object.keys(masterTag)).map((i, k) => {
                                                                        return (
                                                                            <Select.Option value={i}
                                                                                           key={k}>{masterTag[i]}</Select.Option>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                }
                                                <div className="col-4">
                                                    <Form.Item
                                                        label="Extra Info"
                                                        name={common.ACTION_FIELD.extra_info}
                                                    >
                                                        <Input.TextArea disabled/>
                                                    </Form.Item>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                                <Form.Item className="text-end">
                                    <Button type="default" htmlType="button" className="me-3"
                                            onClick={() => props.history.goBack()}>
                                        Back
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Save {isFetching && <Icon type="sync-outlined" spin/>}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </Spin>
            {
                isUpdate ? (
                    <Spin tip="Loading..." spinning={isFetching}>
                        <div className="container bg-white mt-0 pb-4 mb-2">
                            <ModalCreateNote
                                isOpen={isCreateNote}
                                editContent={noteContentUpdate?.note || ""}
                                time={noteContentUpdate?.time}
                                isClose={e => {
                                    setNoteContentUpdate({})
                                    setIsCreateNote(false)
                                }}
                                isAction={{isDetail: true, id: itemDetail?.id}}
                            />
                            <Tabs defaultActiveKey="1">
                                <Tabs.TabPane tab="Notes" key="1">
                                    <div className="text-end">
                                        <Button type="primary" htmlType="button" className="mb-3"
                                                onClick={e => setIsCreateNote(true)}>
                                            Create Note
                                        </Button>
                                    </div>
                                    {
                                        ((itemDetail?.notes || []).length > 0) && (
                                            <Collapse
                                                bordered={false}
                                                // defaultActiveKey={['1']}
                                                activeKey={Object?.keys(itemDetail?.notes || [])}
                                                expandIcon={({isActive}) => null}
                                                // expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                                className="bg-transparent"
                                                collapsible="disabled"
                                            >
                                                {
                                                    (itemDetail?.notes)?.map((i, k) => {
                                                        return (
                                                            <Collapse.Panel
                                                                key={k}
                                                                header={
                                                                    <p className="mb-0 color--black fw-bold">Note <span
                                                                        className="color--description fw--300">by {i?.owner?.email}</span>
                                                                    </p>
                                                                }
                                                                className="mb-3"
                                                                extra={
                                                                    <Space>
                                                                        {genExtra(i?.time)}
                                                                        <AiFillEdit
                                                                            onClick={e => setNoteContentUpdate({
                                                                                note: i?.note,
                                                                                time: i?.real_time
                                                                            })}
                                                                            className="cursorPointer color--black ms-2"
                                                                            size={18}/>
                                                                        <Popconfirm
                                                                            title="Are you sure to delete this note?"
                                                                            onConfirm={() => {
                                                                                actionDeleteNote({
                                                                                    query: {
                                                                                        id: itemDetail?.id,
                                                                                        time: i?.real_time
                                                                                    }
                                                                                })
                                                                            }}
                                                                            onCancel={() => {
                                                                            }}
                                                                            okText="Yes"
                                                                            cancelText="No"
                                                                        >
                                                                            <MdOutlineDeleteOutline
                                                                                className="cursorPointer color--black ms-1"
                                                                                size={18}/>
                                                                        </Popconfirm>
                                                                    </Space>
                                                                }>
                                                                <p className="mb-0">{i?.note}</p>
                                                            </Collapse.Panel>
                                                        )
                                                    })
                                                }
                                            </Collapse>
                                        )
                                    }
                                </Tabs.TabPane>
                            </Tabs>
                        </div>
                    </Spin>
                ) : null
            }
        </>
    )
}

export default Index
