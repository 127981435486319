import {Form} from "@formio/react";
import queryString from "query-string";
import {ActionHandleFormShare} from "../../../data/hooks/embedded";
import {useEffect} from "react";
import {selectHandleFormShare} from "../../../data/reselects/embeddedSelector";
import {useSelector} from "react-redux";
import {message} from "antd";

const Share = props => {
    const params = queryString.parse(props?.location?.search || ""),
        actionHandleFormShare = ActionHandleFormShare(),
        itemComponents = useSelector(selectHandleFormShare());

    useEffect(() => {
        actionHandleFormShare(params.src)
    }, [])

    // const onSubmitHandler = e => {
    //     postJSON(e?.data)
    // }
    //
    // const postJSON = async (val) => {
    //     try {
    //         const response = await fetch("http://dev.api-loyalty.megalith/api/v1/form/create-contact", {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify(val),
    //         });
    //         //
    //         // const result = await response.json();
    //         // console.log("Success:", result);
    //
    //         message.success("Save Success")
    //     } catch (error) {
    //         console.error("Error:", error);
    //     }
    // }

    if (Object?.keys(itemComponents || {}).length > 0) {
        return (
            <div className="container py-5">
                {/*<Form form={itemComponents} url="http://dev.api-loyalty.megalith/api/v1/form/create-contact"*/}
                {/*      onSubmit={onSubmitHandler}/>*/}
                <Form src={params.src} />
            </div>
        )
    } else {
        return (
            <div className="p-4">
            </div>
        )
    }
}

export default Share
