import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class CartResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.item = this.generateItems(data?.item || []);
        this.trial_price = data?.trial_price || "";
        this.monthly_price = data?.monthly_price || "";
        this.quarterly_price = data?.quarterly_price || "";
        this.yearly_price = data?.yearly_price || "";
        this.total_price = data?.total_price || "";
        this._trial_price = data?._trial_price || 0;
        this._monthly_price = data?._monthly_price || 0;
        this._yearly_price = data?._yearly_price || 0;
        this._total_price = data?._total_price || 0;
        this.trial_recurring_date = data?.trial_recurring_date || "";
        this.monthly_recurring_date = data?.monthly_recurring_date || "";
        this.quarterly_recurring_date = data?.quarterly_recurring_date || "";
        this.yearly_recurring_date = data?.yearly_recurring_date || "";
        this.trial_recurring_price = data?.trial_recurring_price || "";
        this.monthly_recurring_price = data?.monthly_recurring_price || "";
        this.quarterly_recurring_price = data?.quarterly_recurring_price || "";
        this.yearly_recurring_price = data?.yearly_recurring_price || "";
    }

    generateItems(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map(i => newItems.push({
                id: i?.id || "",
                name: i?.name || "",
                price: i?.price || "",
                _price: i?._price || 0,
                raw: {
                    id: i?.id || "",
                    type: i?.type || "",
                    period_type: i?.period_type || "",
                    package_type: i?.package_type || "",
                    description: i?.description || "",
                    price: i?.price || 0,
                    sale_price: i?.sale_price || "",
                    status: i?.status,
                    created_at: i?.created_at || "",
                    updated_at: i?.updated_at || "",
                    benefit: i?.benefit || "",
                }
            }))
        }
        return newItems
    }

    exportDetail() {
        return {
            item: this.item,
            trial_price: this.trial_price,
            monthly_price: this.monthly_price,
            quarterly_price: this.quarterly_price,
            yearly_price: this.yearly_price,
            total_price: this.total_price,
            _trial_price: this._trial_price,
            _monthly_price: this._monthly_price,
            _yearly_price: this._yearly_price,
            _total_price: this._total_price,
            trial_recurring_date: this.trial_recurring_date,
            monthly_recurring_date: this.monthly_recurring_date,
            quarterly_recurring_date: this.quarterly_recurring_date,
            yearly_recurring_date: this.yearly_recurring_date,
            trial_recurring_price: this.trial_recurring_price,
            monthly_recurring_price: this.monthly_recurring_price,
            quarterly_recurring_price: this.quarterly_recurring_price,
            yearly_recurring_price: this.yearly_recurring_price,
        }
    }
}

export const column = ["name", "email", "phone_number", "tag", "created_date", "company", "job_title", "owner", "status"];

export const fieldDetailRemove = [];

export const CONSTANT = {
    LIST_PLANS: {
        entry: "3e55a13f-66f7-11ed-9e76-0242ac110004",
        elite: "3f1df8cf-66f7-11ed-9e76-0242ac110004",
        entrepreneur: "3fe04e69-66f7-11ed-9e76-0242ac110004",
    }
}

