import React, {useState, useEffect} from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import TaskContainer from "../components/TaskContainer";
import {ActionGetItem} from "../../../../data/hooks/task";
import TAG_DEFINE from "../../../../constants/common";
import {useSelector} from "react-redux";
import {selectItem} from "../../../../data/reselects/taskSelector";

const finalSpaceCharacters = [
    {
        id: 'gary',
        name: 'Gary Goodspeed',
        thumb: '/images/gary.png'
    },
    {
        id: 'cato',
        name: 'Little Cato',
        thumb: '/images/cato.png'
    },
    {
        id: 'kvn',
        name: 'KVN',
        thumb: '/images/kvn.png'
    },
    {
        id: 'mooncake',
        name: 'Mooncake',
        thumb: '/images/mooncake.png'
    },
    {
        id: 'quinn',
        name: 'Quinn Ergon',
        thumb: '/images/quinn.png'
    }
]

const initialData = {
    tasks: {
        'task-1': {id: 'task-1', content: 'Take out the garbage'},
        'task-2': {id: 'task-2', content: 'Watch my favorite show'},
        'task-3': {id: 'task-3', content: 'Charge my phone'},
        'task-4': {id: 'task-4', content: 'Cook dinner'},
    },
    columns: {
        'column-1': {
            id: 'column-1',
            title: 'To do',
            taskIds: ['task-1', 'task-2', 'task-3', 'task-4'],
        },
        'column-2': {
            id: 'column-2',
            title: 'In progress',
            taskIds: [],
        },
        'column-3': {
            id: 'column-3',
            title: 'Done',
            taskIds: [],
        },
    },
    // Facilitate reordering of the columns
    columnOrder: ['column-1', 'column-2', 'column-3'],
};

const Index = props => {
    const [characters, updateCharacters] = useState(finalSpaceCharacters),
        actionGetItem = ActionGetItem(),
        itemDetail = useSelector(selectItem());

    useEffect(() => {
        const {match} = props;
        if (Object.keys(match.params).length > 0) {
            actionGetItem({id: match.params?.id, props: props});
        }
    }, [props.match]);

    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const items = Array.from(characters);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        updateCharacters(items);
    }

    return (
        <TaskContainer taskId={props?.match.params?.id}/>
    )
}

export default Index
