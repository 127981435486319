import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom';

import {Provider} from 'react-redux';
import store from './data/store';

import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainCheck from "./layouts/MainCheck";
import './assets/index.scss';
import 'reactflow/dist/style.css';
import 'megalith/dist/assets/index.css';

ReactDOM.render(
    <Provider store={store}>
        <Router basename={process.env.PUBLIC_URL}>
            <MainCheck />
        </Router>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
