import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class EmbeddedResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.title = data?.title || "";
        this.owner = data?.owner || "";
        this.type = data?.type || "";
        this.status = data?.status || 0;
        this.data = data?.data || {};
        this.created_date = data?.created_date || "";
    }

    exportList() {
        return {
            id: this.id,
            title: this.title,
            owner: this.owner,
            type: this.type,
            status: this.status,
            created_date: moment(this.created_date).format(DATE_TIME_FORMAT.SHORT_DATE_US),
        }
    }

    exportDetail() {
        return {
            id: this.id,
            title: this.title,
            owner: this.owner,
            type: this.type,
            status: this.status,
            data: this.data,
        }
    }
}

export const column = ["title", "owner", "type", "status", "created_date"];

export const fieldDetailRemove = [];

export const filtersResponse = (filters) => {
    return removeObjectNullFull({
        search: filters?.search || "",
        is_owned: convertBooleanToInt(filters?.is_owned),
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    })
}

const listTypeForm = {
    input: {type: "textfield", input: false, icon: 'terminal',},
    email: {type: "email", input: false, icon: 'terminal',},
    // button: {
    //     block: true,
    //     leftIcon: "fa fa-send",
    //     disableOnInvalid: true,
    //     tableView: false,
    //     type: "button",
    //     input: true,
    //     saveOnEnter: false
    // },
}

export class EmbeddedMasterResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.status = data?.status;
        this.array_status = Object.keys(data?.status || {});
        this.type = data?.type;
        this.contact_attribute = this.generateContactAttribute(data?.contact_attribute || []);
        this.components_form = this.generateComponentsForm(data?.contact_attribute || []);
        this.action_url_form = this.generateUrlActionForm(data?.contact_attribute || []);
    }

    generateComponentsForm(items = []) {
        let newItems = {};
        if (items.length > 0) {
            items.map(i => {
                if (listTypeForm[i?.type]) {
                    newItems = {
                        ...newItems,
                        [i?.name]: {
                            title: i?.title,
                            key: i?.name,
                            icon: 'terminal',
                            // ignore: true,
                            schema: {
                                ...listTypeForm[i?.type],
                                key: i?.name,
                                label: i?.title,
                                detected_key: i?.name,
                                input: true
                            },
                        }
                    }
                }
            })
        }
        return newItems
    }

    generateUrlActionForm(items = []) {
        let url = "";
        if (items.length > 0) {
            items.map(i => {
                if (i?.url) {
                    url = i?.url
                }
            })
        }
        return url
    }

    generateContactAttribute(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map(i => newItems.push({
                title: i?.title || "",
                name: i?.name || "",
                type: i?.type || "",
                url: i?.url || "",
            }))
        }
        return newItems
    }

    exportMaster() {
        return {
            status: this.status,
            array_status: this.array_status,
            type: this.type,
            contact_attribute: this.contact_attribute,
            components_form: this.components_form,
            action_url_form: this.action_url_form,
        }
    }
}
