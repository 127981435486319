import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/Tag';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/tagService';
import * as FunctionAction from '../actions/tagAction';
import * as ContactAction from '../actions/contactAction';
import {message} from "antd";
import TagRequest, {filtersParams} from "../mapping/Request/TagRequest";
import TAG_DEFINE from "../../constants/common";

function* actionGetListTag() {
    yield takeLatest(Types.TAG_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(filtersParams(action.filters) || {});
        const result = yield call(FunctionService.actionTagList, params);
        yield put(FunctionAction.tagListSuccess(result));
    }, errorHandle(Types.TAG_FAIL)))
}

function* actionGetItem() {
    yield takeLatest(Types.TAG_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionTagDetail, action.params.id);
        yield put(FunctionAction.tagDetailSuccess(result));
    }, errorHandle(Types.TAG_FAIL)))
}

function* createAction() {
    yield takeLatest(Types.TAG_CREATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new TagRequest(action.params).exportCreate();
        const result = yield call(FunctionService.create, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
        action.params.props.history.goBack();
        yield put({type: Types.TAG_LIST_ACTION});
    }, errorHandle(Types.TAG_FAIL)))
}

function* updateAction() {
    yield takeLatest(Types.TAG_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new TagRequest(action.params).exportUpdate();
        yield call(FunctionService.update, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.tagDetailAction({id: action.params.id}));
    }, errorHandle(Types.TAG_FAIL)))
}

function* addAction() {
    yield takeLatest(Types.TAG_ADD_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.add, action?.params?.id, action?.params?.ids, action?.params?.is_delete);
        yield put(FunctionAction.tagAddSuccess(result));
        message.success(result?.data?.data?.result)
        yield put(ContactAction.contactListAction(action?.params?.filters));
        // action.params.props.history.goBack();
        // yield put({type: Types.TAG_LIST_ACTION});
    }, errorHandle(Types.TAG_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        console.log("===> ", e)
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* tagSaga() {
    yield all([
        fork(addAction),
        fork(actionGetListTag),
        fork(createAction),
        fork(actionGetItem),
        fork(updateAction),
    ])
}
