import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import CartResponse from "../mapping/Response/CartResponse";

// export function masterContact() {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.CONTACT_MASTER_DATA,
//         // params: removeObjectNull({ city_id }),
//         parser: data => new ContactMasterResponse(data.data.result).exportMaster(),
//     }).get();
// }
//
// export function actionContactList(params) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.CONTACT_LIST,
//         params: params,
//         parser: dataParser,
//     }).get();
// }
//
// export function actionContactExport(params) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.CONTACT_LIST,
//         params: params,
//         isDownload: true,
//         parser: data => data,
//     }).get();
// }

export function actionGetDetail(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CART_DETAIL,
        // endpointParams: {id},
        parser: data => new CartResponse(data.data.result).exportDetail(),
    }).get();
}

export function update(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CART_UPDATE,
        parser: data => data?.data?.result
        // endpointParams: {id: data?.id},
    }).put(data);
}

export function create(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CART_CREATE,
        parser: data => data?.data?.result
    }).post(data);
}

export function remove(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CART_REMOVE,
        endpointParams: {id},
        parser: data => data?.data?.result
    }).delete();
}

// function dataParser(data = {}) {
//     return {
//         ...data.data,
//         result: (data.data.result || []).map(item => new ContactResponse(item).exportList()),
//         total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
//     }
// }
