import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import TaskResponse from "../mapping/Response/TaskResponse";

export function actionTaskList(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TASK_LIST,
        params: params,
        parser: dataParser,
    }).get();
}

export function actionTaskDetail(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TASK_GET_ITEM,
        endpointParams: {id},
        parser: data => new TaskResponse(data.data.result).exportDetail(),
    }).get();
}

export function update(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TASK_UPDATE,
        endpointParams: {id: data?.id},
    }).put(data);
}

export function create(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TASK_CREATE,
    }).post(data);
}

export function updateProcessTask(data, id, idItem) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TASK_UPDATE_PROCESS_TASK,
        endpointParams: {id, idItem},
        parser: data => data.data.result,
    }).put(data);
}

export function createProcessTask(data, id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TASK_CREATE_PROCESS_TASK,
        endpointParams: {id},
        parser: data => data.data.result,
    }).post(data);
}

export function add(id, ids) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TASK_ADD,
        endpointParams: {id},
    }).post({ids});
}

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new TaskResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
