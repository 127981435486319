const position = {x: 0, y: 0};

const nodes = [
    {
        id: "1",
        type: "source",
        data: {
            title: "Source",
            description: "Automations Database contacts",
            tag: "SOURCE",
            stats: {
                started: 0,
            },
        },
        position,
        style: {
            width: 250,
        },
    },
    {
        id: "2",
        type: "action",
        data: {
            title: "Rule",
            description: "Send message to contacts.",
            tag: "RULE",
            stats: {
                started: 0,
            },
            // stats: {
            //     running: 18,
            //     error: 1,
            // },
        },
        position,
        // style: {
        //     width: 250,
        // },
    },
    {
        id: "3",
        type: "waitThenCheck",
        data: {
            title: "Action",
            description: "Send message to contacts.",
            tag: "ACTION",
            stats: {
                running: 17,
            },
        },
        position,
        style: {
            width: 250,
        },
    },
    // {
    //     id: "3",
    //     type: "waitThenCheck",
    //     data: {
    //         title: "Wait then Check",
    //         description: "Check behaviour of the contacts.",
    //         stats: {
    //             running: 17,
    //         },
    //     },
    //     position,
    //     style: {
    //         width: 250,
    //     },
    // },
    // {
    //     id: "4",
    //     type: "email",
    //     data: {
    //         title: "Email",
    //         description: "Send message to contacts.",
    //         stats: {
    //             running: 3,
    //         },
    //     },
    //     position,
    // },
    // {
    //     id: "5",
    //     type: "sms",
    //     data: {
    //         title: "SMS",
    //         description: "Send SMS to contacts.",
    //         stats: {
    //             running: 14,
    //         },
    //     },
    //     position,
    // },
    // {
    //     id: "6",
    //     type: "empty",
    //     data: {},
    //     position,
    //     height: 6,
    //     mergeNodeOfParentId: "3",
    // },
    {
        id: "4",
        type: "end",
        data: {
            title: "End",
            description: "Automation ends.",
            tag: "END",
            stats: {
                completed: 14,
            },
        },
        position,
    },
];

const edges = [
    {
        id: "e1-2",
        source: "1",
        target: "2",
        type: "condition",
        data: {
            isAddButtonHidden: true,
        },
    },
    {
        id: "e2-3",
        source: "2",
        target: "3",
        type: "condition",
        data: {
            isAddButtonHidden: true,
        },
    },
    {
        id: "e3-4",
        source: "3",
        target: "4",
        type: "condition",
        data: {
            // title: "Default condition",
            // disabled: true,
            isAddButtonHidden: true,
        },
    },
    // {
    //     id: "e3-5",
    //     source: "3",
    //     target: "5",
    //     type: "condition",
    //     data: {
    //         title: "Editable branch",
    //         isAddButtonHidden: true
    //     },
    // },
    // {
    //     id: "e4-6",
    //     source: "4",
    //     target: "6",
    //     type: "condition",
    //     data: {
    //         isAddButtonHidden: true,
    //     },
    // },
    // {
    //     id: "e5-6",
    //     source: "5",
    //     target: "6",
    //     type: "condition",
    //     data: {
    //         isAddButtonHidden: true,
    //     },
    // },
    // {
    //     id: "e6-7",
    //     source: "6",
    //     target: "7",
    //     type: "condition",
    //     data: {
    //         isAddButtonHidden: true,
    //     },
    // },
];

export const initialElements = [...nodes, ...edges];
