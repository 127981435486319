import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/TeamPoint';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/teamPointService';
import * as FunctionAction from '../actions/teamPointAction';
import {
    customerTeamPointDetailFiltersParams,
    customerTeamPointFiltersParams
} from "../mapping/Request/TeamPointRequest";
import {actionTeamPointDetail} from "../services/teamPointService";

function* actionGetListTeamPoint() {
    yield takeLatest(Types.TEAM_POINT_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(customerTeamPointFiltersParams(action.filters) || {});
        const result = yield call(FunctionService.actionTeamPointList, params);
        yield put(FunctionAction.teamPointListSuccess(result));
    }, errorHandle(Types.TEAM_POINT_FAIL)))
}

function* actionDetailTeamPoint() {
    yield takeLatest(Types.TEAM_POINT_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(customerTeamPointDetailFiltersParams(action.filters) || {});
        const result = yield call(FunctionService.actionTeamPointDetail, params);
        yield put(FunctionAction.teamPointDetailSuccess(result));
    }, errorHandle(Types.TEAM_POINT_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* teamPointSaga() {
    yield all([
        fork(actionGetListTeamPoint),
        fork(actionDetailTeamPoint),
    ])
}
