import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/EmailHistory';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/emailHistoryService';
import * as FunctionAction from '../actions/emailHistoryAction';
import {message} from "antd";
import EmailHistoryRequest, {filtersParams} from "../mapping/Request/EmailHistoryRequest";
import TAG_DEFINE from "../../constants/common";

function* actionGetListEmailHistory() {
    yield takeLatest(Types.EMAIL_HISTORY_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(filtersParams(action.filters) || {});
        const result = yield call(FunctionService.actionEmailHistoryList, params);
        yield put(FunctionAction.emailHistoryListSuccess(result));
    }, errorHandle(Types.EMAIL_HISTORY_FAIL)))
}

function* actionGetItem() {
    yield takeLatest(Types.EMAIL_HISTORY_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionEmailHistoryDetail, action.params.id);
        yield put(FunctionAction.emailHistoryDetailSuccess(result));
    }, errorHandle(Types.EMAIL_HISTORY_FAIL)))
}

function* actionGetMasterEmailHistory() {
    yield takeLatest(Types.EMAIL_HISTORY_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.masterEmailHistory, action.params);
        yield put(FunctionAction.emailHistoryMasterSuccess(result));
    }, errorHandle(Types.EMAIL_HISTORY_FAIL)))
}

function* createAction() {
    yield takeLatest(Types.EMAIL_HISTORY_CREATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new EmailHistoryRequest(action.params?.request).exportCreate();
        const result = yield call(FunctionService.create, data);
        message.success(result);
        yield put(FunctionAction.emailHistoryCreateSuccess(result));
        // action.params.props.history.goBack();
        yield put(FunctionAction.emailHistoryListAction(action?.params?.filters));
    }, errorHandle(Types.EMAIL_HISTORY_FAIL)))
}

function* actionVerifyEmail() {
    yield takeLatest(Types.EMAIL_HISTORY_VERIFY_EMAIL_ACTION, Func.sagaWrapper(function* (action) {
        const data = new EmailHistoryRequest(action.params).exportVerifyEmail();
        const result = yield call(FunctionService.verifyEmail, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["208"]);
        yield put(FunctionAction.emailHistoryVerifyEmailSuccess(result));
    }, errorHandle(Types.EMAIL_HISTORY_FAIL)))
}

function* updateAction() {
    yield takeLatest(Types.EMAIL_HISTORY_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new EmailHistoryRequest(action.params).exportUpdate();
        yield call(FunctionService.update, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.emailHistoryDetailAction({id: action.params.id}));
    }, errorHandle(Types.EMAIL_HISTORY_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        console.log("===> ", e)
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* emailHistorySaga() {
    yield all([
        fork(actionGetListEmailHistory),
        fork(createAction),
        fork(actionGetMasterEmailHistory),
        fork(actionGetItem),
        fork(updateAction),
        fork(actionVerifyEmail),
    ])
}
