import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/Cart';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/cartService';
import * as FunctionAction from '../actions/cartAction';
import * as AuthAction from '../actions/authAction';
import {message} from "antd";
import CartRequest from "../mapping/Request/CartRequest";
import TAG_DEFINE from "../../constants/common";

// function* actionGetListCart() {
//     yield takeLatest(Types.CART_LIST_ACTION, Func.sagaWrapper(function* (action) {
//         const params = parseListRequestParams(cartFiltersParams(action.filters) || {});
//         const result = yield call(FunctionService.actionCartList, params);
//         yield put(FunctionAction.cartListSuccess(result));
//     }, errorHandle(Types.CART_FAIL)))
// }

function* actionGetItem() {
    yield takeLatest(Types.CART_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionGetDetail);
        yield put(FunctionAction.cartDetailSuccess(result));
    }, errorHandle(Types.CART_FAIL)))
}

// function* actionGetMasterCart() {
//     yield takeLatest(Types.CART_MASTER_ACTION, Func.sagaWrapper(function* (action) {
//         const result = yield call(FunctionService.masterCart, action.params);
//         yield put(FunctionAction.cartMasterSuccess(result));
//     }, errorHandle(Types.CART_FAIL)))
// }

function* createAction() {
    yield takeLatest(Types.CART_CREATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new CartRequest(action.params).exportCreate();
        const result = yield call(FunctionService.create, data);
        message.success("Success");
        window.location = result
        // yield put(FunctionAction.cartCreateSuccess(result));
        // yield put(AuthAction.generalAction(result));
    }, errorHandle(Types.CART_FAIL)))
}

function* updateAction() {
    yield takeLatest(Types.CART_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new CartRequest(action.params).exportUpdate();
        const result = yield call(FunctionService.update, data);
        message.success(result);
        yield put(FunctionAction.cartUpdateSuccess(result));
        yield put(AuthAction.generalAction(result));
        // yield put(FunctionAction.cartDetailAction({id: action.params.id}));
    }, errorHandle(Types.CART_FAIL)))
}

function* removeAction() {
    yield takeLatest(Types.CART_REMOVE_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.remove, action.params?.id);
        message.success(result);
        yield put(FunctionAction.cartDetailAction());
        yield put(AuthAction.generalAction(result));
    }, errorHandle(Types.CART_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        console.log("===> ", e)
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* cartSaga() {
    yield all([
        // fork(actionGetListCart),
        fork(createAction),
        fork(removeAction),
        fork(actionGetItem),
        fork(updateAction),
    ])
}
