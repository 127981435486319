export const STEPS = {
    LOOKUP: 1,
    FIND_COMPARABLES: 2,
    RUN_CMA: 3,
    SHOW_CMA_RESULTS: 4,
    SHOW_CMA_REPORT: 5,
}

export const STEP_VISIBLE = {
    [STEPS.LOOKUP]: [STEPS.LOOKUP, STEPS.FIND_COMPARABLES, STEPS.RUN_CMA],
    [STEPS.FIND_COMPARABLES]: [STEPS.FIND_COMPARABLES, STEPS.RUN_CMA],
    [STEPS.RUN_CMA]: [STEPS.RUN_CMA],
    [STEPS.SHOW_CMA_RESULTS]: [STEPS.SHOW_CMA_RESULTS],
    [STEPS.SHOW_CMA_REPORT]: [STEPS.SHOW_CMA_REPORT]
}

export const MARKET = {
    HOUSTON: {
        PROPERTY_TYPE: {
            "Single-Family": "Single - Family",
            "Multi-Family": "Multi Family",
            "Country Homes/Acreage": "Country Homes/Acreage",
            "Townhouse/Condo": "Townhouse/Condo",
            "Mid/Hi-Rise Condo": "Mid/highrise Condo",
            "Lots": "Lots",
        },
        PROPERTY_SUB_TYPE: {
            "townhouse": "Townhouse",
            "single-family-detached": "Single Family Detached",
            "free-standing": "Free standing"
        },
        STATIC_URI: 'https://photos.harstatic.com/[id]/hr/img-1.jpeg'
    },
    AUSTIN: {
        PROPERTY_TYPE: {
            'Residential': 'Residential',
            'Multi-Family': 'Multi-Family',
            'Lot': 'Lot',
            'Farms/Ranch/Acreage': 'Farms/Ranch/Acreage',
            'Commercial Sal': 'Commercial Sale'
        },
        PROPERTY_SUB_TYPE: {
            'Modular': 'Modular',
            'Condo': 'Condo',
            'Manufactured': 'Manufactured',
            'Mobile Home': 'Mobile Home',
            'Townhouse': 'Townhouse',
            'See Agent': 'See Agent',
            'House': 'House',
            'Attached 1/2 Duplex': 'Attached 1/2 Duplex',
        },
        STATIC_URI: 'https://listingmedia7.harstatic.com'
    },
    DFW: {
        PROPERTY_TYPE: {
            "Residential": "Residential",
            "Commercial": "Commercial",
            "Multi-Family": "Multi-Family",
            "Lots & Acreage": "Lots & Acreage"
        },
        PROPERTY_SUB_TYPE: {
            "RES-Single Family": "RES-Single Family",
            "RES-Half Duplex": "RES-Half Duplex",
            "RES-Farm/Ranch": "RES-Farm/Ranch",
            "RES-Townhouse": "RES-Townhouse",
            "RES-Condo": "RES-Condo",
        },
        STATIC_URI: 'https://listingmedia7.harstatic.com/[id]/lr/1.jpeg'
    },
    SANANTONIO: {
        PROPERTY_TYPE: {
            'Single Residential': 'Single Residential',
            'Residential Rental': 'Residential Rental',
            'Commercial Land/Unimprvd': 'Commercial Land/Unimprvd',
            'Farm Ranch': 'Farm Ranch',
            'Commercial Improved Prop': 'Commercial Improved Prop',
        },
        PROPERTY_SUB_TYPE: {
            'Modular Home': 'Modular Home',
            'Garden/Patio Home/Detchd': 'Garden/Patio Home/Detchd',
            'Townhome': 'Townhome',
            'Manufactured': 'Manufactured',
            'Single Family Detached': 'Single Family Detached',
        },
        STATIC_URI: 'https://listingmedia7.harstatic.com'
    }
}

export const PROPERTY = {
    STATUS: {
        SOLD: {
            label: 'Sold',
            key: 'sold'
        },
        ACTIVE: {
            label: 'Active',
            key: 'active'
        },
        LEASED: {
            label: 'Leased',
            key: 'leased'
        },
        NONACTIVE: {
            label: 'Non-active',
            key: 'nonActive'
        }
    }
}

export const ROUTE_REDIRECT = {
    HOME: "/",
    TEAMS: {
        HOME: "/teams",
        CREATE: "/teams/action",
        UPDATE: "/teams/action/:id",
    },
    TEAM_POINT: {
        HOME: "/team-point",
    }
}
