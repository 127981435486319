import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import TeamPointResponse from "../mapping/Response/TeamPointResponse";

export function actionTeamPointList(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_TEAM_POINT,
        params: params,
        parser: dataParser,
    }).get();
}

export function actionTeamPointDetail(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_TEAM_POINT_DETAIL,
        params: params,
        parser: data => {
            return {
                ...data.data,
                result: (data.data.result || []).map(item => new TeamPointResponse(item).exportDetail()),
                total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
            }

        },
    }).get();
}

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new TeamPointResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
