import {List, Modal, Space, message} from "antd";
import React, {useEffect, useState} from "react";
import {ActionGetList} from "../../../../data/hooks/embedded";
import {useSelector} from "react-redux";
import {selectFetching, selectList} from "../../../../data/reselects/embeddedSelector";
import {CheckCircleOutlined} from "@ant-design/icons";
import * as LocalStorage from "../../../../utils/localStorage";
import {CONFIG_LOCAL_STORAGE} from "../../../../constants/define";

const TemplateContactForm = props => {
    const [isModalOpen, setIsModalOpen] = useState(false),
        [resultList, setResultList] = useState([]),
        actionGetList = ActionGetList(),
        itemIsFetching = useSelector(selectFetching()),
        itemListTeams = useSelector(selectList());

    useEffect(() => {
        actionGetList({page_size: 9999})
    }, [])

    useEffect(() => {
        setResultList(itemListTeams?.result)
    }, [itemListTeams])

    useEffect(() => {
        setIsModalOpen(props?.isOpen)
    }, [props?.isOpen])

    const onClose = e => {
        setIsModalOpen(false)
        props?.actionModal(false)
    }

    return (
        <Modal
            footer={null}
            title="Template Contact Form"
            open={isModalOpen}
            destroyOnClose
            onOk={e => {
            }}
            onCancel={onClose}>
            <List
                header={null}
                footer={null}
                bordered
                dataSource={resultList}
                renderItem={(item) => (
                    <List.Item>
                        <Space.Compact block>
                            <p className="mb-0" style={{
                                width: 'calc(100% - 20px)'
                            }}>{item?.title}</p>
                            <CheckCircleOutlined
                                className={`mt-1 cursorPointer fs--20px ${props?.templateContactSelected === item?.id ? "color--primary" : ""}`}
                                onClick={e => {
                                    props?.setTemplateContactSelected(item?.id)
                                    LocalStorage.set(CONFIG_LOCAL_STORAGE.TEMPLATE_CONTACT, item?.id);
                                    message.success("Selected")
                                    setIsModalOpen(false)
                                    props?.actionModal(false)
                                }}/>
                        </Space.Compact>
                    </List.Item>
                )}
            />
        </Modal>
    )
}

export default TemplateContactForm
