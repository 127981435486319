import * as Types from '../types/Voice';

export const voiceMasterAction = (filters) => ({type: Types.VOICE_MASTER_ACTION, filters});
export const voiceMasterSuccess = (master) => ({
    type: Types.VOICE_MASTER_SUCCESS,
    master
});

export const voiceListAction = (filters) => ({type: Types.VOICE_LIST_ACTION, filters});
export const voiceListSuccess = (voice) => ({
    type: Types.VOICE_LIST_SUCCESS,
    voice
});

export const voiceVerifyAction = (params) => ({type: Types.VOICE_VERIFY_ACTION, params});
export const voiceVerifySuccess = (verify) => ({
    type: Types.VOICE_VERIFY_SUCCESS,
    verify
});

export const voiceUpdateAction = (params) => ({type: Types.VOICE_UPDATE_ACTION, params});
export const voiceUpdateSuccess = (updateData) => ({
    type: Types.VOICE_UPDATE_SUCCESS,
    updateData
});

export const voiceCreateAction = (params) => ({type: Types.VOICE_CREATE_ACTION, params});
export const voiceCreateSuccess = (createData) => ({
    type: Types.VOICE_CREATE_SUCCESS,
    createData
});

export const voiceConfigAction = (params) => ({type: Types.VOICE_CONFIG_ACTION, params});
export const voiceConfigSuccess = (voiceConfig) => ({
    type: Types.VOICE_CONFIG_SUCCESS,
    voiceConfig
});

export const voiceInitStore = (params) => ({type: Types.VOICE_INIT, params});
export const voiceClearStore = (params) => ({type: Types.VOICE_FAIL, params});
