import HeaderAction from "../../common/layout/HeaderAction";
import React, {useEffect} from "react";
import {Button, Card, Divider, Popconfirm, Spin} from "antd";
import {CloseCircleOutlined} from "@ant-design/icons";
import {ActionGetItem, ActionRemove, ActionCreate} from "../../../data/hooks/cart";
import {useSelector} from "react-redux";
import {selectItem, selectFetching, selectStatusAction} from "../../../data/reselects/cartSelector";
import {Icon} from "../../common";

const Index = props => {
    const actionGetItem = ActionGetItem(),
        actionRemove = ActionRemove(),
        actionCreate = ActionCreate(),
        itemStatusAction = useSelector(selectStatusAction()),
        itemCart = useSelector(selectItem()),
        itemFetching = useSelector(selectFetching());

    useEffect(() => {
        actionGetItem()
    }, []);

    return (
        <>
            <HeaderAction
                isActionSignlePage
                title="My Cart"
                disabledFormatUpperCaseTitle
            />
            <Spin spinning={itemFetching}>
                <div className="container-fluid px-4 mt-3">
                    <div className="row">
                        <div className="col-12 col-lg-9 mb-4 mb-lg-0">
                            <Card
                                title="Cart"
                            >
                                {
                                    ((itemCart?.item || []).length > 0) && (itemCart?.item || []).map((i, k) => {
                                        return (
                                            <div key={k}>
                                                <div className="row align-items-center">
                                                    <div className="col-1 pe-0 text-end pe-3">
                                                        <Popconfirm
                                                            title="Are you sure to delete?"
                                                            onConfirm={(e) => actionRemove({id: i?.id})}
                                                            // onCancel={() => {
                                                            // }}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <CloseCircleOutlined className="fs--14px icon-ant"/>
                                                        </Popconfirm>
                                                    </div>
                                                    <div className="col-7 ps-0 color--primary fw-bold">
                                                        {i?.name}
                                                    </div>
                                                    <div className="col-4 text-end color--black">
                                                        {i?.price}
                                                    </div>
                                                </div>
                                                {
                                                    ((itemCart?.item || []).length - 1 > k) && <Divider/>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </Card>
                        </div>
                        <div className="col-12 col-lg-3">
                            <Card
                                title="Cart Totals"
                            >
                                <div>
                                    {
                                        itemCart?.trial_price && (
                                            <>
                                                <div className="row align-items-center">
                                                    <div className="col-6">
                                                        Trial Total
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        {itemCart?.trial_price}
                                                    </div>
                                                </div>
                                                <Divider className="my-3"/>
                                            </>
                                        )
                                    }
                                    {
                                        itemCart?.monthly_price && (
                                            <>
                                                <div className="row align-items-center">
                                                    <div className="col-6">
                                                        Monthly Total
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        {itemCart?.monthly_price}
                                                    </div>
                                                </div>
                                                <Divider className="my-3"/>
                                            </>
                                        )
                                    }
                                    {
                                        itemCart?.quarterly_price && (
                                            <>
                                                <div className="row align-items-center">
                                                    <div className="col-6">
                                                        Quarterly Total
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        {itemCart?.quarterly_price}
                                                    </div>
                                                </div>
                                                <Divider className="my-3"/>
                                            </>
                                        )
                                    }
                                    {
                                        itemCart?.yearly_price && (
                                            <>
                                                <div className="row align-items-center">
                                                    <div className="col-6">
                                                        Yearly Total
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        {itemCart?.yearly_price}
                                                    </div>
                                                </div>
                                                <Divider className="my-3"/>
                                            </>
                                        )
                                    }
                                    {
                                        itemCart?.total_price && (
                                            <div className="row align-items-center">
                                                <div className="col-6">
                                                    Total
                                                </div>
                                                <div className="col-6 text-end">
                                                    {itemCart?.total_price}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <p className="fw-bold fs--18px mt-4">Recurring Totals</p>
                                <Divider className="mt-1"/>
                                <div>
                                    {
                                        itemCart?.trial_recurring_price && (
                                            <>
                                                <div className="row">
                                                    <div className="col-6">
                                                        Recurring Trial Total
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <p className="mb-0">{itemCart?.trial_recurring_price}</p>
                                                        <p className="mb-0 color--disabled">Next
                                                            renewal: {itemCart?.trial_recurring_date}</p>
                                                    </div>
                                                </div>
                                                <Divider className="my-3"/>
                                            </>
                                        )
                                    }
                                    {
                                        itemCart?.monthly_recurring_price && (
                                            <>
                                                <div className="row">
                                                    <div className="col-6">
                                                        Recurring Monthly Total
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <p className="mb-0">{itemCart?.monthly_recurring_price}</p>
                                                        <p className="mb-0 color--disabled">Next
                                                            renewal: {itemCart?.monthly_recurring_date}</p>
                                                    </div>
                                                </div>
                                                <Divider className="my-3"/>
                                            </>
                                        )
                                    }
                                    {
                                        itemCart?.quarterly_recurring_price && (
                                            <>
                                                <div className="row">
                                                    <div className="col-6">
                                                        Recurring Quarterly Total
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <p className="mb-0">{itemCart?.quarterly_recurring_price}</p>
                                                        <p className="mb-0 color--disabled">Next
                                                            renewal: {itemCart?.quarterly_recurring_date}</p>
                                                    </div>
                                                </div>
                                                <Divider className="my-3"/>
                                            </>
                                        )
                                    }
                                    {
                                        itemCart?.yearly_recurring_price && (
                                            <>
                                                <div className="row">
                                                    <div className="col-6">
                                                        Recurring Yearly Total
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <p className="mb-0">{itemCart?.yearly_recurring_price}</p>
                                                        <p className="mb-0 color--disabled">Next
                                                            renewal: {itemCart?.yearly_recurring_date}</p>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                                <Button
                                    type="primary"
                                    className="w-100 mt-2 fw-bold border__radius--5px"
                                    htmlType="button"
                                    onClick={e => actionCreate()}
                                    disabled={!(itemCart?.item || []).length > 0}
                                >
                                    Proceed to checkout {itemStatusAction?.isSubmit && <Icon type="sync-outlined" spin/>}
                                </Button>
                            </Card>
                        </div>
                    </div>
                </div>
            </Spin>
        </>
    )
}

export default Index
