import {AutoComplete, Collapse, Divider, Popconfirm, Select, Spin, Table} from "antd";
import React, {useEffect, useState} from "react";
import {Icon} from "../index";
import {useHistory, useLocation} from "react-router-dom";
import {setParamsUrlFilter} from "../../../utils/functions";
import queryString from "query-string";
import {ActionFiltersDelete, ActionGetList} from "../../../data/hooks/contact";
import {useSelector} from "react-redux";
import {selectFetching, selectStatusAction} from "../../../data/reselects/contactSelector";
import * as LocalStorage from "../../../utils/localStorage";
import {CONFIG_LOCAL_STORAGE} from "../../../constants/define";

const ModalListFilters = props => {
    const routeMatch = useHistory(),
        routeLocation = useLocation(),
        [list, setList] = useState([]),
        [masterPortalFilter, setMasterPortalFilter] = useState([]),
        actionFiltersDelete = ActionFiltersDelete(),
        getListContact = ActionGetList(),
        itemStatusAction = useSelector(selectStatusAction()),
        itemFetching = useSelector(selectFetching());
    const column = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: 80,
            render: (text, record) => (
                <>
                    <Icon type="check-circle-outlined" className="me-3 color--primary" onClick={e => {
                        // console.log(321, setParamsUrlFilter(routeLocation?.pathname, queryString.parse(record?.params, {arrayFormat: 'comma'})))
                        // routeMatch?.go(setParamsUrlFilter(routeLocation?.pathname, queryString.parse(record?.params, {arrayFormat: 'comma'})))
                        window.location = setParamsUrlFilter(routeLocation?.pathname, queryString.parse(record?.params, {arrayFormat: 'comma'}))
                        // props?.onLoadFilter(setParamsUrlFilter(routeLocation?.pathname, queryString.parse(record?.params, {arrayFormat: 'comma'})))
                    }}/>
                    <Popconfirm
                        title="Are you sure you want to delete this filter?"
                        onConfirm={e => actionFiltersDelete({title: record?.title})}
                        onCancel={e => {
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Icon type="delete"/>
                    </Popconfirm>
                </>
            ),
        },
    ]
    const columnMasterPortalFilter = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: 80,
            render: (text, record) => (
                <>
                    <Popconfirm
                        title="Do you want to apply this filter? The default filters in UI will be ignored"
                        // onConfirm={e => console.log(111, record, queryString.parse(record?.params))}
                        onConfirm={e => {
                            props?.setValuePortalFilter({
                                value: record?.params,
                                title: `${record?.titleHeader} - ${record?.title}`
                            })
                            getListContact({...queryString.parse(record?.params)})
                        }}
                        onCancel={e => {
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Icon type="check-circle-outlined" className="me-3 color--primary" onClick={e => {
                            // console.log(321, setParamsUrlFilter(routeLocation?.pathname, queryString.parse(record?.params, {arrayFormat: 'comma'})))
                            // routeMatch?.go(setParamsUrlFilter(routeLocation?.pathname, queryString.parse(record?.params, {arrayFormat: 'comma'})))
                            // window.location = setParamsUrlFilter(routeLocation?.pathname, queryString.parse(record?.params, {arrayFormat: 'comma'}))
                            // props?.onLoadFilter(setParamsUrlFilter(routeLocation?.pathname, queryString.parse(record?.params, {arrayFormat: 'comma'})))
                        }}/>
                    </Popconfirm>
                </>
            ),
        },
    ]

    useEffect(() => {
        if ((props?.master || []).length > 0) {
            let items = [];
            props?.master?.map((i, k) => items?.push({
                key: k,
                title: i?.value,
                params: i?.params
            }))
            setList(items)
        } else {
            setList([])
        }
    }, [props?.master]);

    return (
        <Spin spinning={itemStatusAction?.isDeleteFilter || itemFetching}>
            <Table columns={column}
                   dataSource={list}
                   pagination={false}
                   loading={itemFetching}/>
            {
                ((LocalStorage.get(CONFIG_LOCAL_STORAGE.CONTACT_SOURCE_FILTER)).toUpperCase() === "REALESTATEIQ") ? (
                    <>
                        <p className="fw-bold fs--14px mt-4">Portal Filter</p>
                        <Divider className="my-3"/>
                        {
                            (props?.itemMasterPortalFilter || []).length > 0 ? (props?.itemMasterPortalFilter || []).map((item, index) => {
                                return (
                                    <Collapse defaultActiveKey={[item?.key]}>
                                        <Collapse.Panel header={item?.title} key={item?.key}>
                                            <Table columns={columnMasterPortalFilter}
                                                   dataSource={item?.filters?.map((i, k) => {
                                                       return {
                                                           key: k,
                                                           titleHeader: item?.title,
                                                           title: i?.name,
                                                           params: i?.value
                                                       }
                                                   })}
                                                   pagination={false}/>
                                        </Collapse.Panel>
                                    </Collapse>
                                )
                            }) : null
                        }
                    </>
                ) : null
            }
        </Spin>
    )
}

export default ModalListFilters
