import React, {Component, useEffect, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {Header as HeaderComponent} from 'megalith'
import {
    Alert,
    Avatar,
    Badge,
    Button,
    Divider,
    Dropdown,
    Form,
    Input,
    Menu,
    message,
    Modal, Popconfirm,
    Select,
    Space,
    Spin,
    Tooltip, Upload
} from "antd";
import * as LocalStorage from "../../../utils/localStorage";
import {CONFIG_LOCAL_STORAGE, COUNTRY_CODE} from "../../../constants/define";
import {Icon} from "../index";
import {
    DownOutlined,
    ExclamationCircleOutlined,
    MessageFilled,
    RetweetOutlined,
    CaretDownOutlined, PaperClipOutlined, UploadOutlined, DeleteOutlined
} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {
    getFetchingAuth,
    getGeneral,
    getProfile,
    getStatusAction,
    selectSettingMaster
} from "../../../data/reselects/authSelector";
import {
    ActionProfileConfigUpdate,
    ActionSetting,
    ActionUpdateProfile,
    ActionSettingMaster
} from "../../../data/hooks/auth";

import {
    CheckOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    PieChartOutlined,
    DesktopOutlined,
    ContainerOutlined,
    MailOutlined, ShoppingCartOutlined, CheckCircleTwoTone, SendOutlined, LoadingOutlined, NotificationOutlined,
} from '@ant-design/icons';
import Navigation from "./Navigation";
import HeaderMenu from "./HeaderMenu";
import VerifyEmailForm from "../components/VerifyEmailForm";
import {v4 as uuidv4} from 'uuid';
import {find} from "lodash";
import {ActionImportMonday} from "../../../data/hooks/contact";
import {selectStatusAction} from "../../../data/reselects/contactSelector";
import {ActionUpload, ActionUploadInitStore} from "../../../data/hooks/upload";
import {selectFilesUpload, selectFetching as selectFetchingUpload} from "../../../data/reselects/uploadSelector";
import {FaBell} from "react-icons/fa";
import {GoDotFill} from "react-icons/go";


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

const Header = (props) => {
    const [form] = Form.useForm(),
        [showProfile, setShowProfile] = useState(false),
        [isSetting, setIsSetting] = useState(false),
        [isVerifyEmailForm, setIsVerifyEmailForm] = useState(false),
        [isShowNotify, setIsShowNotify] = useState(false),
        [countNotify, setCountNotify] = useState(0),
        [listColumnsBoardSelect, setListColumnsBoardSelect] = useState([]),

        [files, setFiles] = useState({}),
        [imageUrl, setImageUrl] = useState(),
        [attach, setAttach] = useState([]),
        [loadingUpload, setLoadingUpload] = useState(false),

        [onChangeValue, setOnChangeValue] = useState(),
        getUpdateProfile = ActionUpdateProfile(),
        actionProfileConfigUpdate = ActionProfileConfigUpdate(),
        actionSettingMaster = ActionSettingMaster(),
        actionSetting = ActionSetting(),
        actionImportMonday = ActionImportMonday(),
        actionUpload = ActionUpload(),
        actionUploadInitStore = ActionUploadInitStore(),
        itemFileUpload = useSelector(selectFilesUpload()),
        itemIsUpload = useSelector(selectFetchingUpload()),
        itemStatusAction = useSelector(getStatusAction()),
        itemContactStatusAction = useSelector(selectStatusAction()),
        itemFetching = useSelector(getFetchingAuth()),
        itemSettingMaster = useSelector(selectSettingMaster()),
        itemProfile = useSelector(getProfile()),
        itemGeneral = useSelector(getGeneral());

    useEffect(() => {
        actionSettingMaster();
        setListColumnsBoardSelect(find(itemProfile?.profile_config?.monday_config, {board_id: itemProfile?.profile_config?.monday_board})?.columns || [])

        return () => {
            actionUploadInitStore();
            setFiles({});
        }
    }, []);

    useEffect(() => {
        if (itemProfile?.profile_config) {
            form.setFieldsValue(itemProfile?.profile_config)
            setOnChangeValue(itemProfile?.profile_config?.marketing_email)
        }
        if (JSON.parse(JSON.stringify(itemProfile))?.w4_file) {
            setFiles({file_url: JSON.parse(JSON.stringify(itemProfile))?.w4_file})
        }
    }, [itemProfile]);
    useEffect(() => {
        setLoadingUpload(itemIsUpload)
    }, [itemIsUpload]);

    useEffect(() => {
        if ((itemGeneral?.popup || []).length > 0) {
            let items = (itemGeneral?.popup || []).filter(i => !i?.is_read)
            setCountNotify(items.length)
        }else{
            setCountNotify(0)
        }
    }, [itemGeneral]);

    useEffect(() => {
        if (isShowNotify) {
            window.addEventListener('click', handleClickOutside);
        }
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [isShowNotify]);

    const handleClickOutside = (event) => {
        if (isShowNotify) {
            setIsShowNotify(false);
        }
    };

    const menu = () => (
        <Menu>
            <Menu.Item onClick={() => setShowProfile(true)}>
                <i className="far fa-user me-2"/> {(itemProfile?.first_name || "") + " " + (itemProfile?.last_name || "")}
            </Menu.Item>
            <Menu.Item onClick={() => {
                setShowProfile(false)
                props?.history.push("/my-subscription")
            }}>
                <i className="fas fa-cart-plus me-2"/> My Subscription
            </Menu.Item>
            <Menu.Item onClick={() => {
                setShowProfile(false)
                props?.history.push("/bill/detail")
            }}>
                <i className="far fa-money-bill-alt me-2"/> My Billing
            </Menu.Item>
            <Menu.Item onClick={() => props?.history.push("/settings")}>
                <i className="fas fa-wrench me-2"/> Setting
            </Menu.Item>
            {/*<Menu.Item className="border-top"><i className="fas fa-history me-2"/><Link to="/my-subscription"*/}
            {/*                                                                            className="text-decoration-none fs--14px">My*/}
            {/*    subscription</Link></Menu.Item>*/}
            {/*<Menu.Item className="border-top"><i className="fas fa-history me-2"/><Link to="/my-transaction"*/}
            {/*                                                                            className="text-decoration-none fs--14px">My*/}
            {/*    transaction</Link></Menu.Item>*/}
            {/*<Menu.Item className="border-top"><i className="fas fa-cubes me-2"/><Link to="/package-subscription"*/}
            {/*                                                                          className="text-decoration-none fs--14px">Package*/}
            {/*    subscription</Link></Menu.Item>*/}
            <Menu.Item className="border-top" onClick={() => onLogout()}><i
                className="fas fa-sign-out-alt me-2"/> Logout</Menu.Item>
        </Menu>
    );

    const onLogout = () => {
        const token = LocalStorage.get(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
        if (token) {
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.PROFILE);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.EXPIRES);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.TEMPLATE_CONTACT);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.TEMPLATE_PAGE);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.CONTACT_SOURCE_FILTER);
            localStorage.removeItem("gjsProject");
        }
        props.history.go("/login")
    }

    const updateProfile = (val) => {
        if (val.password || val.confirm_password) {
            if (val.password === val.confirm_password) {
                getUpdateProfile({...val, id: props?.profile?.id, w4_file: files?.file_url})
            } else {
                message.error("Password does not match")
            }
        } else {
            getUpdateProfile({
                ...val,
                id: props?.profile?.id,
                w4_file: files?.file_url || ""
            })
        }
    }

    const updateProfileConfig = val => {
        actionProfileConfigUpdate(val)
    }

    const changeValue = (val) => {
        if (val?.marketing_email) {
            setOnChangeValue(val?.marketing_email)
        } else if (val?.monday_board) {
            form.setFieldsValue({
                monday_address: "",
                monday_email: "",
                monday_extra_info: "",
                monday_first_name: "",
                monday_last_name: "",
                monday_phone_number: "",
                monday_property_address: "",
                monday_property_city: "",
                monday_property_state: "",
            })
            setListColumnsBoardSelect(find(itemProfile?.profile_config?.monday_config, {board_id: val?.monday_board})?.columns || [])
        }
    }

    const handleChangeImage = info => {
        if (info.file.status === 'uploading') {
            setFiles({})
            setLoadingUpload(true)
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                    setLoadingUpload(false);
                    setImageUrl(imageUrl)
                }
            );
        }
    };

    useEffect(() => {
        if (Object.keys(itemFileUpload).length > 0) {
            setFiles(itemFileUpload)
        }
    }, [itemFileUpload]);

    const onShowNotify = item => {
        props?.onShowNotify(item)
    }

    return (
        <>
            <Modal
                title={isSetting ? "Setting" : `My Profile`}
                width={1000}
                destroyOnClose
                visible={showProfile}
                onOk={() => {
                    setIsSetting(false)
                    setShowProfile(false)
                }}
                onCancel={() => {
                    setIsSetting(false)
                    setShowProfile(false)
                }}
                footer={null}>
                {
                    !isSetting ? (
                        <Form
                            layout="vertical"
                            initialValues={{
                                email: itemProfile?.email,
                                first_name: itemProfile?.first_name,
                                last_name: itemProfile?.last_name,
                                cash: itemProfile?.cash,
                                point: itemProfile?.point,
                                referral_code: itemProfile?.referral_code,
                                membership_code: itemProfile?.membership_code,
                            }}
                            onFinish={updateProfile}
                            // onValuesChange={onRequiredTypeChange}
                            // requiredMark={requiredMark}
                        >
                            <div className="row">
                                <div className="col-12">
                                    <Form.Item label="Email" name="email">
                                        <Input disabled/>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        rules={[{required: true, message: 'Please input your first name'}]}
                                        label="First Name"
                                        name="first_name"
                                    >
                                        <Input/>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        rules={[{required: true, message: 'Please input your last name'}]}
                                        label="Last Name"
                                        name="last_name"
                                    >
                                        <Input/>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        rules={[{type: "string", min: 6}, {whitespace: true}, ({getFieldValue}) => ({
                                            validator(rule, value) {
                                                if (value && (value.length > 1)) {
                                                    if (!/^\s+|\s+$/g.test(value[0]) && !/^\s+|\s+$/g.test(value[value.length - 1])) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject("'password' cannot be empty");
                                                }
                                                return Promise.resolve();
                                            },
                                        })]}
                                    >
                                        <Input.Password/>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        label="Confirm Password"
                                        name="confirm_password"
                                        rules={[{type: "string", min: 6}, {whitespace: true}, ({getFieldValue}) => ({
                                            validator(rule, value) {
                                                if (value && (value.length > 1)) {
                                                    if (!/^\s+|\s+$/g.test(value[0]) && !/^\s+|\s+$/g.test(value[value.length - 1])) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject("'password' cannot be empty");
                                                }
                                                return Promise.resolve();
                                            },
                                        })]}
                                    >
                                        <Input.Password/>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        label="Cash"
                                        name="cash"
                                    >
                                        <Input disabled/>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        label="Point"
                                        name="point"
                                    >
                                        <Input disabled/>
                                    </Form.Item>
                                </div>
                                {/*<div className="col-6">*/}
                                {/*    <Form.Item*/}
                                {/*        label="Referral Code"*/}
                                {/*        name="referral_code"*/}
                                {/*    >*/}
                                {/*        <Input disabled/>*/}
                                {/*    </Form.Item>*/}
                                {/*</div>*/}
                                <div className="col-6">
                                    <Form.Item
                                        label="Membership Code"
                                        name="membership_code"
                                    >
                                        <Input disabled/>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        label=" "
                                    >
                                        <Button type="link" onClick={e => {
                                            props?.onCopyRef(`${process.env.REACT_APP_BASE_URL}/login?ref=${itemProfile.membership_code}&type=r`)
                                            // navigator?.clipboard?.writeText(`${process.env.REACT_APP_BASE_URL}login?ref=${itemProfile.membership_code}&type=r`).then(r => message.success("Copied!"))
                                        }}>
                                            Referral link
                                        </Button>
                                    </Form.Item>
                                </div>
                                {/*<div className="col-6">*/}
                                {/*    <Form.Item*/}
                                {/*        label="Api Key"*/}
                                {/*        name="api_key"*/}
                                {/*    >*/}
                                {/*        <Input disabled/>*/}
                                {/*    </Form.Item>*/}
                                {/*</div>*/}
                                <div className="col-12">
                                    <Upload
                                        name="file"
                                        // listType="picture"
                                        // className="avatar-uploader h-100 profile--upload upload-full-wrap"
                                        showUploadList={false}
                                        action={(files) => actionUpload({is_default: true, files})}
                                        customRequest={() => {
                                        }}
                                        // beforeUpload={beforeUpload}
                                        onChange={handleChangeImage}
                                    >
                                        {loadingUpload ? <>
                                            <LoadingOutlined/>
                                        </> : (
                                            <>
                                                <p className="mb-1">W4 File</p>
                                                <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                                            </>
                                        )}
                                    </Upload>
                                    <div className="mt-2">
                                        {
                                            (files?.file_url) ?
                                                <Space>
                                                    <PaperClipOutlined className="cursorPointer icon-ant"/>
                                                    <p className="link-success cursorPointer"
                                                       onClick={e => window.open(files?.file_url, "_blank")}>Uploaded
                                                        File</p>
                                                    <Popconfirm
                                                        title="Are you sure to delete this file?"
                                                        onConfirm={e => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            setFiles({})
                                                        }}
                                                        onCancel={e => {
                                                        }}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <DeleteOutlined className="icon-ant"/>
                                                    </Popconfirm>
                                                </Space> : null
                                        }
                                    </div>
                                </div>
                                <div className="col-6">
                                </div>
                                <div className="col-6">
                                    <Form.Item className="text-end mb-0">
                                        <Button type="primary" disabled={itemFetching} htmlType="submit"
                                                className="me-3 mb-0">
                                            Submit {itemFetching && <Icon type="sync-outlined" spin/>}
                                        </Button>
                                        <Button htmlType="button"
                                                className="mb-0"
                                                onClick={() => setShowProfile(false)}>
                                            Cancel
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    ) : (
                        <Spin spinning={itemStatusAction?.profileConfigUpdate || itemStatusAction?.isLoadProfileConfig}>
                            <VerifyEmailForm
                                itemProfile={onChangeValue}
                                isVerifyEmailForm={isVerifyEmailForm}
                                setIsVerifyEmailForm={e => setIsVerifyEmailForm(e)}
                            />
                            <Form
                                layout="vertical"
                                onFinish={updateProfileConfig}
                                onValuesChange={changeValue}
                                form={form}
                            >
                                <div className="row">
                                    <div className="col-12"><Divider className="fs--22px mt-0">Email Marketing</Divider>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your first name'}]}
                                            className={`${(itemProfile?.profile_config?.marketing_email && !itemProfile?.profile_config?.marketing_email_valid) ? "verify__form-input--warning verify__form-message--warning" : ""} `}
                                            label="Email"
                                            name="marketing_email"
                                            // className={(itemProfile?.profile_config?.marketing_email && !itemProfile?.profile_config?.marketing_email?.marketing_email_valid) ? "icon-ant" : ""}
                                            // validateStatus={(itemProfile?.profile_config?.marketing_email && !itemProfile?.profile_config?.marketing_email?.marketing_email_valid) ? "error" : ""}
                                            // hasFeedback
                                            help={(itemProfile?.profile_config?.marketing_email && !itemProfile?.profile_config?.marketing_email_valid) ? "Please verify your email!" : ""}
                                        >
                                            <Input
                                                suffix={itemProfile?.profile_config?.marketing_email ? !(itemProfile?.profile_config?.marketing_email && !itemProfile?.profile_config?.marketing_email_valid) ? (
                                                    <Tooltip title="Email Verified">
                                                        <CheckOutlined className="cursorPointer" style={{
                                                            color: '#52c41a',
                                                        }}/>
                                                    </Tooltip>
                                                ) : <Tooltip title="Email Verify">
                                                    <SendOutlined className="cursorPointer"
                                                                  onClick={e => setIsVerifyEmailForm(true)} style={{
                                                        color: '#FCCE01',
                                                    }}/>
                                                    {/*<LoadingOutlined />*/}
                                                </Tooltip> : ""}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12"><Divider className="fs--22px mt-2">SMS Twilio
                                        Marketing</Divider></div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="SMS Account ID"
                                            name="sms_account_sid"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="SMS Token"
                                            name="sms_token"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="SMS Number"
                                            name="sms_phone_number"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="SMS Webhook"
                                            name="sms_webhook"
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Divider className="fs--22px mt-2">REIQ Synchronize</Divider>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="API Key"
                                            name="reiq_api_key"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="First Name"
                                            name="reiq_first_name"
                                        >
                                            <Select
                                                // mode="multiple"
                                                showSearch
                                                optionFilterProp="children"
                                                allowClear
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                {
                                                    Object?.keys(itemSettingMaster?.reiq || {}).length > 0 && Object?.keys(itemSettingMaster?.reiq || {} || {}).map((i, k) => {
                                                        return (
                                                            <Select.Option key={k}
                                                                           value={i}>{itemSettingMaster?.reiq[i]}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Last Name"
                                            name="reiq_last_name"
                                        >
                                            <Select
                                                // mode="multiple"
                                                showSearch
                                                optionFilterProp="children"
                                                allowClear
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                {
                                                    Object?.keys(itemSettingMaster?.reiq || {}).length > 0 && Object?.keys(itemSettingMaster?.reiq || {} || {}).map((i, k) => {
                                                        return (
                                                            <Select.Option key={k}
                                                                           value={i}>{itemSettingMaster?.reiq[i]}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Full Address"
                                            name="reiq_address"
                                        >
                                            <Select
                                                // mode="multiple"
                                                showSearch
                                                optionFilterProp="children"
                                                allowClear
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                {
                                                    Object?.keys(itemSettingMaster?.reiq || {}).length > 0 && Object?.keys(itemSettingMaster?.reiq || {} || {}).map((i, k) => {
                                                        return (
                                                            <Select.Option key={k}
                                                                           value={i}>{itemSettingMaster?.reiq[i]}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>


                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Property Address"
                                            name="reiq_property_address"
                                        >
                                            <Select
                                                // mode="multiple"
                                                showSearch
                                                optionFilterProp="children"
                                                allowClear
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                {
                                                    Object?.keys(itemSettingMaster?.reiq || {}).length > 0 && Object?.keys(itemSettingMaster?.reiq || {} || {}).map((i, k) => {
                                                        return (
                                                            <Select.Option key={k}
                                                                           value={i}>{itemSettingMaster?.reiq[i]}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Property City"
                                            name="reiq_property_city"
                                        >
                                            <Select
                                                // mode="multiple"
                                                showSearch
                                                optionFilterProp="children"
                                                allowClear
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                {
                                                    Object?.keys(itemSettingMaster?.reiq || {}).length > 0 && Object?.keys(itemSettingMaster?.reiq || {} || {}).map((i, k) => {
                                                        return (
                                                            <Select.Option key={k}
                                                                           value={i}>{itemSettingMaster?.reiq[i]}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Property State"
                                            name="reiq_property_state"
                                        >
                                            <Select
                                                // mode="multiple"
                                                showSearch
                                                optionFilterProp="children"
                                                allowClear
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                {
                                                    Object?.keys(itemSettingMaster?.reiq || {}).length > 0 && Object?.keys(itemSettingMaster?.reiq || {} || {}).map((i, k) => {
                                                        return (
                                                            <Select.Option key={k}
                                                                           value={i}>{itemSettingMaster?.reiq[i]}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Property Zip Code"
                                            name="reiq_property_zip_code"
                                        >
                                            <Select
                                                // mode="multiple"
                                                showSearch
                                                optionFilterProp="children"
                                                allowClear
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                {
                                                    Object?.keys(itemSettingMaster?.reiq || {}).length > 0 && Object?.keys(itemSettingMaster?.reiq || {} || {}).map((i, k) => {
                                                        return (
                                                            <Select.Option key={k}
                                                                           value={i}>{itemSettingMaster?.reiq[i]}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Email"
                                            name=""
                                        >
                                            <Select
                                                // mode="multiple"
                                                showSearch
                                                optionFilterProp="children"
                                                allowClear
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                {
                                                    Object?.keys(itemSettingMaster?.reiq || {}).length > 0 && Object?.keys(itemSettingMaster?.reiq || {} || {}).map((i, k) => {
                                                        return (
                                                            <Select.Option key={k}
                                                                           value={i}>{itemSettingMaster?.reiq[i]}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Phone Number"
                                            name="reiq_phone_number"
                                        >
                                            <Select
                                                // mode="multiple"
                                                showSearch
                                                optionFilterProp="children"
                                                allowClear
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                {
                                                    Object?.keys(itemSettingMaster?.reiq || {}).length > 0 && Object?.keys(itemSettingMaster?.reiq || {} || {}).map((i, k) => {
                                                        return (
                                                            <Select.Option key={k}
                                                                           value={i}>{itemSettingMaster?.reiq[i]}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Extra Info"
                                            name="reiq_extra_info"
                                        >
                                            <Select
                                                mode="multiple"
                                                showSearch
                                                optionFilterProp="children"
                                                allowClear
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                {
                                                    Object?.keys(itemSettingMaster?.reiq || {}).length > 0 && Object?.keys(itemSettingMaster?.reiq || {} || {}).map((i, k) => {
                                                        return (
                                                            <Select.Option key={k}
                                                                           value={i}>{itemSettingMaster?.reiq[i]}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div className="col-12">
                                        <Divider className="fs--22px mt-2">Monday Synchronize</Divider>
                                    </div>
                                    <div className="col-6">
                                        {
                                            (itemProfile?.profile_config?.monday_config || []).length < 1 ?
                                                <Alert message="API Key required and must be a valid key"
                                                       type="error" className="mb-2"/> : null
                                        }
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="API Key"
                                            name="monday_api_key"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    {
                                        (itemProfile?.profile_config?.monday_config || []).length > 0 ? (
                                            <>
                                                <div className="col-6">
                                                    <Form.Item
                                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                                        label="Board"
                                                        name="monday_board"
                                                    >
                                                        <Select
                                                            // mode="multiple"
                                                            showSearch
                                                            optionFilterProp="children"
                                                            allowClear
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            {
                                                                (itemProfile?.profile_config?.monday_config || []).length > 0 && (itemProfile?.profile_config?.monday_config || []).map((i, k) => {
                                                                    return (
                                                                        <Select.Option key={k}
                                                                                       value={i?.board_id}>{i?.name}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </div>

                                                <div className="col-6">
                                                    <Form.Item
                                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                                        label="Full Address"
                                                        name="monday_address"
                                                    >
                                                        <Select
                                                            // mode="multiple"
                                                            showSearch
                                                            optionFilterProp="children"
                                                            allowClear
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            {
                                                                listColumnsBoardSelect.length > 0 && listColumnsBoardSelect.map((i, k) => {
                                                                    return (
                                                                        <Select.Option key={k}
                                                                                       value={i?.key}>{i?.name}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-6">
                                                    <Form.Item
                                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                                        label="Email"
                                                        name="monday_email"
                                                    >
                                                        <Select
                                                            // mode="multiple"
                                                            showSearch
                                                            optionFilterProp="children"
                                                            allowClear
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            {
                                                                listColumnsBoardSelect.length > 0 && listColumnsBoardSelect.map((i, k) => {
                                                                    return (
                                                                        <Select.Option key={k}
                                                                                       value={i?.key}>{i?.name}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-6">
                                                    <Form.Item
                                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                                        label="Extra Info"
                                                        name="monday_extra_info"
                                                    >
                                                        <Select
                                                            // mode="multiple"
                                                            showSearch
                                                            optionFilterProp="children"
                                                            allowClear
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            {
                                                                listColumnsBoardSelect.length > 0 && listColumnsBoardSelect.map((i, k) => {
                                                                    return (
                                                                        <Select.Option key={k}
                                                                                       value={i?.key}>{i?.name}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-6">
                                                    <Form.Item
                                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                                        label="First Name"
                                                        name="monday_first_name"
                                                    >
                                                        <Select
                                                            // mode="multiple"
                                                            showSearch
                                                            optionFilterProp="children"
                                                            allowClear
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            {
                                                                listColumnsBoardSelect.length > 0 && listColumnsBoardSelect.map((i, k) => {
                                                                    return (
                                                                        <Select.Option key={k}
                                                                                       value={i?.key}>{i?.name}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-6">
                                                    <Form.Item
                                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                                        label="Last Name"
                                                        name="monday_last_name"
                                                    >
                                                        <Select
                                                            // mode="multiple"
                                                            showSearch
                                                            optionFilterProp="children"
                                                            allowClear
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            {
                                                                listColumnsBoardSelect.length > 0 && listColumnsBoardSelect.map((i, k) => {
                                                                    return (
                                                                        <Select.Option key={k}
                                                                                       value={i?.key}>{i?.name}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-6">
                                                    <Form.Item
                                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                                        label="Phone Number"
                                                        name="monday_phone_number"
                                                    >
                                                        <Select
                                                            // mode="multiple"
                                                            showSearch
                                                            optionFilterProp="children"
                                                            allowClear
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            {
                                                                listColumnsBoardSelect.length > 0 && listColumnsBoardSelect.map((i, k) => {
                                                                    return (
                                                                        <Select.Option key={k}
                                                                                       value={i?.key}>{i?.name}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-6">
                                                    <Form.Item
                                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                                        label="Property Address"
                                                        name="monday_property_address"
                                                    >
                                                        <Select
                                                            // mode="multiple"
                                                            showSearch
                                                            optionFilterProp="children"
                                                            allowClear
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            {
                                                                listColumnsBoardSelect.length > 0 && listColumnsBoardSelect.map((i, k) => {
                                                                    return (
                                                                        <Select.Option key={k}
                                                                                       value={i?.key}>{i?.name}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-6">
                                                    <Form.Item
                                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                                        label="Property City"
                                                        name="monday_property_city"
                                                    >
                                                        <Select
                                                            // mode="multiple"
                                                            showSearch
                                                            optionFilterProp="children"
                                                            allowClear
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            {
                                                                listColumnsBoardSelect.length > 0 && listColumnsBoardSelect.map((i, k) => {
                                                                    return (
                                                                        <Select.Option key={k}
                                                                                       value={i?.key}>{i?.name}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-6">
                                                    <Form.Item
                                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                                        label="Property State"
                                                        name="monday_property_state"
                                                    >
                                                        <Select
                                                            // mode="multiple"
                                                            showSearch
                                                            optionFilterProp="children"
                                                            allowClear
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            {
                                                                listColumnsBoardSelect.length > 0 && listColumnsBoardSelect.map((i, k) => {
                                                                    return (
                                                                        <Select.Option key={k}
                                                                                       value={i?.key}>{i?.name}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-6">
                                                    <Form.Item
                                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                                        label="Property Zip Code"
                                                        name="monday_zip_code"
                                                    >
                                                        <Select
                                                            // mode="multiple"
                                                            showSearch
                                                            optionFilterProp="children"
                                                            allowClear
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            {
                                                                listColumnsBoardSelect.length > 0 && listColumnsBoardSelect.map((i, k) => {
                                                                    return (
                                                                        <Select.Option key={k}
                                                                                       value={i?.key}>{i?.name}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </>
                                        ) : null
                                    }
                                    <div className="col-12">
                                        <Button type="primary"
                                                htmlType="button"
                                                className="me-3 mb-0"
                                                onClick={e => actionImportMonday()}
                                        >
                                            Sync REIQ To Monday {itemContactStatusAction?.isImportMonday &&
                                            <Icon type="sync-outlined" spin/>}
                                        </Button>
                                    </div>


                                    <div className="col-12"><Divider className="fs--22px mt-2">Third Party
                                        Service</Divider></div>
                                    <div className="col-6">
                                        {/*<Form.Item*/}
                                        {/*    // rules={[{required: true, message: 'Please input your last name'}]}*/}
                                        {/*    label="API Key"*/}
                                        {/*    name="megalith_api_key"*/}
                                        {/*>*/}
                                        {/*    <Input/>*/}
                                        {/*</Form.Item>*/}
                                        <Form.Item label="Api Key" name="megalith_api_key">
                                            <Input disabled
                                                   addonAfter={<RetweetOutlined className="icon-ant" onClick={e => {
                                                       if (itemProfile?.profile_config?.megalith_api_key) {
                                                           Modal.confirm({
                                                               title: "Notification",
                                                               icon: <ExclamationCircleOutlined/>,
                                                               content: "Are you sure to change new api key?",
                                                               onOk() {
                                                                   form.setFieldsValue({megalith_api_key: (uuidv4()).replaceAll("-", "")})
                                                               },
                                                               onCancel() {
                                                                   // console.log('Cancel');
                                                               },
                                                           })
                                                       } else {
                                                           form.setFieldsValue({megalith_api_key: (uuidv4()).replaceAll("-", "")})
                                                       }
                                                   }}/>}/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Form.Item className="text-end mb-0">
                                            <Button type="primary" disabled={itemStatusAction?.profileConfigUpdate}
                                                    htmlType="submit"
                                                    className="me-3 mb-0">
                                                Submit {itemStatusAction?.profileConfigUpdate &&
                                                <Icon type="sync-outlined" spin/>}
                                            </Button>
                                            <Button htmlType="button"
                                                    className="mb-0"
                                                    onClick={() => {
                                                        setIsSetting(false)
                                                        setShowProfile(false)
                                                    }}>
                                                Cancel
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                        </Spin>
                    )
                }
            </Modal>
            <div className="hn__header">
                <div className="row align-items-center">
                    <div className="col-7 text-start ps-4">
                        <div className="d-none d-lg-block">
                            <div className="row align-items-center">
                                {/*<div className="col-1 pe-0 width-70px">*/}
                                {/*    <i className="fas fa-bars color--black cursorPointer ms-3 fs--24px hn__header--toggle"*/}
                                {/*       onClick={props.toggleCollapsed}/>*/}
                                {/*</div>*/}
                                <div className="col-2 ps-0">
                                    {/*<img*/}
                                    {/*    src="/layout/logo.svg"*/}
                                    {/*    className="" width="130px" height="60px"/>*/}
                                    <img
                                        src="/layout/logo.png"
                                        className="" width="150px" height="70px"/>
                                </div>
                                <div className="col-10">
                                    <HeaderMenu {...props} />
                                </div>
                            </div>
                        </div>
                        <div className="d-lg-none">
                            <div className="row align-items-center pt-2">
                                <div className="col-2 px-0">
                                    <i className="fas fa-bars color--black cursorPointer ms-3 fa-2x hn__header--toggle"
                                       onClick={props.toggleCollapsed}/>
                                </div>
                                <div className="col-10 text-center">
                                    <img
                                        src="/layout/logo.png"
                                        className="" width="150px" height=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 mt-2 text-end pe-4">
                        <Space>
                            <a href={process.env.REACT_APP_MENU_LINK_A}
                               className="color--primary text-decoration-none" target="_blank">
                                <Button type="primary" htmlType="button"
                                        className="bg--primary border-0 border__radius--6px"
                                >
                                    {process.env.REACT_APP_MENU_LINK_TITLE_A}
                                </Button>
                            </a>
                            <a href={process.env.REACT_APP_MENU_LINK_C}
                               className="color--primary text-decoration-none" target="_blank">
                                <Button type="primary" htmlType="button"
                                        className="bg--primary border-0 border__radius--6px"
                                >
                                    {process.env.REACT_APP_MENU_LINK_TITLE_C}
                                </Button>
                            </a>
                            <a href={process.env.REACT_APP_MENU_LINK_B}
                               className="color--primary text-decoration-none"
                               target="_blank">
                                <Button type="primary" htmlType="button"
                                        className="bg--primary border-0 border__radius--6px"
                                >{process.env.REACT_APP_MENU_LINK_TITLE_B}
                                </Button>
                            </a>
                            {
                                (itemGeneral?.companies || []).length > 0 ? (
                                    <Dropdown overlay={
                                        <Menu onClick={e => {
                                            LocalStorage.set(CONFIG_LOCAL_STORAGE.COMPANIES_SYSTEM, e?.key)
                                            window.location.reload();

                                        }}>
                                            {
                                                (itemGeneral?.companies || []).map((i, k) => {
                                                    return (
                                                        <Menu.Item className="cursorPointer"
                                                                   key={i?.key}>{i?.name}</Menu.Item>
                                                    )
                                                })
                                            }
                                        </Menu>
                                    }>
                                        <Button className="bg-transparent color--white border__radius--5px border-1"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                }}>
                                            <Space>
                                                {`${LocalStorage.get(CONFIG_LOCAL_STORAGE.COMPANIES_SYSTEM) ? find(itemGeneral?.companies, {key: LocalStorage.get(CONFIG_LOCAL_STORAGE.COMPANIES_SYSTEM)})?.name : "Choose Company"}`}
                                                <CaretDownOutlined className="icon-ant m-l--s5px"/>
                                            </Space>
                                        </Button>
                                    </Dropdown>
                                ) : null
                            }
                            <div className="mx-2 position-relative">
                                <Badge
                                    className="cursorPointer"
                                    count={itemGeneral?.cart?.count || 0}
                                    onClick={e => props.history.push("/cart")}>
                                    <i className="fa fa-shopping-cart fs--25px color--white"/>
                                </Badge>
                            </div>
                            <div className="mx-2 position-relative">
                                <Badge
                                    count={itemGeneral?.inbox?.count || 0}
                                    className="cursorPointer"
                                    onClick={e => props.history.push("/inbox")}
                                >
                                    {/*<MailOutlined style={{fontSize: 24}} className="icon-ant color--white" />*/}
                                    <MessageFilled className='color--white fs--25px icon-ant'/>
                                    {/*<i className="fa fa-envelope fs--25px color--white"/>*/}
                                </Badge>
                            </div>
                            <div className="position-relative">
                                <Badge count={countNotify}
                                       className="vertical--middle cursorPointer mx-2" offset={[0, 5]}>
                                    <FaBell size={28} className="color--white"
                                            onClick={e => {
                                                setIsShowNotify(!isShowNotify)
                                            }}/>
                                </Badge>
                                <div className={`hn__header--notify ${isShowNotify ? "" : "d-none"}`}>
                                    <p className="text-start h3 mt-3 mb-1 mx-3 color--black">Notification</p>
                                    <Divider className="my-2"/>
                                    {
                                        (itemGeneral?.popup || []).length > 0 ? (
                                            itemGeneral?.popup.map((item, index) => {
                                                return (
                                                    <div
                                                        // ref={dropdownRef}
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            onShowNotify(item)
                                                        }}
                                                        className="clearfix mx-1 hn__header--notify-item cursorPointer"
                                                        key={index}
                                                    >
                                                        <div className="mx-2 py-2 row align-items-center">
                                                            <div
                                                                className={`col-11 text-start color--black fs--14px ${(!Boolean(item?.is_read)) ? "fw-bold" : ""}`}>
                                                                {item?.title}
                                                            </div>
                                                            <div className="col-1 text-end">
                                                                {
                                                                    (!Boolean(item?.is_read)) ? (
                                                                        <GoDotFill className="color--red"/>
                                                                    ) : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        ) : null
                                    }
                                </div>
                            </div>
                            <Dropdown overlay={menu}>
                                <Avatar size={50} icon={<img style={{cursor: "pointer"}} width={50} height={50}
                                                             src={(itemProfile?.first_name && itemProfile?.last_name) ? `https://ui-avatars.com/api/?bold=true&background=ffffff&name=${itemProfile?.first_name} ${itemProfile?.last_name}` : "https://ui-avatars.com/api/?bold=true&background=ffffff&name=NF"}/>}/>
                            </Dropdown>
                        </Space>
                    </div>
                </div>
            </div>

            {/*<HeaderComponent*/}
            {/*    isCollapse={props?.isCollapse}*/}
            {/*    onCollapseNavigation={e => props?.setIsCollapse(e)}*/}
            {/*    itemLeft={*/}
            {/*        <>*/}
            {/*            <div className="d-none d-lg-block">*/}
            {/*                <div className="row align-items-center">*/}
            {/*                    /!*<div className="col-1 pe-0 width-70px">*!/*/}
            {/*                    /!*    <i className="fas fa-bars color--black cursorPointer ms-3 fs--24px hn__header--toggle"*!/*/}
            {/*                    /!*       onClick={props.toggleCollapsed}/>*!/*/}
            {/*                    /!*</div>*!/*/}
            {/*                    <div className="col-2 ps-3 pt-3">*/}
            {/*                        /!*<img*!/*/}
            {/*                        /!*    src="/layout/logo.svg"*!/*/}
            {/*                        /!*    className="" width="130px" height="60px"/>*!/*/}
            {/*                        <img*/}
            {/*                            src="/layout/logo.svg"*/}
            {/*                            className="" width="108px" height="28px"/>*/}
            {/*                    </div>*/}
            {/*                    <div className="col-10">*/}
            {/*                        /!*<HeaderMenu {...props} />*!/*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="d-lg-none">*/}
            {/*                <div className="row align-items-center pt-2">*/}
            {/*                    <div className="col-2 px-0">*/}
            {/*                        <i className="fas fa-bars color--black cursorPointer ms-3 fa-2x hn__header--toggle"*/}
            {/*                           onClick={props.toggleCollapsed}/>*/}
            {/*                    </div>*/}
            {/*                    <div className="col-10 text-center">*/}
            {/*                        <img*/}
            {/*                            src="/layout/logo.png"*/}
            {/*                            className="" width="150px" height=""/>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </>*/}
            {/*    }*/}
            {/*    itemRight={*/}
            {/*        <Space>*/}
            {/*            {*/}
            {/*                (itemGeneral?.companies || []).length > 0 ? (*/}
            {/*                    <Dropdown overlay={*/}
            {/*                        <Menu onClick={e => {*/}
            {/*                            LocalStorage.set(CONFIG_LOCAL_STORAGE.COMPANIES_SYSTEM, e?.key)*/}
            {/*                            window.location.reload();*/}

            {/*                        }}>*/}
            {/*                            {*/}
            {/*                                (itemGeneral?.companies || []).map((i, k) => {*/}
            {/*                                    return (*/}
            {/*                                        <Menu.Item className="cursorPointer"*/}
            {/*                                                   key={i?.key}>{i?.name}</Menu.Item>*/}
            {/*                                    )*/}
            {/*                                })*/}
            {/*                            }*/}
            {/*                        </Menu>*/}
            {/*                    }>*/}
            {/*                        <Button className="bg-transparent color--white border__radius--5px border-1"*/}
            {/*                                onClick={(e) => {*/}
            {/*                                    e.preventDefault()*/}
            {/*                                }}>*/}
            {/*                            <Space>*/}
            {/*                                {`${LocalStorage.get(CONFIG_LOCAL_STORAGE.COMPANIES_SYSTEM) ? find(itemGeneral?.companies, {key: LocalStorage.get(CONFIG_LOCAL_STORAGE.COMPANIES_SYSTEM)})?.name : "Choose Company"}`}*/}
            {/*                                <CaretDownOutlined className="icon-ant m-l--s5px"/>*/}
            {/*                            </Space>*/}
            {/*                        </Button>*/}
            {/*                    </Dropdown>*/}
            {/*                ) : null*/}
            {/*            }*/}
            {/*            <div className="mx-2 position-relative">*/}
            {/*                <Badge*/}
            {/*                    className="cursorPointer"*/}
            {/*                    count={itemGeneral?.cart?.count || 0}*/}
            {/*                    onClick={e => props.history.push("/cart")}>*/}
            {/*                    <i className="fa fa-shopping-cart fs--25px color--white"/>*/}
            {/*                </Badge>*/}
            {/*            </div>*/}
            {/*            <div className="mx-2 position-relative">*/}
            {/*                <Badge*/}
            {/*                    count={itemGeneral?.inbox?.count || 0}*/}
            {/*                    className="cursorPointer"*/}
            {/*                    onClick={e => props.history.push("/inbox")}*/}
            {/*                >*/}
            {/*                    /!*<MailOutlined style={{fontSize: 24}} className="icon-ant color--white" />*!/*/}
            {/*                    <MessageFilled className='color--white fs--25px icon-ant'/>*/}
            {/*                    /!*<i className="fa fa-envelope fs--25px color--white"/>*!/*/}
            {/*                </Badge>*/}
            {/*            </div>*/}
            {/*            <Dropdown overlay={menu}>*/}
            {/*                <Avatar size={50} icon={<img style={{cursor: "pointer"}} width={50} height={50}*/}
            {/*                                             src={(itemProfile?.first_name && itemProfile?.last_name) ? `https://ui-avatars.com/api/?bold=true&background=ffffff&name=${itemProfile?.first_name} ${itemProfile?.last_name}` : "https://ui-avatars.com/api/?bold=true&background=ffffff&name=NF"}/>}/>*/}
            {/*            </Dropdown>*/}
            {/*        </Space>*/}
            {/*    }/>*/}
        </>
    )
}

export default withRouter(Header);
