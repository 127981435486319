export const BILL_MASTER_ACTION = "BILL_MASTER_ACTION";
export const BILL_MASTER_SUCCESS = "BILL_MASTER_SUCCESS";

export const BILL_LIST_ACTION = "BILL_LIST_ACTION";
export const BILL_LIST_SUCCESS = "BILL_LIST_SUCCESS";

export const BILL_DETAIL_ACTION = "BILL_DETAIL_ACTION";
export const BILL_DETAIL_SUCCESS = "BILL_DETAIL_SUCCESS";

export const BILL_UPDATE_ACTION = "BILL_UPDATE_ACTION";
export const BILL_UPDATE_SUCCESS = "BILL_UPDATE_SUCCESS";

export const BILL_CREATE_ACTION = "BILL_CREATE_ACTION";
export const BILL_CREATE_SUCCESS = "BILL_CREATE_SUCCESS";

export const BILL_INIT = "BILL_INIT";
export const BILL_FAIL = "BILL_FAIL";
