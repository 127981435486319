export const EMAIL_HISTORY_MASTER_ACTION = "EMAIL_HISTORY_MASTER_ACTION";
export const EMAIL_HISTORY_MASTER_SUCCESS = "EMAIL_HISTORY_MASTER_SUCCESS";

export const EMAIL_HISTORY_LIST_ACTION = "EMAIL_HISTORY_LIST_ACTION";
export const EMAIL_HISTORY_LIST_SUCCESS = "EMAIL_HISTORY_LIST_SUCCESS";

export const EMAIL_HISTORY_DETAIL_ACTION = "EMAIL_HISTORY_DETAIL_ACTION";
export const EMAIL_HISTORY_DETAIL_SUCCESS = "EMAIL_HISTORY_DETAIL_SUCCESS";

export const EMAIL_HISTORY_UPDATE_ACTION = "EMAIL_HISTORY_UPDATE_ACTION";
export const EMAIL_HISTORY_UPDATE_SUCCESS = "EMAIL_HISTORY_UPDATE_SUCCESS";

export const EMAIL_HISTORY_CREATE_ACTION = "EMAIL_HISTORY_CREATE_ACTION";
export const EMAIL_HISTORY_CREATE_SUCCESS = "EMAIL_HISTORY_CREATE_SUCCESS";

export const EMAIL_HISTORY_VERIFY_EMAIL_ACTION = "EMAIL_HISTORY_VERIFY_EMAIL_ACTION";
export const EMAIL_HISTORY_VERIFY_EMAIL_SUCCESS = "EMAIL_HISTORY_VERIFY_EMAIL_SUCCESS";

export const EMAIL_HISTORY_INIT = "EMAIL_HISTORY_INIT";
export const EMAIL_HISTORY_FAIL = "EMAIL_HISTORY_FAIL";
