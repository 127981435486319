import {combineReducers} from "redux";
import authReducer from './authReducer';
import FastCMAReducer from "./fastCMAReducer";
import teamsReducer from "./teamsReducer";
import teamPointReducer from "./teamPointReducer";
import contactReducer from "./contactReducer";
import uploadReducer from "./uploadReducer";
import tagReducer from "./tagReducer";
import templateReducer from "./templateReducer";
import emailHistoryReducer from "./emailHistoryReducer";
import billReducer from "./billReducer";
import cartReducer from "./cartReducer";
import invoiceReducer from "./invoiceReducer";
import subscriptionReducer from "./subscriptionReducer";
import plansPricingReducer from "./plansPricingReducer";
import smsTemplateReducer from "./smsTemplateReducer";
import smsHistoryReducer from "./smsHistoryReducer";
import inboxReducer from "./inboxReducer";
import workflowReducer from "./workflowReducer";
import embeddedReducer from "./embeddedReducer";
import pageBuildersReducer from "./pageBuildersReducer";
import voiceReducer from "./voiceReducer";
import taskReducer from "./taskReducer";

export default combineReducers({
    authReducer,
    FastCMAReducer,
    teamsReducer,
    teamPointReducer,
    contactReducer,
    uploadReducer,
    tagReducer,
    templateReducer,
    emailHistoryReducer,
    billReducer,
    cartReducer,
    invoiceReducer,
    subscriptionReducer,
    plansPricingReducer,
    smsTemplateReducer,
    smsHistoryReducer,
    inboxReducer,
    workflowReducer,
    embeddedReducer,
    pageBuildersReducer,
    voiceReducer,
    taskReducer,
});
