import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/voiceAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.voiceInitStore(params)),
        [dispatch]
    )
}

export const ActionGetListVoice = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.voiceListAction(filters)),
        [dispatch]
    )
}

export const ActionGetVerify = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.voiceVerifyAction(params)),
        [dispatch]
    )
}

export const ActionMasterData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.voiceMasterAction(params)),
        [dispatch]
    )
}

export const ActionCreateVoice = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.voiceCreateAction(params)),
        [dispatch]
    )
}
export const ActionUpdateVoice = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.voiceUpdateAction(params)),
        [dispatch]
    )
}

export const ActionConfigVoice = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.voiceConfigAction(params)),
        [dispatch]
    )
}
