import React, {useEffect, useState} from "react";
import {Button, Drawer, Form, Input, Modal, Select, Spin, Space, Tooltip, message} from "antd";
import grapesjs from "grapesjs";
import gjsPresetWebpage from "grapesjs-preset-webpage";
import gjsBlockBasic from "grapesjs-blocks-basic";
import gjsNavBar from "grapesjs-navbar";
import gjsForms from 'grapesjs-plugin-forms';
import customCodePlugin from 'grapesjs-custom-code';

import {Icon} from "../../common";
import {common} from "../../../data/mapping/Request/PageBuildersRequest";
import {ActionGetItem, ActionGetList, ActionUpdate} from "../../../data/hooks/pageBuilders";
import {selectItem, selectFetching, selectStatusAction, selectList} from "../../../data/reselects/pageBuildersSelector";
import {selectList as selectListEmbedded} from "../../../data/reselects/embeddedSelector";
import {useSelector} from "react-redux";
import TemplateContactForm from "./components/TemplateContactForm";
import * as LocalStorage from "../../../utils/localStorage";
import {CONFIG_LOCAL_STORAGE} from "../../../constants/define";
import queryString from "query-string";
import {AiOutlineGlobal} from "react-icons/ai"

const Index = props => {
    const [form] = Form.useForm(),
        [editor, setEditor] = useState(null),
        [isInfoOpen, setIsInfoOpen] = useState(false),
        [isModalTemplateContact, setIsModalTemplateContact] = useState(false),
        [templateContactSelected, setTemplateContactSelected] = useState(""),
        [templatePageSelected, setTemplatePageSelected] = useState(""),
        [typeAction, setTypeAction] = useState(),
        actionGetList = ActionGetList(),
        actionGetItem = ActionGetItem(),
        actionUpdate = ActionUpdate(),
        itemStatusAction = useSelector(selectStatusAction()),
        itemListTemplate = useSelector(selectList()),
        itemListTemplateEmbedded = useSelector(selectListEmbedded()),
        itemFetching = useSelector(selectFetching()),
        itemDetail = useSelector(selectItem());

    useEffect(() => {
        if (Object.keys(queryString.parse(props?.location?.search || "") || {}).length > 0) {
            setTemplatePageSelected(queryString.parse(props?.location?.search || "")?.template)
        }
        actionGetList();
        actionGetItem();
        setTemplateContactSelected(LocalStorage.get(CONFIG_LOCAL_STORAGE.TEMPLATE_CONTACT) ? LocalStorage.get(CONFIG_LOCAL_STORAGE.TEMPLATE_CONTACT) : "")
        // setTemplatePageSelected(LocalStorage.get(CONFIG_LOCAL_STORAGE.TEMPLATE_PAGE) ? LocalStorage.get(CONFIG_LOCAL_STORAGE.TEMPLATE_PAGE) : "")
    }, []);

    useEffect(() => {
        // window.location.reload()
        if (Object.keys(queryString.parse(props?.location?.search || "") || {}).length > 0) {
            setTemplatePageSelected(queryString.parse(props?.location?.search || "")?.template)
        }
    }, [props?.location]);

    function embedForm(e) {
        // Use the API: https://grapesjs.com/docs/api/
        e.Blocks.add('embed-form', {
            label: 'Form Contact Template',
            media: `<svg viewBox="0 0 24 24">
            <path d="M14.6 16.6l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4m-5.2 0L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4z"></path>
          </svg>`,
            content: [
                `<div class="page-content">
          <div class="container-fluid">
            <div id="formio">Your Form Will Be Here</div>
          </div>
        </div>`
            ],
        });
    }

    useEffect(() => {
        form.setFieldsValue(itemDetail)
        if (itemDetail?.html && !templatePageSelected) {
            localStorage.setItem("gjsProject", itemDetail?.html)
        }
        setTimeout(() => {
            const editor = grapesjs.init({
                container: "#editor",
                plugins: [gjsBlockBasic, gjsPresetWebpage, gjsNavBar, gjsForms, customCodePlugin, embedForm],
                pluginsOpts: {
                    gjsBlockBasic: {},
                    gjsNavBar: {},
                    gjsPresetWebpage: {},
                    gjsForms: {},
                    customCodePlugin: {},
                    embedForm: {},
                },
            })
            // editor.loadProjectData();
            setEditor(editor);
        }, 1000)
    }, [itemDetail]);

    const onSubmit = val => {
        const htmlCode = editor.getHtml();
        const cssCode = editor.getCss();

        const combinedCode = `<style>${cssCode}</style><link rel="stylesheet"
  href="${process.env.REACT_APP_BASE_URL}/layout/assets/plugins/font-awesome/css/font-awesome.min.css">
<link rel="stylesheet"
  href="${process.env.REACT_APP_BASE_URL}/layout/assets/plugins/bootstrap/css/bootstrap.min.css">
<link rel="stylesheet"
  href="${process.env.REACT_APP_BASE_URL}/layout/assets/plugins/formio/css/formio.full.min.css">
<script
  src="${process.env.REACT_APP_BASE_URL}/layout/assets/plugins/formio/js/formio.full.min.js"></script>
<script type="text/javascript">
    document.addEventListener("DOMContentLoaded", () => {
      Formio.createForm(document.getElementById('formio'), "${process.env.REACT_APP_API_URL}/api/v1/forms/share/${templateContactSelected}").then(function(form) {
      
      // Register for the submit event to get the completed submission.
      form.on('submit', function(submission) {
        console.log('Submission was made!', submission);
      });
      
      // Everytime the form changes, this will fire.
      form.on('change', function(changed) {
        console.log('Form was changed', changed);
      });
    });
  });
  </script>${htmlCode}`;
        let valuePageBuilders = localStorage.getItem("gjsProject"),
            queryUpdate = {...val, html: valuePageBuilders, html_raw: combinedCode};
        if (itemListTemplate?.is_edit) {
            queryUpdate = {
                ...queryUpdate,
                is_template: val?.action_type,
                is_reiq_website: ([4, 5].includes(typeAction)) ? 1 : "",
                reiq_website_path: ([4, 5].includes(typeAction)) ? queryUpdate?.reiq_website_path : "",
            }
        }
        actionUpdate({query: queryUpdate})
    }

    const onChangeForm = field => {
        if (field?.action) {
            form.setFieldsValue({
                action_type: field?.action > 1 ? 1 : 0,
                template_id: ((field?.action === 2) || (field?.action === 5)) ? templatePageSelected : ""
            })
            setTypeAction(field?.action)
        }
    }

    return (
        <div>
            <TemplateContactForm
                templateContactSelected={templateContactSelected}
                setTemplateContactSelected={e => setTemplateContactSelected(e)}
                actionModal={e => setIsModalTemplateContact(e)} isOpen={isModalTemplateContact}/>
            <div id="hn-page-builders__navigation-id">
                <Drawer
                    title="Information"
                    // width={500}
                    placement="right"
                    onClose={e => setIsInfoOpen(false)}
                    visible={isInfoOpen}
                    getContainer={() => document.getElementById("hn-page-builders__navigation-id")}
                >
                    <Spin spinning={itemStatusAction?.isSubmit}>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onSubmit}
                            onValuesChange={onChangeForm}
                            // onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <div className="row bg-white py-3 align-items-center">
                                <div className="col-12">
                                    <Form.Item
                                        label="Title"
                                        name="title"
                                        rules={[{required: true, message: 'Please input your title!'}]}
                                    >
                                        <Input allowClear/>
                                    </Form.Item>
                                </div>
                                {
                                    itemListTemplate?.is_edit ? (
                                        <>
                                            <div className="col-12 d-none">
                                                <Form.Item
                                                    label=""
                                                    name="template_id"
                                                >
                                                    <Input allowClear/>
                                                </Form.Item>
                                            </div>
                                            <div className="col-12 d-none">
                                                <Form.Item
                                                    label=""
                                                    name="action_type"
                                                >
                                                    <Input allowClear/>
                                                </Form.Item>
                                            </div>
                                        </>
                                    ) : null
                                }
                                <div className="col-12">
                                    <Form.Item
                                        label="Status"
                                        name="status"
                                        initialValue={0}
                                        rules={[{required: true, message: 'Please select your status!'}]}
                                    >
                                        <Select
                                            className="w-100"
                                            style={{width: 120}}>
                                            {
                                                (common.STATUS).length > 0 && (common.STATUS).map((i, k) => {
                                                    return (
                                                        <Select.Option value={i?.value}
                                                                       key={k}>{i?.label}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                {
                                    itemListTemplate?.is_edit ? (
                                        <div className="col-12">
                                            <Form.Item
                                                label="Action Type"
                                                name="action"
                                            >
                                                <Select
                                                    className="w-100"
                                                    allowClear
                                                    style={{width: 120}}>
                                                    {
                                                        (common.TYPE_EDIT).length > 0 && (common.TYPE_EDIT).map((i, k) => {
                                                            return (
                                                                <Select.Option value={i?.value}
                                                                               key={k}>{i?.label}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    ) : null
                                }
                                {
                                    ([4, 5].includes(typeAction)) ? (
                                        <div className="col-12">
                                            <Form.Item
                                                label="Path"
                                                name="reiq_website_path"
                                            >
                                                <Input/>
                                            </Form.Item>
                                        </div>
                                    ) : null
                                }
                                <div className="col-12">
                                    <Form.Item className="text-end mb-0">
                                        <Button
                                            htmlType="button"
                                            className="mb-0 me-3"
                                            onClick={() => setIsInfoOpen(false)}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="primary"
                                            disabled={itemStatusAction?.isSubmit}
                                            htmlType="submit"
                                            className="mb-0">
                                            Submit
                                            {itemStatusAction?.isSubmit && <Icon type="sync-outlined" spin/>}
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </Spin>
                </Drawer>
            </div>
            <div className="hn-page-builders__navigation mt-1">
                <Space className=" pt--2px">
                    {
                        itemDetail?.code ? (
                            <div className="width-30px">
                                <Tooltip title="Visit your website">
                                    <AiOutlineGlobal
                                        size={25}
                                        className="color--white cursorPointer"
                                        onClick={e => window.open(itemDetail?.code, "_blank")}/>
                                </Tooltip>
                            </div>
                        ) : null
                    }
                    <button className="btn btn-info me-2 color--white height--32px py-0" type="button"
                            onClick={e => props.history.push("/")}>Back home
                    </button>
                    <button className="btn btn-success me-2  height--32px py-0" type="button"
                            onClick={e => setIsInfoOpen(true)}>Next Step
                    </button>
                    {/*<button className="btn btn-danger" type="button" onClick={e => setIsModalTemplateContact(true)}>Template*/}
                    {/*    Form*/}
                    {/*    Contact*/}
                    {/*</button>*/}
                    <div className="width-200px pt--3px">
                        <Select
                            value={templateContactSelected}
                            allowClear
                            // mode="multiple"
                            // disabled
                            onChange={(id, all) => {
                                if (id) {
                                    setTemplateContactSelected(id)
                                    LocalStorage.set(CONFIG_LOCAL_STORAGE.TEMPLATE_CONTACT, id);
                                } else {
                                    setTemplateContactSelected("")
                                    LocalStorage.set(CONFIG_LOCAL_STORAGE.TEMPLATE_CONTACT, "");
                                }
                                // message.success("Selected")
                                // setIsModalOpen(false)
                                // props?.actionModal(false)
                            }}
                            style={{
                                width: '100%',
                            }}
                            placeholder="Choose Template"

                        >
                            <Select.Option value=""
                                           key="-1">Choose Form</Select.Option>
                            {
                                (itemListTemplateEmbedded?.result || []).length > 0 && (itemListTemplateEmbedded?.result).map((i, k) => {
                                    return (
                                        <Select.Option value={i?.id} all={i}
                                                       key={k}>{i?.title}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    <div className="width-300px pt--3px">
                        <Select
                            value={templatePageSelected}
                            allowClear
                            // mode="multiple"
                            // disabled
                            onChange={(id, all) => {
                                if (id) {
                                    localStorage.setItem("gjsProject", all?.all?.html)
                                    props?.history?.push(`/page-builders?template=${id}`)
                                    window.location.reload()
                                } else {
                                    localStorage.setItem("gjsProject", itemDetail?.html)
                                    props?.history?.push(`/page-builders`)
                                    window.location.reload()
                                }
                            }}
                            style={{
                                width: '100%',
                            }}
                            placeholder="Choose Template"

                        >
                            <Select.Option value=""
                                           key="-1">Choose Template</Select.Option>
                            {
                                (itemListTemplate?.result || []).length > 0 && (itemListTemplate?.result).map((i, k) => {
                                    return (
                                        <Select.Option value={i?.id} all={i}
                                                       key={k}>{`${i?.is_reiq_website === 1 ? `[${i?.reiq_website_path}] ` : ""}${i?.title}`}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </Space>
                {/*<button className="btn btn-bg--foreign" type="button" onClick={e => {*/}
                {/*    localStorage.setItem("gjsProject", common.DEFAULT_TEMPLATE);*/}
                {/*    window.reload()*/}
                {/*}}>Template default*/}
                {/*</button>*/}
            </div>
            {
                (Object.keys(itemDetail || {}).length > 0) ? (
                    <div id="editor"></div>
                ) : null
            }
        </div>
    )
}

export default Index
