export const LOGIN_ACTION = "LOGIN_ACTION";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_INIT = "LOGIN_INIT";

export const LOGOUT_ACTION = "LOGOUT_ACTION";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REFRESH_TOKEN_ACTION = "REFRESH_TOKEN_ACTION";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";

export const CHECK_USER_ACTION = "CHECK_USER_ACTION";
export const CHECK_USER_SUCCESS = "CHECK_USER_SUCCESS";

export const REGISTER_ACTION = "REGISTER_ACTION";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

export const PASSWORD_REQUEST_ACTION = "PASSWORD_REQUEST_ACTION";
export const PASSWORD_REQUEST_SUCCESS = "PASSWORD_REQUEST_SUCCESS";

export const PASSWORD_RESET_ACTION = "PASSWORD_RESET_ACTION";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";

export const GENERAL_ACTION = "GENERAL_ACTION";
export const GENERAL_SUCCESS = "GENERAL_SUCCESS";

export const PROFILE_CONFIG_UPDATE_ACTION = "PROFILE_CONFIG_UPDATE_ACTION";
export const PROFILE_CONFIG_UPDATE_SUCCESS = "PROFILE_CONFIG_UPDATE_SUCCESS";

export const SETTING_ACTION = "SETTING_ACTION";
export const SETTING_SUCCESS = "SETTING_SUCCESS";

export const SETTING_MASTER_ACTION = "SETTING_MASTER_ACTION";
export const SETTING_MASTER_SUCCESS = "SETTING_MASTER_SUCCESS";

export const AUTHENTICATION_SSO_ACTION = "AUTHENTICATION_SSO_ACTION";
export const AUTHENTICATION_SSO_SUCCESS = "AUTHENTICATION_SSO_SUCCESS";

export const UPDATE_PROFILE_ACTION = "UPDATE_PROFILE_ACTION";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
