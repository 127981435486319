import HeaderAction from "../../common/layout/HeaderAction";
import React, {useEffect, useState} from "react";
import {Button, Card, Form, Input} from "antd";
import {BsFillTelephoneFill} from "react-icons/bs";
import {ActionConfigVoice, ActionGetVerify} from "../../../data/hooks/voice";
import {selectFetching, selectGetVerify, selectStatusAction} from "../../../data/reselects/voiceSelector";
import {useSelector} from "react-redux";
import Ready from "./components/Ready";
import {Icon} from "../../common";
// import {Device} from '@twilio/voice-sdk';
const {Device} = require('twilio-client');

const states = {
    CONNECTING: "Connecting",
    READY: "Ready",
    INCOMING: "Incoming",
    ON_CALL: "On call",
    OFFLINE: "Offline"
};
const Index = props => {
    const [form] = Form.useForm(),
        actionGetVerify = ActionGetVerify(),
        actionConfigVoice = ActionConfigVoice(),
        itemStatusAction = useSelector(selectStatusAction()),
        itemFetching = useSelector(selectFetching()),
        itemVerify = useSelector(selectGetVerify());
    const [device, setDevice] = useState(null),
        [isVoiceForward, setIsVoiceForward] = useState(0);

    useEffect(() => {
        actionGetVerify();
    }, [])

    useEffect(() => {
        setIsVoiceForward(Number(itemVerify?.is_voice_forward || 0))
    }, [itemVerify])

    return (
        <>
            <HeaderAction
                isActionSignlePage
                title="Voice Call"
                disabledFormatUpperCaseTitle
            />

            <div className="mt-3 container-fluid">
                <div className="text-end mb-3">
                    <Button disabled={itemStatusAction?.isUpdateConfig} type="primary"
                            danger={(isVoiceForward === 1)} htmlType="button"
                            className="me-3"
                            onClick={() => {
                                actionConfigVoice({
                                    query: {
                                        is_voice_forward: (isVoiceForward === 1) ? 0 : 1
                                    }
                                })
                                setIsVoiceForward((isVoiceForward === 1) ? 0 : 1)
                            }}>
                        {
                            (isVoiceForward === 1) ? "Turn Off Call Forward" : "Turn On Call Forward"
                        }{itemStatusAction?.isUpdateConfig && <Icon type="sync-outlined" spin/>}
                    </Button>
                </div>
                <Ready location={props?.location} history={props?.history} getDevice={e => setDevice(e)}
                       token={itemVerify?.token} identify={itemVerify?.identity}/>
            </div>
        </>
    )
}

export default Index
