import {FormBuilder} from "@formio/react";
import React, {useEffect, useState} from "react";
import {Button, Input, message, Modal, Select, Spin, Tabs, Tooltip, Form} from "antd";
import {CopyOutlined} from "@ant-design/icons";
import {ActionMasterData, ActionCreate, ActionUpdate, ActionGetItem, ActionInit} from "../../../../data/hooks/embedded";
import {selectFetching, selectItem, selectMasterData} from "../../../../data/reselects/embeddedSelector";
import {useSelector} from "react-redux";
import {ROUTE_REDIRECT} from "../../../../constants/define";
import {common} from "../../../../data/mapping/Request/ContactRequest";
import TAG_DEFINE from "../../../../constants/common";
import HeaderAction from "../../../common/layout/HeaderAction";
import {find} from "lodash";
import {Icon} from "../../../common";
import {copyToClipboard} from "../../../../utils/commonUtils";

const customComponents = {
    title: 'Custom Fields',
    weight: 10,
    components: {
        selectBox: {
            title: 'Select Box',
            key: '"selector"',
            schema: {
                label: 'Select Box',
                type: 'select',
                key: 'selector',
                width: "100%",
                input: true,
                widget: "choicesjs",
                tableView: true,
            },
        },
        textBox: {
            title: 'Text Box',
            key: 'textBox',
            schema: {
                label: 'Text Box',
                type: 'textfield',
                key: 'textBox',
                input: true
            }
        },
        email: {
            title: 'Email',
            key: 'email',
            schema: {
                label: 'Email',
                type: 'email',
                key: 'email',
                input: true
            }
        },
        textArea: {
            title: 'Text Area',
            key: 'textArea',
            schema: {
                label: "Text Area",
                autoExpand: false,
                tableView: true,
                key: "textArea",
                type: "textarea",
                input: true
            }
        },
        password: {
            title: 'Password',
            key: 'passwordKey',
            schema: {
                label: "Password",
                tableView: false,
                key: "passwordKey",
                type: "password",
                input: true,
                protected: true
            }
        },
        checkbox: {
            title: 'Checkbox',
            key: 'checkboxKey',
            schema: {
                label: "Checkbox",
                tableView: false,
                key: "checkboxKey",
                type: "checkbox",
                input: true
            }
        },
        datetime: {
            title: 'Datetime',
            key: 'datetimeKey',
            schema: {
                label: "Datetime",
                // tableView: false,
                key: "datetimeKey",
                type: "datetime",
                input: true
            }
        },
        button: {
            title: 'Button',
            key: 'buttonKey',
            schema: {
                label: "Button",
                block: true,
                leftIcon: "fa fa-send",
                disableOnInvalid: true,
                tableView: false,
                key: "buttonKey",
                type: "button",
                input: true,
                saveOnEnter: false
            }
        },
        // radioBox: {
        //     title: 'Radio',
        //     key: 'checkboxKey',
        //     schema: {
        //         label: "Radio",
        //         optionsLabelPosition: "right",
        //         customClass: "pl-3",
        //         inline: false,
        //         tableView: false,
        //         values: [
        //             {
        //                 "label": "A",
        //                 "value": "a",
        //                 "shortcut": ""
        //             },
        //             {
        //                 "label": "B",
        //                 "value": "b",
        //                 "shortcut": ""
        //             },
        //             {
        //                 "label": "C",
        //                 "value": "c",
        //                 "shortcut": ""
        //             }
        //         ],
        //         key: "checkboxKey",
        //         type: "radio",
        //         input: true
        //     }
        // },
    }
}

const initSchema = {
    "type": "button",
    "label": "Submit",
    "key": "submit",
    "size": "md",
    "block": false,
    "action": "submit",
    "disableOnInvalid": true,
    "theme": "primary",
    "input": true,
    "placeholder": "",
    "prefix": "",
    "customClass": "",
    "suffix": "",
    "multiple": false,
    "defaultValue": null,
    "protected": false,
    "unique": false,
    "persistent": false,
    "hidden": false,
    "clearOnHide": true,
    "refreshOn": "",
    "redrawOn": "",
    "tableView": false,
    "modalEdit": false,
    "dataGridLabel": true,
    "labelPosition": "top",
    "description": "",
    "errorLabel": "",
    "tooltip": "",
    "hideLabel": false,
    "tabindex": "",
    "disabled": false,
    "autofocus": false,
    "dbIndex": false,
    "customDefaultValue": "",
    "calculateValue": "",
    "calculateServer": false,
    "widget": {"type": "input"},
    "attributes": {},
    "validateOn": "change",
    "validate": {
        "required": false,
        "custom": "",
        "customPrivate": false,
        "strictDateValidation": false,
        "multiple": false,
        "unique": false
    },
    "conditional": {"show": null, "when": null, "eq": ""},
    "overlay": {"style": "", "left": "", "top": "", "width": "", "height": ""},
    "allowCalculateOverride": false,
    "encrypted": false,
    "showCharCount": false,
    "showWordCount": false,
    "properties": {},
    "allowMultipleMasks": false,
    "addons": [],
    "leftIcon": "",
    "rightIcon": "",
    "id": "e0kyad"
}

const Index = props => {
    const [form] = Form.useForm(),
        [jsonSchema, setSchema] = useState({
            action: "/submit-form",
            components: []
        }),
        [typePage, setTypePage] = useState(TAG_DEFINE.ACTION.create),
        [isShare, setIsShare] = useState(false),
        [isFetching, setIsFetching] = useState(false),
        [isUpdate, setIsUpdate] = useState(false),
        [componentsForm, setComponentsForm] = useState({}),
        actionMasterData = ActionMasterData(),
        actionGetItem = ActionGetItem(),
        actionCreate = ActionCreate(),
        actionUpdate = ActionUpdate(),
        actionInit = ActionInit(),
        itemDetail = useSelector(selectItem()),
        itemsFetching = useSelector(selectFetching()),
        itemMaster = useSelector(selectMasterData());

    useEffect(() => {
        const {match} = props;
        if (Object.keys(match.params).length > 0) {
            setIsUpdate(true);
            setTypePage(TAG_DEFINE.ACTION.update)
            actionGetItem({id: match.params?.id, props: props});
        } else {
            setIsUpdate(false)
        }
    }, [props.match]);

    useEffect(() => {
        actionMasterData();

        return () => {
            actionInit({clearDetail: true});
        }
    }, [])

    useEffect(() => {
        if (Object.keys(itemDetail || {}).length > 0) {
            form.setFieldsValue(itemDetail)
            setSchema(itemDetail?.data)
        }
    }, [itemDetail]);

    useEffect(() => {
        setComponentsForm(itemMaster?.components_form)
        setSchema({...jsonSchema, components: [...jsonSchema.components], action: itemMaster?.action_url_form});
    }, [itemMaster])

    useEffect(() => {
        let getComponentsForm = {...itemMaster?.components_form};
        jsonSchema?.components.map(i => {
            if (Object.keys(getComponentsForm).includes(i?.detected_key)) {
                getComponentsForm = {
                    ...getComponentsForm,
                    [i?.detected_key]: {
                        ...getComponentsForm[i?.detected_key],
                        ignore: true,
                    }
                }
            }
        })
        if (!find(jsonSchema?.components, {type: "button"})) {
            setSchema({
                ...jsonSchema,
                components: [...jsonSchema.components, initSchema]
            })
        }
        setComponentsForm(getComponentsForm)
    }, [jsonSchema])

    const onFormChange = (schema) => {
        setSchema({...schema, components: [...schema.components]});
    };

    const onSaveForm = val => {
        if (isUpdate) {
            actionUpdate({query: {...val, ...jsonSchema}, props, id: itemDetail?.id})
        } else {
            actionCreate({query: {...val, ...jsonSchema}, props})
        }
    }

    const onCoppy = val => {
        copyToClipboard(val)
        message.success("Copied")
    }

    const getEmbeddedCode = id => (
`<head>
    <link rel="stylesheet" href="${process.env.REACT_APP_BASE_URL}/layout/assets/plugins/font-awesome/css/font-awesome.min.css">
    <link rel="stylesheet" href="${process.env.REACT_APP_BASE_URL}/layout/assets/plugins/bootstrap/css/bootstrap.min.css">
    <link rel="stylesheet" href="${process.env.REACT_APP_BASE_URL}/layout/assets/plugins/formio/css/formio.full.min.css">
    <script src="${process.env.REACT_APP_BASE_URL}/layout/assets/plugins/formio/js/formio.full.min.js"></script>
</head>
<body>
    <script type="text/javascript">
      document.addEventListener("DOMContentLoaded", () => {
        Formio.createForm(document.getElementById('formio'), "${process.env.REACT_APP_API_URL}/api/v1/forms/share/${id}").then(function(form) {
        
        // Register for the submit event to get the completed submission.
        form.on('submit', function(submission) {
          console.log('Submission was made!', submission);
        });
        
        // Everytime the form changes, this will fire.
        form.on('change', function(changed) {
          console.log('Form was changed', changed);
        });
      });
    });
    </script>

    <div class="page-content">
      <div class="container-fluid">
        <div id="formio"></div>
      </div>
    </div>
</body>
`);

    return (
        <>
            <HeaderAction title={`Embedded Form ${TAG_DEFINE.ACTION[typePage.toLowerCase()]}`}/>
            <Spin spinning={itemsFetching}>
                <div id="hn__embedded-form--model-share-id">
                    <Modal
                        title="Embed your form"
                        visible={isShare}
                        onOk={() => setIsShare(false)}
                        onCancel={() => setIsShare(false)}
                        getContainer={() => document.getElementById("hn__embedded-form--model-share-id")}
                    >
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab="Share Link" key="1">
                                <p className="text-center m-auto mb-2">
                                    Start sharing your form with contacts. Your form has been securely published and
                                    ready
                                    to use at the web address below.
                                </p>
                                <Input.Group compact className="w-100">
                                    <Input
                                        disabled
                                        style={{
                                            width: '90%',
                                        }}
                                        defaultValue={`${process.env.REACT_APP_BASE_URL}/embedded-form/share?src=${process.env.REACT_APP_API_URL}/api/v1/forms/share/${props?.match?.params?.id}`}
                                    />
                                    <Tooltip title="Copy Share Link">
                                        <Button icon={<CopyOutlined/>}
                                                onClick={e => onCoppy(`${process.env.REACT_APP_BASE_URL}/embedded-form/share?src=${process.env.REACT_APP_API_URL}/api/v1/forms/share/${props?.match?.params?.id}`)}/>
                                    </Tooltip>
                                </Input.Group>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Embed code" key="2">
                                <p className="text-center m-auto mb-2">
                                    To embed this form, simply copy and paste the code below into the HTML code on your
                                    website.
                                </p>
                                <Input.Group compact className="w-100">
                                    <Input.TextArea
                                        disabled
                                        style={{
                                            width: '90%',
                                            height: '32px'
                                        }}
                                        defaultValue={getEmbeddedCode(props?.match?.params?.id)}
                                    />
                                    <Tooltip title="Copy Embeded Code">
                                        <Button icon={<CopyOutlined/>}
                                                onClick={e => onCoppy(getEmbeddedCode(props?.match?.params?.id))}/>
                                    </Tooltip>
                                </Input.Group>
                            </Tabs.TabPane>
                        </Tabs>
                    </Modal>
                </div>
                <div className="container py-5">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onSaveForm}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <div className="col-12 text-end">
                            <Button htmlType="button" className="me-3"
                                    onClick={e => props.history.push(ROUTE_REDIRECT.EMBEDDED.HOME)}
                                    disabled={isFetching}>
                                Back
                            </Button>
                            {
                                isUpdate ? (
                                    <Button htmlType="button" className="me-3" onClick={e => setIsShare(true)}
                                            disabled={isFetching}>
                                        Share
                                    </Button>
                                ) : null
                            }
                            <Button type="primary" htmlType="submit" disabled={isFetching}>
                                {/*<Button type="primary" htmlType="button" onClick={onSaveForm}>*/}
                                Save {isFetching && <Icon type="sync-outlined" spin/>}
                            </Button>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <Form.Item
                                    label="Title"
                                    name="title"
                                    rules={[{
                                        required: true,
                                        message: 'Please input your title!'
                                    }]}
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="col-4">
                                <Form.Item
                                    label="Type"
                                    name="type"
                                    rules={[{required: true, message: 'Please select your type!'}]}
                                >
                                    <Select
                                        className="w-100"
                                        style={{width: 120}}>
                                        {
                                            (Object.keys(itemMaster)).length > 0 && (Object.keys(itemMaster?.type || {})).map((i, k) => {
                                                return (
                                                    <Select.Option value={itemMaster?.type[i]}
                                                                   key={k}>{i}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-4">
                                <Form.Item
                                    label="Status"
                                    name="status"
                                    rules={[{required: true, message: 'Please select your status!'}]}
                                    initialValue={0}
                                >
                                    <Select
                                        className="w-100"
                                        style={{width: 120}}>
                                        {
                                            (Object.keys(itemMaster)).length > 0 && (Object.keys(itemMaster?.status || {})).map((i, k) => {
                                                return (
                                                    <Select.Option value={itemMaster?.status[i]}
                                                                   key={k}>{i}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                    {
                        (Object.keys(componentsForm || {}).length > 0) ? (
                            <div className="hn__embedded--wrapper">
                                <FormBuilder
                                    key={JSON.stringify(componentsForm)}
                                    form={jsonSchema}
                                    onChange={onFormChange}
                                    options={{
                                        alwaysConfirmComponentRemoval: true,
                                        noNewEdit: true,
                                        showFullJsonSchema: true,
                                        builder: {
                                            basic: false,
                                            resource: false,
                                            advanced: false,
                                            premium: false,
                                            layout: false,
                                            data: false,
                                            custom: {
                                                title: 'Contact',
                                                weight: 10,
                                                default: true,
                                                components: {
                                                    ...componentsForm
                                                }
                                            }
                                        },
                                    }}
                                />
                            </div>
                        ) : null
                    }
                </div>
            </Spin>
        </>
    )
}

export default Index
