import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"

export default class UserRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || undefined;
        this.avatar = data?.avatar || undefined;
        this.username = data?.username || undefined;
        this.email = data?.email || undefined;
        this.password = data?.password || undefined;
        this.first_name = data?.first_name || undefined;
        this.last_name = data?.last_name || undefined;
        this.ref = data?.ref || undefined;
        this.status = data?.status || 1;
    }

    actionRegister() {
        return {
            email: this.email,
            password: this.password,
            first_name: this.first_name,
            last_name: this.last_name,
            ref: this.ref,
        }
    }
}
