import React, {useEffect, useState} from "react";
import {Button, Form, Input, Modal, Select, Spin} from "antd";
import {isEmail} from "../../../../utils/validation";
import {COUNTRY_CODE} from "../../../../constants/define";
import {Icon} from "../../../common";
import {ActionCreateNote, ActionUpdateNote} from "../../../../data/hooks/contact";
import {selectStatusAction} from "../../../../data/reselects/contactSelector";
import {useSelector} from "react-redux";

const ModalCreateNote = props => {
    const [form] = Form.useForm(),
        [isModalOpen, setIsModalOpen] = useState(false),
        [editContent, setEditContent] = useState(""),
        actionCreateNote = ActionCreateNote(),
        actionUpdateNote = ActionUpdateNote(),
        itemStatusAction = useSelector(selectStatusAction());

    useEffect(() => {
        setIsModalOpen(!!props?.editContent)
        setEditContent(props?.editContent)
        if (props?.editContent) {
            form.setFieldsValue({
                note: props?.editContent
            })
        }
    }, [props?.editContent]);

    useEffect(() => {
        setIsModalOpen(props?.isOpen)
    }, [props?.isOpen]);

    useEffect(() => {
        if (itemStatusAction?.createNoteSuccess) {
            form.resetFields()
            setIsModalOpen(false)
            props?.isClose(false)
        }
    }, [itemStatusAction]);

    useEffect(() => {
        form.resetFields()
        return () => {
            setEditContent("")
            form.resetFields()
            setIsModalOpen(false)
        }
    }, []);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        form.resetFields()
        setIsModalOpen(false);
        props?.isClose(false)
    };

    const onSubmit = val => {
        if (editContent) {
            actionUpdateNote({
                ...props?.isAction,
                query: {...val, id: props?.isAction?.id, time: props?.time},
            })
        } else {
            actionCreateNote({
                ...props?.isAction,
                query: {...val, id: props?.isAction?.id},
            })
        }
    }

    return (
        <Modal
            title={`${props?.editContent ? "Update" : "Create"} Note`}
            maskClosable={false}
            destroyOnClose
            visible={isModalOpen}
            onOk={handleOk}
            footer={null}
            onCancel={handleCancel}>
            <Spin spinning={itemStatusAction?.isCreateNote}>
                <Form
                    layout="vertical"
                    onFinish={onSubmit}
                    form={form}
                >
                    <div className="row">
                        <div className="col-12">
                            <Form.Item
                                className="w-100"
                                label="Note"
                                name="note"
                            >
                                <Input.TextArea/>
                            </Form.Item>
                        </div>
                        <div className="col-12">
                            <Form.Item className="text-end mb-0">
                                <Button type="primary"
                                        htmlType="submit"
                                        className="me-3 mb-0">
                                    Submit
                                </Button>
                                <Button htmlType="button"
                                        className="mb-0"
                                        onClick={() => {
                                            form.resetFields()
                                            setIsModalOpen(false)
                                            props?.isClose(false)
                                        }}>
                                    Cancel
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </Spin>
        </Modal>
    )
}

export default ModalCreateNote
