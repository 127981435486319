import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    removeObjectNull, removeObjectNullFull, setParamsUrlFilter
} from "../../../utils/functions";
import {omit} from "lodash";
import TAG_DEFINE from "../../../constants/common";
import {PATHS} from "../../../constants/define";

// ============== LAYOUT ==============
import HeaderAction from "../../common/layout/HeaderAction";
import {Icon} from "../../common";
import ListComponent from "../../common/ListComponent";
import {Tooltip, Button, Tag, Form, Input, Select, Checkbox, Modal, Upload, message, Space, Popconfirm} from 'antd'

// ============== ACTION ==============
import {
    ActionGetList,
    ActionMasterData,
    ActionManualTrigger,
    ActionDetailLog,
    ActionInit
} from '../../../data/hooks/workflow';
import {ActionUpload} from '../../../data/hooks/upload';

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectErrors,
    selectList,
    selectMasterData,
    selectDetailLog
} from '../../../data/reselects/workflowSelector';
import WorkflowResponse, {
    column,
    detailLogColumn,
    filtersResponse, WorkflowDetailLogResponse
} from "../../../data/mapping/Response/WorkflowResponse";
import {ROUTE_REDIRECT} from "../../../constants/define";
import queryString from "query-string";
import {common} from "../../../data/mapping/Request/WorkflowRequest";

const ModelFields = Object.getOwnPropertyNames(new WorkflowResponse());
const Index = props => {
    const namePage = "workflow",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(column, ['action'], []),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        [form] = Form.useForm(),
        componentTable = {
            tag: {
                width: 150,
                render: (val, record) => (val || []).join(", "),
            },
            // status: {
            //     width: 150,
            //     render: (val, record) => Object.keys(itemMasterData).length > 0 && (itemMasterData?.status[val]),
            // },
            action: {
                width: 100,
                align: 'center',
                key: 'action',
                fixed: 'right',
                render: (text, record) => {
                    return (
                        <>
                            <Popconfirm
                                title="Are you sure to run this workflow?"
                                onConfirm={() => {
                                    actionManualTrigger({query: {id: record.id}, filters: paramsFilters})
                                }}
                                onCancel={() => {
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Tooltip title="Manual Run">
                                    <Icon type="play-circle-outlined" className="cursorPointer me-2"/>
                                </Tooltip>
                            </Popconfirm>
                            <Tooltip title="Log">
                                <Icon type="info-outlined" className="cursorPointer me-2"
                                      onClick={() => {
                                          actionDetailLog({query: {id: record.id}, filters: paramsFilters})
                                          setIsOpenDetailLog(true)
                                      }}/>
                            </Tooltip>
                            <Tooltip title="Edit">
                                <Icon type="edit-outlined" className="cursorPointer"
                                      onClick={() => props.history.push((ROUTE_REDIRECT.WORKFLOW.UPDATE).replace(":id", record.id))}/>
                            </Tooltip>
                        </>
                    )
                },
            }
        };
    const [fileList, setFileList] = useState([]),
        [isFetching, setIsFetching] = useState(false),
        [isImport, setIsImport] = useState(false),
        [isOpenDetailLog, setIsOpenDetailLog] = useState(false),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        // ============== ACTION ==============
        getList = ActionGetList(),
        actionInit = ActionInit(),
        actionUpload = ActionUpload(),
        actionManualTrigger = ActionManualTrigger(),
        actionDetailLog = ActionDetailLog(),
        // ============== SELECT DATA ==============
        itemList = useSelector(selectList()),
        itemErrors = useSelector(selectErrors()),
        itemMasterData = useSelector(selectMasterData()),
        itemDetailLog = useSelector(selectDetailLog()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        if (Object.keys(queryString.parse(props.location.search)).length > 0) {
            let paramsFilters = queryString.parse(props.location.search, {arrayFormat: 'comma'});
            paramsFilters = removeObjectNullFull(paramsFilters)
            setPramsFilters(paramsFilters)
            form.setFieldsValue(paramsFilters)
            getList(paramsFilters);
        } else {
            getList();
        }
        return () => {
            actionInit({clearDetailLog: true})
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        setResultList(itemList?.result || [])
        setPagination({
            total: itemList?.pagination?.total || 0,
            totalPage: itemList?.pagination?.total_page || 0,
            defaultCurrent: itemList?.pagination?.page_index || 1,
            pageSize: itemList?.pagination?.page_size || 10
        })
    }, [itemList]);

    const onTableChange = (pagination, filters, sorter) => {
        // let currentFilters = getCurrentFilters();
        filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        setPramsFilters(newFilters)
        setPramsFilters(newFilters)
        form.setFieldsValue(newFilters)
        setInitData({
            ...initData,
            filters: newFilters
        });
        props.history.replace(setParamsUrlFilter(ROUTE_REDIRECT.WORKFLOW.HOME, newFilters))
        getList(newFilters);
    };

    const onCreate = (e, data) => {
        if (e === TAG_DEFINE.ACTION_OPTIONS.UPDATE) {
            props.history.push((ROUTE_REDIRECT.WORKFLOW.UPDATE).replace(":id", data.id))
        } else {
            props.history.push(ROUTE_REDIRECT.WORKFLOW.CREATE)
        }
    };

    const onSearch = (val) => {
        let getFilters = filtersResponse(removeObjectNullFull({
            ...initData.filters, ...paramsFilters, ...val,
            page_index: 1
        }));
        setPramsFilters(getFilters);
        setInitData({...initData, filters: paramsFilters})
        props.history.replace(setParamsUrlFilter(ROUTE_REDIRECT.WORKFLOW.HOME, getFilters))
        getList(removeObjectNullFull(getFilters));
    };

    return <>
        <HeaderAction
            isActionSignlePage
            title="Workflow List"
            disabledFormatUpperCaseTitle
            isCreate
            onClick={e => onCreate(e)}
            isCustomize
        />
        <Modal
            // maskClosable={false}
            destroyOnClose
            title="List Log"
            visible={isOpenDetailLog}
            footer={null}
            // onOk={e => onCreate()}
            onCancel={() => {
                actionInit({clearDetailLog: true})
                setIsOpenDetailLog(false)
            }}
        >
            <ListComponent
                classParent="m-0 p-0 col-md-12"
                className="custom-table"
                marginTop={'0'}
                marginBottom={'0'}
                loading={{spinning: isFetching, size: 'large'}}
                rowKey="id"
                dataSource={getDataSource(itemDetailLog?.result || [], Object.getOwnPropertyNames(new WorkflowDetailLogResponse()))}
                columns={columnsTable({
                    titleTable: getTitleTable(detailLogColumn, [], []),
                    component: {},
                    itemName: "workflow_detail_log"
                })}
                pagination={false}
                // onChange={false}
                componentTableList={{}}
            />
        </Modal>
        <div className="container-fluid px-4">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSearch}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="row bg-white py-3 align-items-center">
                    <div className="col-4">
                        <Form.Item
                            label="Search"
                            name="search"
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </div>
                    {/*<div className="col-2">*/}
                    {/*    <Form.Item*/}
                    {/*        label=" "*/}
                    {/*        name="is_owned"*/}
                    {/*        valuePropName="checked"*/}
                    {/*    >*/}
                    {/*        <Checkbox>My Workflow</Checkbox>*/}
                    {/*    </Form.Item>*/}
                    {/*</div>*/}
                    <div className="col-2">
                        <Space>
                            <Form.Item className="" label=" ">
                                <Button type="primary" htmlType="submit">
                                    Search {isFetching && <Icon type="sync-outlined" spin/>}
                                </Button>
                            </Form.Item>
                        </Space>
                    </div>
                </div>
            </Form>
        </div>
        <ListComponent
            scroll={{x: 1000}}
            classParent="m-0 p-0 col-md-12"
            className="custom-table"
            marginTop={'0'} marginBottom={'0'}
            loading={{spinning: isFetching, size: 'large'}}
            rowKey="id"
            dataSource={getDataSource(resultList || [], initData.modelItem)}
            columns={columnsTable({
                titleTable: initData.titleItemTable,
                component: componentTable,
                itemName: namePage
            })}
            pagination={{
                total: pagination.total,
                totalPage: pagination.totalPage,
                current: pagination.defaultCurrent,
                pageSize: pagination.pageSize,
                responsive: true
            }}
            onChange={onTableChange}
            componentTableList={{}}
        />
    </>
}

export default Index
