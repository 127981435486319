import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class TemplateRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.title = data?.title || "";
        this.template = data?.template || "";
        this.is_template = Number(data?.is_template);
    }

    exportCreate() {
        return {
            title: this.title,
            template: this.template,
            is_template: this.is_template,
        }
    }

    exportUpdate() {
        return {
            id: this.id,
            title: this.title,
            template: this.template,
            is_template: this.is_template,
        }
    }

}

export const common = {
    ACTION_FIELD: {
        title: "title",
        template: "template",
    },
}

export const filtersParams = (filters) => {
    return removeObjectNullFull({
        search: filters?.search || "",
        tag: filters?.tag || "",
        is_owned: filters?.is_owned,
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
    })
}

