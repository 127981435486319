import React, {useState, useEffect} from "react";
import {useSelector} from 'react-redux'

// ============== LAYOUT ==============
import {Form, Input, Button, Select, Spin, Checkbox} from 'antd';
import HeaderAction from "../../../common/layout/HeaderAction";

// ============== ACTION ==============
import {
    ActionMasterData,
    ActionCreateTeams,
    ActionInit,
    ActionGetItem,
    ActionUpdateTeams
} from "../../../../data/hooks/teams";

// ============== SELECT DATA ==============
import {selectFetching, selectTeamsItem, selectMasterData} from "../../../../data/reselects/teamsSelector";
import {common} from "../../../../data/mapping/Request/TeamsRequest";
import {Icon} from "../../../common";
import {isEmail} from "../../../../utils/validation";
import TAG_DEFINE from "../../../../constants/common";
import {ROUTE_REDIRECT} from "../../../../constants/define";

const Index = (props) => {
    const [form] = Form.useForm(),
        [isFetching, setIsFetching] = useState(false),
        [typePage, setTypePage] = useState(TAG_DEFINE.ACTION.create),
        [masterStatus, setMasterStatus] = useState({}),
        [isUpdate, setIsUpdate] = useState(false),
        // ============== ACTION ==============
        getMasterData = ActionMasterData(),
        actionCreateTeams = ActionCreateTeams(),
        actionUpdateTeams = ActionUpdateTeams(),
        getInitAction = ActionInit(),
        actionGetItem = ActionGetItem(),
        // ============== SELECT DATA ==============
        itemFetching = useSelector(selectFetching()),
        itemDetail = useSelector(selectTeamsItem()),
        itemMasterData = useSelector(selectMasterData());

    useEffect(() => {
        const {match} = props;
        if (Object.keys(match.params).length > 0) {
            setIsUpdate(true);
            if (Object.keys(itemDetail || {}).length < 1) {
                setTypePage(TAG_DEFINE.ACTION.update)
                actionGetItem({id: match.params?.id, props: props});
            }
        } else {
            setIsUpdate(false)
        }
    }, [props.match]);

    useEffect(() => {
        getMasterData()

        return () => {
            getInitAction({clearDetail: true});
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);

    useEffect(() => {
        if (Object.keys(itemDetail || {}).length > 0) {
            form.setFieldsValue(itemDetail)
        }
    }, [itemDetail]);

    useEffect(() => {
        if (Object.keys(itemMasterData?.status || {}).length > 0) {
            setMasterStatus(itemMasterData?.status)
        }
    }, [itemMasterData]);

    const onFinish = (val) => {
        if (isUpdate) {
            actionUpdateTeams({...val, props, id: itemDetail?.id})
        } else {
            actionCreateTeams({...val, props})
        }
    }

    return (
        <>
            <HeaderAction title={`Teams ${TAG_DEFINE.ACTION[typePage.toLowerCase()]}`}/>
            <Spin tip="Loading..." spinning={isFetching}>
                <div className="container bg-white mt-4 p-4">
                    <div className="row">
                        <div className="col-12">
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={onFinish}
                                // onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <div className="row">
                                    <div className="col-4">
                                        <Form.Item
                                            label="First name"
                                            name={common.ACTION_FIELD.first_name}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Last name"
                                            name={common.ACTION_FIELD.last_name}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Password"
                                            name={common.ACTION_FIELD.password}
                                            // rules={[
                                            //     {
                                            //         required: true,
                                            //         message: 'Please input your password!'
                                            //     }, {type: "string", min: 6}, ({getFieldValue}) => ({
                                            //         validator(rule, value) {
                                            //             if (value) {
                                            //                 if (!/^\s+|\s+$/g.test(value[0]) && !/^\s+|\s+$/g.test(value[value.length - 1])) {
                                            //                     return Promise.resolve();
                                            //                 }
                                            //                 return Promise.reject("'password' cannot be empty");
                                            //             }
                                            //             return Promise.resolve();
                                            //         },
                                            //     })
                                            // ]}
                                        >
                                            <Input.Password/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Email"
                                            name={common.ACTION_FIELD.email}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your Email!'
                                                }, ({getFieldValue}) => ({
                                                    validator(rule, value) {
                                                        if ((itemDetail[common.ACTION_FIELD.email] === value)) {
                                                            return Promise.resolve();
                                                        }
                                                        if (value) {
                                                            if (!isEmail(value) && ((value || "").length >= 10)) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject("Invalid email");
                                                        }
                                                        return Promise.reject("Invalid email")
                                                    },
                                                })
                                            ]}
                                        >
                                            <Input disabled={isUpdate}/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Phone number"
                                            name={common.ACTION_FIELD.phone_number}
                                            rules={[{required: true}]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Membership Code"
                                            name={common.ACTION_FIELD.membership_code}
                                            // rules={[{required: true}]}
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Address"
                                            name={common.ACTION_FIELD.address}
                                            // rules={[{required: true}]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Status"
                                            name={common.ACTION_FIELD.status}
                                            rules={[{required: true, message: 'Please select your status!'}]}
                                        >
                                            <Select
                                                className="w-100"
                                                style={{width: 120}}>
                                                {
                                                    (Object.keys(masterStatus)).length > 0 && (Object.keys(masterStatus)).map((i, k) => {
                                                        return (
                                                            <Select.Option value={i}
                                                                           key={k}>{masterStatus[i]}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                <Form.Item className="text-end">
                                    <Button type="default" htmlType="button" className="me-3"
                                            onClick={() => props.history.push(ROUTE_REDIRECT.TEAMS.HOME)}>
                                        Back
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Save {isFetching && <Icon type="sync-outlined" spin/>}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </Spin>
        </>
    )
}

export default Index
