import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    removeObjectNull, removeObjectNullFull, setParamsUrlFilter
} from "../../../utils/functions";
import {omit} from "lodash";
import TAG_DEFINE from "../../../constants/common";
import {PATHS} from "../../../constants/define";

// ============== LAYOUT ==============
import HeaderAction from "../../common/layout/HeaderAction";
import {Icon} from "../../common";
import ListComponent from "../../common/ListComponent";
import {Tooltip, Button, Tag, Form, Input, Select, Checkbox, Modal, Upload, message, Space} from 'antd'

// ============== ACTION ==============
import {ActionGetList, ActionMasterData} from '../../../data/hooks/tag';
import {ActionUpload} from '../../../data/hooks/upload';

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectErrors,
    selectList,
    selectMasterData
} from '../../../data/reselects/tagSelector';
import TagResponse, {column, filtersResponse} from "../../../data/mapping/Response/TagResponse";
import {ROUTE_REDIRECT} from "../../../constants/define";
import queryString from "query-string";

const ModelFields = Object.getOwnPropertyNames(new TagResponse());
const Index = props => {
    const namePage = "tag",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(column, ['action'], []),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        [form] = Form.useForm(),
        componentTable = {
            status: {
                width: 150,
                render: (val, record) => Object.keys(itemMasterData).length > 0 && (itemMasterData?.status[val]),
            },
            action: {
                width: 100,
                align: 'center',
                key: 'action',
                fixed: 'right',
                render: (text, record) => <Icon type="edit-outlined" className="cursorPointer"
                                                onClick={() => props.history.push((ROUTE_REDIRECT.TAG.UPDATE).replace(":id", record.id))}/>,
            }
        };
    const [fileList, setFileList] = useState([]),
        [selectLeadType, setSelectLeadType] = useState(),
        [isFetching, setIsFetching] = useState(false),
        [isImport, setIsImport] = useState(false),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        // ============== ACTION ==============
        getListTag = ActionGetList(),
        getMasterData = ActionMasterData(),
        actionUpload = ActionUpload(),
        // ============== SELECT DATA ==============
        itemList = useSelector(selectList()),
        itemErrors = useSelector(selectErrors()),
        itemMasterData = useSelector(selectMasterData()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        if (Object.keys(queryString.parse(props.location.search)).length > 0) {
            let paramsFilters = queryString.parse(props.location.search, {arrayFormat: 'comma'});
            paramsFilters = removeObjectNullFull(paramsFilters)
            setPramsFilters(paramsFilters)
            form.setFieldsValue(paramsFilters)
            getListTag(paramsFilters);
        } else {
            getListTag();
        }
        // getListTag();
        getMasterData()
    }, []);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        setResultList(itemList?.result || [])
        setPagination({
            total: itemList?.pagination?.total || 0,
            totalPage: itemList?.pagination?.total_page || 0,
            defaultCurrent: itemList?.pagination?.page_index || 1,
            pageSize: itemList?.pagination?.page_size || 10
        })
    }, [itemList]);

    const onTableChange = (pagination, filters, sorter) => {
        // let currentFilters = getCurrentFilters();
        filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        setPramsFilters(newFilters)
        setPramsFilters(newFilters)
        setInitData({
            ...initData,
            filters: newFilters
        });
        props.history.replace(setParamsUrlFilter(ROUTE_REDIRECT.TAG.HOME, newFilters))
        getListTag(newFilters);
    };

    const onCreate = (e, data) => {
        if (e === TAG_DEFINE.ACTION_OPTIONS.UPDATE) {
            props.history.push((ROUTE_REDIRECT.TAG.UPDATE).replace(":id", data.id))
        } else {
            props.history.push(ROUTE_REDIRECT.TAG.CREATE)
        }
    };

    const onSearch = (val) => {
        let getFilters = filtersResponse(removeObjectNullFull({
            ...initData.filters, ...paramsFilters, ...val,
            page_index: 1
        }));
        setPramsFilters(getFilters);
        setInitData({...initData, filters: paramsFilters})
        props.history.replace(setParamsUrlFilter(ROUTE_REDIRECT.TAG.HOME, getFilters))
        getListTag(removeObjectNullFull(getFilters));
    };

    return <>
        <HeaderAction
            isActionSignlePage
            title="Tag Management"
            disabledFormatUpperCaseTitle
            isCreate
            onClick={e => onCreate(e)}
            isCustomize
        >
            <button type="button" className="btn btn-default btn-rounded fs--14px ms-3"
                    style={{border: "1px solid gray"}}
                    onClick={() => {
                        // form.setFieldsValue(itemProfile?.profile_config)
                        props.history.push("/contact")
                    }}>
                Back to contact
            </button>
        </HeaderAction>
        <div className="container-fluid px-4">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSearch}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="row bg-white py-3 align-items-center">
                    <div className="col-6">
                        <Form.Item
                            label="Search"
                            name="search"
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </div>
                    <div className="col-2">
                        <Form.Item
                            label=" "
                            name="is_owned"
                            valuePropName="checked"
                        >
                            <Checkbox>My Tag</Checkbox>
                        </Form.Item>
                    </div>
                    <div className="col-2">
                        <Form.Item className="" label=" ">
                            <Button type="primary" htmlType="submit">
                                Search {isFetching && <Icon type="sync-outlined" spin/>}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
        <ListComponent
            scroll={{x: 1000}}
            classParent="m-0 p-0 col-md-12"
            className="custom-table"
            marginTop={'0'} marginBottom={'0'}
            loading={{spinning: isFetching, size: 'large'}}
            rowKey="id"
            dataSource={getDataSource(resultList || [], initData.modelItem)}
            columns={columnsTable({
                titleTable: initData.titleItemTable,
                component: componentTable,
                itemName: namePage
            })}
            pagination={{
                total: pagination.total,
                totalPage: pagination.totalPage,
                current: pagination.defaultCurrent,
                pageSize: pagination.pageSize,
                responsive: true
            }}
            onChange={onTableChange}
            componentTableList={{}}
        />
    </>
}

export default Index
