import {DragDropContext} from "react-beautiful-dnd";
import {useEffect, useState} from "react";
import TaskList from "./TaskList";
import {Button, Checkbox, Form, Input, Spin} from "antd";
import {Icon} from "../../../common";
import {useSelector} from "react-redux";
import {
    selectFetching,
    selectGetItemAction,
    selectItem,
    selectStatusAction
} from "../../../../data/reselects/taskSelector";
import {ActionCreateTaskProcess, ActionUpdateTaskProcess} from "../../../../data/hooks/task";

const TaskContainer = props => {
    const [form] = Form.useForm(),
        [elements, setElements] = useState({
            todo: [],
            inProgress: [],
            done: [],
        }),
        [isCreate, setIsCreate] = useState(false),
        actionCreateTaskProcess = ActionCreateTaskProcess(),
        actionUpdateTaskProcess = ActionUpdateTaskProcess(),
        itemFetching = useSelector(selectFetching()),
        itemStatusAction = useSelector(selectStatusAction()),
        itemGetAction = useSelector(selectGetItemAction()),
        itemDetail = useSelector(selectItem());

    useEffect(() => {
    }, []);

    useEffect(() => {
        if (itemStatusAction?.createSuccess && (Object?.keys(itemGetAction || {}).length > 0)) {
            form.resetFields();
            setElements(prevState => {
                return {
                    ...prevState,
                    todo: [...prevState.todo, itemGetAction]
                }
            })
            setIsCreate(false)
        }
    }, [itemStatusAction, itemGetAction]);

    useEffect(() => {
        if (Object.keys(itemDetail?.items || {}).length > 0) {
            setElements({
                todo: itemDetail?.items["0"] || [],
                inProgress: itemDetail?.items["1"] || [],
                done: itemDetail?.items["2"] || []
            });
        }
    }, [itemDetail]);

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        let statusDestination = listsColumn[result.destination?.droppableId],
            statusSource = listsColumn[result.source?.droppableId]

        const listCopy = {...elements};

        const sourceList = listCopy[result.source.droppableId];

        const [removedElement, newSourceList] = removeFromList(
            sourceList,
            result.source.index
        );
        listCopy[result.source.droppableId] = newSourceList;
        const destinationList = listCopy[result.destination.droppableId];

        actionUpdateTaskProcess({
            query: {
                status: statusDestination,
                title: removedElement?.title
            },
            id: props?.taskId,
            idItem: removedElement?.id
        })

        listCopy[result.destination.droppableId] = addToList(
            destinationList,
            result.destination.index,
            removedElement
        );

        setElements(listCopy);
    };

    const onCreateTask = val => {
        setIsCreate(true)
    }

    const onSubmit = val => {
        // console.log({...val, id: props?.taskId})
        actionCreateTaskProcess({
            query: val,
            id: props?.taskId
        })
    }

    return (
        <Spin spinning={itemFetching}>
            <div className="task__container--wrapper">
                <DragDropContext onDragEnd={onDragEnd}>
                    <div className="">
                        <Form
                            className="task__container--list-wrapper"
                            form={form}
                            layout="vertical"
                            onFinish={onSubmit}
                            // onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            {lists.map((listKey) => (
                                <TaskList
                                    isCreate={isCreate}
                                    elements={elements[listKey]}
                                    key={listKey}
                                    status={listsColumn[listKey]}
                                    prefix={listKey}
                                    listsColumn={listsColumn}
                                    onCreateTask={e => onCreateTask(e)}
                                    onSubmit={e => onSubmit(e)}
                                />
                            ))}
                        </Form>

                    </div>
                </DragDropContext>
            </div>
        </Spin>
    )
}

export default TaskContainer
const removeFromList = (list, index) => {
    const result = Array.from(list);
    const [removed] = result.splice(index, 1);
    return [removed, result];
};

const addToList = (list, index, element) => {
    const result = Array.from(list);
    result.splice(index, 0, element);
    return result;
};

const lists = ["todo", "inProgress", "done"];
const listsColumn = {
    todo: "0",
    inProgress: "1",
    done: "2",
};
