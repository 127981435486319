import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/cartAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.cartInitStore(params)),
        [dispatch]
    )
}

export const ActionGetList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.cartListAction(filters)),
        [dispatch]
    )
}

export const ActionGetItem = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.cartDetailAction(params)),
        [dispatch]
    )
}

export const ActionMasterData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.cartMasterAction(params)),
        [dispatch]
    )
}

export const ActionCreate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.cartCreateAction(params)),
        [dispatch]
    )
}

export const ActionUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.cartUpdateAction(params)),
        [dispatch]
    )
}

export const ActionRemove = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.cartRemoveAction(params)),
        [dispatch]
    )
}
