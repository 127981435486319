import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/teamPointAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        () => dispatch(HookAction.teamPointInitStore()),
        [dispatch]
    )
}

export const ActionGetListTeamPoint = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.teamPointListAction(filters)),
        [dispatch]
    )
}


export const ActionDetailTeamPoint = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.teamPointDetailAction(filters)),
        [dispatch]
    )
}
