import * as Types from '../types/Contact';
import {PAGINATION} from '../../constants/define';
// import HotelRequest from "../mapping/Request/HotelRequest";

const initialState = {
    isFetching: false,
    statusAction: {
        isSubmit: false,
        isUnlock: false,
        unlockField: "",
        isExport: false,
        isCreateNote: false,
        createNoteSuccess: false,
        isImportMonday: false,
        isImportHubspot: false,
        isImportPodio: false,
        saveFilterSuccess: false,
        isCreateFilter: false,
        isDeleteFilter: false,
        isSaveColumn: false
    },
    all: [],
    errors: [],
    // item: new HotelRequest().update(),
    data: {
        result: [],
        pagination: {
            page_size: PAGINATION.page_size,
            page_index: PAGINATION.page_index,
            total: PAGINATION.total,
            total_page: PAGINATION.total_page,
        }
    },
    detail: {},
    listFilters: {},
    listColumn: {},
    master: {},
    masterPortalFilter: []
};

const getResult = (page) => {
    return {
        result: page.result || [],
        pagination: {
            page_size: page?.pagination?.page_size || page?.page_size || PAGINATION.page_size,
            page_index: page?.pagination?.page_index || page?.page_index || PAGINATION.page_index,
            total: page?.pagination?.total || page?.total || PAGINATION.total,
            total_page: page?.pagination?.total_page || page?.total_page || PAGINATION.total_page,
        }
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.CONTACT_OPT_OUT_ACTION:
        case Types.CONTACT_OPT_IN_ACTION:
        case Types.CONTACT_CHECK_DUPLICATE_ACTION:
        case Types.CONTACT_FILTER_LIST_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.CONTACT_IMPORT_MONDAY_ACTION:
            return {
                ...state, statusAction: {
                    ...initialState.statusAction,
                    isImportMonday: true,
                }
            };
        case Types.CONTACT_IMPORT_HUBSPOT_ACTION:
            return {
                ...state, statusAction: {
                    ...initialState.statusAction,
                    isImportHubspot: true,
                }
            };
        case Types.CONTACT_IMPORT_PODIO_ACTION:
            return {
                ...state, statusAction: {
                    ...initialState.statusAction,
                    isImportPodio: true,
                }
            };
        case Types.CONTACT_CREATE_NOTE_ACTION:
            return {
                ...state, statusAction: {
                    ...initialState.statusAction,
                    isCreateNote: true,
                    createNoteSuccess: false
                }
            };
        case Types.CONTACT_UPDATE_NOTE_ACTION:
            return {
                ...state, statusAction: {
                    ...initialState.statusAction,
                    isCreateNote: true,
                    createNoteSuccess: false
                }
            };
        case Types.CONTACT_DELETE_NOTE_ACTION:
            return {
                ...state, statusAction: {
                    ...initialState.statusAction,
                    isCreateNote: true,
                    createNoteSuccess: false
                }
            };
        case Types.CONTACT_FILTER_SAVE_ACTION:
            return {
                ...state, statusAction: {
                    ...initialState.statusAction,
                    saveFilterSuccess: false,
                    isCreateFilter: true
                }
            };
        case Types.CONTACT_FILTER_SAVE_SUCCESS:
            return {
                ...state, statusAction: {
                    ...initialState.statusAction,
                    saveFilterSuccess: true,
                    isCreateFilter: false
                }
            };
        case Types.CONTACT_FILTER_DELETE_ACTION:
            return {
                ...state, statusAction: {
                    ...initialState.statusAction,
                    isDeleteFilter: true
                }
            };
        case Types.CONTACT_FILTER_DELETE_SUCCESS:
            return {
                ...state, statusAction: {
                    ...initialState.statusAction,
                    isDeleteFilter: false
                }
            };
        case Types.CONTACT_CREATE_NOTE_SUCCESS:
            return {
                ...state, statusAction: {
                    ...initialState.statusAction,
                    isCreateNote: false,
                    createNoteSuccess: true
                }
            };
        case Types.CONTACT_UPDATE_NOTE_SUCCESS:
            return {
                ...state, statusAction: {
                    ...initialState.statusAction,
                    isCreateNote: false,
                    createNoteSuccess: true
                }
            };
        case Types.CONTACT_DELETE_NOTE_SUCCESS:
            return {
                ...state, statusAction: {
                    ...initialState.statusAction,
                    isCreateNote: false,
                    createNoteSuccess: true
                }
            };
        case Types.CONTACT_IMPORT_MONDAY_SUCCESS:
            return {
                ...state, statusAction: {
                    ...initialState.statusAction,
                    isImportMonday: false,
                }
            };
        case Types.CONTACT_IMPORT_HUBSPOT_SUCCESS:
            return {
                ...state, statusAction: {
                    ...initialState.statusAction,
                    isImportHubspot: false,
                }
            };
        case Types.CONTACT_IMPORT_PODIO_SUCCESS:
            return {
                ...state, statusAction: {
                    ...initialState.statusAction,
                    isImportPodio: false,
                }
            };
        case Types.CONTACT_MASTER_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.CONTACT_MASTER_SUCCESS:
            return {...state, isFetching: false, master: action.master, statusAction: initialState.statusAction};
        case Types.CONTACT_LIST_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.CONTACT_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: getResult(action.contact),
                statusAction: initialState.statusAction
            };
        case Types.CONTACT_DETAIL_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.CONTACT_DETAIL_SUCCESS:
            return {...state, isFetching: false, detail: action.detail, statusAction: initialState.statusAction};
        case Types.CONTACT_COLUMN_LIST_SUCCESS:
            return {...state, isFetching: false, listColumn: action.columns, statusAction: initialState.statusAction};
        case Types.CONTACT_FILTER_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                listFilters: action.listFilters,
                statusAction: initialState.statusAction
            };
        case Types.CONTACT_UPDATE_ACTION:
            return {...state, statusAction: {...initialState.statusAction, isSubmit: true}};
        case Types.CONTACT_UPDATE_SUCCESS:
            return {...state, statusAction: {...initialState.statusAction, isSubmit: false}};
        case Types.CONTACT_CREATE_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.CONTACT_CREATE_SUCCESS:
            return {...state, isFetching: false, statusAction: initialState.statusAction};
        case Types.CONTACT_DELETE_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.CONTACT_DELETE_SUCCESS:
            return {...state, isFetching: false, statusAction: initialState.statusAction};
        case Types.CONTACT_OPT_OUT_SUCCESS:
        case Types.CONTACT_OPT_IN_SUCCESS:
        case Types.CONTACT_CHECK_DUPLICATE_SUCCESS:
            return {...state, isFetching: false, statusAction: initialState.statusAction};
        case Types.CONTACT_COLUMN_SAVE_ACTION:
            return {...state, isFetching: false, statusAction: {...initialState.statusAction, isSaveColumn: true}};
        case Types.CONTACT_COLUMN_SAVE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                statusAction: {...initialState.statusAction, isSaveColumn: false}
            };
        case Types.CONTACT_MASTER_PORTAL_FILTER_ACTION:
            return {...state, isFetching: false, statusAction: initialState.statusAction};
        case Types.CONTACT_MASTER_PORTAL_FILTER_SUCCESS:
            return {...state, isFetching: false, masterPortalFilter: action?.masterPortalFilter};
        case Types.CONTACT_HIGHLIGHT_CELL_ACTION:
            return {
                ...state, isFetching: true, statusAction: initialState.statusAction
            };
        case Types.CONTACT_HIGHLIGHT_CELL_SUCCESS:
            return {
                ...state, isFetching: false, statusAction: initialState.statusAction
            };
        case Types.CONTACT_FAIL:
            return {...state, errors: action.errors, isFetching: false, statusAction: initialState.statusAction};
        case Types.CONTACT_INIT:
            return {
                ...state,
                data: action.params?.clearDetail ? state.data : initialState.data,
                detail: action.params?.clearDetail ? initialState.detail : state.detail,
                statusAction: initialState.statusAction
            };
        default:
            return state;
    }
}
