import React, {createElement, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import _ from "lodash";
import Layout from "./components/Automation";
import {getIncomers, getOutgoers} from "react-flow-renderer";
import {initialElements} from "./components/Data/Elements1";
import {getUpdatedElementsAfterNodeAddition} from "./components/Utils/WorkflowElementUtils";
import {Button, Drawer, Form, Input, InputNumber, Select, message, Spin} from "antd";
import {isEmail} from "../../../utils/validation";
import {COUNTRY_CODE} from "../../../constants/define";
import {Icon} from "../../common";
import {ActionCreate, ActionGetItem, ActionInit, ActionMasterData, ActionUpdate} from "../../../data/hooks/workflow";
import {selectFetching, selectItem, selectMasterData} from "../../../data/reselects/workflowSelector";
import {ACTION_TYPE, DAY_OF_WEEK, PERIOD, SKIP_TYPE, STATUS, TRIGGER_TYPE} from "./constant";
import {
    DayOfMonth,
    DayOfWeek, MonthOfYear,
    Period,
    SkipType,
    SkipValue,
    Tag, Template,
    Time,
    TriggerType, Type,
    CompoAction
} from "./components/FormDetailComponent";
import {CaretLeftOutlined, CloudUploadOutlined} from "@ant-design/icons";
import TAG_DEFINE from "../../../constants/common";
// import "./index.scss";

const CustomNodeFlow = (props) => {
    const [form] = Form.useForm(),
        [valuesForm, setValuesForm] = React.useState(),
        [elements, setElements] = React.useState([]),
        [nodeSelected, setNodeSelected] = React.useState(""),
        [idNodeSelected, setIdNodeSelected] = React.useState(""),
        [modulesConfig, setModulesConfig] = React.useState({
            SOURCE: {},
            RULE: {},
            ACTION: {},
        }),
        [isOpenDescript, setIsOpenDescript] = React.useState(false),
        [isUpdate, setIsUpdate] = React.useState(false),
        [isVerifyValueNode, setIsVerifyValueNode] = React.useState({
            source: false,
            rule: false,
            action: false,
            end: false,
        }),
        actionMasterData = ActionMasterData(),
        actionCreate = ActionCreate(),
        actionGetItem = ActionGetItem(),
        actionUpdate = ActionUpdate(),
        actionInit = ActionInit(),
        itemFetching = useSelector(selectFetching()),
        itemDetail = useSelector(selectItem()),
        itemMaster = useSelector(selectMasterData());

    const [itemFormSelected, setItemFormSelected] = React.useState({});

    // console.log(form.getFieldValue("period"))
    const onAddNodeCallback = ({id, type}) => {
        setElements((elements) =>
            getUpdatedElementsAfterNodeAddition({
                elements,
                targetEdgeId: id,
                type,
                onDeleteNodeCallback,
                onNodeClickCallback,
                onAddNodeCallback,
            })
        );
    };

    const onDeleteNodeCallback = (id) => {
        setElements((elements) => {
            const clonedElements = _.cloneDeep(elements);
            const incomingEdges = clonedElements.filter((x) => x.target === id);
            const outgoingEdges = clonedElements.filter((x) => x.source === id);
            const updatedIncomingEdges = incomingEdges.map((x) => ({
                ...x,
                target: outgoingEdges[0].target,
            }));
            const filteredElements = clonedElements.filter(
                (x) =>
                    x.id !== id &&
                    x.target !== incomingEdges[0].target &&
                    x.source !== outgoingEdges[0].source
            );
            filteredElements.push(...updatedIncomingEdges);
            return filteredElements;
        });
    };

    const onNodeClickCallback = (id) => {
        if (form.getFieldValue("title") && form.getFieldValue("timezone") && (form.getFieldValue("status") !== null || form.getFieldValue("status") !== "" || form.getFieldValue("status") !== undefined)) {
            setElements((elements) => {
                const currentNode = elements.find((x) => x.id === id);
                const nodes = elements.filter((x) => x.position);
                const edges = elements.filter((x) => !x.position);
                setNodeSelected(currentNode?.data?.tag)
                setIdNodeSelected(id)
                console.error({
                    incomers: getIncomers(currentNode, nodes, edges),
                    outgoers: getOutgoers(currentNode, nodes, edges),
                });
                return elements;
            });
            setIsOpenDescript(true)
        } else {
            form.submit();
            message.error("Please input your title & timezone & status")
        }
        // alert(`You clicked the "${id}" node`);
    };

    useEffect(() => {
        const {match} = props;
        if (Object.keys(match.params).length > 0) {
            setIsUpdate(true);
            actionGetItem({id: match.params?.id, props: props});
        } else {
            setIsUpdate(false)
        }
    }, [props.match]);

    React.useEffect(() => {
        actionMasterData();
        const nodes = initialElements
            .filter((x) => !x.target)
            .map((x) => ({
                ...x,
                data: {...x.data, onDeleteNodeCallback, onNodeClickCallback},
            }));
        const edges = initialElements
            .filter((x) => x.target)
            .map((x) => ({...x, data: {...x.data, onAddNodeCallback}}));
        setElements([...nodes, ...edges]);

        return () => {
            actionInit({clearDetail: true})
            setNodeSelected("")
            setIdNodeSelected("")
            form.resetFields()
            setIsUpdate(false)
        }

    }, []);

    React.useEffect(() => {
        setItemFormSelected({
            SOURCE: [createElement(Tag, {
                itemMaster,
                form,
                valuesForm
            })],
            RULE: [
                createElement(TriggerType, {
                    itemMaster,
                    form,
                    valuesForm
                }), createElement(Period, {
                    itemMaster,
                    form,
                    valuesForm
                }), createElement(Time, {
                    itemMaster,
                    form,
                    valuesForm
                }), createElement(DayOfWeek, {
                    itemMaster,
                    form,
                    valuesForm
                }), createElement(DayOfMonth, {
                    itemMaster,
                    form,
                    valuesForm
                }), createElement(MonthOfYear, {
                    itemMaster,
                    form,
                    valuesForm
                }), createElement(SkipValue, {
                    itemMaster,
                    form,
                    valuesForm
                }), createElement(SkipType, {
                    itemMaster,
                    form,
                    valuesForm
                })
            ],
            ACTION: [createElement(CompoAction, {
                itemMaster,
                form,
                valuesForm
            })],
        })
        if (Object.keys(props.match.params).length < 1) {
            form.setFieldsValue({
                timezone: itemMaster?.default_timezone || ""
            })
        }
    }, [itemMaster]);

    React.useEffect(() => {
        let newItems = {
            SOURCE: [createElement(Tag, {
                itemMaster,
                form,
                valuesForm
            })],
            RULE: [
                createElement(TriggerType, {
                    itemMaster,
                    form,
                    valuesForm
                }), createElement(Period, {
                    itemMaster,
                    form,
                    valuesForm
                }), createElement(Time, {
                    itemMaster,
                    form,
                    valuesForm
                }), createElement(DayOfWeek, {
                    itemMaster,
                    form,
                    valuesForm
                }), createElement(DayOfMonth, {
                    itemMaster,
                    form,
                    valuesForm
                }), createElement(MonthOfYear, {
                    itemMaster,
                    form,
                    valuesForm
                }), createElement(SkipValue, {
                    itemMaster,
                    form,
                    valuesForm
                }), createElement(SkipType, {
                    itemMaster,
                    form,
                    valuesForm
                })
            ],
            ACTION: [createElement(CompoAction, {
                itemMaster,
                form,
                valuesForm
            })],
            // ACTION: [createElement(Type, {
            //     itemMaster,
            //     form,
            //     valuesForm
            // }), createElement(Template, {
            //     itemMaster,
            //     form,
            //     valuesForm
            // })],
        }
        setItemFormSelected(newItems)
    }, [valuesForm])

    React.useEffect(() => {
        setElements((elements) => {
            const clonedElements = _.cloneDeep(elements);
            let newValue = {...modulesConfig, ...valuesForm};
            let newElements = [];
            clonedElements.map((x) => {
                // console.log(443333, !!newValue[(x.type || "").toUpperCase()], (x.type || "").toUpperCase(), newValue[(x.type || "").toUpperCase()])
                // console.log(443333, newValue)
                let detected = detectedChangeConfig(newValue, x.type);
                newElements.push({
                    ...x,
                    data: {
                        ...x.data,
                        isConfig: detected
                        // isConfig: !!newValue[(x.type || "").toUpperCase()]
                    }
                })
            });
            return newElements;
        });
    }, [valuesForm, modulesConfig])

    React.useEffect(() => {
        form.setFieldsValue(itemDetail?.FORM)
        setValuesForm(itemDetail?.FORM)
        setModulesConfig({
            SOURCE: itemDetail?.SOURCE,
            RULE: itemDetail?.RULE,
            ACTION: itemDetail?.ACTION,
        })
    }, [itemDetail])

    const onChangeValue = (val, full) => {
        let valueConfig = {...valuesForm, ...full};
        // if (val?.action) {
        //     valueConfig.template = "";
        //     form.setFieldsValue({template: ""})
        // }
        setValuesForm(valueConfig)
    }

    const onSubmit = val => {
        let modules = {...modulesConfig}
        modules = {
            ...modules,
            [nodeSelected.toUpperCase()]: val
        }
        setModulesConfig(modules)
        setIsOpenDescript(false)
        // let currentSelectedIndex = _.findIndex(elements, {id: idNodeSelected}),
        //     itemSelected = _.cloneDeep(_.find(elements, {id: idNodeSelected}));
        // itemSelected = {
        //     ...itemSelected,
        //     data: {
        //         ...itemSelected?.data,
        //         description: "ai biet gi dau"
        //     }
        // }
        // setElements((elements) => {
        //     const clonedElements = _.cloneDeep(elements);
        //     clonedElements[currentSelectedIndex] =itemSelected
        //     return clonedElements;
        // });
    }

    const onSubmitWorkflow = () => {
        form.submit();
        if (isUpdate) {
            actionUpdate({query: {...modulesConfig, ...valuesForm, id: props.match.params?.id}, props})
        } else {
            actionCreate({query: {...modulesConfig, ...valuesForm}, props})
        }
        // setTimeout(() => {
        //     console.log(modulesConfig)
        // }, 1000)
    }

    return (
        <Spin spinning={itemFetching}>
            <div className="hn__workflow--submit">
                <Button
                    className="me-3"
                    disabled={itemFetching}
                    type="default"
                    // shape="circle"
                    onClick={() => props.history.push('/workflow')}
                    // icon={<CaretLeftOutlined/>}
                    size="large">
                    Back
                </Button>
                <Button type="primary"
                    // shape="circle"
                        onClick={onSubmitWorkflow}
                    // icon={itemFetching ? <Icon type="sync-outlined" spin/> : <CloudUploadOutlined/>}
                        size="large">
                    Save
                    {itemFetching ?
                        <Icon type="sync-outlined" spin/> : null}
                </Button>
            </div>
            <div id="">
                <Form
                    id="hn__workflow--id-detail"
                    className="pt-3"
                    layout="vertical"
                    onFinish={onSubmit}
                    onValuesChange={onChangeValue}
                    form={form}
                >
                    <div className="row px-3">
                        <div className="col-4">
                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[{
                                    required: true,
                                    message: 'Please input your title!'
                                }]}
                            >
                                <Input min={1} max={30} className="w-100"/>
                            </Form.Item>
                        </div>
                        <div className="col-4">
                            <Form.Item
                                label="Timezone"
                                name="timezone"
                                rules={[{
                                    required: true,
                                    message: 'Please choose your timezone!'
                                }]}
                                initialValue={itemMaster?.default_timezone || ""}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    allowClear
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    {
                                        (itemMaster?.timezones || []).map((i, k) => {
                                            return (
                                                <Select.Option key={k}
                                                               value={i}>{i}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-4">
                            <Form.Item
                                label="Status"
                                name="status"
                                rules={[{
                                    required: true,
                                    message: 'Please choose status!'
                                }]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    allowClear
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    {
                                        STATUS.map((i, k) => {
                                            return (
                                                <Select.Option key={k}
                                                               value={i?.value}>{i?.title}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                    <Drawer
                        closable={false}
                        maskClosable={false}
                        getContainer={() => document.getElementById("hn__workflow--id-detail")}
                        title="Information"
                        placement="right"
                        onClose={e => {
                            setIsOpenDescript(false)
                            setNodeSelected("")
                            setIdNodeSelected("")
                        }}
                        visible={isOpenDescript}>
                        <div className="row">
                            {
                                itemFormSelected[nodeSelected] ? itemFormSelected[nodeSelected] : null
                            }
                            <div className="col-12">
                                <Form.Item className="text-end mb-0">
                                    <Button type="primary"
                                            htmlType="submit"
                                            className="me-3 mb-0">
                                        Save
                                        {/*{props?.itemStatusAction?.profileConfigUpdate &&*/}
                                        {/*<Icon type="sync-outlined" spin/>}*/}
                                    </Button>
                                    <Button htmlType="button"
                                            className="mb-0"
                                            onClick={() => {
                                                if (nodeSelected === 'ACTION') {
                                                    form.setFieldsValue({action: itemDetail?.FORM?.action || []});
                                                    setValuesForm({
                                                        ...itemDetail?.FORM,
                                                        action: itemDetail?.FORM?.action || []
                                                    })
                                                }
                                                // form.resetFields();
                                                // form.setFieldsValue(valuesForm)
                                                setIsOpenDescript(false);
                                            }}>
                                        Cancel
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Drawer>
                </Form>
            </div>
            <div className="App">
                <Layout elements={elements}/>
            </div>
        </Spin>
    );
};

export default CustomNodeFlow;

const detectedChangeConfig = (value, type) => {
    if (type.toLowerCase() === "source") {
        return (value?.tag || []).length > 0;
    } else if (type.toLowerCase() === "action") {
        const listField = ["trigger_type", "period", "time", "day_of_week", "day_of_month", "month_of_year", "skip_value", "skip_type"]
        let check = false;
        listField.map(i => {
            if (value[i]) {
                check = true
            }
        })
        return check
    } else if (type.toLowerCase() === "waitthencheck") {
        return (value?.action || []).length > 0;
    }
    return false
}
