import * as Types from '../types/Bill';

export const billMasterAction = (filters) => ({type: Types.BILL_MASTER_ACTION, filters});
export const billMasterSuccess = (master) => ({
    type: Types.BILL_MASTER_SUCCESS,
    master
});

export const billListAction = (filters) => ({type: Types.BILL_LIST_ACTION, filters});
export const billListSuccess = (list) => ({
    type: Types.BILL_LIST_SUCCESS,
    list
});

export const billDetailAction = (params) => ({type: Types.BILL_DETAIL_ACTION, params});
export const billDetailSuccess = (item) => ({
    type: Types.BILL_DETAIL_SUCCESS,
    item
});

export const billUpdateAction = (params) => ({type: Types.BILL_UPDATE_ACTION, params});
export const billUpdateSuccess = (updateData) => ({
    type: Types.BILL_UPDATE_SUCCESS,
    updateData
});

export const billCreateAction = (params) => ({type: Types.BILL_CREATE_ACTION, params});
export const billCreateSuccess = (createData) => ({
    type: Types.BILL_CREATE_SUCCESS,
    createData
});

export const billInitStore = (params) => ({type: Types.BILL_INIT, params});
export const billClearStore = (params) => ({type: Types.BILL_FAIL, params});
