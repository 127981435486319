import * as Types from '../types/PlansPricing';

export const plansPricingMasterAction = (filters) => ({type: Types.PLANS_PRICING_MASTER_ACTION, filters});
export const plansPricingMasterSuccess = (master) => ({
    type: Types.PLANS_PRICING_MASTER_SUCCESS,
    master
});

export const plansPricingListAction = (filters) => ({type: Types.PLANS_PRICING_LIST_ACTION, filters});
export const plansPricingListSuccess = (list) => ({
    type: Types.PLANS_PRICING_LIST_SUCCESS,
    list
});

export const plansPricingDetailAction = (params) => ({type: Types.PLANS_PRICING_DETAIL_ACTION, params});
export const plansPricingDetailSuccess = (item) => ({
    type: Types.PLANS_PRICING_DETAIL_SUCCESS,
    item
});

export const plansPricingUpdateAction = (params) => ({type: Types.PLANS_PRICING_UPDATE_ACTION, params});
export const plansPricingUpdateSuccess = (updateData) => ({
    type: Types.PLANS_PRICING_UPDATE_SUCCESS,
    updateData
});

export const plansPricingCreateAction = (params) => ({type: Types.PLANS_PRICING_CREATE_ACTION, params});
export const plansPricingCreateSuccess = (createData) => ({
    type: Types.PLANS_PRICING_CREATE_SUCCESS,
    createData
});


export const plansPricingRemoveAction = (params) => ({type: Types.PLANS_PRICING_REMOVE_ACTION, params});
export const plansPricingRemoveSuccess = (removeData) => ({
    type: Types.PLANS_PRICING_REMOVE_SUCCESS,
    removeData
});

export const plansPricingInitStore = (params) => ({type: Types.PLANS_PRICING_INIT, params});
export const plansPricingClearStore = (params) => ({type: Types.PLANS_PRICING_FAIL, params});
