import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class TagRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.tag = data?.tag || "";
        this.emails = data?.emails || "";
    }

    exportCreate() {
        return {
            tag: this.tag,
            emails: this.emails,
        }
    }

    exportUpdate() {
        return {
            id: this.id,
            tag: this.tag,
            emails: this.emails,
        }
    }

}

export const common = {
    ACTION_FIELD: {
        tag: "tag",
        emails: "emails",
        created_date: "created_date",
        owner: "owner",
    },
}

export const filtersParams = (filters) => {
    return removeObjectNullFull({
        search: filters?.search || "",
        is_owned: filters?.is_owned,
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
    })
}

