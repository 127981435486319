import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getProfile} from "../../../data/reselects/authSelector";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import {capitalizeFirstLetter} from "../../../utils/functions";
import {CaretDownOutlined, MailOutlined} from "@ant-design/icons";

const {SubMenu} = Menu;

const HeaderMenu = props => {
    const [itemMenu, setItemMenu] = useState([]),
        itemAuthProfile = useSelector(getProfile());

    useEffect(() => {
        setItemMenu(itemAuthProfile?.menu || [])
    }, [itemAuthProfile.menu]);

    return (
        <>
            {
                itemMenu.length > 0 && (
                    <Menu
                        defaultSelectedKeys={['/']}
                        selectedKeys={[props?.location?.pathname]}
                        mode="horizontal"
                        // inlineCollapsed={props?.collapsed}
                        className="hn__header--menu"
                    >
                        {
                            itemMenu.map((i, k) => {
                                if ((i?.items || []).length > 0) {
                                    return (
                                        <SubMenu
                                            popupClassName={`${props?.collapsed ? "left--60px" : ""} hn__navigation--hide-all-popup-submenu`}
                                            key={i?.group}
                                            title={
                                                <>
                                                    {/*<i className={`width-18px height--20px hn__navigation--icon icon--${i?.icon ? i?.icon : "off-market"} ${props?.collapsed ? "me-2" : "me-2"}`}/>*/}
                                                    {
                                                        props.collapsed ?
                                                            <i className={`icon--arrow-right ${props?.collapsed ? "me-2" : ""}`}/> : ""
                                                    }
                                                    {!props?.collapsed && i?.name}
                                                    <CaretDownOutlined className="icon-ant ms-1"/>
                                                </>
                                            } className="fs--14px hn__navigation--hide-all-submenu"
                                            onTitleClick={() => ((i?.items || []).length > 0) ? false : props.history.push(`/${i?.route}`)}>
                                            {renderMenuItem(i?.items || [], props)}
                                        </SubMenu>
                                    )
                                } else {
                                    return (
                                        <Menu.Item key={`/${i.route}`} className="">
                                            <Link to={`/${i.route}`}
                                                  className="fs--14px text-decoration-none">
                                                {/*<i className={`width-18px height--20px hn__navigation--icon icon--${i?.icon ? i?.icon : "off-market"} ${props?.collapsed ? "me-3" : "me-2"}`}/>*/}
                                                {!props?.collapsed && capitalizeFirstLetter(i.name)}
                                            </Link>
                                        </Menu.Item>
                                    )
                                }
                            })
                        }
                    </Menu>
                )
            }
        </>
    )
}

const renderMenuItem = (item, props) => {
    let arrayMenu = [];
    if (item.length > 0) {
        item.map((i, k) => {
            if ((i?.items || []).length > 0) {
                arrayMenu.push(
                    <SubMenu
                        // popupClassName={`${props?.collapsed ? "left--60px" : ""} hn__navigation--hide-all-popup-submenu`}
                        key={`${i?.route}_${k}`}
                        title={i?.name} className="fs--14px hn__navigation--hide-all-submenu"
                        onTitleClick={() => {}}>
                        {renderMenuItem(i?.items || [])}
                    </SubMenu>
                )
            } else {
                arrayMenu.push(
                    <Menu.Item key={`/${i.route}`} className="">
                        <Link to={`/${i.route}`}
                              className="fs--14px text-decoration-none">{capitalizeFirstLetter(i.name)}</Link>
                    </Menu.Item>
                )
            }
        })
    }
    return arrayMenu
}
export default HeaderMenu
