import React, {useEffect, useState, createRef} from 'react';
import Slider from "react-slick";

function SampleNextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={className}
            style={{...style, display: "block"}}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={className}
            style={{...style, display: "block"}}
            onClick={onClick}
        />
    );
}

let firstClientX, clientX;

const preventTouch = e => {
    const minValue = 3; // threshold

    clientX = e.touches[0].clientX - firstClientX;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
        e.preventDefault();
        e.returnValue = false;

        return false;
    }
};

const touchStart = e => {
    firstClientX = e.touches[0].clientX;
};

const SlideItem = (props) => {
    let containerRef = createRef();

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener("touchstart", touchStart);
            containerRef.current.addEventListener("touchmove", preventTouch, {
                passive: false
            });
        }

        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener("touchstart", touchStart);
                containerRef.current.removeEventListener("touchmove", preventTouch, {
                    passive: false
                });
            }
        };
    });

    let settings = {
        ...props.settings,
        dots: props?.settings?.dots !== undefined ? props?.settings?.dots : false,
        infinite: props?.settings?.infinite,
        autoplay: props?.settings?.autoplay !== undefined ? props?.settings?.autoplay : true,
        speed: props?.settings?.speed || 500,
        slidesToShow: props?.settings?.slidesToShow || 1,
        slidesToScroll: props?.settings?.slidesToScroll || 1,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>
    };
    return (
        <div ref={containerRef}>
            <Slider
                className={props.className || ""} {...settings}>
                {props.children}
            </Slider>
        </div>
    )
}

export default SlideItem;
