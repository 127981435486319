import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/PlansPricing';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/plansPricingService';
import * as FunctionAction from '../actions/plansPricingAction';
import * as AuthAction from '../actions/authAction';
import {message} from "antd";
// import PlansPricingRequest from "../mapping/Request/PlansPricingRequest";
import TAG_DEFINE from "../../constants/common";

// function* actionGetListPlansPricing() {
//     yield takeLatest(Types.PLANS_PRICING_LIST_ACTION, Func.sagaWrapper(function* (action) {
//         const params = parseListRequestParams(plansPricingFiltersParams(action.filters) || {});
//         const result = yield call(FunctionService.actionPlansPricingList, params);
//         yield put(FunctionAction.plansPricingListSuccess(result));
//     }, errorHandle(Types.PLANS_PRICING_FAIL)))
// }

// function* actionGetItem() {
//     yield takeLatest(Types.PLANS_PRICING_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
//         const result = yield call(FunctionService.actionGetDetail);
//         yield put(FunctionAction.plansPricingDetailSuccess(result));
//     }, errorHandle(Types.PLANS_PRICING_FAIL)))
// }

function* actionGetMasterPlansPricing() {
    yield takeLatest(Types.PLANS_PRICING_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.getMaster, action.params);
        yield put(FunctionAction.plansPricingMasterSuccess(result));
    }, errorHandle(Types.PLANS_PRICING_FAIL)))
}

// function* createAction() {
//     yield takeLatest(Types.PLANS_PRICING_CREATE_ACTION, Func.sagaWrapper(function* (action) {
//         const data = new PlansPricingRequest(action.params).exportCreate();
//         const result = yield call(FunctionService.create, data);
//         message.success("Success");
//         window.location = result
//         // yield put(FunctionAction.plansPricingCreateSuccess(result));
//         // yield put(AuthAction.generalAction(result));
//     }, errorHandle(Types.PLANS_PRICING_FAIL)))
// }
//
// function* updateAction() {
//     yield takeLatest(Types.PLANS_PRICING_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
//         const data = new PlansPricingRequest(action.params).exportUpdate();
//         const result = yield call(FunctionService.update, data);
//         message.success(result);
//         yield put(FunctionAction.plansPricingUpdateSuccess(result));
//         yield put(AuthAction.generalAction(result));
//         // yield put(FunctionAction.plansPricingDetailAction({id: action.params.id}));
//     }, errorHandle(Types.PLANS_PRICING_FAIL)))
// }
//
// function* removeAction() {
//     yield takeLatest(Types.PLANS_PRICING_REMOVE_ACTION, Func.sagaWrapper(function* (action) {
//         const result = yield call(FunctionService.remove, action.params?.id);
//         message.success(result);
//         yield put(FunctionAction.plansPricingDetailAction());
//         yield put(AuthAction.generalAction(result));
//     }, errorHandle(Types.PLANS_PRICING_FAIL)))
// }

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        console.log("===> ", e)
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* plansPricingSaga() {
    yield all([
        fork(actionGetMasterPlansPricing),
        // fork(createAction),
        // fork(removeAction),
        // fork(actionGetItem),
        // fork(updateAction),
    ])
}
