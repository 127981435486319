import {createSelector} from 'reselect'

const stateReducer = state => state.subscriptionReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        resultReducer => resultReducer.isFetching
    )

export const selectErrors = (initItems) =>
    createSelector(
        stateReducer,
        resultReducer => resultReducer.errors
    )

export const selectList = (initItems) =>
    createSelector(
        stateReducer,
        resultReducer => resultReducer.data
    )

export const selectGetCurrent = (initItems) =>
    createSelector(
        stateReducer,
        resultReducer => resultReducer.current
    )
export const selectItem = (initItems) =>
    createSelector(
        stateReducer,
        resultReducer => resultReducer.detail
    )
