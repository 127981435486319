import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import PageBuildersResponse from "../mapping/Response/PageBuildersResponse";

// export function masterPageBuilders() {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.WORKFLOW_MASTER,
//         // params: removeObjectNull({ city_id }),
//         parser: data => new PageBuildersResponse(data.data.result).exportMaster(),
//     }).get();
// }
//
// export function actionPageBuildersList(params) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.WORKFLOW_LIST,
//         params: params,
//         parser: dataParser,
//     }).get();
// }
//
// export function actionPageBuildersExport(params) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.WORKFLOW_LIST,
//         params: params,
//         isDownload: true,
//         parser: data => data,
//     }).get();
// }

export function actionPageBuildersDetail(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.PAGE_BUILDERS_GET_ITEM,
        // endpointParams: {id},
        parser: data => new PageBuildersResponse(data.data.result).exportDetail(),
    }).get();
}

export function actionPageBuildersTemplateList(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.PAGE_BUILDERS_LIST_TEMPLATE,
        // endpointParams: {id},
        parser: dataParser,
    }).get();
}

export function update(data, id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.PAGE_BUILDERS_UPDATE,
        // endpointParams: {id},
        parser: data => data?.data?.result,
    }).put(data);
}

// export function create(data) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.WORKFLOW_CREATE,
//         parser: data => data?.data?.result,
//     }).post(data);
// }
//
// export function manualTrigger(data, id) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.WORKFLOW_MANUAL_TRIGGER,
//         endpointParams: {id},
//         parser: data => data?.data?.result,
//     }).post(data);
// }


// export function actionPageBuildersDetailLogList(id) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.WORKFLOW_DETAIL_LOG,
//         // params: params,
//         endpointParams: {id},
//         parser: detailLogParser,
//     }).get();
// }

function dataParser(data = {}) {
    return {
        ...data.data?.result,
        result: (data.data.result?.templates || []).map(item => new PageBuildersResponse(item).exportListTemplate()),
    }
}

//
// function detailLogParser(data = {}) {
//     return {
//         ...data.data,
//         result: (data.data.result || []).map(item => new PageBuildersDetailLogResponse(item).exportDetail()),
//     }
// }
