import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class PageBuildersResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.code = data?.code || "";
        this.created_at = data?.created_at || "";
        this.html = data?.html || "";
        this.id = data?.id || "";
        this.status = data?.status ?? 0;
        this.title = data?.title || "";
        this.updated_at = data?.updated_at || "";
        this.user_id = data?.user_id || "";
        this.html_raw = data?.html_raw || "";
        this.template = {
            id: data?.id || "",
            user_id: data?.user_id || "",
            code: data?.code || "",
            title: data?.title || "",
            html: data?.html || "",
            html_raw: data?.html_raw || "",
            created_at: data?.created_at || "",
            updated_at: data?.updated_at || "",
            status: data?.status || 0,
            is_template: data?.is_template || 0,
            is_reiq_website: data?.is_reiq_website || 0,
            reiq_website_path: data?.reiq_website_path || "",
        }
    }

    //
    // exportList() {
    //     return {
    //         id: this.id,
    //         title: this.title,
    //         owner: this.owner,
    //         status: this.status,
    //         last_run_at: moment(this.last_run_at).format(DATE_TIME_FORMAT.SHORT_DATE_US_FULL),
    //         next_run_at: moment(this.next_run_at).format(DATE_TIME_FORMAT.SHORT_DATE_US_FULL),
    //         created_date: moment(this.created_date).format(DATE_TIME_FORMAT.SHORT_DATE_US),
    //     }
    // }

    exportListTemplate() {
        return {
            id: this.template?.id || "",
            user_id: this.template?.user_id || "",
            code: this.template?.code || "",
            title: this.template?.title || "",
            html: this.template?.html || "",
            html_raw: this.template?.html_raw || "",
            created_at: this.template?.created_at || "",
            updated_at: this.template?.updated_at || "",
            status: this.template?.status || 0,
            is_template: this.template?.is_template || 0,
            is_reiq_website: this.template?.is_reiq_website,
            reiq_website_path: this.template?.reiq_website_path,
        }
    }

    exportDetail() {
        return {
            code: this.code,
            created_at: this.created_at,
            html: this.html,
            id: this.id,
            status: this.status,
            title: this.title,
            updated_at: this.updated_at,
            user_id: this.user_id,
            html_raw: this.html_raw,
        }
    }
}

export const column = ["title", "status", "owner", "last_run_at", "next_run_at", "created_date"];

export const fieldDetailRemove = [];

export const filtersResponse = (filters) => {
    return removeObjectNullFull({
        search: filters?.search || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    })
}

export class WorkflowMasterResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.tag = data?.tag || {};
        this.sms_template = data?.sms_template || {};
        this.email_template = data?.email_template || {};
        this.default_timezone = data?.default_timezone || "";
        this.timezones = data?.timezones || [];
    }

    exportMaster() {
        return {
            tag: this.tag,
            sms_template: this.sms_template,
            email_template: this.email_template,
            default_timezone: this.default_timezone,
            timezones: this.timezones,
        }
    }
}

export class WorkflowDetailLogResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.running_time = data?.running_time || {};
        this.status = data?.status || {};
        this.log = data?.log || "";
    }

    exportDetail() {
        return {
            running_time: moment(this.running_time).format(DATE_TIME_FORMAT.SHORT_DATE_US_FULL),
            status: this.status,
            log: this.log,
        }
    }
}

export const detailLogColumn = ["running_time", "status", "log"];
