export const PAGE_BUILDERS_MASTER_ACTION = "PAGE_BUILDERS_MASTER_ACTION";
export const PAGE_BUILDERS_MASTER_SUCCESS = "PAGE_BUILDERS_MASTER_SUCCESS";

export const PAGE_BUILDERS_LIST_ACTION = "PAGE_BUILDERS_LIST_ACTION";
export const PAGE_BUILDERS_LIST_SUCCESS = "PAGE_BUILDERS_LIST_SUCCESS";

export const PAGE_BUILDERS_DETAIL_ACTION = "PAGE_BUILDERS_DETAIL_ACTION";
export const PAGE_BUILDERS_DETAIL_SUCCESS = "PAGE_BUILDERS_DETAIL_SUCCESS";

export const PAGE_BUILDERS_UPDATE_ACTION = "PAGE_BUILDERS_UPDATE_ACTION";
export const PAGE_BUILDERS_UPDATE_SUCCESS = "PAGE_BUILDERS_UPDATE_SUCCESS";

export const PAGE_BUILDERS_CREATE_ACTION = "PAGE_BUILDERS_CREATE_ACTION";
export const PAGE_BUILDERS_CREATE_SUCCESS = "PAGE_BUILDERS_CREATE_SUCCESS";

export const PAGE_BUILDERS_DETAIL_LOG_ACTION = "PAGE_BUILDERS_DETAIL_LOG_ACTION";
export const PAGE_BUILDERS_DETAIL_LOG_SUCCESS = "PAGE_BUILDERS_DETAIL_LOG_SUCCESS";

export const PAGE_BUILDERS_MANUAL_TRIGGER_ACTION = "PAGE_BUILDERS_MANUAL_TRIGGER_ACTION";
export const PAGE_BUILDERS_MANUAL_TRIGGER_SUCCESS = "PAGE_BUILDERS_MANUAL_TRIGGER_SUCCESS";

export const PAGE_BUILDERS_INIT = "PAGE_BUILDERS_INIT";
export const PAGE_BUILDERS_FAIL = "PAGE_BUILDERS_FAIL";
