import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class SmsTemplateResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.title = data?.title || "";
        this.owner = data?.owner || "";
        this.template = data?.template || "";
        this.created_date = data?.created_date || "";
    }

    exportList() {
        return {
            id: this.id,
            title: this.title,
            owner: this.owner,
            created_date: moment(this.created_date).format(DATE_TIME_FORMAT.SHORT_DATE_US),
        }
    }

    exportDetail() {
        return {
            id: this.id,
            title: this.title,
            owner: this.owner,
            template: this.template,
            created_date: moment(this.created_date).format(DATE_TIME_FORMAT.SHORT_DATE_US),
        }
    }
}

export const column = ["title", "owner", "created_date"];

export const fieldDetailRemove = [];

export const filtersResponse = (filters) => {
    return removeObjectNullFull({
        search: filters?.search || "",
        tag: filters?.tag || "",
        is_owned: convertBooleanToInt(filters?.is_owned),
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    })
}

export class SmsTemplateMasterResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.status = data?.STATUS;
        this.tag = data?.TAG
    }

    exportMaster() {
        return {
            status: this.status,
            tag: this.tag,
        }
    }
}
