const Define = {
    GENERAL: {}
};
export default Define;

export const FILES = {
    CDN_IMG_DEFAULT: "",
    BANNER_DEFAULT: "/layout/banner/404.png",
    SPINNING: "/layout/banner/spinning.gif",
    SIZE_THUMB: "200x"
};

export const UPLOAD = {
    MAX_FILE_SIZE: 10 * 1024 * 1024, // 5MB
}

export const PATHS = {
    ROOT: {path: '/'},
    // AUTHENTICATION_NOT_FOUND: {path: `${Config.API_BASE_URL}/auth/login`},
    LOGIN: {path: '/login'},
    LOGOUT: {path: '/logout'},
    PROFILE: {path: '/profile'},
    REFRESH: {path: '/refresh'},
    PAGE_403: {path: '/403'},
    HOTELS: {
        TITLE: "hotels",
        path: '/hotels',
        LIST: {path: '/hotels'},
        CREATE: {path: '/hotels/action'},
        DETAIL: {path: '/hotels/action/:id'},
    },
};

export const CONFIG_LOCAL_STORAGE = {
    PREFIX: 'hn',
    COMMON_ACCESS_TOKEN: 'hn_utk',
    ACCESS_TOKEN: 'utk',
    TWILIO_TOKEN: 'twilio_tk',
    TEMPLATE_CONTACT: '_template_contact',
    TEMPLATE_PAGE: '_template_page',
    REFRESH_TOKEN: 'rft',
    EXPIRES: 'expires',
    PROFILE: 'pf',
    MENU_COLLAPSED: 'menu_collapsed',
    CONTENT_LANGUAGE: 'content_language',
    TOKEN_V1: "connect.sid",
    COMPANIES_SYSTEM: "x-company-id",
    REFERENCE: "ref",
    CONTACT_SOURCE_FILTER: "CONTACT_SOURCE_FILTER"
};

export const DATE_TIME_FORMAT = {
    MINIMUM_YEAR: 1970,
    SHORT_DATE: 'DD/MM/YYYY',
    SHORT_DATE_EXPORT: 'YYYY-MM-DD',
    SHORT_DATE_US: 'MM-DD-YYYY',
    SHORT_DATE_US_FULL: 'MM-DD-YYYY HH:mm:ss',
    DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
    DAY_WEEK_DATETIME: 'dddd, DD/MM/YYYY HH:mm',
    TIME_AND_DATE: 'HH:mm DD/MM/YYYY',
    SHORT_TIME: 'HH:mm',
    SHORT_TIME_FULL: 'HH:mm a',
    FULL_TIME: 'HH:mm:ss',
    SHORT_MONTH: 'MM/YYYY',
    INBOX_DATE: 'MM/DD/YYYY HH:mm:ss',
}

export const PAGINATION = {
    page_index: 1,
    page_size: 10,
    total: 0,
    total_page: 1,
}

export const REFRESH_TOKEN_DELAY_TIMEOUT = (5 * 60 * 1000); // 5 minutes before the access token was expired

export const ROUTE_REDIRECT = {
    HOME: "/",
    INBOX: "/inbox",
    EMBEDDED: {
        HOME: "/embedded-form",
        CREATE: "/embedded-form/action",
        UPDATE: "/embedded-form/action/:id",
    },
    TEAMS: {
        HOME: "/teams",
        CREATE: "/teams/action",
        UPDATE: "/teams/action/:id",
    },
    CONTACT: {
        HOME: "/contact",
        CREATE: "/contact/action",
        UPDATE: "/contact/action/:id",
    },
    TAG: {
        HOME: "/tag",
        CREATE: "/tag/action",
        UPDATE: "/tag/action/:id",
    },
    EMAIL_HISTORY: {
        HOME: "/email-history",
        CREATE: "/email-history/action",
        UPDATE: "/email-history/action/:id",
    },
    TEAM_POINT: {
        HOME: "/team-point",
        DETAIL: "/team-point/detail",
    },
    TEMPLATE: {
        HOME: "/email-template",
        CREATE: "/email-template/action",
        UPDATE: "/email-template/action/:id",
    },
    WORKFLOW: {
        HOME: "/workflow",
        CREATE: "/workflow/action",
        UPDATE: "/workflow/action/:id",
    },
    SMS_TEMPLATE: {
        HOME: "/sms-template",
        CREATE: "/sms-template/action",
        UPDATE: "/sms-template/action/:id",
    },
    SMS_HISTORY: {
        HOME: "/sms-history",
        CREATE: "/sms-history/action",
        UPDATE: "/sms-history/action/:id",
    },
    BILL: {
        HOME: "/bill/detail",
        CREATE: "/bill/action",
        UPDATE: "/bill/action/:id",
    },
    VOICE_CALL: {
        HOME: "/voice-call",
        CREATE: "/voice-call/action",
        UPDATE: "/voice-call/action/:id",
    },
    TASK: {
        HOME: "/task",
        CREATE: "/task/action",
        UPDATE: "/task/action/:id",
    },
}

export const COUNTRY_CODE = [
    {label: "Afghanistan", value: "AFG"},
    {label: "Albania", value: "ALB"},
    {label: "Algeria", value: "DZA"},
    {label: "American Samoa", value: "ASM"},
    {label: "Andorra", value: "AND"},
    {label: "Angola", value: "AGO"},
    {label: "Anguilla", value: "AIA"},
    {label: "Antarctica", value: "ATA"},
    {label: "Antigua and Barbuda", value: "ATG"},
    {label: "Argentina", value: "ARG"},
    {label: "Armenia", value: "ARM"},
    {label: "Aruba", value: "ABW"},
    {label: "Australia", value: "AUS"},
    {label: "Austria", value: "AUT"},
    {label: "Azerbaijan", value: "AZE"},
    {label: "Bahamas", value: "BHS"},
    {label: "Bahrain", value: "BHR"},
    {label: "Bangladesh", value: "BGD"},
    {label: "Barbados", value: "BRB"},
    {label: "Belarus", value: "BLR"},
    {label: "Belgium", value: "BEL"},
    {label: "Belize", value: "BLZ"},
    {label: "Benin", value: "BEN"},
    {label: "Bermuda", value: "BMU"},
    {label: "Bhutan", value: "BTN"},
    {label: "Bolivia (Plurinational State of)", value: "BOL"},
    {label: "Bonaire, Sint Eustatius and Saba", value: "BES"},
    {label: "Bosnia and Herzegovina", value: "BIH"},
    {label: "Botswana", value: "BWA"},
    {label: "Bouvet Island", value: "BVT"},
    {label: "Brazil", value: "BRA"},
    {label: "British Indian Ocean Territory", value: "IOT"},
    {label: "Brunei Darussalam", value: "BRN"},
    {label: "Bulgaria", value: "BGR"},
    {label: "Burkina Faso", value: "BFA"},
    {label: "Burundi", value: "BDI"},
    {label: "Cabo Verde", value: "CPV"},
    {label: "Cambodia", value: "KHM"},
    {label: "Cameroon", value: "CMR"},
    {label: "Canada", value: "CAN"},
    {label: "Cayman Islands", value: "CYM"},
    {label: "Central African Republic", value: "CAF"},
    {label: "Chad", value: "TCD"},
    {label: "Chile", value: "CHL"},
    {label: "China", value: "CHN"},
    {label: "Christmas Island", value: "CXR"},
    {label: "Cocos (Keeling) Islands", value: "CCK"},
    {label: "Colombia", value: "COL"},
    {label: "Comoros", value: "COM"},
    {label: "Congo (the Democratic Republic of the)", value: "COD"},
    {label: "Congo", value: "COG"},
    {label: "Cook Islands", value: "COK"},
    {label: "Costa Rica", value: "CRI"},
    {label: "Croatia", value: "HRV"},
    {label: "Cuba", value: "CUB"},
    {label: "Curaçao", value: "CUW"},
    {label: "Cyprus", value: "CYP"},
    {label: "Czechia", value: "CZE"},
    {label: "Côte d'Ivoire", value: "CIV"},
    {label: "Denmark", value: "DNK"},
    {label: "Djibouti", value: "DJI"},
    {label: "Dominica", value: "DMA"},
    {label: "Dominican Republic", value: "DOM"},
    {label: "Ecuador", value: "ECU"},
    {label: "Egypt", value: "EGY"},
    {label: "El Salvador", value: "SLV"},
    {label: "Equatorial Guinea", value: "GNQ"},
    {label: "Eritrea", value: "ERI"},
    {label: "Estonia", value: "EST"},
    {label: "Eswatini", value: "SWZ"},
    {label: "Ethiopia", value: "ETH"},
    {label: "Falkland Islands [Malvinas]", value: "FLK"},
    {label: "Faroe Islands", value: "FRO"},
    {label: "Fiji", value: "FJI"},
    {label: "Finland", value: "FIN"},
    {label: "France", value: "FRA"},
    {label: "French Guiana", value: "GUF"},
    {label: "French Polynesia", value: "PYF"},
    {label: "French Southern Territories", value: "ATF"},
    {label: "Gabon", value: "GAB"},
    {label: "Gambia", value: "GMB"},
    {label: "Georgia", value: "GEO"},
    {label: "Germany", value: "DEU"},
    {label: "Ghana", value: "GHA"},
    {label: "Gibraltar", value: "GIB"},
    {label: "Greece", value: "GRC"},
    {label: "Greenland", value: "GRL"},
    {label: "Grenada", value: "GRD"},
    {label: "Guadeloupe", value: "GLP"},
    {label: "Guam", value: "GUM"},
    {label: "Guatemala", value: "GTM"},
    {label: "Guernsey", value: "GGY"},
    {label: "Guinea", value: "GIN"},
    {label: "Guinea-Bissau", value: "GNB"},
    {label: "Guyana", value: "GUY"},
    {label: "Haiti", value: "HTI"},
    {label: "Heard Island and McDonald Islands", value: "HMD"},
    {label: "Holy See", value: "VAT"},
    {label: "Honduras", value: "HND"},
    {label: "Hong Kong", value: "HKG"},
    {label: "Hungary", value: "HUN"},
    {label: "Iceland", value: "ISL"},
    {label: "India", value: "IND"},
    {label: "Indonesia", value: "IDN"},
    {label: "Iran (Islamic Republic of)", value: "IRN"},
    {label: "Iraq", value: "IRQ"},
    {label: "Ireland", value: "IRL"},
    {label: "Isle of Man", value: "IMN"},
    {label: "Israel", value: "ISR"},
    {label: "Italy", value: "ITA"},
    {label: "Jamaica", value: "JAM"},
    {label: "Japan", value: "JPN"},
    {label: "Jersey", value: "JEY"},
    {label: "Jordan", value: "JOR"},
    {label: "Kazakhstan", value: "KAZ"},
    {label: "Kenya", value: "KEN"},
    {label: "Kiribati", value: "KIR"},
    {label: "Korea (the Democratic People's Republic of)", value: "PRK"},
    {label: "Korea (the Republic of)", value: "KOR"},
    {label: "Kuwait", value: "KWT"},
    {label: "Kyrgyzstan", value: "KGZ"},
    {label: "Lao People's Democratic Republic", value: "LAO"},
    {label: "Latvia", value: "LVA"},
    {label: "Lebanon", value: "LBN"},
    {label: "Lesotho", value: "LSO"},
    {label: "Liberia", value: "LBR"},
    {label: "Libya", value: "LBY"},
    {label: "Liechtenstein", value: "LIE"},
    {label: "Lithuania", value: "LTU"},
    {label: "Luxembourg", value: "LUX"},
    {label: "Macao", value: "MAC"},
    {label: "Madagascar", value: "MDG"},
    {label: "Malawi", value: "MWI"},
    {label: "Malaysia", value: "MYS"},
    {label: "Maldives", value: "MDV"},
    {label: "Mali", value: "MLI"},
    {label: "Malta", value: "MLT"},
    {label: "Marshall Islands", value: "MHL"},
    {label: "Martinique", value: "MTQ"},
    {label: "Mauritania", value: "MRT"},
    {label: "Mauritius", value: "MUS"},
    {label: "Mayotte", value: "MYT"},
    {label: "Mexico", value: "MEX"},
    {label: "Micronesia (Federated States of)", value: "FSM"},
    {label: "Moldova (the Republic of)", value: "MDA"},
    {label: "Monaco", value: "MCO"},
    {label: "Mongolia", value: "MNG"},
    {label: "Montenegro", value: "MNE"},
    {label: "Montserrat", value: "MSR"},
    {label: "Morocco", value: "MAR"},
    {label: "Mozambique", value: "MOZ"},
    {label: "Myanmar", value: "MMR"},
    {label: "Namibia", value: "NAM"},
    {label: "Nauru", value: "NRU"},
    {label: "Nepal", value: "NPL"},
    {label: "Netherlands", value: "NLD"},
    {label: "New Caledonia", value: "NCL"},
    {label: "New Zealand", value: "NZL"},
    {label: "Nicaragua", value: "NIC"},
    {label: "Niger", value: "NER"},
    {label: "Nigeria", value: "NGA"},
    {label: "Niue", value: "NIU"},
    {label: "Norfolk Island", value: "NFK"},
    {label: "Northern Mariana Islands", value: "MNP"},
    {label: "Norway", value: "NOR"},
    {label: "Oman", value: "OMN"},
    {label: "Pakistan", value: "PAK"},
    {label: "Palau", value: "PLW"},
    {label: "Palestine, State of", value: "PSE"},
    {label: "Panama", value: "PAN"},
    {label: "Papua New Guinea", value: "PNG"},
    {label: "Paraguay", value: "PRY"},
    {label: "Peru", value: "PER"},
    {label: "Philippines", value: "PHL"},
    {label: "Pitcairn", value: "PCN"},
    {label: "Poland", value: "POL"},
    {label: "Portugal", value: "PRT"},
    {label: "Puerto Rico", value: "PRI"},
    {label: "Qatar", value: "QAT"},
    {label: "Republic of North Macedonia", value: "MKD"},
    {label: "Romania", value: "ROU"},
    {label: "Russian Federation", value: "RUS"},
    {label: "Rwanda", value: "RWA"},
    {label: "Réunion", value: "REU"},
    {label: "Saint Barthélemy", value: "BLM"},
    {label: "Saint Helena, Ascension and Tristan da Cunha", value: "SHN"},
    {label: "Saint Kitts and Nevis", value: "KNA"},
    {label: "Saint Lucia", value: "LCA"},
    {label: "Saint Martin (French part)", value: "MAF"},
    {label: "Saint Pierre and Miquelon", value: "SPM"},
    {label: "Saint Vincent and the Grenadines", value: "VCT"},
    {label: "Samoa", value: "WSM"},
    {label: "San Marino", value: "SMR"},
    {label: "Sao Tome and Principe", value: "STP"},
    {label: "Saudi Arabia", value: "SAU"},
    {label: "Senegal", value: "SEN"},
    {label: "Serbia", value: "SRB"},
    {label: "Seychelles", value: "SYC"},
    {label: "Sierra Leone", value: "SLE"},
    {label: "Singapore", value: "SGP"},
    {label: "Sint Maarten (Dutch part)", value: "SXM"},
    {label: "Slovakia", value: "SVK"},
    {label: "Slovenia", value: "SVN"},
    {label: "Solomon Islands", value: "SLB"},
    {label: "Somalia", value: "SOM"},
    {label: "South Africa", value: "ZAF"},
    {label: "South Georgia and the South Sandwich Islands", value: "SGS"},
    {label: "South Sudan", value: "SSD"},
    {label: "Spain", value: "ESP"},
    {label: "Sri Lanka", value: "LKA"},
    {label: "Sudan", value: "SDN"},
    {label: "Suriname", value: "SUR"},
    {label: "Svalbard and Jan Mayen", value: "SJM"},
    {label: "Sweden", value: "SWE"},
    {label: "Switzerland", value: "CHE"},
    {label: "Syrian Arab Republic", value: "SYR"},
    {label: "Taiwan (Province of China)", value: "TWN"},
    {label: "Tajikistan", value: "TJK"},
    {label: "Tanzania, United Republic of", value: "TZA"},
    {label: "Thailand", value: "THA"},
    {label: "Timor-Leste", value: "TLS"},
    {label: "Togo", value: "TGO"},
    {label: "Tokelau", value: "TKL"},
    {label: "Tonga", value: "TON"},
    {label: "Trinidad and Tobago", value: "TTO"},
    {label: "Tunisia", value: "TUN"},
    {label: "Turkey", value: "TUR"},
    {label: "Turkmenistan", value: "TKM"},
    {label: "Turks and Caicos Islands", value: "TCA"},
    {label: "Tuvalu", value: "TUV"},
    {label: "Uganda", value: "UGA"},
    {label: "Ukraine", value: "UKR"},
    {label: "United Arab Emirates", value: "ARE"},
    {label: "United Kingdom of Great Britain and Northern Ireland", value: "GBR"},
    {label: "United States Minor Outlying Islands", value: "UMI"},
    {label: "United States of America", value: "USA"},
    {label: "Uruguay", value: "URY"},
    {label: "Uzbekistan", value: "UZB"},
    {label: "Vanuatu", value: "VUT"},
    {label: "Venezuela (Bolivarian Republic of)", value: "VEN"},
    {label: "Viet Nam", value: "VNM"},
    {label: "Virgin Islands (British)", value: "VGB"},
    {label: "Virgin Islands (U.S.)", value: "VIR"},
    {label: "Wallis and Futuna", value: "WLF"},
    {label: "Western Sahara", value: "ESH"},
    {label: "Yemen", value: "YEM"},
    {label: "Zambia", value: "ZMB"},
    {label: "Zimbabwe", value: "ZWE"},
    {label: "Åland Islands", value: "ALA"},
]
