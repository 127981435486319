import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/Template';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/templateService';
import * as FunctionAction from '../actions/templateAction';
import {message} from "antd";
import TemplateRequest, {filtersParams} from "../mapping/Request/TemplateRequest";
import TAG_DEFINE from "../../constants/common";
import {actionTemplateEmailList} from "../services/templateService";
import {ROUTE_REDIRECT} from "../../constants/define";

function* actionGetListTemplate() {
    yield takeLatest(Types.TEMPLATE_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(filtersParams(action.filters) || {});
        const result = yield call(FunctionService.actionTemplateList, params);
        yield put(FunctionAction.templateListSuccess(result));
    }, errorHandle(Types.TEMPLATE_FAIL)))
}

function* actionGetItem() {
    yield takeLatest(Types.TEMPLATE_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionTemplateDetail, action.params.id);
        yield put(FunctionAction.templateDetailSuccess(result));
    }, errorHandle(Types.TEMPLATE_FAIL)))
}

function* actionGetMasterTemplate() {
    yield takeLatest(Types.TEMPLATE_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.masterTemplate, action.params);
        yield put(FunctionAction.templateMasterSuccess(result));
    }, errorHandle(Types.TEMPLATE_FAIL)))
}

function* createAction() {
    yield takeLatest(Types.TEMPLATE_CREATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new TemplateRequest(action.params).exportCreate();
        const result = yield call(FunctionService.create, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
        action.params.props.history.goBack();
        yield put({type: Types.TEMPLATE_LIST_ACTION});
    }, errorHandle(Types.TEMPLATE_FAIL)))
}

function* updateAction() {
    yield takeLatest(Types.TEMPLATE_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new TemplateRequest(action.params).exportUpdate();
        yield call(FunctionService.update, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.templateDetailAction({id: action.params.id}));
    }, errorHandle(Types.TEMPLATE_FAIL)))
}

function* deleteAction() {
    yield takeLatest(Types.TEMPLATE_DELETE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(FunctionService.deleteData, action.params?.id);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.templateListAction({filters: action.params?.filters}));
    }, errorHandle(Types.TEMPLATE_FAIL)))
}

function* cloneAction() {
    yield takeLatest(Types.TEMPLATE_CLONE_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.clone, action?.params?.query);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
        console.log(333, result)
        if (action?.params?.query?.is_template === 1) {
            action?.params?.history.push((ROUTE_REDIRECT.TEMPLATE.UPDATE).replace(":id", result?.id))
        } else {
            yield put({type: Types.TEMPLATE_LIST_ACTION});
        }
    }, errorHandle(Types.TEMPLATE_FAIL)))
}

function* actionGetListEmailTemplate() {
    yield takeLatest(Types.TEMPLATE_EMAIL_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionTemplateEmailList, {});
        yield put(FunctionAction.templateEmailListSuccess(result));
    }, errorHandle(Types.TEMPLATE_FAIL)))
}

export function* actionReadNotify() {
    yield takeLatest(Types.TEMPLATE_READ_NOTIFY_ACTION, Func.sagaWrapper(function* (action) {
        yield delay(500)
        const result = yield call(FunctionService.templateReadNotify, action.params?.id);
        yield put(FunctionAction.templateReadNotifySuccess(result));
    }, errorHandle(Types.TEMPLATE_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        console.log("===> ", e)
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* templateSaga() {
    yield all([
        fork(actionGetListTemplate),
        fork(createAction),
        fork(actionGetMasterTemplate),
        fork(actionGetItem),
        fork(updateAction),
        fork(deleteAction),
        fork(cloneAction),
        fork(actionGetListEmailTemplate),
        fork(actionReadNotify),
    ])
}
