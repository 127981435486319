export const CART_MASTER_ACTION = "CART_MASTER_ACTION";
export const CART_MASTER_SUCCESS = "CART_MASTER_SUCCESS";

export const CART_LIST_ACTION = "CART_LIST_ACTION";
export const CART_LIST_SUCCESS = "CART_LIST_SUCCESS";

export const CART_DETAIL_ACTION = "CART_DETAIL_ACTION";
export const CART_DETAIL_SUCCESS = "CART_DETAIL_SUCCESS";

export const CART_UPDATE_ACTION = "CART_UPDATE_ACTION";
export const CART_UPDATE_SUCCESS = "CART_UPDATE_SUCCESS";

export const CART_CREATE_ACTION = "CART_CREATE_ACTION";
export const CART_CREATE_SUCCESS = "CART_CREATE_SUCCESS";

export const CART_REMOVE_ACTION = "CART_REMOVE_ACTION";
export const CART_REMOVE_SUCCESS = "CART_REMOVE_SUCCESS";

export const CART_INIT = "CART_INIT";
export const CART_FAIL = "CART_FAIL";
