export const INBOX_MASTER_ACTION = "INBOX_MASTER_ACTION";
export const INBOX_MASTER_SUCCESS = "INBOX_MASTER_SUCCESS";

export const INBOX_LIST_ACTION = "INBOX_LIST_ACTION";
export const INBOX_LIST_SUCCESS = "INBOX_LIST_SUCCESS";

export const INBOX_DETAIL_ACTION = "INBOX_DETAIL_ACTION";
export const INBOX_DETAIL_SUCCESS = "INBOX_DETAIL_SUCCESS";

export const INBOX_UPDATE_ACTION = "INBOX_UPDATE_ACTION";
export const INBOX_UPDATE_SUCCESS = "INBOX_UPDATE_SUCCESS";

export const INBOX_SEND_ACTION = "INBOX_SEND_ACTION";
export const INBOX_SEND_SUCCESS = "INBOX_SEND_SUCCESS";

export const INBOX_INIT = "INBOX_INIT";
export const INBOX_FAIL = "INBOX_FAIL";
