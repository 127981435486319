import * as Types from '../types/SmsHistory';

export const smsHistoryMasterAction = (filters) => ({type: Types.SMS_HISTORY_MASTER_ACTION, filters});
export const smsHistoryMasterSuccess = (master) => ({
    type: Types.SMS_HISTORY_MASTER_SUCCESS,
    master
});

export const smsHistoryListAction = (filters) => ({type: Types.SMS_HISTORY_LIST_ACTION, filters});
export const smsHistoryListSuccess = (list) => ({
    type: Types.SMS_HISTORY_LIST_SUCCESS,
    list
});

export const smsHistoryDetailAction = (params) => ({type: Types.SMS_HISTORY_DETAIL_ACTION, params});
export const smsHistoryDetailSuccess = (detail) => ({
    type: Types.SMS_HISTORY_DETAIL_SUCCESS,
    detail
});

export const smsHistoryUpdateAction = (params) => ({type: Types.SMS_HISTORY_UPDATE_ACTION, params});
export const smsHistoryUpdateSuccess = (updateData) => ({
    type: Types.SMS_HISTORY_UPDATE_SUCCESS,
    updateData
});

export const smsHistoryCreateAction = (params) => ({type: Types.SMS_HISTORY_CREATE_ACTION, params});
export const smsHistoryCreateSuccess = (createData) => ({
    type: Types.SMS_HISTORY_CREATE_SUCCESS,
    createData
});

export const smsHistoryInitStore = (params) => ({type: Types.SMS_HISTORY_INIT, params});
export const smsHistoryClearStore = (params) => ({type: Types.SMS_HISTORY_FAIL, params});
