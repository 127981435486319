import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/pageBuildersAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.pageBuildersInitStore(params)),
        [dispatch]
    )
}

export const ActionGetList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.pageBuildersListAction(filters)),
        [dispatch]
    )
}

export const ActionGetItem = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.pageBuildersDetailAction(params)),
        [dispatch]
    )
}

export const ActionMasterData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.pageBuildersMasterAction(params)),
        [dispatch]
    )
}

export const ActionCreate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.pageBuildersCreateAction(params)),
        [dispatch]
    )
}
export const ActionUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.pageBuildersUpdateAction(params)),
        [dispatch]
    )
}

export const ActionDetailLog = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.pageBuildersDetailLogAction(params)),
        [dispatch]
    )
}

export const ActionManualTrigger = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.pageBuildersManualTriggerAction(params)),
        [dispatch]
    )
}
