import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class WorkflowResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || ""
        this.title = data?.title || ""
        this.owner = data?.owner || ""
        this.status = data?.status || 0
        this.last_run_at = data?.last_run_at || ""
        this.next_run_at = data?.next_run_at || ""
        this.created_date = data?.created_date || ""
        this.detail = {
            title: data?.detail?.title || "",
            timezone: data?.detail?.timezone || 0,
            status: data?.detail?.status || 0,
            SOURCE: this.generateDetailSource(data?.detail?.source),
            RULE: {
                trigger_type: data?.detail?.rule?.trigger_type || "",
                period: data?.detail?.rule?.period || "",
                time: data?.detail?.rule?.time || [],
                day_of_week: data?.detail?.rule?.day_of_week || [],
                day_of_month: data?.detail?.rule?.day_of_month || [],
                month_of_year: data?.detail?.rule?.month_of_year || [],
                skip_value: data?.detail?.rule?.skip_value || "",
                skip_type: data?.detail?.rule?.skip_type || "",
            },
            ACTION: {action: data?.detail?.action || []},
            FORM: {
                title: data?.detail?.title || "",
                action: data?.detail?.action || "",
                timezone: data?.detail?.timezone || 0,
                status: data?.detail?.status || 0,
                ...this.generateDetailSource(data?.detail?.source),
                trigger_type: data?.detail?.rule?.trigger_type || "",
                period: data?.detail?.rule?.period || "",
                time: data?.detail?.rule?.time || [],
                day_of_week: data?.detail?.rule?.day_of_week || [],
                day_of_month: data?.detail?.rule?.day_of_month || [],
                month_of_year: data?.detail?.rule?.month_of_year || [],
                skip_value: data?.detail?.rule?.skip_value || "",
                skip_type: data?.detail?.rule?.skip_type || "",

                type: this.generateDetailAction(data?.detail?.action)[0]?.type,
                template: this.generateDetailAction(data?.detail?.action)[0]?.template,
            }
        }
    }

    generateDetailSource(items = {}) {
        let newItems = {};
        if (Object.keys(items).length > 0) {
            Object.keys(items).map(i => {
                newItems = {
                    ...newItems,
                    [i]: items[i]
                }
            })
        }
        return newItems
    }

    generateDetailAction(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map(i => newItems.push({
                type: i?.type,
                template: i?.template,
            }))
        }
        return newItems
    }

    exportList() {
        return {
            id: this.id,
            title: this.title,
            owner: this.owner,
            status: this.status,
            last_run_at: moment(this.last_run_at).format(DATE_TIME_FORMAT.SHORT_DATE_US_FULL),
            next_run_at: moment(this.next_run_at).format(DATE_TIME_FORMAT.SHORT_DATE_US_FULL),
            created_date: moment(this.created_date).format(DATE_TIME_FORMAT.SHORT_DATE_US),
        }
    }

    exportDetail() {
        return {
            ...this.detail
        }
    }
}

export const column = ["title", "status", "owner", "last_run_at", "next_run_at", "created_date"];

export const fieldDetailRemove = [];

export const filtersResponse = (filters) => {
    return removeObjectNullFull({
        search: filters?.search || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    })
}

export class WorkflowMasterResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.tag = data?.tag || {};
        this.sms_template = data?.sms_template || {};
        this.email_template = data?.email_template || {};
        this.default_timezone = data?.default_timezone || "";
        this.timezones = data?.timezones || [];
    }

    exportMaster() {
        return {
            tag: this.tag,
            sms_template: this.sms_template,
            email_template: this.email_template,
            default_timezone: this.default_timezone,
            timezones: this.timezones,
        }
    }
}

export class WorkflowDetailLogResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.running_time = data?.running_time || {};
        this.status = data?.status || {};
        this.log = data?.log || "";
    }

    exportDetail() {
        return {
            running_time: moment(this.running_time).format(DATE_TIME_FORMAT.SHORT_DATE_US_FULL),
            status: this.status,
            log: this.log,
        }
    }
}

export const detailLogColumn = ["running_time", "status", "log"];
