import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/PageBuilders';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/pageBuildersService';
import * as FunctionAction from '../actions/pageBuildersAction';
import {message} from "antd";
import PageBuildersRequest from "../mapping/Request/PageBuildersRequest";
import TAG_DEFINE from "../../constants/common";

function* actionGetItem() {
    yield takeLatest(Types.PAGE_BUILDERS_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionPageBuildersDetail, action.params?.id);
        yield put(FunctionAction.pageBuildersDetailSuccess(result));
    }, errorHandle(Types.PAGE_BUILDERS_FAIL)))
}

function* actionGetListTemplate() {
    yield takeLatest(Types.PAGE_BUILDERS_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionPageBuildersTemplateList);
        yield put(FunctionAction.pageBuildersListSuccess(result));
    }, errorHandle(Types.PAGE_BUILDERS_FAIL)))
}

function* updateAction() {
    yield takeLatest(Types.PAGE_BUILDERS_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        // console.log(3333,  new PageBuildersRequest(action.params.query).exportUpdate())
        const data = new PageBuildersRequest(action.params.query).exportUpdate(),
            result = yield call(FunctionService.update, data);
        // console.log(444, data)
        yield put(FunctionAction.pageBuildersUpdateSuccess(true));
        message.success(result);
        window.location = "/page-builders"
        // yield put(FunctionAction.pageBuildersDetailAction({id: action.params.query.id}));
    }, errorHandle(Types.PAGE_BUILDERS_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        console.log("===> ", e)
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* pageBuildersSaga() {
    yield all([
        fork(actionGetItem),
        fork(updateAction),
        fork(actionGetListTemplate),
    ])
}
