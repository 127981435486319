export const WORKFLOW_MASTER_ACTION = "WORKFLOW_MASTER_ACTION";
export const WORKFLOW_MASTER_SUCCESS = "WORKFLOW_MASTER_SUCCESS";

export const WORKFLOW_LIST_ACTION = "WORKFLOW_LIST_ACTION";
export const WORKFLOW_LIST_SUCCESS = "WORKFLOW_LIST_SUCCESS";

export const WORKFLOW_DETAIL_ACTION = "WORKFLOW_DETAIL_ACTION";
export const WORKFLOW_DETAIL_SUCCESS = "WORKFLOW_DETAIL_SUCCESS";

export const WORKFLOW_UPDATE_ACTION = "WORKFLOW_UPDATE_ACTION";
export const WORKFLOW_UPDATE_SUCCESS = "WORKFLOW_UPDATE_SUCCESS";

export const WORKFLOW_CREATE_ACTION = "WORKFLOW_CREATE_ACTION";
export const WORKFLOW_CREATE_SUCCESS = "WORKFLOW_CREATE_SUCCESS";

export const WORKFLOW_DETAIL_LOG_ACTION = "WORKFLOW_DETAIL_LOG_ACTION";
export const WORKFLOW_DETAIL_LOG_SUCCESS = "WORKFLOW_DETAIL_LOG_SUCCESS";

export const WORKFLOW_MANUAL_TRIGGER_ACTION = "WORKFLOW_MANUAL_TRIGGER_ACTION";
export const WORKFLOW_MANUAL_TRIGGER_SUCCESS = "WORKFLOW_MANUAL_TRIGGER_SUCCESS";

export const WORKFLOW_INIT = "WORKFLOW_INIT";
export const WORKFLOW_FAIL = "WORKFLOW_FAIL";
