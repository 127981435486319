import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/Embedded';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/embeddedService';
import * as FunctionAction from '../actions/embeddedAction';
import {message} from "antd";
import EmbeddedRequest, {filtersParams} from "../mapping/Request/EmbeddedRequest";
import EMBEDDED_DEFINE from "../../constants/common";

function* actionGetMaster() {
    yield takeLatest(Types.EMBEDDED_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionMasterEmbedded, action.params);
        yield put(FunctionAction.embeddedMasterSuccess(result));
    }, errorHandle(Types.EMBEDDED_FAIL)))
}

function* actionGetListEmbedded() {
    yield takeLatest(Types.EMBEDDED_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(filtersParams(action.filters) || {});
        const result = yield call(FunctionService.actionEmbeddedList, params);
        yield put(FunctionAction.embeddedListSuccess(result));
    }, errorHandle(Types.EMBEDDED_FAIL)))
}

function* actionGetItem() {
    yield takeLatest(Types.EMBEDDED_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionEmbeddedDetail, action.params.id);
        yield put(FunctionAction.embeddedDetailSuccess(result));
    }, errorHandle(Types.EMBEDDED_FAIL)))
}

function* actionHandleFormShare() {
    yield takeLatest(Types.EMBEDDED_HANDLE_FORM_SHARE_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionHandleFormShare, action.params);
        yield put(FunctionAction.embeddedHandleFormShareSuccess(result));
    }, errorHandle(Types.EMBEDDED_FAIL)))
}

function* createAction() {
    yield takeLatest(Types.EMBEDDED_CREATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new EmbeddedRequest(action.params.query).exportCreate();
        const result = yield call(FunctionService.create, data);
        message.success(result);
        action.params.props.history.goBack();
        yield put({type: Types.EMBEDDED_LIST_ACTION});
    }, errorHandle(Types.EMBEDDED_FAIL)))
}

function* updateAction() {
    yield takeLatest(Types.EMBEDDED_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new EmbeddedRequest(action.params.query).exportUpdate();
        const result = yield call(FunctionService.update, data, action.params.id);
        message.success(result);
        yield put(FunctionAction.embeddedDetailAction({id: action.params.id}));
    }, errorHandle(Types.EMBEDDED_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        console.log("===> ", e)
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* embeddedSaga() {
    yield all([
        fork(actionGetListEmbedded),
        fork(createAction),
        fork(actionGetItem),
        fork(updateAction),
        fork(actionGetMaster),
        fork(actionHandleFormShare),
    ])
}
