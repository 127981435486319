import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import ContactResponse, {ContactMasterResponse} from "../mapping/Response/ContactResponse";
import User from "../mapping/User";
import {getUserProfile, getUserProfileConfig} from "./authService";

export function masterContact() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_MASTER_DATA,
        // params: removeObjectNull({ city_id }),
        parser: data => new ContactMasterResponse(data.data.result).exportMaster(),
    }).get();
}

export function actionContactList(params, options) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_LIST,
        params: params,
        ...options,
        parser: data => params?.is_export ? data : {
            ...data.data,
            result: (data.data.result || []).map(item => new ContactResponse(item).exportList()),
            total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
        }
    }).get();
}

export function actionContactColumnList(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_COLUMN_ACTION,
        endpointParams: {id},
        parser: data => data?.data?.result,
    }).get();
}

export function actionContactExport(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_LIST,
        params: params,
        isDownload: true,
        parser: data => data,
    }).get();
}

export function actionContactDetail(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_GET_ITEM,
        endpointParams: {id},
        parser: data => new ContactResponse(data.data.result).exportDetail(),
    }).get();
}

export function update(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_UPDATE,
        endpointParams: {id: data?.id},
    }).put(data);
}

export function create(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_CREATE,
    }).post(data);
}

export function createNote(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_CREATE_NOTE,
        endpointParams: {id: data?.id},
    }).post(data);
}

export function deleteFunction(id, ids = []) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_DELETE,
        endpointParams: {id},
    }).delete();
}

export function optOut(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_OPT_OUT,
    }).post(data);
}

export function optIn(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_OPT_IN,
    }).post(data);
}

export function checkDuplicate(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_CHECK_DUPLICATE,
        // params: params,
        parser: data => data?.data?.result,
    }).get();
}

export function importMonday(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_IMPORT_MONDAY,
        parser: data => data?.data?.result,
    }).post(data);
}

export function importHubspot(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_HUBSPOT_MONDAY,
        parser: data => data?.data?.result,
    }).post(data);
}

export function importPodio(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_PODIO_MONDAY,
        parser: data => data?.data?.result,
    }).post(data);
}

export function actionContactFiltersList(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_FILTERS_LIST,
        params: params,
        parser: data => new ContactResponse(data.data.result).exportListFilters(),
    }).get();
}

export function saveFilters(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_FILTERS_SAVE,
        endpointParams: {id: data?.id},
    }).post(data);
}

export function deleteFilters(id, ids = []) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_FILTERS_DELETE,
        endpointParams: {id},
    }).delete();
}

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new ContactResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}

export function createColumn(data, id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_COLUMN_ACTION,
        endpointParams: {id},
    }).post(data);
}

export function deleteColumn(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_COLUMN_ACTION + "/list_column",
        endpointParams: {id},
    }).delete();
}

export function getAllContact(params) {
    return new Promise((resolve, reject) => {
        return Promise.all([
            actionContactList(params),
            actionContactColumnList(params?.source),
        ]).then((responses) => {
            const contactList = responses[0] || [],
                contactColumnList = responses[1] || [];
            return resolve({contactList, contactColumnList});
        }).catch((e) => {
            console.log('===== Get CONTACT  service error', e);
            reject(e)
            // resolve({})
        })
    })
}

export function updateNote(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_UPDATE_NOTE,
        endpointParams: {id: data?.id, time: data?.time},
    }).put(data);
}

export function deleteNote(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_DELETE_NOTE,
        endpointParams: {id: data?.id, time: data?.time},
    }).delete();
}

export function actionMasterPortalFilter() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_MASTER_PORTAL_FILTER,
        parser: data => data.data.result,
    }).get();
}

export function highlightCell(data, id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONTACT_HIGHLIGHT_CELL,
        endpointParams: {id},
        parser: data => data.data.result,
    }).post(data);
}
