import {createSelector} from 'reselect'

const stateReducer = state => state.pageBuildersReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        resultReducer => resultReducer.isFetching
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        resultReducer => resultReducer.statusAction
    )

export const selectErrors = (initItems) =>
    createSelector(
        stateReducer,
        resultReducer => resultReducer.errors
    )

export const selectList = (initItems) =>
    createSelector(
        stateReducer,
        resultReducer => resultReducer.data
    )

export const selectMasterData = (initItems) =>
    createSelector(
        stateReducer,
        resultReducer => resultReducer.master
    )
export const selectItem = (initItems) =>
    createSelector(
        stateReducer,
        resultReducer => resultReducer.detail
    )

export const selectDetailLog = (initItems) =>
    createSelector(
        stateReducer,
        resultReducer => resultReducer.detailLog
    )
