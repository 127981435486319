import * as Types from '../types/Auth';

export const loginAction = (params) => ({type: Types.LOGIN_ACTION, params});
export const loginSuccess = (data) => ({type: Types.LOGIN_SUCCESS, data});

export const checkUserAction = (token) => ({type: Types.CHECK_USER_ACTION, token});
export const checkUserSuccess = (user) => ({type: Types.CHECK_USER_SUCCESS, user});

export const logoutAction = (params) => ({type: Types.LOGOUT_ACTION, params});
export const logoutSuccess = (data) => ({type: Types.LOGOUT_SUCCESS, data});

export const registerAction = (params) => ({type: Types.REGISTER_ACTION, params});
export const registerSuccess = (register) => ({type: Types.REGISTER_SUCCESS, register});

export const updateProfileAction = (params) => ({type: Types.UPDATE_PROFILE_ACTION, params});
export const updateProfileSuccess = (updateProfile) => ({type: Types.UPDATE_PROFILE_SUCCESS, updateProfile});

export const passwordRequestAction = (params) => ({type: Types.PASSWORD_REQUEST_ACTION, params});
export const passwordRequestSuccess = (hash) => ({type: Types.PASSWORD_REQUEST_SUCCESS, hash});

export const passwordResetAction = (params) => ({type: Types.PASSWORD_RESET_ACTION, params});
export const passwordResetSuccess = (passwordReset) => ({type: Types.PASSWORD_RESET_SUCCESS, passwordReset});

export const generalAction = (params) => ({type: Types.GENERAL_ACTION, params});
export const generalSuccess = (general) => ({type: Types.GENERAL_SUCCESS, general});

export const profileConfigUpdateAction = (params) => ({type: Types.PROFILE_CONFIG_UPDATE_ACTION, params});
export const profileConfigUpdateSuccess = (profileConfigUpdate) => ({type: Types.PROFILE_CONFIG_UPDATE_SUCCESS, profileConfigUpdate});

export const settingAction = (params) => ({type: Types.SETTING_ACTION, params});
export const settingSuccess = (setting) => ({type: Types.SETTING_SUCCESS, setting});

export const settingMasterAction = (params) => ({type: Types.SETTING_MASTER_ACTION, params});
export const settingMasterSuccess = (settingMaster) => ({type: Types.SETTING_MASTER_SUCCESS, settingMaster});

export const authenticationSSOAction = (params) => ({type: Types.AUTHENTICATION_SSO_ACTION, params});
export const authenticationSSOSuccess = (ssoCode) => ({type: Types.AUTHENTICATION_SSO_SUCCESS, ssoCode});

export const refreshTokenAction = (refreshToken) => ({type: Types.REFRESH_TOKEN_ACTION, refreshToken});
export const initAction = (refreshToken) => ({type: Types.LOGIN_INIT, refreshToken});
