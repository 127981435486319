import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import TemplateResponse, {TemplateMasterResponse} from "../mapping/Response/TemplateResponse";

export function masterTemplate() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TEMPLATE_MASTER_DATA,
        // params: removeObjectNull({ city_id }),
        parser: data => new TemplateMasterResponse(data.data.result).exportMaster(),
    }).get();
}

export function actionTemplateList(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TEMPLATE_LIST,
        params: params,
        parser: dataParser,
    }).get();
}

export function actionTemplateExport(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TEMPLATE_LIST,
        params: params,
        isDownload: true,
        parser: data => data,
    }).get();
}

export function actionTemplateDetail(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TEMPLATE_GET_ITEM,
        endpointParams: {id},
        parser: data => new TemplateResponse(data.data.result).exportDetail(),
    }).get();
}

export function update(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TEMPLATE_UPDATE,
        endpointParams: {id: data?.id},
    }).put(data);
}

export function create(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TEMPLATE_CREATE,
        parser: data => data?.data?.result,
    }).post(data);
}

export function clone(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TEMPLATE_CLONE,
        parser: data => data?.data?.result,
    }).post(data);
}

export function deleteData(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TEMPLATE_DELETE,
        endpointParams: {id},
    }).delete();
}

export function actionTemplateEmailList(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TEMPLATE_EMAIL_LIST,
        params: params,
        parser: data => data?.data?.result,
    }).get();
}

export function templateReadNotify(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.TEMPLATE_READ_NOTIFY,
        endpointParams: {id},
        parser: data => data.data.result,
    }).post({});
}

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new TemplateResponse(item).exportList()),
        is_admin: data?.data?.is_admin || 0,
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
