export const TEAMS_MASTER_ACTION = "TEAMS_MASTER_ACTION";
export const TEAMS_MASTER_SUCCESS = "TEAMS_MASTER_SUCCESS";

export const TEAMS_LIST_ACTION = "TEAMS_LIST_ACTION";
export const TEAMS_LIST_SUCCESS = "TEAMS_LIST_SUCCESS";

export const TEAMS_DETAIL_ACTION = "TEAMS_DETAIL_ACTION";
export const TEAMS_DETAIL_SUCCESS = "TEAMS_DETAIL_SUCCESS";

export const TEAMS_UPDATE_ACTION = "TEAMS_UPDATE_ACTION";
export const TEAMS_UPDATE_SUCCESS = "TEAMS_UPDATE_SUCCESS";

export const TEAMS_CREATE_ACTION = "TEAMS_CREATE_ACTION";
export const TEAMS_CREATE_SUCCESS = "TEAMS_CREATE_SUCCESS";

export const TEAMS_INIT = "TEAMS_INIT";
export const TEAMS_FAIL = "TEAMS_FAIL";
