import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class TeamPointRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.first_name = data?.first_name || "";
        this.last_name = data?.last_name || "";
        this.email = data?.email || "";
        this.password = data?.password || "";
        this.phone_number = data?.phone_number || "";
        this.status = formatInt(data?.status) || 0;
    }

    exportCreate() {
        return {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            password: this.password,
            phone_number: this.phone_number,
            status: this.status,
        }
    }

    exportUpdate() {
        return {
            id: this.id,
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            password: this.password,
            phone_number: this.phone_number,
            status: this.status,
        }
    }

}

export const common = {
    ACTION_FIELD: {
        first_name: "first_name",
        last_name: "last_name",
        email: "email",
        status: "status",
        password: "password",
        phone_number: "phone_number",
    },
}

export const customerTeamPointFiltersParams = (filters) => {
    let newDateAdded = (filters?.date || []).length > 0 ? [moment(filters?.date[0]).format("YYYY-MM-DD"), moment(filters?.date[1]).format("YYYY-MM-DD")] : ["", ""]
    return removeObjectNullFull({
        from_date: newDateAdded[0],
        to_date: newDateAdded[1],
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
    })
}


export const customerTeamPointDetailFiltersParams = (filters) => {
    // let newDateAdded = (filters?.date || []).length > 0 ? [moment(filters?.date[0]).format("YYYY-MM-DD"), moment(filters?.date[1]).format("YYYY-MM-DD")] : ["", ""]
    return removeObjectNullFull({
        search: filters?.search || "",
        timezone: filters?.timezone || ((new Date().toString().match(/([-\+][0-9]+)\s/)[1]).replaceAll("0", "")).replace("+", ""),
        // from_date: newDateAdded[0],
        // to_date: newDateAdded[1],
        from_date: filters?.from_date ? moment(filters?.from_date).format("YYYY-MM-DD") : undefined,
        to_date: filters?.to_date ? moment(filters?.to_date).format("YYYY-MM-DD") : undefined,
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
    })
}
