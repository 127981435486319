import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/smsTemplateAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.smsTemplateInitStore(params)),
        [dispatch]
    )
}

export const ActionGetList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.smsTemplateListAction(filters)),
        [dispatch]
    )
}

export const ActionGetItem = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.smsTemplateDetailAction(params)),
        [dispatch]
    )
}

export const ActionMasterData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.smsTemplateMasterAction(params)),
        [dispatch]
    )
}

export const ActionCreate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.smsTemplateCreateAction(params)),
        [dispatch]
    )
}
export const ActionUpdate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.smsTemplateUpdateAction(params)),
        [dispatch]
    )
}
