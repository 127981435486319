import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {convertBooleanToInt, formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class InboxRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.message = data?.send_message || "";
    }

    exportCreate() {
        return {
            message: this.message,
        }
    }

}

export const common = {
    ACTION_FIELD: {},
}
