import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/Subscription';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/subscriptionService';
import * as FunctionAction from '../actions/subscriptionAction';
import {message} from "antd";
// import SubscriptionRequest, {subscriptionFiltersParams} from "../mapping/Request/SubscriptionRequest";
import TAG_DEFINE from "../../constants/common";

// function* actionGetListSubscription() {
//     yield takeLatest(Types.SUBSCRIPTION_LIST_ACTION, Func.sagaWrapper(function* (action) {
//         const params = parseListRequestParams(subscriptionFiltersParams(action.filters) || {});
//         const result = yield call(FunctionService.actionSubscriptionList, params);
//         yield put(FunctionAction.subscriptionListSuccess(result));
//     }, errorHandle(Types.SUBSCRIPTION_FAIL)))
// }
//
// function* actionGetItem() {
//     yield takeLatest(Types.SUBSCRIPTION_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
//         const result = yield call(FunctionService.actionSubscriptionDetail, action.params.id);
//         yield put(FunctionAction.subscriptionDetailSuccess(result));
//     }, errorHandle(Types.SUBSCRIPTION_FAIL)))
// }

function* actionGetCurrentSubscription() {
    yield takeLatest(Types.SUBSCRIPTION_CURRENT_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.getSubscriptionCurrent, action.params);
        yield put(FunctionAction.subscriptionCurrentSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_FAIL)))
}

// function* createAction() {
//     yield takeLatest(Types.SUBSCRIPTION_CREATE_ACTION, Func.sagaWrapper(function* (action) {
//         const data = new SubscriptionRequest(action.params).exportCreate();
//         const result = yield call(FunctionService.create, data);
//         message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
//         action.params.props.history.goBack();
//         yield put({type: Types.SUBSCRIPTION_LIST_ACTION});
//     }, errorHandle(Types.SUBSCRIPTION_FAIL)))
// }
//
// function* updateAction() {
//     yield takeLatest(Types.SUBSCRIPTION_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
//         const data = new SubscriptionRequest(action.params).exportUpdate();
//         yield call(FunctionService.update, data);
//         message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
//         yield put(FunctionAction.subscriptionDetailAction({id: action.params.id}));
//     }, errorHandle(Types.SUBSCRIPTION_FAIL)))
// }

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        console.log("===> ", e)
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* subscriptionSaga() {
    yield all([
        // fork(actionGetListSubscription),
        // fork(createAction),
        fork(actionGetCurrentSubscription),
        // fork(actionGetItem),
        // fork(updateAction),
    ])
}
