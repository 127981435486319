import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/Task';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/taskService';
import * as FunctionAction from '../actions/taskAction';
import * as ContactAction from '../actions/contactAction';
import {message} from "antd";
import TaskRequest, {filtersParams} from "../mapping/Request/TaskRequest";
import TASK_DEFINE from "../../constants/common";
import {createProcessTask, updateProcessTask} from "../services/taskService";

function* actionGetList() {
    yield takeLatest(Types.TASK_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(filtersParams(action.filters) || {});
        const result = yield call(FunctionService.actionTaskList, params);
        yield put(FunctionAction.taskListSuccess(result));
    }, errorHandle(Types.TASK_FAIL)))
}

function* actionGetItem() {
    yield takeLatest(Types.TASK_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionTaskDetail, action.params.id);
        yield put(FunctionAction.taskDetailSuccess(result));
    }, errorHandle(Types.TASK_FAIL)))
}

function* createAction() {
    yield takeLatest(Types.TASK_CREATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new TaskRequest(action.params).exportCreate();
        const result = yield call(FunctionService.create, data);
        message.success(TASK_DEFINE.VALIDATION.statusCode.code["201"]);
        // action.params.props.history.goBack();
        yield put(FunctionAction.taskCreateSuccess(result));
        yield put({type: Types.TASK_LIST_ACTION});
    }, errorHandle(Types.TASK_FAIL)))
}

function* updateAction() {
    yield takeLatest(Types.TASK_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new TaskRequest(action.params).exportUpdate();
        yield call(FunctionService.update, data);
        message.success(TASK_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.taskDetailAction({id: action.params.id}));
    }, errorHandle(Types.TASK_FAIL)))
}

function* createProcessTaskAction() {
    yield takeLatest(Types.TASK_PROCESS_TASK_CREATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new TaskRequest(action.params?.query).exportCreate();
        const result = yield call(FunctionService.createProcessTask, data, action.params?.id, action.params?.idItem);
        // message.success(TASK_DEFINE.VALIDATION.statusCode.code["201"]);
        // action.params.props.history.goBack();
        yield put(FunctionAction.taskProcessTaskCreateSuccess(result));
        // yield put(FunctionAction.taskDetailAction({id: action.params.id}));
    }, errorHandle(Types.TASK_FAIL)))
}

function* updateProcessTaskAction() {
    yield takeLatest(Types.TASK_PROCESS_TASK_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new TaskRequest(action.params?.query).exportUpdate();
        yield call(FunctionService.updateProcessTask, data, action.params?.id, action.params?.idItem);
        // message.success(TASK_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.taskProcessTaskUpdateSuccess(true));
        yield put(FunctionAction.taskDetailAction({id: action.params.id}));
    }, errorHandle(Types.TASK_FAIL)))
}

function* addAction() {
    yield takeLatest(Types.TASK_ADD_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.add, action?.params?.id, action?.params?.ids);
        yield put(FunctionAction.taskAddSuccess(result));
        message.success(result?.data?.data?.result)
        yield put(ContactAction.contactListAction(action?.params?.filters));
        // action.params.props.history.goBack();
        // yield put({type: Types.TASK_LIST_ACTION});
    }, errorHandle(Types.TASK_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        console.log("===> ", e)
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* taskSaga() {
    yield all([
        fork(addAction),
        fork(actionGetList),
        fork(createAction),
        fork(actionGetItem),
        fork(updateAction),
        fork(createProcessTaskAction),
        fork(updateProcessTaskAction),
    ])
}
