import {call, put, takeEvery, fork, all} from 'redux-saga/effects';
import {notification, message} from 'antd';

import * as Func from "../../utils/functions";
import * as Types from '../types/Upload';
import * as FunctionService from '../services/uploadService';
import * as FunctionAction from '../actions/uploadAction';
import {UPLOAD} from "../../constants/define";
import {checkUserAction} from "../actions/authAction";
import {uploadDefault} from "../services/uploadService";


function* uploadFile() {
    yield takeEvery(Types.UPLOAD_ACTION, Func.sagaWrapper(function* (action) {
        if (action?.filters?.is_default) {
            const file = action.filters?.files;
            if (file.size > UPLOAD.MAX_FILE_SIZE) {
                yield put({type: Types.UPLOAD_INIT});
                message.error("Dữ liệu file không được lớn hơn 10MB")
            } else {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('file_name', file.name);
                // formData.append('customer_id', action.filters?.customer_id);
                // formData.append('key', action?.filters.type !== undefined ? action?.filters.type : 1);
                const result = yield call(FunctionService.uploadDefault, formData);
                yield put(FunctionAction.uploadSuccess(result));
                // yield put(checkUserAction());
                message.success("Update data successful")
            }
        } else {
            const file = action.filters?.file;
            // if (file.size > UPLOAD.MAX_FILE_SIZE) {
            //     yield put({type: Types.UPLOAD_INIT});
            //     message.error("Dữ liệu file không được lớn hơn 10MB")
            // } else {
            const formData = new FormData();
            formData.append('file', file);
            if (action.filters?.tag_id) {
                formData.append('tag_id', action.filters?.tag_id);
            }
            // formData.append('file_name', file.name);
            // formData.append('customer_id', action.filters?.customer_id || "");
            // formData.append('key', action?.filters.type !== undefined ? action?.filters.type : 1);
            const result = yield call(FunctionService.upload, formData);
            yield put(FunctionAction.uploadSuccess(result));
            // yield put(checkUserAction());
            message.success(result?.message)
            // message.success("Update data successful")
            // }

        }
    }, errorHandle(Types.UPLOAD_INIT)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType})
    });
}

export function* uploadSaga() {
    yield all([
        fork(uploadFile),
    ])
}
