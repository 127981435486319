import * as Types from '../types/Teams';

export const teamsMasterAction = (filters) => ({type: Types.TEAMS_MASTER_ACTION, filters});
export const teamsMasterSuccess = (master) => ({
    type: Types.TEAMS_MASTER_SUCCESS,
    master
});

export const teamsListAction = (filters) => ({type: Types.TEAMS_LIST_ACTION, filters});
export const teamsListSuccess = (teams) => ({
    type: Types.TEAMS_LIST_SUCCESS,
    teams
});

export const teamsDetailAction = (params) => ({type: Types.TEAMS_DETAIL_ACTION, params});
export const teamsDetailSuccess = (detail) => ({
    type: Types.TEAMS_DETAIL_SUCCESS,
    detail
});

export const teamsUpdateAction = (params) => ({type: Types.TEAMS_UPDATE_ACTION, params});
export const teamsUpdateSuccess = (updateData) => ({
    type: Types.TEAMS_UPDATE_SUCCESS,
    updateData
});

export const teamsCreateAction = (params) => ({type: Types.TEAMS_CREATE_ACTION, params});
export const teamsCreateSuccess = (createData) => ({
    type: Types.TEAMS_CREATE_SUCCESS,
    createData
});

export const teamsInitStore = (params) => ({type: Types.TEAMS_INIT, params});
export const teamsClearStore = (params) => ({type: Types.TEAMS_FAIL, params});
