import {Button, Divider, Form, Input, InputNumber, Select, Space} from "antd";
import React, {useEffect, useState} from "react";
import {ACTION_TYPE, DAY_OF_WEEK, PERIOD, SKIP_TYPE, TRIGGER_TYPE} from "../constant";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

export const Tag = props => {
    return (
        <div className="col-12">
            <Form.Item
                label="Customer Group"
                name="tag"
            >
                <Select
                    // showSearch
                    mode="multiple"
                    optionFilterProp="children"
                    allowClear
                    // filterOption={(input, option) =>
                    //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                    style={{
                        width: '100%',
                    }}
                >
                    {
                        Object.keys(props?.itemMaster?.tag || {}).length > 0 && Object.keys(props?.itemMaster?.tag || {}).map((i, k) => {
                            return (
                                <Select.Option key={k} value={i}>{props?.itemMaster?.tag[i]}</Select.Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
        </div>
    )
}

export const TriggerType = props => {
    return (
        <div className="col-12">
            <Form.Item
                label="Trigger Type"
                name="trigger_type"
                initialValue="TIME"
            >
                <Select
                    // showSearch
                    optionFilterProp="children"
                    // allowClear
                    // filterOption={(input, option) =>
                    //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                    style={{
                        width: '100%',
                    }}
                >
                    {
                        TRIGGER_TYPE.map((i, k) => {
                            return (
                                <Select.Option key={k} disabled={k === 1}
                                               value={i.value}>{i?.title}</Select.Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
        </div>
    )
}

export const Period = props => {
    return (
        <div className="col-12">
            <Form.Item
                label="Period"
                name="period"
            >
                <Select
                    // showSearch
                    optionFilterProp="children"
                    allowClear
                    // filterOption={(input, option) =>
                    //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                    style={{
                        width: '100%',
                    }}
                >
                    {
                        PERIOD.map((i, k) => {
                            return (
                                <Select.Option key={k}
                                               value={i.value}>{i?.title}</Select.Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
        </div>
    )
}

export const Time = props => {
    return (
        <div
            className={`col-12 ${["WEEKLY", "DAILY", "YEARLY", "MONTHLY", "TIME SKIPPING"].includes(props?.valuesForm?.period) ? "" : "d-none"}`}>
            <Form.Item
                label="Time (Hour)"
                name="time"
            >
                <Select
                    mode="multiple"
                    // showSearch
                    optionFilterProp="children"
                    allowClear
                    // filterOption={(input, option) =>
                    //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                    style={{
                        width: '100%',
                    }}
                >
                    {
                        new Array(24).fill(null).map((i, k) => {
                            return (
                                <Select.Option key={k}
                                               value={k}>{k}</Select.Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
        </div>
    )
}

export const DayOfWeek = props => {
    return (
        <div className={`col-12 ${["WEEKLY"].includes(props?.valuesForm?.period) ? "" : "d-none"}`}>
            <Form.Item
                label="Day of week"
                name="day_of_week"
            >
                <Select
                    mode="multiple"
                    // showSearch
                    optionFilterProp="children"
                    allowClear
                    // filterOption={(input, option) =>
                    //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                    style={{
                        width: '100%',
                    }}
                >
                    {
                        DAY_OF_WEEK.map((i, k) => {
                            return (
                                <Select.Option key={k}
                                               value={i?.value}>{i?.title}</Select.Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
        </div>
    )
}

export const DayOfMonth = props => {
    return (
        <div
            className={`col-12 ${["MONTHLY", "YEARLY"].includes(props?.valuesForm?.period) ? "" : "d-none"}`}>
            <Form.Item
                label="Day of month"
                name="day_of_month"
            >
                <Select
                    mode="multiple"
                    // showSearch
                    optionFilterProp="children"
                    allowClear
                    // filterOption={(input, option) =>
                    //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                    style={{
                        width: '100%',
                    }}
                >
                    {
                        new Array(29).fill(null).map((i, k) => {
                            return (
                                <Select.Option key={k}
                                               value={k === 28 ? -1 : k + 1}>{k === 28 ? "Last day of month" : k + 1}</Select.Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
        </div>
    )
}

export const MonthOfYear = props => {
    return (
        <div className={`col-12 ${["YEARLY"].includes(props?.valuesForm?.period) ? "" : "d-none"}`}>
            <Form.Item
                label="Month of year"
                name="month_of_year"
            >
                <Select
                    mode="multiple"
                    // showSearch
                    optionFilterProp="children"
                    allowClear
                    // filterOption={(input, option) =>
                    //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                    style={{
                        width: '100%',
                    }}
                >
                    {
                        new Array(12).fill(null).map((i, k) => {
                            return (
                                <Select.Option key={k}
                                               value={k + 1}>{k + 1}</Select.Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
        </div>
    )
}

export const SkipValue = props => {
    return (
        <div className={`col-12 ${["TIME SKIPPING"].includes(props?.valuesForm?.period) ? "" : "d-none"}`}>
            <Form.Item
                label="Skipping Value"
                name="skip_value"
            >
                <InputNumber min={1} max={30} className="w-100"/>
            </Form.Item>
        </div>
    )
}

export const SkipType = props => {
    return (
        <div className={`col-12 ${["TIME SKIPPING"].includes(props?.valuesForm?.period) ? "" : "d-none"}`}>
            <Form.Item
                label="Skipping Type"
                name="skip_type"
            >
                <Select
                    // showSearch
                    optionFilterProp="children"
                    allowClear
                    // filterOption={(input, option) =>
                    //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                    style={{
                        width: '100%',
                    }}
                >
                    {
                        SKIP_TYPE.map((i, k) => {
                            return (
                                <Select.Option key={k}
                                               value={i?.value}>{i?.title}</Select.Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
        </div>
    )
}

export const Type = props => {
    return (
        <div className="col-12">
            <Form.Item
                label="Type"
                name="type"
            >
                <Select
                    // showSearch
                    optionFilterProp="children"
                    allowClear
                    // filterOption={(input, option) =>
                    //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                    style={{
                        width: '100%',
                    }}
                >
                    {
                        ACTION_TYPE.map((i, k) => {
                            return (
                                <Select.Option key={k}
                                               value={i?.value}>{i?.title}</Select.Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
        </div>
    )
}

export const Template = props => {
    const [items, setItems] = useState([])

    useEffect(() => {
        if (props?.valuesForm?.type) {
            if (props?.valuesForm?.type === "SMS") {
                if (Object.keys(props?.itemMaster?.sms_template).length > 0) {
                    let sms = []
                    Object.keys(props?.itemMaster?.sms_template).map((i) => sms.push({
                        title: props?.itemMaster?.sms_template[i],
                        value: i
                    }))
                    setItems(sms)
                }
            } else if (props?.valuesForm?.type === "EMAIL") {
                if (Object.keys(props?.itemMaster?.email_template).length > 0) {
                    let email = []
                    Object.keys(props?.itemMaster?.email_template).map((i) => email.push({
                        title: props?.itemMaster?.email_template[i],
                        value: i
                    }))
                    setItems(email)
                }
            } else {
                setItems([])
            }
        } else {
            setItems([])
        }
    }, [props?.valuesForm])

    return items.length > 0 ? (
        <div className="col-12">
            <Form.Item
                label="Template"
                name="template"
            >
                <Select
                    // showSearch
                    optionFilterProp="children"
                    allowClear
                    // filterOption={(input, option) =>
                    //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                    style={{
                        width: '100%',
                    }}
                >
                    {
                        items.map((i, k) => {
                            return (
                                <Select.Option key={k}
                                               value={i?.value}>{i?.title}</Select.Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
        </div>
    ) : null
}

export const CompoAction = props => {
    const [items, setItems] = useState([])

    // useEffect(() => {
    //     if (props?.valuesForm?.type) {
    //         if (props?.valuesForm?.type === "SMS") {
    //             if (Object.keys(props?.itemMaster?.sms_template).length > 0) {
    //                 let sms = []
    //                 Object.keys(props?.itemMaster?.sms_template).map((i) => sms.push({
    //                     title: props?.itemMaster?.sms_template[i],
    //                     value: i
    //                 }))
    //                 setItems(sms)
    //             }
    //         } else if (props?.valuesForm?.type === "EMAIL") {
    //             if (Object.keys(props?.itemMaster?.email_template).length > 0) {
    //                 let email = []
    //                 Object.keys(props?.itemMaster?.email_template).map((i) => email.push({
    //                     title: props?.itemMaster?.email_template[i],
    //                     value: i
    //                 }))
    //                 setItems(email)
    //             }
    //         } else {
    //             setItems([])
    //         }
    //     } else {
    //         setItems([])
    //     }
    // }, [props?.valuesForm])

    console.log(999, props?.itemMaster)

    return (
        <div className="col-12">
            <Form.List name="action">
                {(fields, {add, remove}) => (
                    <>
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined className="icons"/>}>
                                Add Action
                            </Button>
                        </Form.Item>
                        {fields.map((field, key) => {
                            let valueSelectTemplate = [];
                            console.log(666, props?.form.getFieldValue("action")[field.name]?.type)
                            if (props?.form.getFieldValue("action")[field.name]?.type === "SMS") {
                                if (Object.keys(props?.itemMaster?.sms_template).length > 0) {
                                    Object.keys(props?.itemMaster?.sms_template).map((i) => valueSelectTemplate.push({
                                        title: props?.itemMaster?.sms_template[i],
                                        value: i
                                    }))
                                }
                            } else if (props?.form.getFieldValue("action")[field.name]?.type === "EMAIL") {
                                if (Object.keys(props?.itemMaster?.email_template).length > 0) {
                                    Object.keys(props?.itemMaster?.email_template).map((i) => valueSelectTemplate.push({
                                        title: props?.itemMaster?.email_template[i],
                                        value: i
                                    }))
                                }
                            }
                            return (
                                <>
                                    <Divider orientation="left" plain className="my-1">
                                        <Space>
                                            <p className="h5">Action {field.name + 1}</p>
                                            <MinusCircleOutlined className="color--red fs--18px"
                                                                 onClick={() => remove(field.name)}/>
                                        </Space>
                                    </Divider>
                                    <Form.Item
                                        label="Type"
                                        name={[field.name, "type"]}
                                        // rules={[{
                                        //     required: true,
                                        //     message: 'Please choose your type!'
                                        // }]}
                                    >
                                        <Select
                                            // showSearch
                                            optionFilterProp="children"
                                            allowClear
                                            style={{
                                                width: '100%',
                                            }}
                                            onChange={e => {
                                                const selectorBValue = props.form.getFieldValue(['action', key, 'template']);
                                                if (selectorBValue) {
                                                    props.form.setFieldsValue({
                                                        action: props.form.getFieldValue('action').map((item, itemIndex) => {
                                                            if (itemIndex === key) {
                                                                return {
                                                                    ...item,
                                                                    template: "",
                                                                };
                                                            }
                                                            return item;
                                                        }),
                                                    });
                                                }
                                            }}
                                        >
                                            {
                                                ACTION_TYPE.map((i, k) => {
                                                    return (
                                                        <Select.Option key={k}
                                                                       value={i?.value}>{i?.title}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Template"
                                        name={[field.name, "template"]}
                                        // rules={[{
                                        //     required: true,
                                        //     message: 'Please choose your type!'
                                        // }]}
                                    >
                                        <Select
                                            disabled={!(valueSelectTemplate.length > 0)}
                                            optionFilterProp="children"
                                            allowClear
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            {
                                                valueSelectTemplate.map((i, k) => {
                                                    return (
                                                        <Select.Option key={k}
                                                                       value={i?.value}>{i?.title}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Tag"
                                        name={[field.name, "tag_id"]}
                                        className={`${["ADD_TAG", "UPDATE_TAG"].includes(props?.form.getFieldValue("action")[field.name]?.type) ? "" : "d-none"}`}
                                        // rules={[{
                                        //     required: true,
                                        //     message: 'Please choose your type!'
                                        // }]}
                                    >
                                        <Select
                                            // disabled={!(valueSelectTemplate.length > 0)}
                                            optionFilterProp="children"
                                            allowClear
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            {
                                                (Object?.keys(props?.itemMaster?.tag || {})).map((i, k) => {
                                                    return (
                                                        <Select.Option key={k}
                                                                       value={i}>{props?.itemMaster?.tag[i]}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                    {/*<Form.Item*/}
                                    {/*    noStyle*/}
                                    {/*    shouldUpdate={(prevValues, curValues) =>*/}
                                    {/*        prevValues.area !== curValues.area || prevValues.sights !== curValues.sights*/}
                                    {/*    }*/}
                                    {/*>*/}
                                    {/*    {() => (*/}
                                    {/*        <Form.Item*/}
                                    {/*            {...field}*/}
                                    {/*            label="Sight"*/}
                                    {/*            name={[field.name, 'sight']}*/}
                                    {/*            rules={[*/}
                                    {/*                {*/}
                                    {/*                    required: true,*/}
                                    {/*                    message: 'Missing sight',*/}
                                    {/*                },*/}
                                    {/*            ]}*/}
                                    {/*        >*/}
                                    {/*            <Select*/}
                                    {/*                disabled={!form.getFieldValue('area')}*/}
                                    {/*                style={{*/}
                                    {/*                    width: 130,*/}
                                    {/*                }}*/}
                                    {/*            >*/}
                                    {/*                {(sights[form.getFieldValue('area')] || []).map((item) => (*/}
                                    {/*                    <Select.Option key={item} value={item}>*/}
                                    {/*                        {item}*/}
                                    {/*                    </Select.Option>*/}
                                    {/*                ))}*/}
                                    {/*            </Select>*/}
                                    {/*        </Form.Item>*/}
                                    {/*    )}*/}
                                    {/*</Form.Item>*/}
                                    {/*<Form.Item*/}
                                    {/*    {...field}*/}
                                    {/*    label="Price"*/}
                                    {/*    name={[field.name, 'price']}*/}
                                    {/*    rules={[*/}
                                    {/*        {*/}
                                    {/*            required: true,*/}
                                    {/*            message: 'Missing price',*/}
                                    {/*        },*/}
                                    {/*    ]}*/}
                                    {/*>*/}
                                    {/*    <Input/>*/}
                                    {/*</Form.Item>*/}

                                    {/*<MinusCircleOutlined onClick={() => remove(field.name)}/>*/}
                                </>
                            )
                        })}
                    </>
                )}
            </Form.List>
        </div>
    )
}
