import React from 'react'
import PropTypes from 'prop-types'
import {FILES} from "../../../constants/define";

const ImageComponent = ({src, ...restProps}) => {
    return typeof src === 'string' ?
        src.startsWith('http') ? <img src={src} {...restProps} /> : <img src={src} {...restProps} />
        : <img src={FILES.BANNER_DEFAULT} {...restProps} />
}

const Img = ({
                 id, key,
                 className,
                 onClick, src, alt,
                 width, height, children,
                 layout,
                 loading = 'lazy',
                 objectFit, objectPosition,
                 unoptimized = false,
                 ...restProps
             }) => {


    return (
        <ImageComponent id={id} key={key}
                        className={className} src={src} alt={alt}
                        onClick={onClick}
                        layout={!width || !height ? 'fill' : layout}
                        width={width} height={height}
                        loading={loading}
                        objectFit={objectFit}
                        objectPosition={objectPosition}
                        unoptimized={unoptimized}
                        {...restProps}
        >
            {children}
        </ImageComponent>
    )
}

Img.propTypes = {
    layout: PropTypes.oneOf(['fixed', 'intrinsic', 'responsive', 'fill']),
    loading: PropTypes.oneOf(['lazy', 'eager']),
    objectFit: PropTypes.oneOf(['fill', 'contain', 'cover', 'none', 'scale-down']),
    unoptimized: PropTypes.bool,
}

export default Img
