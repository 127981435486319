import {Button, Form, Input, Modal, Select, Spin} from "antd";
import React, {useEffect} from "react";
import {Icon} from "../index";
import {COUNTRY_CODE} from "../../../constants/define";
import {ActionVerifyEmail} from "../../../data/hooks/emailHistory";
import {selectStatusAction, selectFetching} from "../../../data/reselects/emailHistorySelector";
import {common} from "../../../data/mapping/Request/ContactRequest";
import {isEmail} from "../../../utils/validation";
import {useSelector} from "react-redux";

const VerifyEmailForm = props => {
    const [form] = Form.useForm(),
        actionVerifyEmail = ActionVerifyEmail(),
        itemStatusAction = useSelector(selectStatusAction()),
        itemFetching = useSelector(selectFetching());

    const onSubmit = (val) => {
        actionVerifyEmail(val)
    }

    useEffect(() => {
        if (itemStatusAction?.verifyEmail) {
            form.resetFields();
            props?.setIsVerifyEmailForm(false)
        }
    }, [itemStatusAction]);

    return (
        <Modal
            title="Verify Email"
            destroyOnClose
            visible={props?.isVerifyEmailForm}
            onOk={() => {
                props?.setIsVerifyEmailForm(false)
            }}
            onCancel={() => {
                props?.setIsVerifyEmailForm(false)
            }}
            footer={null}>
            <Spin spinning={itemFetching}>
                <Form
                    layout="vertical"
                    onFinish={onSubmit}
                    form={form}
                >
                    <div className="row">
                        <div className="col-6">
                            <Form.Item
                                rules={[{required: true, message: 'Please input your Email'}]}
                                label="Email"
                                name="email"
                                initialValue={props?.itemProfile}
                            >
                                <Input disabled/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                rules={[{required: true, message: 'Please input your Nickname'}]}
                                label="Nickname"
                                name="nickname"
                            >
                                <Input/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                rules={[{required: true, message: 'Please input your from name'}]}
                                label="From Name"
                                name="name"
                            >
                                <Input/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                // rules={[{required: true, message: 'Please input your reply email'}]}
                                label="Reply Email"
                                name="reply_email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Email!'
                                    }, ({getFieldValue}) => ({
                                        validator(rule, value) {
                                            if (value) {
                                                if (!isEmail(value) && ((value || "").length >= 10)) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject("Invalid email");
                                            }
                                            return Promise.reject("Invalid email")
                                        },
                                    })
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                rules={[{required: true, message: 'Please input your reply name'}]}
                                label="Reply Name"
                                name="reply_name"
                            >
                                <Input/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                rules={[{required: true, message: 'Please input your company address'}]}
                                label="Address 1"
                                name="address_1"
                            >
                                <Input/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                // rules={[{required: true, message: 'Please input your last name'}]}
                                label="Address 2"
                                name="address_2"
                            >
                                <Input/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                rules={[{required: true, message: 'Please input your city'}]}
                                label="City"
                                name="city"
                            >
                                <Input/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                // rules={[{required: true, message: 'Please input your last name'}]}
                                label="Zip Code"
                                name="zip"
                            >
                                <Input/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                rules={[{required: true, message: 'Please input your country'}]}
                                label="Country"
                                name="country"
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    allowClear
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    {
                                        (COUNTRY_CODE).length > 0 && (COUNTRY_CODE || {}).map((i, k) => {
                                            return (
                                                <Select.Option key={k} value={i?.value}>{i?.label}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-12">
                            <Form.Item className="text-end mb-0">
                                <Button type="primary" disabled={props?.itemStatusAction?.profileConfigUpdate}
                                        htmlType="submit"
                                        className="me-3 mb-0">
                                    Submit {props?.itemStatusAction?.profileConfigUpdate &&
                                    <Icon type="sync-outlined" spin/>}
                                </Button>
                                <Button htmlType="button"
                                        className="mb-0"
                                        onClick={() => {
                                            props?.setIsVerifyEmailForm(false)
                                        }}>
                                    Cancel
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </Spin>
        </Modal>
    )
}

export default VerifyEmailForm
