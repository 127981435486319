import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    columnsTable, debounce,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    removeObjectNull, removeObjectNullFull, setParamsUrlFilter
} from "../../../utils/functions";
import {omit, find, truncate} from "lodash";
import {DatePicker, Popover, Table} from "antd";
import TAG_DEFINE from "../../../constants/common";
import {CONFIG_LOCAL_STORAGE, PATHS} from "../../../constants/define";

// ============== LAYOUT ==============
import HeaderAction from "../../common/layout/HeaderAction";
import {Icon} from "../../common";
import ListComponent from "../../common/ListComponent";
import {Tooltip, Button, Tag, Form, Input, Select, Checkbox, Modal, Upload, message, Space} from 'antd'

// ============== ACTION ==============
import {
    ActionDelete,
    ActionGetList,
    ActionMasterData,
    ActionCheckDuplicate,
    ActionOptIn,
    ActionOptOut,
    ActionFiltersList, ActionMasterPortalFilter, ActionHighlightCell
} from '../../../data/hooks/contact';
import {ActionAdd as ActionAddTag, ActionGetList as ActionTagGetList} from '../../../data/hooks/tag';
import {ActionUpload} from '../../../data/hooks/upload';

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectErrors,
    selectList,
    selectMasterData,
    selectFiltersList, selectListColumn, selectMasterPortalFilter
} from '../../../data/reselects/contactSelector';
import {
    selectStatusAction as selectTagStatusAction
} from '../../../data/reselects/tagSelector';
import ContactResponse, {column, filtersResponse} from "../../../data/mapping/Response/ContactResponse";
import {ROUTE_REDIRECT} from "../../../constants/define";
import queryString from "query-string";
import {common, contactFiltersParams} from "../../../data/mapping/Request/ContactRequest";
import {ExclamationCircleOutlined, MessageOutlined} from "@ant-design/icons";
import AddTags from "./components/AddTags";
import ModalCreateNote from "./components/ModalCreateNote";
import ModalListFilters from "../../common/components/ModalListFilters";
import ModalSaveFilters from "../../common/components/ModalSaveFilters";
import {useRouteMatch} from "react-router-dom";
import * as LocalStorage from "../../../utils/localStorage";
import moment from "moment";
import {selectList as selectListTag} from "../../../data/reselects/tagSelector";
import ModalColumn from "./components/ModalColumn";
import {BsCheckAll, BsFillPhoneVibrateFill} from "react-icons/bs";
import {AiFillFormatPainter, AiOutlineInfoCircle, AiOutlineMessage} from "react-icons/ai";
import {FcCheckmark} from "react-icons/fc";

const _listColorPicker = ["#00B8D4", "#ffe800", "#a5a5a5", "#F9A825", "#ff0000"]
const ModelFields = Object.getOwnPropertyNames(new ContactResponse());
const Index = props => {
    const namePage = "contact",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(column, ['delete'], []),
            modelItem: ModelFields,
        }),
        [isCreateNote, setIsCreateNote] = useState(false),
        [isShowColumn, setIsShowColumn] = useState(false),
        [showColorPicker, setShowColorPicker] = useState(false),
        [isHighlight, setIsHighlight] = useState(false),
        [colorPicker, setColorPicker] = useState("#00B8D4"),
        [listColumn, setListColumn] = useState([]),
        [listSelectedHighlight, setListSelectedHighlight] = useState({}),
        [idNote, setIdNote] = useState(""),
        [paramsFilters, setPramsFilters] = useState({}),
        [importSelectedTag, setImportSelectedTag] = useState(""),
        [valuePortalFilter, setValuePortalFilter] = useState({}),
        [form] = Form.useForm(),
        componentTable = {
            tag: {
                width: 150,
                render: (val, record) => (val || []).join(", "),
            },
            cell_phones_1: {
                render: (val, record) => val ? (
                    <div className="clearfix">
                        <div
                            className={`float-start me-2`}>
                            {val}
                        </div>

                        <div className="float-end mx-2">
                            <BsFillPhoneVibrateFill size={22} className="cursorPointer"
                                                    onClick={e => window.open(setParamsUrlFilter(ROUTE_REDIRECT.VOICE_CALL.HOME, {phone_number: val}), "_blank")}/>
                        </div>
                    </div>
                ) : "",
            },
            cell_phones_2: {
                render: (val, record) => val ? (
                    <div className="clearfix">
                        <div
                            className={`float-start me-2`}>
                            {val}
                        </div>

                        <div className="float-end mx-2">
                            <BsFillPhoneVibrateFill size={22} className="cursorPointer"
                                                    onClick={e => window.open(setParamsUrlFilter(ROUTE_REDIRECT.VOICE_CALL.HOME, {phone_number: val}), "_blank")}/>
                        </div>
                    </div>
                ) : "",
            },
            cell_phones_3: {
                render: (val, record) => val ? (
                    <div className="clearfix">
                        <div
                            className={`float-start me-2`}>
                            {val}
                        </div>

                        <div className="float-end mx-2">
                            <BsFillPhoneVibrateFill size={22} className="cursorPointer"
                                                    onClick={e => window.open(setParamsUrlFilter(ROUTE_REDIRECT.VOICE_CALL.HOME, {phone_number: val}), "_blank")}/>
                        </div>
                    </div>
                ) : "",
            },
            cell_phones_4: {
                render: (val, record) => val ? (
                    <div className="clearfix">
                        <div
                            className={`float-start me-2`}>
                            {val}
                        </div>

                        <div className="float-end mx-2">
                            <BsFillPhoneVibrateFill size={22} className="cursorPointer"
                                                    onClick={e => window.open(setParamsUrlFilter(ROUTE_REDIRECT.VOICE_CALL.HOME, {phone_number: val}), "_blank")}/>
                        </div>
                    </div>
                ) : "",
            },
            cell_phones_5: {
                render: (val, record) => val ? (
                    <div className="clearfix">
                        <div
                            className={`float-start me-2`}>
                            {val}
                        </div>

                        <div className="float-end mx-2">
                            <BsFillPhoneVibrateFill size={22} className="cursorPointer"
                                                    onClick={e => window.open(setParamsUrlFilter(ROUTE_REDIRECT.VOICE_CALL.HOME, {phone_number: val}), "_blank")}/>
                        </div>
                    </div>
                ) : "",
            },
            phone_numbers_2: {
                render: (val, record) => val ? (
                    <div className="clearfix">
                        <div
                            className={`float-start me-2`}>
                            {val}
                        </div>

                        <div className="float-end mx-2">
                            <BsFillPhoneVibrateFill size={22} className="cursorPointer"
                                                    onClick={e => window.open(setParamsUrlFilter(ROUTE_REDIRECT.VOICE_CALL.HOME, {phone_number: val}), "_blank")}/>
                        </div>
                    </div>
                ) : "",
            },
            phone_numbers_3: {
                render: (val, record) => val ? (
                    <div className="clearfix">
                        <div
                            className={`float-start me-2`}>
                            {val}
                        </div>

                        <div className="float-end mx-2">
                            <BsFillPhoneVibrateFill size={22} className="cursorPointer"
                                                    onClick={e => window.open(setParamsUrlFilter(ROUTE_REDIRECT.VOICE_CALL.HOME, {phone_number: val}), "_blank")}/>
                        </div>
                    </div>
                ) : "",
            },
            phone_numbers_4: {
                render: (val, record) => val ? (
                    <div className="clearfix">
                        <div
                            className={`float-start me-2`}>
                            {val}
                        </div>

                        <div className="float-end mx-2">
                            <BsFillPhoneVibrateFill size={22} className="cursorPointer"
                                                    onClick={e => window.open(setParamsUrlFilter(ROUTE_REDIRECT.VOICE_CALL.HOME, {phone_number: val}), "_blank")}/>
                        </div>
                    </div>
                ) : "",
            },
            phone_numbers_5: {
                render: (val, record) => val ? (
                    <div className="clearfix">
                        <div
                            className={`float-start me-2`}>
                            {val}
                        </div>

                        <div className="float-end mx-2">
                            <BsFillPhoneVibrateFill size={22} className="cursorPointer"
                                                    onClick={e => window.open(setParamsUrlFilter(ROUTE_REDIRECT.VOICE_CALL.HOME, {phone_number: val}), "_blank")}/>
                        </div>
                    </div>
                ) : "",
            },
            phone_number: {
                render: (val, record) => val ? (
                    <div className="clearfix">
                        <div
                            className={`float-start me-2 ${record?.is_opt_out ? "text-decoration-line-through color--red" : ""}`}>
                            {val}
                        </div>

                        <div className="float-end mx-2">
                            <BsFillPhoneVibrateFill size={22} className="cursorPointer"
                                                    onClick={e => window.open(setParamsUrlFilter(ROUTE_REDIRECT.VOICE_CALL.HOME, {phone_number: val}), "_blank")}/>
                        </div>
                        {
                            !record?.is_opt_out && (
                                <div className="float-end">
                                    {
                                        record?.inbox_message_error ? (
                                            <AiOutlineMessage className="cursorPointer" size={20}
                                                              onClick={e => message.error(record?.inbox_message_error)}/>
                                        ) : (
                                            <AiOutlineMessage className="cursorPointer" size={20}
                                                              onClick={e => window.open(`/inbox?p=${record?.id}`)}/>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                ) : "",
            },
            email: {
                render: (val, record) => val ? (
                    <div
                        className={`${record?.is_opt_out_email ? "text-decoration-line-through color--red" : ""}`}>
                        {val}
                    </div>
                ) : "",
            },
            status: {
                width: 150,
                render: (val, record) => Object.keys(itemMasterData).length > 0 && (itemMasterData?.status[val]),
            },
            action: {
                width: 100,
                align: 'center',
                key: 'action',
                fixed: 'left',
                render: (text, record) => {
                    return (
                        <>
                            <Tooltip title="Edit">
                                <Icon type="edit-outlined" className="cursorPointer"
                                      onClick={() => props.history.push((ROUTE_REDIRECT.CONTACT.UPDATE).replace(":id", record.id))}/>
                            </Tooltip>
                            <Tooltip title="Add Note">
                                <Icon type="carry-out-outlined" className="cursorPointer mx-2"
                                      onClick={() => {
                                          setIdNote(record?.id)
                                          setIsCreateNote(true)
                                      }}/>
                            </Tooltip>
                            {
                                (record?.action_note || []).length > 0 ? (
                                    <Tooltip title={
                                        <>
                                            {
                                                record?.action_note?.map((item, index) => {
                                                    return (
                                                        <p className="mb-1">- {item}</p>
                                                    )
                                                })
                                            }
                                        </>
                                    }>
                                        <AiOutlineInfoCircle className="cursorPointer"/>
                                    </Tooltip>
                                ) : null
                            }
                        </>
                    )
                },
            },
            action_note: {
                render: (text, record) => {
                    return (
                        <>
                            {
                                text?.map((item, index) => {
                                    return (
                                        <p className="mb-1">- {item}</p>
                                    )
                                })
                            }
                        </>
                    )
                },
            },
            delete: {
                width: 100,
                align: 'center',
                key: 'delete',
                fixed: 'right',
                render: (text, record) => {
                    return (
                        <>
                            {
                                record?.is_delete && (
                                    <Icon type="delete" className="cursorPointer ms-2"
                                          onClick={() => {
                                              Modal.confirm({
                                                  title: 'Notification',
                                                  icon: <ExclamationCircleOutlined/>,
                                                  content: 'Do you want to delete these items?',
                                                  onOk() {
                                                      actionDelete({id: record?.id, filters: paramsFilters})
                                                  },
                                                  onCancel() {
                                                  },
                                              })
                                          }}/>
                                )
                            }
                        </>
                    )
                },
            },
            extra_info: {
                key: 'extra_info',
                render: (text, record) => truncate(text, {
                    length: 30,
                }),
            },
            latest_note: {
                key: 'latest_note',
                width: 300,
                render: (text, record) => <Tooltip title={text}>
                    {
                        truncate(text, {
                            length: 60,
                        })
                    }
                </Tooltip>,
            }
        };
    const routeMatch = useRouteMatch(),
        [fileList, setFileList] = useState([]),
        [isFetching, setIsFetching] = useState(false),
        [isImport, setIsImport] = useState(false),
        [isDisabledButtonDelete, setIsDisabledButtonDelete] = useState(false),
        [onOpenAddTag, setOnOpenAddTag] = useState(false),
        [isOpenFilters, setIsOpenFilters] = useState(false),
        [isOpenFilterRealEstateIQ, setIsOpenFilterRealEstateIQ] = useState(false),
        [resultList, setResultList] = useState([]),
        [listSelectedItem, setListSelectedItem] = useState([]),
        [phoneNumbers, setPhoneNumbers] = useState([]),
        [pagination, setPagination] = useState({}),
        [selectionType, setSelectionType] = useState('checkbox'),
        [typeModal, setTypeModal] = useState(""),
        // ============== ACTION ==============
        actionDelete = ActionDelete(),
        getListContact = ActionGetList(),
        getMasterData = ActionMasterData(),
        actionUpload = ActionUpload(),
        actionTagGetList = ActionTagGetList(),
        actionCheckDuplicate = ActionCheckDuplicate(),
        actionOptIn = ActionOptIn(),
        actionOptOut = ActionOptOut(),
        actionFiltersList = ActionFiltersList(),
        actionMasterPortalFilter = ActionMasterPortalFilter(),
        actionHighlightCell = ActionHighlightCell(),
        // ============== SELECT DATA ==============
        itemListFilters = useSelector(selectFiltersList()),
        itemList = useSelector(selectList()),
        itemTagStatusAction = useSelector(selectTagStatusAction()),
        itemMasterPortalFilter = useSelector(selectMasterPortalFilter()),
        itemErrors = useSelector(selectErrors()),
        itemMasterData = useSelector(selectMasterData()),
        itemTagList = useSelector(selectListTag()),
        itemListColumn = useSelector(selectListColumn()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        if (Object.keys(queryString.parse(props.location.search)).length > 0) {
            let newParamsFilters = queryString.parse(props.location.search, {arrayFormat: 'comma'});
            newParamsFilters = removeObjectNullFull(newParamsFilters)
            if (newParamsFilters?.from_date && newParamsFilters?.to_date) {
                newParamsFilters.date = [moment(newParamsFilters?.from_date), moment(newParamsFilters?.to_date)]
            } else {
                if (!Boolean(newParamsFilters?.without_date)) {
                    newParamsFilters.date = [moment().subtract(30, 'days'), moment()]
                }
            }
            setPramsFilters(contactFiltersParams(removeObjectNullFull(newParamsFilters)))

            if (((LocalStorage.get(CONFIG_LOCAL_STORAGE.CONTACT_SOURCE_FILTER)).toUpperCase() === "REALESTATEIQ") || (newParamsFilters?.source.toUpperCase() === "REALESTATEIQ")) {
                setIsOpenFilterRealEstateIQ(true)
            } else {
                setIsOpenFilterRealEstateIQ(false)
            }

            if (newParamsFilters?.source) {
                if (LocalStorage.get(CONFIG_LOCAL_STORAGE.CONTACT_SOURCE_FILTER) !== newParamsFilters?.source) {
                    LocalStorage.set(CONFIG_LOCAL_STORAGE.CONTACT_SOURCE_FILTER, newParamsFilters?.source)
                }
            }
            props.history.replace(setParamsUrlFilter(ROUTE_REDIRECT.CONTACT.HOME, contactFiltersParams(removeObjectNullFull(newParamsFilters))))
            form.setFieldsValue(newParamsFilters)
            getListContact(contactFiltersParams(removeObjectNullFull(newParamsFilters)));
        } else {
            if (LocalStorage.get(CONFIG_LOCAL_STORAGE.CONTACT_SOURCE_FILTER)) {
                form.setFieldsValue({source: LocalStorage.get(CONFIG_LOCAL_STORAGE.CONTACT_SOURCE_FILTER)})
                let newDate = {}
                if ((LocalStorage.get(CONFIG_LOCAL_STORAGE.CONTACT_SOURCE_FILTER)).toUpperCase() === "REALESTATEIQ") {
                    setPramsFilters(contactFiltersParams(removeObjectNullFull({date: [moment().subtract(30, 'days'), moment()]})))
                    form.setFieldsValue({date: [moment().subtract(30, 'days'), moment()]})
                    newDate = {date: [moment().subtract(30, 'days'), moment()]}
                    setIsOpenFilterRealEstateIQ(true)
                } else {
                    setPramsFilters(contactFiltersParams(removeObjectNullFull({date: [], auction_date: []})))
                    form.setFieldsValue({date: [], auction_date: []})
                    newDate = {}
                    setIsOpenFilterRealEstateIQ(false)
                }
                setPramsFilters({source: LocalStorage.get(CONFIG_LOCAL_STORAGE.CONTACT_SOURCE_FILTER)})
                form.setFieldsValue({...newDate, source: LocalStorage.get(CONFIG_LOCAL_STORAGE.CONTACT_SOURCE_FILTER)})
                props.history.replace(setParamsUrlFilter(ROUTE_REDIRECT.CONTACT.HOME, contactFiltersParams({
                    ...newDate,
                    source: LocalStorage.get(CONFIG_LOCAL_STORAGE.CONTACT_SOURCE_FILTER)
                })))
                getListContact(contactFiltersParams({
                    ...newDate,
                    source: LocalStorage.get(CONFIG_LOCAL_STORAGE.CONTACT_SOURCE_FILTER)
                }));
            } else {
                LocalStorage.set(CONFIG_LOCAL_STORAGE.CONTACT_SOURCE_FILTER, "MEGALITH");
                setPramsFilters({source: "MEGALITH"})
                form.setFieldsValue({source: "MEGALITH"})
                props.history.replace(setParamsUrlFilter(ROUTE_REDIRECT.CONTACT.HOME, {source: "MEGALITH"}))
                getListContact({source: "MEGALITH"});
            }
        }
        actionTagGetList({page_size: 1000});
        getMasterData()
        actionFiltersList()
        actionMasterPortalFilter()

        return () => {
            setIsCreateNote(false)
            setIdNote("")
            setValuePortalFilter({})
            setListSelectedHighlight({})
            setIsDisabledButtonDelete(false)
            setOnOpenAddTag(false)
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        if (itemTagStatusAction?.addTagSuccess) {
            setOnOpenAddTag(false)
        }
    }, [itemTagStatusAction]);

    useEffect(() => {
        setResultList(itemList?.result || [])
        setPagination({
            total: itemList?.pagination?.total || 0,
            totalPage: itemList?.pagination?.total_page || 0,
            defaultCurrent: itemList?.pagination?.page_index || 1,
            pageSize: itemList?.pagination?.page_size || 10
        })
    }, [itemList]);

    const onTableChange = (pagination, filters, sorter) => {
        if (valuePortalFilter?.title) {
            getListContact({...queryString.parse(valuePortalFilter?.value), ...genaratePaginateFilterSort(pagination, filters, sorter, true)})
        } else {
            // let currentFilters = getCurrentFilters();
            filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
            let newFilters = genaratePaginateFilterSort(pagination, filters, sorter, true);
            setPramsFilters(newFilters)
            setInitData({
                ...initData,
                filters: newFilters
            });
            props.history.replace(setParamsUrlFilter(ROUTE_REDIRECT.CONTACT.HOME, newFilters))
            getListContact(removeObjectNullFull(newFilters));
        }
    };

    const onCreate = (e, data) => {
        if (e === TAG_DEFINE.ACTION_OPTIONS.UPDATE) {
            props.history.push((ROUTE_REDIRECT.CONTACT.UPDATE).replace(":id", data.id))
        } else {
            props.history.push(ROUTE_REDIRECT.CONTACT.CREATE)
        }
    };

    const onSearch = (val) => {
        setValuePortalFilter({})
        LocalStorage.set(CONFIG_LOCAL_STORAGE.CONTACT_SOURCE_FILTER, val.source);
        setListSelectedItem([]);
        setIsDisabledButtonDelete(false)
        let getFilters = contactFiltersParams(removeObjectNullFull({
            ...initData.filters, ...paramsFilters, ...val,
            page_index: 1
        }));
        setPramsFilters(getFilters);
        setInitData({...initData, filters: paramsFilters})
        props.history.replace(setParamsUrlFilter(ROUTE_REDIRECT.CONTACT.HOME, getFilters))
        getListContact(removeObjectNullFull(getFilters));
    };

    const propsUpload = {
        onRemove: file => {
            setFileList([])
            return false
        },
        beforeUpload: file => {
            setFileList([file])
            return false;
        },
        fileList,
    };

    const handleUpload = () => {
        // const formData = new FormData();
        // fileList.forEach(file => {
        //     formData.append('files', file);
        // });
        // formData.append('files', fileList[0]);
        if (fileList.length > 0) {
            actionUpload({file: fileList[0], tag_id: importSelectedTag})
            setFileList([])
        } else {
            message.error("Please choose file upload")
        }
    };

    const onClosePopup = val => {
        setImportSelectedTag("")
        setIsImport(false);
        let paramsFilters = queryString.parse(props.location.search, {arrayFormat: 'comma'});
        paramsFilters = removeObjectNullFull(paramsFilters)
        getListContact(paramsFilters);
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            let detectedIsDelete = find(selectedRows, {is_delete: false}),
                newPhoneNumbers = [];
            if (Object?.keys(detectedIsDelete || {}).length > 0) {
                setIsDisabledButtonDelete(true)
            }
            newPhoneNumbers = selectedRows.map(i => i.phone_number);
            setPhoneNumbers(newPhoneNumbers)
            setListSelectedItem(selectedRowKeys)
        },
        // getCheckboxProps: (record) => ({
        //     disabled: record.name === 'Disabled User',
        //     // Column configuration not to be checked
        //     name: record.name,
        // }),
    };

    const onDeleteAll = () => {
        if (listSelectedItem.length > 0) {
            Modal.confirm({
                title: 'Notification',
                icon: <ExclamationCircleOutlined/>,
                content: 'Do you want to delete these items?',
                onOk() {
                    actionDelete({id: listSelectedItem.join(","), filters: paramsFilters})
                },
                onCancel() {
                },
            })
        }
    }

    const onFilterChange = val => {
        if (val.source) {
            if ((val.source).toUpperCase() === "REALESTATEIQ") {
                setIsOpenFilterRealEstateIQ(true)
                if (!(paramsFilters?.date || [])[0] && !(paramsFilters?.date || [])[1]) {
                    let newFilters = {
                        ...paramsFilters,
                        date: [moment().subtract(30, 'days'), moment()]
                    }
                    setPramsFilters(newFilters)
                    form.setFieldsValue({
                        date: [moment().subtract(30, 'days'), moment()]
                    })
                }
            } else {
                setIsOpenFilterRealEstateIQ(false)
                setPramsFilters({
                    ...paramsFilters,
                    date: [],
                    auction_date: []
                })
                form.setFieldsValue({
                    date: [],
                    auction_date: []
                })
            }
        }
        if (!val?.date) {
            setPramsFilters({...paramsFilters, without_date: 1})
        }else{
            setPramsFilters({...paramsFilters, without_date: ""})
        }
    }

    const onExport = val => {
        if (valuePortalFilter?.title) {
            getListContact({...queryString.parse(valuePortalFilter?.value), is_export: 1})
        } else {
            let auction_date = [],
                date = [];
            if (paramsFilters?.auction_date_from && paramsFilters?.auction_date_to) {
                auction_date = [moment(paramsFilters?.auction_date_from), moment(paramsFilters?.auction_date_to)]
            }
            if (paramsFilters?.from_date && paramsFilters?.to_date) {
                date = [moment(paramsFilters?.from_date), moment(paramsFilters?.to_date)]
            }
            getListContact(removeObjectNullFull(contactFiltersParams({
                ...initData.filters, ...paramsFilters, ...val,
                auction_date,
                date,
                page_index: 1,
                is_export: 1
            })));
        }
    }

    const onCellSelected = (val, name, rowTag = {}) => {
        let newItems = {}
        // if (listSelectedHighlight[name]) {
        //     Object.keys(listSelectedHighlight || {}).map(i => {
        //         console.log({i, name}, i !== name)
        //         if (i !== name) {
        //             newItems = {
        //                 ...newItems,
        //                 [i]: listSelectedHighlight[i]
        //             }
        //         }
        //     })
        // } else {
        //     newItems = {
        //         ...rowTag,
        //         [val?.columnName]: colorPicker,
        //     }
        // }
        // setListSelectedHighlight(newItems)

        newItems = {
            ...rowTag,
            [val?.columnName]: colorPicker,
        }
        actionHighlightCell({
            query: {
                data: newItems
            },
            id: val?.idRow,
            filters: paramsFilters
        })
    }

    return <>
        <HeaderAction
            isActionSignlePage
            title="Contact List"
            disabledFormatUpperCaseTitle
            isCreate
            onClick={e => onCreate(e)}
            isCustomize
        >
            <ModalColumn
                isOpen={isShowColumn}
                isClose={e => setIsShowColumn(e)}
                listColumn={e => setListColumn(e)}
                source={queryString.parse(props.location.search, {arrayFormat: 'comma'})?.source}
                getPositionColumn={e => {
                }}
            />
            <button type="button" className="btn btn-warning btn-rounded fs--14px ms-3 color--white"
                    onClick={e => {
                        props.history.push(ROUTE_REDIRECT.INBOX)
                    }}>
                SMS Inbox
            </button>
            <button type="button" className="btn btn-success btn-rounded fs--14px ms-3" onClick={e => {
                window.location = `${process.env.REACT_APP_API_URL}/static/import-template/Import_Contact_Template.xlsx`
            }}>
                <i className="fas fa-arrow-down"/> Download Template Import
            </button>
            <button type="button" className="btn btn-danger btn-rounded fs--14px ms-3" onClick={e => {
                props.history.push(ROUTE_REDIRECT.TAG.HOME)
            }}>
                Tag Management
            </button>
            {/*<button type="button" className="btn btn-info btn-rounded fs--14px ms-3 color--white" onClick={e => {*/}
            {/*    props.history.push(ROUTE_REDIRECT.EMAIL_HISTORY.HOME)*/}
            {/*}}>*/}
            {/*    Email Management*/}
            {/*</button>*/}
            {/*<button type="button" className="btn btn-info btn-rounded fs--14px ms-3 color--white" onClick={e => {*/}
            {/*    props.history.push(ROUTE_REDIRECT.SMS_HISTORY.HOME)*/}
            {/*}}>*/}
            {/*    SMS Management*/}
            {/*</button>*/}
            <Modal
                maskClosable={false}
                destroyOnClose
                title="Upload File"
                visible={isImport}
                onOk={e => handleUpload()}
                onCancel={() => onClosePopup()}
            >
                <Form.Item
                    label="Tag"
                    // name="tag"
                >
                    <Select
                        // mode="multiple"
                        // disabled
                        onChange={e => setImportSelectedTag(e)}
                        allowClear
                        style={{
                            width: '100%',
                        }}
                    >
                        {
                            (itemTagList?.result || []).length > 0 && (itemTagList?.result || []).map((i, k) => {
                                return (
                                    <Select.Option key={k} value={i?.id}>{i?.tag}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <p className="mb-2">File Import</p>
                <Upload {...propsUpload} maxCount={1} className="data-upload">
                    <Button icon={<Icon type="upload-outlined"/>}>Select File</Button>
                </Upload>
            </Modal>
        </HeaderAction>
        <Modal
            title={typeModal === "SF" ? "Save Your Filters" : "List Filters"}
            visible={isOpenFilters}
            onOk={() => setIsOpenFilters(false)}
            onCancel={() => setIsOpenFilters(false)}
            destroyOnClose
            footer={null}
        >
            {
                typeModal === "SF" ? <ModalSaveFilters
                        isSubmit={e => setIsOpenFilters(e)}
                        lead={"contact"}
                        routeMatch={props}
                        onClose={e => setIsOpenFilters(false)}
                        master={itemListFilters?.listFilters || []}/> :
                    <ModalListFilters
                        setValuePortalFilter={val => setValuePortalFilter(val)}
                        onClose={e => setIsOpenFilters(false)}
                        master={itemListFilters?.listFilters || []}
                        itemMasterPortalFilter={itemMasterPortalFilter}
                    />
            }
        </Modal>
        <ModalCreateNote
            isOpen={isCreateNote}
            isClose={e => setIsCreateNote(false)}
            isAction={{isDetail: true, id: idNote}}
        />
        <div className="container-fluid px-4">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSearch}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
                onValuesChange={onFilterChange}
            >
                <div className="row bg-white py-3 align-items-center">
                    <div className="col-4">
                        <Form.Item
                            label="Search"
                            name="search"
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </div>
                    <div className="col-4">
                        <Form.Item
                            label="Tag"
                            name="tag"
                        >
                            <Select
                                // mode="multiple"
                                // disabled
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                            >
                                {
                                    Object?.keys(itemMasterData?.tag || {}).length > 0 && Object?.keys(itemMasterData?.tag || {}).map((i, k) => {
                                        return (
                                            <Select.Option key={k}
                                                           value={itemMasterData?.tag[i]}>{i}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-3">
                        <Form.Item
                            label="Source"
                            name="source"
                            initialValue="MEGALITH"
                        >
                            <Select
                                // mode="multiple"
                                // disabled
                                style={{
                                    width: '100%',
                                }}
                            >
                                {
                                    Object?.keys(itemMasterData?.source || {}).length > 0 && Object?.keys(itemMasterData?.source || {}).map((i, k) => {
                                        return (
                                            <Select.Option key={k}
                                                           value={itemMasterData?.source[i]}>{i}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-3">
                        <Form.Item
                            label="Status"
                            name="status"
                        >
                            <Select
                                // mode="multiple"
                                // disabled
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                            >
                                {
                                    Object?.keys(itemMasterData?.status || {}).length > 0 && Object?.keys(itemMasterData?.status || {}).map((i, k) => {
                                        return (
                                            <Select.Option key={k}
                                                           value={i}>{itemMasterData?.status[i]}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    {
                        isOpenFilterRealEstateIQ ? (
                            <>
                                <div className="col-3">
                                    <Form.Item
                                        label="Lead type"
                                        name="lead_type"
                                    >
                                        <Select
                                            // mode="multiple"
                                            // disabled
                                            allowClear
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            {
                                                (itemMasterData?.lead_type || []).length > 0 && (itemMasterData?.lead_type || []).map((i, k) => {
                                                    return (
                                                        <Select.Option key={k} value={i}>{i}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </>
                        ) : null
                    }
                    <div className={`col-3 ${isOpenFilterRealEstateIQ ? "" : "d-none"}`}>
                        <Form.Item
                            label="From Date - To Date"
                            name="date"
                        >
                            <DatePicker.RangePicker style={{width: "100%"}}/>
                        </Form.Item>
                    </div>
                    <div className={`col-3 ${isOpenFilterRealEstateIQ ? "" : "d-none"}`}>
                        <Form.Item
                            label="Auction Date"
                            name="auction_date"
                        >
                            <DatePicker.RangePicker style={{width: "100%"}}/>
                        </Form.Item>
                    </div>
                    <div className="col-2">
                        <Form.Item
                            label=" "
                            name="is_owned"
                            valuePropName="checked"
                        >
                            <Checkbox>My Contact</Checkbox>
                        </Form.Item>
                    </div>
                    <div className="col-12" style={{fontStyle: "italic"}}>
                        *Note: Contacts from third party services need tags to check for duplicate
                    </div>
                    <div className="col-6">
                        <Space>
                            <Button type="primary"
                                    htmlType="button"
                                    className="mb-0"
                                    style={{
                                        backgroundColor: "#123963"
                                    }}
                                    onClick={e => {
                                        setTypeModal("F");
                                        setIsOpenFilters(true)
                                    }}>
                                Filters
                            </Button>
                            <Button type="primary"
                                    htmlType="button"
                                    className="mb-0 mx-3"
                                    style={{
                                        backgroundColor: "#123963"
                                    }}
                                    onClick={e => {
                                        setTypeModal("SF");
                                        setIsOpenFilters(true)
                                    }}>
                                Save Current Filter
                            </Button>
                            <Popover
                                content={
                                    <Space>
                                        <div style={{
                                            cursor: 'pointer',
                                            width: "35px",
                                            height: "35px",
                                            borderRadius: "6px",
                                            paddingTop: "5px",
                                            textAlign: "center",
                                            backgroundColor: "white",
                                            border: "1px solid gray"
                                        }}
                                             onClick={e => {
                                                 setColorPicker("");
                                                 setShowColorPicker(false)
                                             }}
                                        >
                                            <p>X</p>
                                        </div>
                                        {
                                            _listColorPicker?.map((color, index) => {
                                                return (
                                                    <div style={{
                                                        cursor: 'pointer',
                                                        width: "35px",
                                                        height: "35px",
                                                        borderRadius: "6px",
                                                        paddingTop: "5px",
                                                        textAlign: "center",
                                                        backgroundColor: color
                                                    }}
                                                         onClick={e => {
                                                             setColorPicker(color);
                                                             setShowColorPicker(false)
                                                         }}
                                                    >
                                                        {
                                                            (colorPicker === color) ?
                                                                <BsCheckAll className="color--white" size={18}/> : null
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </Space>
                                }
                                title="Highlight Color"
                                trigger="click"
                                open={showColorPicker}
                                onOpenChange={e => setShowColorPicker(e)}
                            >
                                <div style={{
                                    borderRadius: "8px",
                                    border: "1px solid gray",
                                    padding: "2px",
                                    cursor: "pointer"
                                }}>
                                    {
                                        colorPicker ? (
                                            <div style={{
                                                width: "35px",
                                                height: "35px",
                                                borderRadius: "6px",
                                                backgroundColor: colorPicker
                                            }}/>
                                        ) : <div style={{
                                            cursor: 'pointer',
                                            width: "35px",
                                            height: "35px",
                                            borderRadius: "6px",
                                            paddingTop: "6px",
                                            textAlign: "center",
                                            backgroundColor: "white",
                                            // border: "1px solid gray"
                                        }}
                                        >
                                            <p className="text-center">X</p>
                                        </div>
                                    }
                                </div>
                            </Popover>
                            <div
                                className={`mx-2`}
                            >
                                <Checkbox onChange={e => setIsHighlight(e.target.checked)}>Tag
                                    Color</Checkbox>
                            </div>
                            {valuePortalFilter?.title ?
                                <p className="fs--16px color--red">Filter Apply: {valuePortalFilter?.title}</p> : null}
                        </Space>
                    </div>
                    <div className="col-6 text-end">
                        <Space>
                            <Form.Item className="mb-0" label=" ">
                                <Button type="primary" htmlType="button"
                                        onClick={e => setIsShowColumn(true)}>
                                    List Column
                                </Button>
                            </Form.Item>
                            <Form.Item className="mb-0" label=" ">
                                <Button type="primary" htmlType="submit">
                                    Search {isFetching && <Icon type="sync-outlined" spin/>}
                                </Button>
                            </Form.Item>
                            <Form.Item className="mb-0" label=" ">
                                <Button type="primary"
                                        htmlType="button"
                                        style={{
                                            backgroundColor: "#123963"
                                        }}
                                        onClick={e => setIsImport(!isImport)}>
                                    <i className="fas fa-arrow-up me-2"/> Import
                                </Button>
                            </Form.Item>
                            <Form.Item className="mb-0" label=" ">
                                <Button type="primary"
                                        htmlType="button"
                                        onClick={e => onExport()}>
                                    <i className="fas fa-arrow-down me-2"/> Export {isFetching &&
                                    <Icon type="sync-outlined" spin/>}
                                </Button>
                            </Form.Item>
                            <Form.Item className="mb-0" label=" ">
                                <Button type="danger" htmlType="button" className="ms-2"
                                        onClick={e => actionCheckDuplicate({filters: paramsFilters})}>
                                    Check Duplicate
                                </Button>
                            </Form.Item>
                        </Space>
                    </div>
                </div>
            </Form>
        </div>
        <Modal
            title="Tag"
            // width={1000}
            destroyOnClose
            visible={onOpenAddTag}
            onOk={() => {
                setOnOpenAddTag(false)
            }}
            onCancel={() => {
                setOnOpenAddTag(false)
            }}
            footer={null}>
            <AddTags
                paramsFilters={paramsFilters}
                setOnOpenAddTag={e => setOnOpenAddTag(e)}
                onOpenAddTag={onOpenAddTag}
                itemMasterData={itemMasterData}
                listSelectedItem={listSelectedItem}
            />
        </Modal>
        {
            (listSelectedItem.length > 0) && (
                <div className="container-fluid mt-0 pt-0">
                    <Button disabled={isDisabledButtonDelete} type="danger" htmlType="button"
                            onClick={e => onDeleteAll()}>
                        Delete
                    </Button>
                    <Button type="danger" htmlType="button" className="ms-2"
                            onClick={e => actionOptOut({
                                query: {phone_numbers: phoneNumbers},
                                filters: paramsFilters
                            })}>
                        Opt Out
                    </Button>
                    <Button type="primary" htmlType="button" className="ms-2 bg--foreign border-0"
                            onClick={e => actionOptIn({
                                query: {phone_numbers: phoneNumbers},
                                filters: paramsFilters
                            })}>
                        Opt In
                    </Button>
                    <Button type="primary" htmlType="button" className="ms-2"
                            onClick={e => setOnOpenAddTag(true)}>
                        Tag {isFetching && <Icon type="sync-outlined" spin/>}
                    </Button>
                </div>
            )
        }
        <ListComponent
            rowSelection={{
                type: selectionType,
                ...rowSelection,
                selectedRowKeys: listSelectedItem
            }}
            scroll={{
                x: "max-content"
            }}
            classParent="m-0 p-0 col-md-12"
            className="custom-table hn__contact--table"
            marginTop={'0'} marginBottom={'0'}
            loading={{spinning: isFetching, size: 'large'}}
            rowKey="id"
            dataSource={getDataSource(resultList || [], initData.modelItem)}
            columns={columnsTable({
                titleTable: ["action", ...listColumn, "delete"],
                component: componentTable,
                itemName: namePage,
                listColumn: {action: "Action", ...itemMasterData?.real_column, delete: "Delete"}
            }).map((column, index) => {
                return {
                    ...column,
                    render: (text, record, rowIndex) => {
                        return (
                            <div
                                onClick={event => {
                                    event.preventDefault()
                                    event?.stopPropagation()
                                    const columnName = column.dataIndex;
                                    const idRow = record?.id;
                                    if (isHighlight) {
                                        if (!["action", "delete"].includes(column.dataIndex)) {
                                            onCellSelected({
                                                columnName,
                                                idRow
                                            }, `${idRow}--${columnName}`, record?.row_tag)
                                        }
                                    }
                                }}
                                data-row={rowIndex}
                                data-column={column.title}
                                className={`${isHighlight ? "cursorPointer" : ""} ${record?.is_duplicate ? "hn__contact--line-table" : ""}`}
                                style={{
                                    backgroundColor: (record?.row_tag || {})[column.dataIndex] || "white",
                                    color: Object?.keys((record?.row_tag || {})).includes(column.dataIndex) ? (record?.row_tag || {})[column.dataIndex] ? "white" : "black" : "black",
                                    padding: "16px"
                                }}
                            >
                                {componentTable[column.dataIndex]?.hasOwnProperty("render") ? componentTable[column.dataIndex]?.render(text, record, rowIndex) : text}
                            </div>
                        )
                    },
                };
            })}
            // rowClassName={(record, index) => {
            //     if (record?.is_duplicate) {
            //         return "hn__contact--line-table"
            //     }
            // }}
            pagination={{
                total: pagination.total,
                totalPage: pagination.totalPage,
                current: pagination.defaultCurrent,
                pageSize: pagination.pageSize,
                responsive: true
            }}
            onChange={onTableChange}
            componentTableList={{}}
        />
    </>
}

export default Index
