const TAG_DEFINE = {
    GENERAL: {
        ITEM: {
            TEAMS: {
                referral_email: "Referral Email",
                email: "Email",
                first_name: "First Name",
                last_name: "Last Name",
                phone_number: "Phone Number",
                status: "Status",
                created_at: "Created Date",
                membership_code: "Membership Code",
                action: "Action"
            },
            TEAM_POINT: {
                email: "Customer",
                total_point: "Total Point",
                total_trans: "Total Transaction",
                team_point: "Owned Point",
                team_trans: "Owned Transaction",
                team_point_level_1: "LV1 Ref Point",
                team_trans_level_1: "LV1 Ref Transaction",
                team_point_level_2: "LV2 Ref Point",
                team_trans_level_2: "LV2 Ref Transaction",
                team_point_level_3: "LV3 Ref Point",
                team_trans_level_3: "LV3 Ref Transaction",
                team_point_level_4: "LV4 Ref Point",
                team_trans_level_4: "LV4 Ref Transaction",
                total_commission: "Total Commission",
                team_commission: "Owned Commission",
                team_commission_level_1: "LV1 Ref Commission",
                team_commission_level_2: "LV2 Ref Commission",
                team_commission_level_3: "LV3 Ref Commission",
                team_commission_level_4: "LV4 Ref Commission",
            },
            TEAM_POINT_DETAIL: {
                email: "Email",
                note: "Note",
                point_amount: "Point",
                source: "Partner",
                referral: "Referral",
                created_at: "Created Date",
            },
            CONTACT: {
                name: "Name",
                email: "Email",
                phone_number: "Phone Number",
                company: "Company",
                job_title: "Job Title",
                owner: "Owner",
                tag: "Tag",
                status: "Status",
                created_date: "Created Date",
                first_name: "First Name",
                last_name: "Last Name",
                address: "Address",
                delete: "Delete",
                extra_info: "Extra Info",
                yr_bt: "Yr Bt",
                estimated_equity: "Estimated Equity",
                equity: "Equity",
                appraised_value: "Appraised Value",
                auction_date: "Auction Date",
                action: "Action"
            },
            TAG: {
                tag: "Tag",
                owner: "Owner",
                created_date: "Created Date",
                action: "Action"
            },
            TEMPLATE: {
                title: "Title",
                owner: "Owner",
                created_date: "Created Date",
                is_template: "Is Publish",
                action: "Action"
            },
            WORKFLOW: {
                title: "Title",
                owner: "Owner",
                status: "Status",
                last_run_at: "Last Run",
                next_run_at: "Next Run",
                created_date: "Created Date",
                action: "Action",
            },
            WORKFLOW_DETAIL_LOG: {
                running_time: "Running Time",
                status: "Status",
                log: "Log",
            },
            SMS_TEMPLATE: {
                title: "Title",
                owner: "Owner",
                created_date: "Created Date",
                action: "Action"
            },
            EMAIL_HISTORY: {
                template: "Template",
                owner: "Owner",
                tag: "Tag",
                created_date: "Created Date",
                log: "Log",
                action: "Action"
            },
            SMS_HISTORY: {
                template: "Template",
                owner: "Owner",
                tag: "Tag",
                created_date: "Created Date",
                log: "Log",
                action: "Action"
            },
            MEMBERSHIP_PLANS_QUOTATION: {
                description: " ",
                entry: "Entry",
                elite: "Elite",
                entrepreneur: "Entrepreneur",
            },
            INVOICE_LIST: {
                code: "Transaction",
                transaction_id: "Payment Id",
                amount: "Price",
                type: "Type",
                owner: "User",
                created_date: "Date",
            },
            EMBEDDED_FORM: {
                title: "Title",
                owner: "Owner",
                type: "Type",
                status: "Status",
                created_date: "Date"
            },
            TASK: {
                title: "Title",
                owner: "Owner",
                status_explain: "Status",
                created_date: "Created Date",
                action: "Action"
            },
        }
    },
    ACTION: {
        info: "Info 1111",
        export: "Export file",
        import: "Import file",
        create: "Create",
        update: "Update",
        filter: "Filter",
        show: "Show",
        remove: "Remove",
        action: "Action",
        exit: "Exit",
        close: 'Close',
        lock: "Lock",
        unlock: "Unlock",
        refreshData: "Làm mới dữ liệu",
        back: "Bạck",
        backIndex: "Back home",
        statusHide: "Không hiển thị",
        statusShow: "Hiển thị",
        uploadImage: "Tải Hình ảnh",
        collapse: "Thu gọn",
        detail: "Detail",
        noData: "No data",
        cancel: "Cancel",
        confirm: "Confirm",
        edit: 'Chỉnh sửa',
        add: 'Add',
        addMulti: 'Thêm nhiều',
        save: 'Save',
        send: 'Send',
        use: 'Sử dụng',
        resendEmail: 'Gửi lại email',
        approve: 'Cho phép',
        unApprove: 'Không cho phép',
        translate: 'Phiên dịch',
        unlink: 'Xóa liên kết',
        unActive: "Hủy kích hoạt",
        search: "Search"
    },
    NOTIFICATION: {
        title: "Thông báo",
        building: "Tính năng đang được phát triển",
        notInternet: "Kết nối bị lỗi, máy chủ không phản hồi!",
        createSuccess: "Thêm dữ liệu thành công",
        updateSuccess: "Cập nhật dữ liệu thành công",
        removeSuccess: "Xoá dữ liệu thành công",
        checkSuccess: "Kiểm tra thành công",
        uploadSuccess: "Tải dữ liệu thành công",
        resendMailSuccess: "Gởi email thành công",
        changePasswordSuccess: "Đổi mật khẩu thành công",
        assigneeSuccess: "Yêu cầu đã được chuyển",
        cancelTicketSuccess: "Đã huỷ yêu cầu",
        sureRemove: "Bạn chắc chắn muốn xóa ?",
        sureDeActive: "Bạn muốn vô hiệu tài khoản ?",
        sureUse: "Bạn chắc chắn muốn sử dụng ?",
        sureLogout: "Bạn chắc chắn muốn thoát?",
        changePassword: "Bạn chắc chắn muốn đổi mật khẩu?",
        confirmExit: 'Bạn có chắc chắn muốn thoát?',
        confirmBack: 'Bạn có chắc chắn muốn quay về?',
        downloadSuccess: 'Tải về file thành công.',
        youCantAccessThisPage: 'Bạn không thể vào trang này!',
        maxFileSize: 'Kích thước file tối đa',
    },
    PAGINATE: {
        showLine: "Items per page",
        first: "First page",
        last: "Last page",
        previous: "Previous",
        next: "Next",
        of: 'of',
        items: 'items',
        jumpTo: 'Go to',
        itemsPerPage: 'Items / page',
        page: 'page',
    },
    VALIDATION: {
        dropImage: "Kéo và thả hình ảnh ở đây",
        email: "Vui lòng nhập đúng email",
        password:
            "Mật khẩu gồm ký tự thường, ký tự viết hoa, ký tự số, ký tự đặt biệt và trên 6 ký tự",
        image: "File phải là hình",
        required: "Dữ liệu không được rỗng",
        requiredImage: "Vui lòng chọn hình",
        string: "Dữ liệu phải là ký tự",
        number: "Dữ liệu phải là số",
        priceRequired: "Vui lòng nhập giá",
        invalidDate: "Ngày tháng không hợp lệ",
        salePriceLessThenPrice: "Giá giảm phải thấp hơn giá gốc",
        sureUse: "Bạn chắc chắn muốn sử dụng ?",
        image1pic: "Vui lòng chọn 1 tấm hình",
        maxImage: "Tối đa 5 tấm hình",
        passwordRequired: "Mật khẩu không được rỗng",
        currentPasswordRequired: "Mật khẩu cũ không được rỗng",
        newPasswordRequired: "Mật khẩu mới không được rỗng",
        confirmPasswordRequired: "Mật khẩu xác nhận không được rỗng",
        usernameRequired: "Tài khoản không được rỗng",
        usernameOrPasswordNotMatch: "Tài khoản hoặc mật khẩu không đúng",
        userLocked: "Sai thông tin đăng nhập hoặc tài khoản bị khoá",
        connectFail: "Kết nối bị lỗi, máy chủ không phản hồi!",
        voucherInvalid: "Mã số voucher không hợp lệ",
        userNameExists: "Tài khoản đã tồn tại!",
        uploadFail: "Tải tập tin không thành công",
        percent: "Không được quá 100%",
        minOrderValue: "Dữ liệu phải nhỏ hơn đơn hàng tối đa",
        maxPhone: "Không được quá 12 ký tự",
        minPhone: "Dữ liệu phải từ 8 ký tự trở lên",
        minpassword: "Dữ liệu phải từ 6 ký tự trở lên",
        quantity: "Số lượng ít nhất là 1",
        LessThenZero: "Dữ liệu phải lớn hơn 0",
        MinValue: "Dữ liệu phải lớn hơn",
        isPhone: "Vui lòng nhập đúng số điện thoại",
        isSpecialString: "Vui lòng không nhập ký tự đặc biệt",
        isUrl: "Vui lòng nhập đúng đường dẫn",
        recaptcha: "Vui lòng xác nhận trước khi đăng nhập",
        comparePassword: "Mật khẩu xác nhận không trùng khớp",
        nameRequired: "Tên không được rỗng",
        codeRequired: "Code không được rỗng",
        starRequired: "Hạng không được để trống",
        checkForm: "Vui lòng kiểm tra lại dữ liệu",
        unknowError: 'Lỗi không xác định',
        nullData: "No data",
        hotelsUnActiveConfirm: "Huỷ kích họat khách sạn sẽ ần thông tin của khách sạn này trên các sản phẩm đang có. Bạn có chắn muốn huỷ kích hoạt?",
        statusCode: {
            code: {
                200: "Upload successful",
                201: "Create successful",
                204: "Update data successful",
                203: "Delete data successful",
                205: "Successfully added to favorites",
                206: "Successfully remove to favorites",
                207: "Password reset successful",
                208: "Verify requested. Please check your email",
                209: "Action successful",
                400: "Không cập không hiệu lực",
                401: "Please login to the page!",
                403: "You do not have permission to access this site!.",
                404: "Page Not Found",
                405: 'Không hỗ trợ',
                1: "%s is reuqired",
                2: "%s is number",
                3: "%s quá giới hạn",
                4: "Không được quá %s ký tự",
                5: "Không được quá %s file",
                6: "Chưa có dữ liệu",
                7: "Bạn chắn chắn muốn xoá %s",
                8: 'Giá trị không được lớn hơn "%s"',
                9: "Hình ảnh tối đa là %s tấm hình",
                10: "Vui lòng nhập %s",
                11: "Vui lòng chọn %s",
                12: "Vui lòng chọn ít nhất %s tấm hình",
                13: "Vui lòng chọn ít nhất %s",
                14: "Bạn chắn chắn muốn cập nhật %s",
                15: 'Giá trị không được nhỏ hơn "%s"',
                1208: "Tài khoản hoặc mật khẩu không hợp lệ",
                1403: "Tài khoản hoặc mật khẩu không hợp lệ",
                1406: "Số điện thoại không hợp lệ",
                1409: "Vui lòng chọn trạm",
                1404: "Tài khoản hoặc email đã tồn tại!",
                1414: "Thông tin đăng nhập không đúng !",
                1210: "Thông tin đăng nhập không đúng !",
                1417: "Bạn không thể cập nhật nhân viên này!",
                1418: "Mã nhân viên không được trùng",
                1555: "Vui lòng nhập lý do hủy phiếu!",
                1563: "Nhân viên được giao không có quyền thực hiện phiếu yêu cầu",
                8001: "Tài khoàn hoặc mật khẩu của bạn không đúng. Vui lòng thử lại",
                8002: "IP đã bị chặn truy cập vào đường dẫn trên. Vui lòng quay lại sau",
                8003: "Tài khoản đã bị tạm khóa trong vòng 15'. Vui lòng quay lại sau",
                8004: "Bạn không có quyền để hủy ticket này",
            }
        }
    },
    ACTION_OPTIONS: {
        CREATE: 'CREATE',
        CLONE: 'CLONE',
        UPDATE: 'UPDATE',
        TRANSLATE: 'TRANSLATE',
    }
};
export default TAG_DEFINE;
