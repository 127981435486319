import VerifyEmailForm from "../../common/components/VerifyEmailForm";
import {Alert, Button, Divider, Form, Input, Menu, message, Modal, Select, Spin, Tabs, Tooltip} from "antd";
import {CheckOutlined, ExclamationCircleOutlined, RetweetOutlined, SendOutlined} from "@ant-design/icons";
import {Icon} from "../../common";
import {v4 as uuidv4} from "uuid";
import React, {useEffect, useState} from "react";
import {
    ActionProfileConfigUpdate,
    ActionSetting,
    ActionSettingMaster,
    ActionUpdateProfile
} from "../../../data/hooks/auth";
import {ActionImportHubspot, ActionImportMonday, ActionImportPodio} from "../../../data/hooks/contact";
import {useSelector} from "react-redux";
import {
    getFetchingAuth,
    getGeneral,
    getProfile,
    getStatusAction,
    selectSettingMaster
} from "../../../data/reselects/authSelector";
import {selectStatusAction} from "../../../data/reselects/contactSelector";
import {find} from "lodash";
import * as LocalStorage from "../../../utils/localStorage";
import {CONFIG_LOCAL_STORAGE} from "../../../constants/define";
import HeaderAction from "../../common/layout/HeaderAction";

const Index = props => {

    const [form] = Form.useForm(),
        [showProfile, setShowProfile] = useState(false),
        [isSetting, setIsSetting] = useState(false),
        [isVerifyEmailForm, setIsVerifyEmailForm] = useState(false),
        [listColumnsBoardSelect, setListColumnsBoardSelect] = useState([]),
        [onChangeValue, setOnChangeValue] = useState(),
        getUpdateProfile = ActionUpdateProfile(),
        actionProfileConfigUpdate = ActionProfileConfigUpdate(),
        actionSettingMaster = ActionSettingMaster(),
        actionSetting = ActionSetting(),
        actionImportMonday = ActionImportMonday(),
        actionImportHubspot = ActionImportHubspot(),
        actionImportPodio = ActionImportPodio(),
        itemStatusAction = useSelector(getStatusAction()),
        itemContactStatusAction = useSelector(selectStatusAction()),
        itemFetching = useSelector(getFetchingAuth()),
        itemSettingMaster = useSelector(selectSettingMaster()),
        itemProfile = useSelector(getProfile()),
        itemGeneral = useSelector(getGeneral());
    useEffect(() => {
        actionSettingMaster();
        setListColumnsBoardSelect(find(itemProfile?.profile_config?.monday_config, {board_id: itemProfile?.profile_config?.monday_board})?.columns || [])
    }, []);

    useEffect(() => {
        if (itemProfile?.profile_config) {
            form.setFieldsValue(itemProfile?.profile_config)
            setOnChangeValue(itemProfile?.profile_config?.marketing_email)
        }
    }, [itemProfile]);

    const updateProfileConfig = val => {
        actionProfileConfigUpdate({...itemProfile?.profile_config, ...val})
    }

    const changeValue = (val) => {
        if (val?.marketing_email) {
            setOnChangeValue(val?.marketing_email)
        } else if (val?.monday_board) {
            form.setFieldsValue({
                monday_address: "",
                monday_email: "",
                monday_extra_info: "",
                monday_first_name: "",
                monday_last_name: "",
                monday_phone_number: "",
                monday_property_address: "",
                monday_property_city: "",
                monday_property_state: "",
            })
            setListColumnsBoardSelect(find(itemProfile?.profile_config?.monday_config, {board_id: val?.monday_board})?.columns || [])
        }
    }

    return (
        <>
            <HeaderAction
                isActionSignlePage
                title="Settings"
                disabledFormatUpperCaseTitle
                // isCreate
                // onClick={e => onCreate(e)}
                isCustomize
            />
            <div className="container-fluid px-4">
                <Spin spinning={itemStatusAction?.profileConfigUpdate || itemStatusAction?.isLoadProfileConfig}>
                    <VerifyEmailForm
                        itemProfile={onChangeValue}
                        isVerifyEmailForm={isVerifyEmailForm}
                        setIsVerifyEmailForm={e => setIsVerifyEmailForm(e)}
                    />
                    <Form
                        layout="vertical"
                        onFinish={updateProfileConfig}
                        onValuesChange={changeValue}
                        form={form}
                    >
                        <Tabs
                            tabPosition="left"
                        >
                            <Tabs.TabPane tab="Email Marketing" key="item-1">
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your first name'}]}
                                        className={`${(itemProfile?.profile_config?.marketing_email && !itemProfile?.profile_config?.marketing_email_valid) ? "verify__form-input--warning verify__form-message--warning" : ""} `}
                                        label="API Key"
                                        name="marketing_email_api_key"
                                        // className={(itemProfile?.profile_config?.marketing_email && !itemProfile?.profile_config?.marketing_email?.marketing_email_valid) ? "icon-ant" : ""}
                                        // validateStatus={(itemProfile?.profile_config?.marketing_email && !itemProfile?.profile_config?.marketing_email?.marketing_email_valid) ? "error" : ""}
                                        // hasFeedback
                                        // help={(itemProfile?.profile_config?.marketing_email && !itemProfile?.profile_config?.marketing_email_valid) ? "Please verify your email!" : ""}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your first name'}]}
                                        className={`${(itemProfile?.profile_config?.marketing_email && !itemProfile?.profile_config?.marketing_email_valid) ? "verify__form-input--warning verify__form-message--warning" : ""} `}
                                        label="Email"
                                        name="marketing_email"
                                        // className={(itemProfile?.profile_config?.marketing_email && !itemProfile?.profile_config?.marketing_email?.marketing_email_valid) ? "icon-ant" : ""}
                                        // validateStatus={(itemProfile?.profile_config?.marketing_email && !itemProfile?.profile_config?.marketing_email?.marketing_email_valid) ? "error" : ""}
                                        // hasFeedback
                                        help={(itemProfile?.profile_config?.marketing_email && !itemProfile?.profile_config?.marketing_email_valid) ? "Please verify your email!" : ""}
                                    >
                                        <Input
                                            suffix={itemProfile?.profile_config?.marketing_email ? !(itemProfile?.profile_config?.marketing_email && !itemProfile?.profile_config?.marketing_email_valid) ? (
                                                <Tooltip title="Email Verified">
                                                    <CheckOutlined className="cursorPointer" style={{
                                                        color: '#52c41a',
                                                    }}/>
                                                </Tooltip>
                                            ) : <Tooltip title="Email Verify">
                                                <SendOutlined className="cursorPointer"
                                                              onClick={e => setIsVerifyEmailForm(true)} style={{
                                                    color: '#FCCE01',
                                                }}/>
                                                {/*<LoadingOutlined />*/}
                                            </Tooltip> : ""}
                                        />
                                    </Form.Item>
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Twilio Marketing" key="item-2">
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                        label="SMS Account ID"
                                        name="sms_account_sid"
                                    >
                                        <Input/>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                        label="SMS Token"
                                        name="sms_token"
                                    >
                                        <Input/>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                        label="SMS Number"
                                        name="sms_phone_number"
                                    >
                                        <Input/>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                        label="SMS Webhook"
                                        name="sms_webhook"
                                    >
                                        <Input disabled/>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                        label="Voice API ID"
                                        name="voice_api_sid"
                                    >
                                        <Input/>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                        label="Voice API Key"
                                        name="voice_api_key"
                                    >
                                        <Input/>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                        label="Voice Twiml App ID"
                                        name="voice_twiml_app_sid"
                                    >
                                        <Input/>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                        label="Voice Forward Phone Numer"
                                        name="voice_forward_phone_number"
                                    >
                                        <Input/>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                        label="Voice Webhook"
                                        name="voice_webhook"
                                    >
                                        <Input disabled/>
                                    </Form.Item>
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="REIQ Synchronize" key="item-3">
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                        label="API Key"
                                        name="reiq_api_key"
                                    >
                                        <Input/>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                        label="First Name"
                                        name="reiq_first_name"
                                    >
                                        <Select
                                            // mode="multiple"
                                            showSearch
                                            optionFilterProp="children"
                                            allowClear
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            {
                                                Object?.keys(itemSettingMaster?.reiq || {}).length > 0 && Object?.keys(itemSettingMaster?.reiq || {} || {}).map((i, k) => {
                                                    return (
                                                        <Select.Option key={k}
                                                                       value={i}>{itemSettingMaster?.reiq[i]}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                        label="Last Name"
                                        name="reiq_last_name"
                                    >
                                        <Select
                                            // mode="multiple"
                                            showSearch
                                            optionFilterProp="children"
                                            allowClear
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            {
                                                Object?.keys(itemSettingMaster?.reiq || {}).length > 0 && Object?.keys(itemSettingMaster?.reiq || {} || {}).map((i, k) => {
                                                    return (
                                                        <Select.Option key={k}
                                                                       value={i}>{itemSettingMaster?.reiq[i]}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                        label="Full Address"
                                        name="reiq_address"
                                    >
                                        <Select
                                            // mode="multiple"
                                            showSearch
                                            optionFilterProp="children"
                                            allowClear
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            {
                                                Object?.keys(itemSettingMaster?.reiq || {}).length > 0 && Object?.keys(itemSettingMaster?.reiq || {} || {}).map((i, k) => {
                                                    return (
                                                        <Select.Option key={k}
                                                                       value={i}>{itemSettingMaster?.reiq[i]}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                        label="Property Address"
                                        name="reiq_property_address"
                                    >
                                        <Select
                                            // mode="multiple"
                                            showSearch
                                            optionFilterProp="children"
                                            allowClear
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            {
                                                Object?.keys(itemSettingMaster?.reiq || {}).length > 0 && Object?.keys(itemSettingMaster?.reiq || {} || {}).map((i, k) => {
                                                    return (
                                                        <Select.Option key={k}
                                                                       value={i}>{itemSettingMaster?.reiq[i]}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                        label="Property City"
                                        name="reiq_property_city"
                                    >
                                        <Select
                                            // mode="multiple"
                                            showSearch
                                            optionFilterProp="children"
                                            allowClear
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            {
                                                Object?.keys(itemSettingMaster?.reiq || {}).length > 0 && Object?.keys(itemSettingMaster?.reiq || {} || {}).map((i, k) => {
                                                    return (
                                                        <Select.Option key={k}
                                                                       value={i}>{itemSettingMaster?.reiq[i]}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                        label="Property State"
                                        name="reiq_property_state"
                                    >
                                        <Select
                                            // mode="multiple"
                                            showSearch
                                            optionFilterProp="children"
                                            allowClear
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            {
                                                Object?.keys(itemSettingMaster?.reiq || {}).length > 0 && Object?.keys(itemSettingMaster?.reiq || {} || {}).map((i, k) => {
                                                    return (
                                                        <Select.Option key={k}
                                                                       value={i}>{itemSettingMaster?.reiq[i]}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                        label="Property Zip Code"
                                        name="reiq_property_zip_code"
                                    >
                                        <Select
                                            // mode="multiple"
                                            showSearch
                                            optionFilterProp="children"
                                            allowClear
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            {
                                                Object?.keys(itemSettingMaster?.reiq || {}).length > 0 && Object?.keys(itemSettingMaster?.reiq || {} || {}).map((i, k) => {
                                                    return (
                                                        <Select.Option key={k}
                                                                       value={i}>{itemSettingMaster?.reiq[i]}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                        label="Email"
                                        name="reiq_email"
                                    >
                                        <Select
                                            // mode="multiple"
                                            showSearch
                                            optionFilterProp="children"
                                            allowClear
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            {
                                                Object?.keys(itemSettingMaster?.reiq || {}).length > 0 && Object?.keys(itemSettingMaster?.reiq || {} || {}).map((i, k) => {
                                                    return (
                                                        <Select.Option key={k}
                                                                       value={i}>{itemSettingMaster?.reiq[i]}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                        label="Phone Number"
                                        name="reiq_phone_number"
                                    >
                                        <Select
                                            // mode="multiple"
                                            showSearch
                                            optionFilterProp="children"
                                            allowClear
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            {
                                                Object?.keys(itemSettingMaster?.reiq || {}).length > 0 && Object?.keys(itemSettingMaster?.reiq || {} || {}).map((i, k) => {
                                                    return (
                                                        <Select.Option key={k}
                                                                       value={i}>{itemSettingMaster?.reiq[i]}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-6">
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                        label="Extra Info"
                                        name="reiq_extra_info"
                                    >
                                        <Select
                                            mode="multiple"
                                            showSearch
                                            optionFilterProp="children"
                                            allowClear
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            {
                                                Object?.keys(itemSettingMaster?.reiq || {}).length > 0 && Object?.keys(itemSettingMaster?.reiq || {} || {}).map((i, k) => {
                                                    return (
                                                        <Select.Option key={k}
                                                                       value={i}>{itemSettingMaster?.reiq[i]}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Hubspot Synchronize" key="item-6">
                                <div className="row">
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="API Key"
                                            name="hubspot_api_key"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="First Name"
                                            name="hubspot_first_name"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Last Name"
                                            name="hubspot_last_name"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Full Address"
                                            name="hubspot_address"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Property Address"
                                            name="hubspot_property_address"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Property City"
                                            name="hubspot_property_city"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Property State"
                                            name="hubspot_property_state"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Property Zip Code"
                                            name="hubspot_property_zip_code"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Email"
                                            name="hubspot_email"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Phone Number"
                                            name="hubspot_phone_number"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Extra Info"
                                            name="hubspot_extra_info"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Button type="primary"
                                                htmlType="button"
                                                className="me-3 mb-0"
                                                onClick={e => actionImportHubspot()}
                                        >
                                            Sync REIQ To Hubspot {itemContactStatusAction?.isImportHubspot &&
                                            <Icon type="sync-outlined" spin/>}
                                        </Button>
                                    </div>
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Podio Synchronize" key="item-7">
                                <div className="row">
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Client ID"
                                            name="podio_client_id"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Client Secret"
                                            name="podio_client_secret"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="App ID"
                                            name="podio_app_id"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="App Token"
                                            name="podio_app_token"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Full Address"
                                            name="podio_address"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Property Address"
                                            name="podio_property_address"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Property City"
                                            name="podio_property_city"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Property State"
                                            name="podio_property_state"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Property Zip Code"
                                            name="podio_property_zip_code"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="First Name"
                                            name="podio_first_name"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Last Name"
                                            name="podio_last_name"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Email"
                                            name="podio_email"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Phone Number"
                                            name="podio_phone_number"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>

                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Email Address 2"
                                            name="podio_email_address_2"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Email Address 3"
                                            name="podio_email_address_3"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Email Address 4"
                                            name="podio_email_address_4"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Email Address 5"
                                            name="podio_email_address_5"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Phone Number 2"
                                            name="podio_phone_number_2"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Phone Number 3"
                                            name="podio_phone_number_3"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Phone Number 4"
                                            name="podio_phone_number_4"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Phone Number 5"
                                            name="podio_phone_number_5"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Cell Phones 1"
                                            name="podio_cell_phones_1"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Cell Phones 2"
                                            name="podio_cell_phones_2"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Cell Phones 3"
                                            name="podio_cell_phones_3"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Cell Phones 4"
                                            name="podio_cell_phones_4"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Cell Phones 5"
                                            name="podio_cell_phones_5"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Lead Type"
                                            name="podio_lead_type"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>

                                    <div className="col-6">
                                        <Form.Item
                                            // rules={[{required: true, message: 'Please input your last name'}]}
                                            label="Extra Info"
                                            name="podio_extra_info"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Button type="primary"
                                                htmlType="button"
                                                className="me-3 mb-0"
                                                onClick={e => actionImportPodio()}
                                        >
                                            Sync REIQ To Podio {itemContactStatusAction?.isImportPodio &&
                                            <Icon type="sync-outlined" spin/>}
                                        </Button>
                                    </div>
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Monday Synchronize" key="item-4">
                                <div className="col-6">
                                    {
                                        (itemProfile?.profile_config?.monday_config || []).length < 1 ?
                                            <Alert message="API Key required and must be a valid key"
                                                   type="error" className="mb-2"/> : null
                                    }
                                    <Form.Item
                                        // rules={[{required: true, message: 'Please input your last name'}]}
                                        label="API Key"
                                        name="monday_api_key"
                                    >
                                        <Input/>
                                    </Form.Item>
                                </div>
                                {
                                    (itemProfile?.profile_config?.monday_config || []).length > 0 ? (
                                        <>
                                            <div className="col-6">
                                                <Form.Item
                                                    // rules={[{required: true, message: 'Please input your last name'}]}
                                                    label="Board"
                                                    name="monday_board"
                                                >
                                                    <Select
                                                        // mode="multiple"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        allowClear
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {
                                                            (itemProfile?.profile_config?.monday_config || []).length > 0 && (itemProfile?.profile_config?.monday_config || []).map((i, k) => {
                                                                return (
                                                                    <Select.Option key={k}
                                                                                   value={i?.board_id}>{i?.name}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>

                                            <div className="col-6">
                                                <Form.Item
                                                    // rules={[{required: true, message: 'Please input your last name'}]}
                                                    label="Full Address"
                                                    name="monday_address"
                                                >
                                                    <Select
                                                        // mode="multiple"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        allowClear
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {
                                                            listColumnsBoardSelect.length > 0 && listColumnsBoardSelect.map((i, k) => {
                                                                return (
                                                                    <Select.Option key={k}
                                                                                   value={i?.key}>{i?.name}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div className="col-6">
                                                <Form.Item
                                                    // rules={[{required: true, message: 'Please input your last name'}]}
                                                    label="Email"
                                                    name="monday_email"
                                                >
                                                    <Select
                                                        // mode="multiple"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        allowClear
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {
                                                            listColumnsBoardSelect.length > 0 && listColumnsBoardSelect.map((i, k) => {
                                                                return (
                                                                    <Select.Option key={k}
                                                                                   value={i?.key}>{i?.name}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div className="col-6">
                                                <Form.Item
                                                    // rules={[{required: true, message: 'Please input your last name'}]}
                                                    label="Extra Info"
                                                    name="monday_extra_info"
                                                >
                                                    <Select
                                                        // mode="multiple"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        allowClear
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {
                                                            listColumnsBoardSelect.length > 0 && listColumnsBoardSelect.map((i, k) => {
                                                                return (
                                                                    <Select.Option key={k}
                                                                                   value={i?.key}>{i?.name}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div className="col-6">
                                                <Form.Item
                                                    // rules={[{required: true, message: 'Please input your last name'}]}
                                                    label="First Name"
                                                    name="monday_first_name"
                                                >
                                                    <Select
                                                        // mode="multiple"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        allowClear
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {
                                                            listColumnsBoardSelect.length > 0 && listColumnsBoardSelect.map((i, k) => {
                                                                return (
                                                                    <Select.Option key={k}
                                                                                   value={i?.key}>{i?.name}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div className="col-6">
                                                <Form.Item
                                                    // rules={[{required: true, message: 'Please input your last name'}]}
                                                    label="Last Name"
                                                    name="monday_last_name"
                                                >
                                                    <Select
                                                        // mode="multiple"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        allowClear
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {
                                                            listColumnsBoardSelect.length > 0 && listColumnsBoardSelect.map((i, k) => {
                                                                return (
                                                                    <Select.Option key={k}
                                                                                   value={i?.key}>{i?.name}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div className="col-6">
                                                <Form.Item
                                                    // rules={[{required: true, message: 'Please input your last name'}]}
                                                    label="Phone Number"
                                                    name="monday_phone_number"
                                                >
                                                    <Select
                                                        // mode="multiple"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        allowClear
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {
                                                            listColumnsBoardSelect.length > 0 && listColumnsBoardSelect.map((i, k) => {
                                                                return (
                                                                    <Select.Option key={k}
                                                                                   value={i?.key}>{i?.name}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div className="col-6">
                                                <Form.Item
                                                    // rules={[{required: true, message: 'Please input your last name'}]}
                                                    label="Property Address"
                                                    name="monday_property_address"
                                                >
                                                    <Select
                                                        // mode="multiple"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        allowClear
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {
                                                            listColumnsBoardSelect.length > 0 && listColumnsBoardSelect.map((i, k) => {
                                                                return (
                                                                    <Select.Option key={k}
                                                                                   value={i?.key}>{i?.name}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div className="col-6">
                                                <Form.Item
                                                    // rules={[{required: true, message: 'Please input your last name'}]}
                                                    label="Property City"
                                                    name="monday_property_city"
                                                >
                                                    <Select
                                                        // mode="multiple"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        allowClear
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {
                                                            listColumnsBoardSelect.length > 0 && listColumnsBoardSelect.map((i, k) => {
                                                                return (
                                                                    <Select.Option key={k}
                                                                                   value={i?.key}>{i?.name}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div className="col-6">
                                                <Form.Item
                                                    // rules={[{required: true, message: 'Please input your last name'}]}
                                                    label="Property State"
                                                    name="monday_property_state"
                                                >
                                                    <Select
                                                        // mode="multiple"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        allowClear
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {
                                                            listColumnsBoardSelect.length > 0 && listColumnsBoardSelect.map((i, k) => {
                                                                return (
                                                                    <Select.Option key={k}
                                                                                   value={i?.key}>{i?.name}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div className="col-6">
                                                <Form.Item
                                                    // rules={[{required: true, message: 'Please input your last name'}]}
                                                    label="Property Zip Code"
                                                    name="monday_zip_code"
                                                >
                                                    <Select
                                                        // mode="multiple"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        allowClear
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {
                                                            listColumnsBoardSelect.length > 0 && listColumnsBoardSelect.map((i, k) => {
                                                                return (
                                                                    <Select.Option key={k}
                                                                                   value={i?.key}>{i?.name}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </>
                                    ) : null
                                }
                                <div className="col-12">
                                    <Button type="primary"
                                            htmlType="button"
                                            className="me-3 mb-0"
                                            onClick={e => actionImportMonday()}
                                    >
                                        Sync REIQ To Monday {itemContactStatusAction?.isImportMonday &&
                                        <Icon type="sync-outlined" spin/>}
                                    </Button>
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Third Party Service" key="item-5">
                                <div className="col-6">
                                    {/*<Form.Item*/}
                                    {/*    // rules={[{required: true, message: 'Please input your last name'}]}*/}
                                    {/*    label="API Key"*/}
                                    {/*    name="megalith_api_key"*/}
                                    {/*>*/}
                                    {/*    <Input/>*/}
                                    {/*</Form.Item>*/}
                                    <Form.Item label="Api Key" name="megalith_api_key">
                                        <Input disabled
                                               addonAfter={<RetweetOutlined className="icon-ant" onClick={e => {
                                                   if (itemProfile?.profile_config?.megalith_api_key) {
                                                       Modal.confirm({
                                                           title: "Notification",
                                                           icon: <ExclamationCircleOutlined/>,
                                                           content: "Are you sure to change new api key?",
                                                           onOk() {
                                                               form.setFieldsValue({megalith_api_key: (uuidv4()).replaceAll("-", "")})
                                                           },
                                                           onCancel() {
                                                               // console.log('Cancel');
                                                           },
                                                       })
                                                   } else {
                                                       form.setFieldsValue({megalith_api_key: (uuidv4()).replaceAll("-", "")})
                                                   }
                                               }}/>}/>
                                    </Form.Item>
                                </div>
                            </Tabs.TabPane>
                        </Tabs>
                        <div className="row">
                            <div className="col-12">
                                <Form.Item className="text-end mb-0">
                                    <Button type="primary" disabled={itemStatusAction?.profileConfigUpdate}
                                            htmlType="submit"
                                            className="me-3 mb-0">
                                        Save {itemStatusAction?.profileConfigUpdate &&
                                        <Icon type="sync-outlined" spin/>}
                                    </Button>
                                    <Button htmlType="button"
                                            className="mb-0"
                                            onClick={() => {
                                                // form.setFieldsValue(itemProfile?.profile_config)
                                                props.history.push("/contact")
                                            }}>
                                        Back to contact
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </Spin>
            </div>
        </>
    )
}

export default Index
