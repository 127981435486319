import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/Teams';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/teamsService';
import * as FunctionAction from '../actions/teamsAction';
import {message} from "antd";
import TeamRequest, {teamsFiltersParams} from "../mapping/Request/TeamsRequest";
import TAG_DEFINE from "../../constants/common";

function* actionGetListTeams() {
    yield takeLatest(Types.TEAMS_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(teamsFiltersParams(action.filters) || {});
        const result = yield call(FunctionService.actionTeamsList, params);
        yield put(FunctionAction.teamsListSuccess(result));
    }, errorHandle(Types.TEAMS_FAIL)))
}

function* actionGetItem() {
    yield takeLatest(Types.TEAMS_DETAIL_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.actionTeamsDetail, action.params.id);
        yield put(FunctionAction.teamsDetailSuccess(result));
    }, errorHandle(Types.TEAMS_FAIL)))
}

function* actionGetMasterTeams() {
    yield takeLatest(Types.TEAMS_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.masterTeams, action.params);
        yield put(FunctionAction.teamsMasterSuccess(result));
    }, errorHandle(Types.TEAMS_FAIL)))
}

function* createAction() {
    yield takeLatest(Types.TEAMS_CREATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new TeamRequest(action.params).exportCreate();
        const result = yield call(FunctionService.create, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
        action.params.props.history.goBack();
        yield put({type: Types.TEAMS_LIST_ACTION});
    }, errorHandle(Types.TEAMS_FAIL)))
}

function* updateAction() {
    yield takeLatest(Types.TEAMS_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new TeamRequest(action.params).exportUpdate();
        yield call(FunctionService.update, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.teamsDetailAction({id: action.params.id}));
    }, errorHandle(Types.TEAMS_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        console.log("===> ", e)
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* teamsSaga() {
    yield all([
        fork(actionGetListTeams),
        fork(createAction),
        fork(actionGetMasterTeams),
        fork(actionGetItem),
        fork(updateAction),
    ])
}
