import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    removeObjectNull, removeObjectNullFull, setParamsUrlFilter
} from "../../../utils/functions";
import {omit} from "lodash";
import TAG_DEFINE from "../../../constants/common";
import {PATHS} from "../../../constants/define";

// ============== LAYOUT ==============
import HeaderAction from "../../common/layout/HeaderAction";
import {Icon} from "../../common";
import ListComponent from "../../common/ListComponent";
import {
    Tooltip,
    Button,
    Tag,
    Form,
    Input,
    Select,
    Checkbox,
    Modal,
    Upload,
    message,
    Space,
    List,
    Popconfirm
} from 'antd'

// ============== ACTION ==============
import {
    ActionClone,
    ActionDelete,
    ActionGetList,
    ActionGetTemplateEmailList,
    ActionMasterData
} from '../../../data/hooks/template';
import {ActionUpload} from '../../../data/hooks/upload';

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectErrors,
    selectList,
    selectMasterData,
    selectTemplateEmailList, selectStatusAction
} from '../../../data/reselects/templateSelector';
import TemplateResponse, {column, filtersResponse} from "../../../data/mapping/Response/TemplateResponse";
import {ROUTE_REDIRECT} from "../../../constants/define";
import queryString from "query-string";
import {common} from "../../../data/mapping/Request/TemplateRequest";
import {FaRegClone} from "react-icons/fa";
import {MdOutlineCreateNewFolder} from "react-icons/md";
import {FcCheckmark} from "react-icons/fc";

const ModelFields = Object.getOwnPropertyNames(new TemplateResponse());
const Index = props => {
    const namePage = "template",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(column, ['action'], []),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        [form] = Form.useForm(),
        componentTable = {
            tag: {
                width: 150,
                render: (val, record) => (val || []).join(", "),
            },
            is_template: {
                width: 150,
                render: (val, record) => val ? <FcCheckmark/> : null,
            },
            status: {
                width: 150,
                render: (val, record) => Object.keys(itemMasterData).length > 0 && (itemMasterData?.status[val]),
            },
            action: {
                width: 100,
                align: 'center',
                key: 'action',
                fixed: 'right',
                render: (text, record) => (
                    <>
                        <Icon type="edit-outlined" className="cursorPointer me-2"
                              onClick={() => props.history.push((ROUTE_REDIRECT.TEMPLATE.UPDATE).replace(":id", record.id))}/>
                        <Popconfirm
                            title="Are you sure to delete this template?"
                            onConfirm={e => actionDelete({id: record?.id})}
                            onCancel={e => {
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Icon type="delete" className="cursorPointer me-2"
                                  onClick={() => {
                                  }}/>
                        </Popconfirm>
                        <Tooltip title="Clone item">
                            <FaRegClone className="cursorPointer" onClick={e => actionClone({
                                query: {
                                    "template_id": record?.id,
                                    "is_template": 0
                                }
                            })}/>
                        </Tooltip>
                    </>
                ),
            }
        };
    const [fileList, setFileList] = useState([]),
        [isFetching, setIsFetching] = useState(false),
        [isImport, setIsImport] = useState(false),
        [isShowTemplateEmail, setIsShowTemplateEmail] = useState(false),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        // ============== ACTION ==============
        getList = ActionGetList(),
        actionUpload = ActionUpload(),
        actionGetTemplateEmailList = ActionGetTemplateEmailList(),
        actionDelete = ActionDelete(),
        actionClone = ActionClone(),
        // ============== SELECT DATA ==============
        itemStatusAction = useSelector(selectStatusAction()),
        itemTemplateEmailList = useSelector(selectTemplateEmailList()),
        itemList = useSelector(selectList()),
        itemErrors = useSelector(selectErrors()),
        itemMasterData = useSelector(selectMasterData()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        if (Object.keys(queryString.parse(props.location.search)).length > 0) {
            let paramsFilters = queryString.parse(props.location.search, {arrayFormat: 'comma'});
            paramsFilters = removeObjectNullFull(paramsFilters)
            setPramsFilters(paramsFilters)
            form.setFieldsValue(paramsFilters)
            getList(paramsFilters);
        } else {
            getList();
        }
        actionGetTemplateEmailList()
    }, []);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        setResultList(itemList?.result || [])
        setPagination({
            total: itemList?.pagination?.total || 0,
            totalPage: itemList?.pagination?.total_page || 0,
            defaultCurrent: itemList?.pagination?.page_index || 1,
            pageSize: itemList?.pagination?.page_size || 10
        })
    }, [itemList]);

    const onTableChange = (pagination, filters, sorter) => {
        // let currentFilters = getCurrentFilters();
        filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        setPramsFilters(newFilters)
        setPramsFilters(newFilters)
        form.setFieldsValue(newFilters)
        setInitData({
            ...initData,
            filters: newFilters
        });
        props.history.replace(setParamsUrlFilter(ROUTE_REDIRECT.TEMPLATE.HOME, newFilters))
        getList(newFilters);
    };

    const onCreate = (e, data) => {
        if (e === TAG_DEFINE.ACTION_OPTIONS.UPDATE) {
            props.history.push((ROUTE_REDIRECT.TEMPLATE.UPDATE).replace(":id", data.id))
        } else {
            props.history.push(ROUTE_REDIRECT.TEMPLATE.CREATE)
        }
    };

    const onSearch = (val) => {
        let getFilters = filtersResponse(removeObjectNullFull({
            ...initData.filters, ...paramsFilters, ...val,
            page_index: 1
        }));
        setPramsFilters(getFilters);
        setInitData({...initData, filters: paramsFilters})
        props.history.replace(setParamsUrlFilter(ROUTE_REDIRECT.TEMPLATE.HOME, getFilters))
        getList(removeObjectNullFull(getFilters));
    };
    return <>
        <HeaderAction
            isActionSignlePage
            title="Template List"
            disabledFormatUpperCaseTitle
            isCreate
            onClick={e => onCreate(e)}
            isCustomize
        >
            <button type="button" className="btn btn-warning btn-rounded fs--14px ms-3 color--white"
                    onClick={e => {
                        setIsShowTemplateEmail(true)
                    }}>
                Create From Template
            </button>
        </HeaderAction>
        <Modal
            maskClosable={false}
            destroyOnClose
            title="Template Email List"
            visible={isShowTemplateEmail}
            footer={null}
            onCancel={() => setIsShowTemplateEmail(false)}
        >
            <List
                className="demo-loadmore-list"
                loading={itemStatusAction?.isFetchingTemplateEmail}
                itemLayout="horizontal"
                dataSource={itemTemplateEmailList}
                renderItem={(item) => (
                    <List.Item
                        actions={[<Tooltip title="Create"><MdOutlineCreateNewFolder
                            className="cursorPointer color--black" size={20}
                            onClick={e => actionClone({
                                query: {
                                    "template_id": item?.id,
                                    "is_template": 1
                                },
                                history: props.history
                            })}/></Tooltip>]}
                    >
                        {item?.title}
                    </List.Item>
                )}
            />
        </Modal>
        <div className="container-fluid px-4">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSearch}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="row bg-white py-3 align-items-center">
                    <div className="col-4">
                        <Form.Item
                            label="Search"
                            name="search"
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </div>
                    <div className="col-2">
                        <Form.Item
                            label=" "
                            name="is_owned"
                            valuePropName="checked"
                        >
                            <Checkbox>My Template</Checkbox>
                        </Form.Item>
                    </div>
                    <div className="col-2">
                        <Space>
                            <Form.Item className="" label=" ">
                                <Button type="primary" htmlType="submit">
                                    Search {isFetching && <Icon type="sync-outlined" spin/>}
                                </Button>
                            </Form.Item>
                        </Space>
                    </div>
                </div>
            </Form>
        </div>
        <ListComponent
            scroll={{x: 1000}}
            classParent="m-0 p-0 col-md-12"
            className="custom-table"
            marginTop={'0'} marginBottom={'0'}
            loading={{spinning: isFetching, size: 'large'}}
            rowKey="id"
            dataSource={getDataSource(resultList || [], initData.modelItem)}
            columns={columnsTable({
                titleTable: initData.titleItemTable,
                component: componentTable,
                itemName: namePage,
                condition: [{fieldCheck: ["is_template"], is_condition: !itemList?.is_admin}]
            })}
            pagination={{
                total: pagination.total,
                totalPage: pagination.totalPage,
                current: pagination.defaultCurrent,
                pageSize: pagination.pageSize,
                responsive: true
            }}
            onChange={onTableChange}
            componentTableList={{}}
        />
    </>
}

export default Index
